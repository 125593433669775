import React from 'react';
import { createMatch, fetchMatch, listMatches } from '../Helpers/Api/Match';
import { subscribeToMessages } from '../Helpers/Api/Message';
import { AuthContext } from '../Hooks/AuthContext';

const ApiPlayground = () => {
  const { signOut } = React.useContext(AuthContext);
  const [matches, setMatches] = React.useState([]);

  React.useEffect(() => {
    const init = async () => {
      const subscriber = await subscribeToMessages({
        handleUpdate: handleMessageCreate,
        matchID: '123ABC',
      });
      return subscriber;
    };
    const subscriber = init();

    return () => {
      subscriber.unsubscribe();
    };
  }, []);

  const handleMessageCreate = (data) => {
    const { value: { data: { onCreateMessage: message } = {} } = {} } =
      data || {};
  };

  const fetchMatches = async () => {
    const matchResponse = await listMatches();
    if (matchResponse) setMatches(matchResponse);
  };

  const fetchOneMatch = async () => {
    const matchResponse = await fetchMatch({ matchID: '123ABC' });
    if (matchResponse) setMatches([matchResponse]);
  };

  const onCreateMatch = async () => {
    const matchResponse = await createMatch();
  };

  return (
    <div>
      <button onClick={signOut}>Sign Out</button>
      <button onClick={fetchMatches}>Fetch Matches</button>
      <button onClick={fetchOneMatch}>Fetch Match Code: 123ABC</button>
      <button onClick={onCreateMatch}>Create a New Match</button>
      {matches.map(({ id, active }) => (
        <div key={id}>
          {id} - {active}
        </div>
      ))}
    </div>
  );
};

export default ApiPlayground;
