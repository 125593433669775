import { checkTruthy, get } from '../Helpers/UtilHelper';

export const TOSS_UP_QUESTION = 'Toss-Up';
export const TEAM_QUESTION = 'Team';
export const BONUS_QUESTION = 'Bonus';
export const TIMEOUT = 'Timeout';
export const MANUAL_SCORES = 'Manual-Scores';
export const DETAILED_SCORES = 'Detailed-Scores';
export const setDefaultQuestionType = ({
  settings: { items: [options] = [] } = {},
} = {}) => {
  let defaultQuestionType;
  if (!options.tossUpEnabled) {
    if (!checkTruthy(options.bonusQuestionEnabled)) {
      defaultQuestionType = TEAM_QUESTION;
    } else {
      defaultQuestionType = BONUS_QUESTION;
    }
  } else {
    defaultQuestionType = TOSS_UP_QUESTION;
  }
  return defaultQuestionType;
};

export const getTimerSecondsLeft = (timerState) => {
  if (timerState) {
    const secondsLeft = get(timerState, 'currentSecondsLeft');
    return secondsLeft;
  }
};

export const getTimerExpired = (timerState) => {
  if (timerState) {
    const timerExpired = get(timerState, 'timerExpired');
    return timerExpired;
  }
};
