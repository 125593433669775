import React from 'react';
import styled from 'styled-components';
import { MatchSettingsContext } from '../../../../Hooks/MatchSettingsContext';
import SettingsHeader from '../../../SettingsHeader';

const NameAndDate = ({ className }) => {
  const { matchName, matchDate, errors, setMatchSetting } = React.useContext(
    MatchSettingsContext,
  );

  const handleMatchNameChange = (e) => {
    setMatchSetting({
      payload: {
        matchName: e.target.value,
      },
      errorsToClear: ['matchName'],
    });
  };

  const handleMatchDateChange = (e) => {
    setMatchSetting({
      payload: {
        matchDate: e.target.value,
      },
      errorsToClear: ['matchDate'],
    });
  };

  return (
    <div className={`settings-name-container ${className}`}>
      <SettingsHeader text="Match Name and Date" />
      <div className="settings-container">
        <div className="name-container">
          <label className="label-container">
            <span className="select-label">Match Name</span>
            <input
              type="text"
              className="input-name"
              value={matchName}
              onChange={handleMatchNameChange}
              maxLength="80"
            />
          </label>
          {errors.matchName && (
            <div className="error-container">
              <p>{errors.matchName}</p>
            </div>
          )}
        </div>
        <div className="date-container">
          <label>
            <span className="select-label">Match Date</span>
            <input
              type="date"
              className="input-date"
              value={matchDate}
              onChange={handleMatchDateChange}
            />
          </label>
          {errors.matchDate && (
            <div className="error-container">
              <p>{errors.matchDate}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const StyledNameAndDate = styled(NameAndDate)`
  .settings-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 1rem;
  }

  .select-label {
  }

  .input-name {
    flex: 1;
  }

  .label-container {
    display: flex;
    padding-right: 1rem;
    align-items: left;
  }

  @media (min-width: 900px) {
    .settings-container {
      width: 100%;
      flex-direction: row;
      align-items: center;
    }

    .label-container {
      align-items: center;
    }

    .date-container {
      width: 50%;
    }

    .name-container {
      width: 50%;
    }
    .select-label {
      display: inline;
    }
  }
`;

export default StyledNameAndDate;
