import React from 'react';
import styled from 'styled-components';
import SettingsHeader from '../../../../../../Components/SettingsHeader';
import {
  ASC,
  DATE,
  DESC,
  NAME,
  sortIconToDisplay,
} from '../../../../../../Helpers/DashboardHelper';
import MatchListItem from '../MatchListItem';

const MatchList = ({
  className,
  fetchMatches,
  matchList,
  fetchMatchesComplete,
  matchListToken,
  fetchMoreMatchesComplete,
  matchSortOrder,
  matchSortType,
  deleteMatch,
}) => {
  const content = matchList.map((match) => {
    return (
      <MatchListItem
        match={match}
        fetchMatches={fetchMatches}
        key={match.id}
        deleteMatch={deleteMatch}
      />
    );
  });

  const fetchMatchesByDateOrName = (nextMatchListSortType) => {
    const nextMatchListSortOrder =
      nextMatchListSortType !== matchSortType || matchSortOrder === ASC
        ? DESC
        : ASC;
    fetchMatches({
      ignoreToken: true,
      nextMatchListSortType,
      nextMatchListSortOrder,
    });
  };

  const sortDateIcon = sortIconToDisplay({
    currentType: DATE,
    matchSortType,
    matchSortOrder,
  });

  const sortNameIcon = sortIconToDisplay({
    currentType: NAME,
    matchSortType,
    matchSortOrder,
  });

  return (
    <div className={`matchlist-container ${className}`}>
      <SettingsHeader text="Your Matches" />
      <table className="matchlist-table">
        <thead>
          <tr>
            <th className="col-sm">Match ID</th>
            <th
              className="col-lg"
              onClick={() => fetchMatchesByDateOrName(NAME)}
            >
              <div className="sort-header">
                Match Name
                {sortNameIcon}
              </div>
            </th>
            <th
              className="col-med"
              onClick={() => fetchMatchesByDateOrName(DATE)}
            >
              <div className="sort-header">
                Match Date
                {sortDateIcon}
              </div>
            </th>
            <th className="col-med">Match Status</th>
            <th className="col-med">Actions</th>
          </tr>
        </thead>
        {fetchMatchesComplete ? (
          <tbody>{content}</tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan="5" className="loading-text">
                Loading...
              </td>
            </tr>
          </tbody>
        )}
      </table>
      {matchListToken ? (
        <button
          className="loadmore-button"
          onClick={() =>
            fetchMatches({
              ignoreToken: false,
              nextMatchListSortType: matchSortType,
              nextMatchListSortOrder: matchSortOrder,
              loadMore: true,
            })
          }
        >
          {fetchMoreMatchesComplete ? 'Load More' : 'Loading...'}
        </button>
      ) : null}
    </div>
  );
};

const StyledMatchList = styled(MatchList)`
  width: 100%;
  line-height: 2rem;
  font-size: 1.6rem;
  margin: 0 auto 1rem;
  text-align: center;

  .loading-text {
    text-align: center;
    font-weight: bold;
    padding: 5rem;
  }

  .loading-container {
    display: flex;
    justify-content: center;
  }

  .matchlist-header {
    margin: 2rem auto;
    padding: 1rem;
    background-color: steelblue;
    color: white;
    border-radius: 3px;
  }

  .matchlist-table {
    width: 100%;
  }

  .sort-header:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .sort-spacer {
    display: inline-block;
    width: 16px;
    height: '16px';
  }

  .col-lg {
    width: 45%;
  }

  .col-med {
    width: 15%;
  }

  .col-sm {
    width: 10%;
  }

  th,
  td {
    text-align: center;
  }

  .loadmore-button {
    margin-top: 1rem;
  }

  @media (min-width: 900px) {
    max-width: 80%;
  }
`;

export default StyledMatchList;
