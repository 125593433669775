import React from 'react';
import styled from 'styled-components';
import { checkTruthy } from '../../../../../Helpers/UtilHelper';
import { MatchSettingsContext } from '../../../../../Hooks/MatchSettingsContext';
import NumberInput from '../../../../NumberInput';

const TimerSettings = ({ className, disabledEdits = false }) => {
  const {
    setMatchSetting,
    errors,
    bonusAnswerTimerEnabled,
    bonusAnswerSeconds,
    bonusDeliberationTimerEnabled,
    bonusDeliberationSeconds,
    bonusComputationTimerEnabled,
    bonusComputationSeconds,
  } = React.useContext(MatchSettingsContext);

  const handleBonusAnswerTimerEnabledChange = (e) => {
    setMatchSetting({
      payload: {
        bonusAnswerTimerEnabled: e.target.value,
      },
      errorsToClear: ['bonusAnswerTimerEnabled'],
    });
  };

  const handleBonusAnswerSecondsChange = (e) => {
    setMatchSetting({
      payload: {
        bonusAnswerSeconds: e.target.value,
      },
      bonusAnswerSeconds: ['resetTimerDuration'],
    });
  };

  const handleBonusDeliberationTimerEnabledChange = (e) => {
    setMatchSetting({
      payload: {
        bonusDeliberationTimerEnabled: e.target.value,
      },
      bonusDeliberationTimerEnabled: ['resetTimerDuration'],
    });
  };

  const handleBonusDeliberationSecondsChange = (e) => {
    setMatchSetting({
      payload: {
        bonusDeliberationSeconds: e.target.value,
      },
      bonusDeliberationSeconds: ['resetTimerDuration'],
    });
  };

  const handleBonusComputationTimerEnabledChange = (e) => {
    setMatchSetting({
      payload: {
        bonusComputationTimerEnabled: e.target.value,
      },
      bonusComputationTimerEnabled: ['resetTimerDuration'],
    });
  };

  const handleBonusComputationSecondsChange = (e) => {
    setMatchSetting({
      payload: {
        bonusComputationSeconds: e.target.value,
      },
      bonusComputationSeconds: ['resetTimerDuration'],
    });
  };

  return (
    <div className={`timer-settings-container ${className}`}>
      <div className="points-error-container">
        <div className="setting-container">
          <label>
            <span className="select-label">Enable Answer Timer</span>
            <select
              disabled={disabledEdits}
              value={bonusAnswerTimerEnabled}
              onChange={handleBonusAnswerTimerEnabledChange}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </label>
          <NumberInput
            disabledEdits={disabledEdits}
            shouldRender={checkTruthy(bonusAnswerTimerEnabled)}
            value={bonusAnswerSeconds}
            onChange={handleBonusAnswerSecondsChange}
            label="Seconds"
          />
        </div>
        {errors.bonusAnswerSecondsError && (
          <div className="error-container">
            <p>{errors.bonusAnswerSecondsError}</p>
          </div>
        )}
      </div>
      <div className="points-error-container">
        <div className="setting-container">
          <label>
            <span className="select-label">Enable Deliberation Timer</span>
            <select
              disabled={disabledEdits}
              value={bonusDeliberationTimerEnabled}
              onChange={handleBonusDeliberationTimerEnabledChange}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </label>
          <NumberInput
            disabledEdits={disabledEdits}
            shouldRender={checkTruthy(bonusDeliberationTimerEnabled)}
            value={bonusDeliberationSeconds}
            onChange={handleBonusDeliberationSecondsChange}
            label="Seconds"
          />
        </div>
        {errors.bonusDeliberationSecondsError && (
          <div className="error-container">
            <p>{errors.bonusDeliberationSecondsError}</p>
          </div>
        )}
      </div>
      <div className="points-error-container">
        <div className="setting-container">
          <label>
            <span className="select-label">Enable Computation Timer</span>
            <select
              disabled={disabledEdits}
              value={bonusComputationTimerEnabled}
              onChange={handleBonusComputationTimerEnabledChange}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </label>
          <NumberInput
            disabledEdits={disabledEdits}
            shouldRender={checkTruthy(bonusComputationTimerEnabled)}
            value={bonusComputationSeconds}
            onChange={handleBonusComputationSecondsChange}
            label="Seconds"
          />
        </div>
        {errors.bonusComputationSecondsError && (
          <div className="error-container">
            <p>{errors.bonusComputationSecondsError}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const StyledTimerSettings = styled(TimerSettings)`
  .setting-container {
    border: 0.5px solid #f5f5f5;
    margin: 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .timer-input {
    width: 5rem;
  }

  @media (min-width: 900px) {
    .setting-container {
      border: none;
      margin: 0;
      flex-direction: row;
    }
  }
`;

export default StyledTimerSettings;
