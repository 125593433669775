import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { WindowClose } from '../../Helpers/IconHelper';
import { parseMinutesAndSeconds } from '../../Helpers/UtilHelper';

const EditTimerModal = ({
  currentTime,
  className,
  toggleModal,
  handleEditTimerSubmit,
  timerHeading = '',
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { minutes, seconds } = parseMinutesAndSeconds(currentTime);
  const [minutesDisplay, setMinutesDisplay] = React.useState(minutes);
  const [secondsDisplay, setSecondsDisplay] = React.useState(seconds);
  const [errors, setErrors] = React.useState({});

  const validateMinutes = (minutes) => {
    if (minutes === '' || minutes === null) {
      setErrors((prev) => ({
        ...prev,
        minutes: 'Please enter a numeric minute value',
      }));
      return true;
    }
    if (minutes < 0) {
      setErrors((prev) => ({
        ...prev,
        minutes: 'Please enter a positive minute value',
      }));
      return true;
    }
    return false;
  };

  const validateSeconds = (seconds) => {
    if (seconds === '' || seconds === null) {
      setErrors((prev) => ({
        ...prev,
        seconds: 'Please enter a numeric second value',
      }));
      return true;
    }
    if (seconds < 0) {
      setErrors((prev) => ({
        ...prev,
        seconds: 'Please enter a positive second value',
      }));
      return true;
    }
    if (seconds > 59) {
      setErrors((prev) => ({
        ...prev,
        seconds: 'Please enter a second value less than 60',
      }));
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    setErrors({});
    const hasMinutesErrors = validateMinutes(minutesDisplay);
    const hasSecondsErrors = validateSeconds(secondsDisplay);
    if (hasMinutesErrors || hasSecondsErrors) {
      setIsSubmitting(false);
      return;
    }
    const totalSecondsLeft =
      parseInt(minutesDisplay, 10) * 60 + parseInt(secondsDisplay, 10);
    handleEditTimerSubmit(totalSecondsLeft);
    setIsSubmitting(false);
    toggleModal((prev) => !prev);
  };

  const handleMinutesChange = (e) => {
    let { minutes, ...remainingErrors } = errors;
    setErrors({ ...remainingErrors });
    setMinutesDisplay(e.target.value);
  };

  const handleSecondsChange = (e) => {
    let { seconds, ...remainingErrors } = errors;
    setErrors({ ...remainingErrors });
    setSecondsDisplay(e.target.value);
  };

  return ReactDOM.createPortal(
    <div className={`${className}`}>
      <div className="modal-overlay" onClick={toggleModal}></div>
      <div className="modal">
        <div className="modal-header">
          <p className="modal-text">{timerHeading}</p>
          <button className="modal-close-button" onClick={toggleModal}>
            <WindowClose />
          </button>
        </div>
        <div className="timer-container">
          <div className="minutes-container">
            <label>
              <span className="select-label">Minutes</span>
              <input
                type="number"
                min="0"
                className="input-time"
                value={minutesDisplay}
                onChange={handleMinutesChange}
              />
            </label>
            {errors.minutes && (
              <div className="error-container">
                <p>{errors.minutes}</p>
              </div>
            )}
          </div>
          <div className="seconds-container">
            <label>
              <span>Seconds</span>
              <input
                type="number"
                min="0"
                max="59"
                className="input-time"
                value={secondsDisplay}
                onChange={handleSecondsChange}
              />
            </label>
            {errors.seconds && (
              <div className="error-container">
                <p>{errors.seconds}</p>
              </div>
            )}
          </div>
        </div>

        {/* {errors && <div className="error-display">{errors}</div>} */}
        <div className="modal-button-container">
          <button
            disabled={isSubmitting}
            className="button-yes"
            onClick={handleSubmit}
          >
            {isSubmitting ? 'Loading...' : 'Save'}
          </button>
          <button className="button-no" onClick={toggleModal}>
            Cancel
          </button>
        </div>
      </div>
    </div>,
    document.body,
  );
};

const StyledEditTimerModal = styled(EditTimerModal)`
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    background: white;
    margin: 0 auto;
    border-radius: 3px;
    width: 40rem;
    padding: 2rem;
    transition: all 0.5s;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .modal-text {
    font-size: 1.5em;
  }

  .minutes-container,
  .seconds-container {
    width: 35%;
  }

  .modal-close-button {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    background-color: white;
    color: steelblue;
    cursor: pointer;
    border: none;
    padding: 0;
    border-radius: 15%;
    border: 0px;
    margin: 0;
    height: 2rem;

    &:hover {
      background-color: steelblue;
      color: white;
    }
  }

  .timer-container {
    display: flex;
  }

  .input-time {
    width: 6rem;
  }

  .modal-button-container {
    display: flex;
    justify-content: center;
    margin: 1rem;
  }

  .button-yes,
  .button-no {
    margin: 1rem;
  }

  .error-display {
    margin: 1rem;
    text-align: center;
    color: red;
    font-size: 1.25rem;
  }
`;

export default StyledEditTimerModal;
