import React from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../Hooks/AuthContext';

const ShareTeamsForm = ({
  className = '',
  enableShareTeams,
  newGroup,
  onChangeNewGroup,
  onChangeSelectedGroup,
  selectedGroup,
  setEnableShareTeams,
  teamGroups,
  handleRemoveGroupError,
}) => {
  const { isAdmin } = React.useContext(AuthContext);
  if (!isAdmin) return null;

  const shouldEnableShareTeams = (e) => {
    setEnableShareTeams(e.target.value === 'yes');
    handleRemoveGroupError();
  };

  return (
    <div className={`share-teams-form ${className}`}>
      <label className="label-container">
        <span>Share team(s) with all hosts?</span>
        <select
          onChange={shouldEnableShareTeams}
          value={enableShareTeams ? 'yes' : 'no'}
        >
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </label>
      {enableShareTeams && (
        <div className="group-options">
          <label className="label-container">
            <span className="group-text">Add to an existing group:</span>
            <select
              className="group-dropdown"
              onChange={onChangeSelectedGroup}
              value={selectedGroup}
            >
              <option value={''}>Select a group</option>
              {teamGroups &&
                teamGroups.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
            </select>
          </label>
          <div className="separator">OR</div>
          <label className="label-container">
            <span>Create a new group:</span>
            <input
              type="text"
              onChange={onChangeNewGroup}
              placeholder="New group name"
              value={newGroup}
              maxLength="80"
            />
          </label>
        </div>
      )}
    </div>
  );
};

const styledShareTeamsForm = styled(ShareTeamsForm)`
  margin-top: 2rem;
  max-width: 50rem;
  margin: 0 auto;
  text-align: center;

  .label-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    input,
    select {
      margin-bottom: 0;
    }
  }

  .group-text {
    line-height: 1.75rem;
    margin-bottom: 0.25rem;
  }

  .group-dropdown {
    margin: 0.25rem 0;
    max-width: 16rem;
    text-overflow: ellipsis;
  }

  .group-options {
    display: flex;
    align-items: flex-end;
    margin: 0.5rem 0;

    .label-container {
      flex-direction: column;

      input,
      select {
        margin: 0;
      }
    }

    .separator {
      align-self: flex-start;
      margin: 0.5rem;
      text-decoration: underline;
    }
  }
`;

export default styledShareTeamsForm;
