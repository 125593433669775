import { checkTruthy } from '../Helpers/UtilHelper';

export const validateMatchSettings = ({
  computationTime,
  earlyCorrectAnswerPoints,
  earlyWrongAnswerPoints,
  preserveMinTimeEnabled,
  preserveMinTimeSeconds,
  matchDate,
  matchName,
  resetTimerDuration,
  resetTimerOption,
  ringInTimer,
  roundMinutes,
  roundSeconds,
  roundTimerEnabled,
  showComputationTime,
  showEarlyCorrectAnswerBonus,
  showEarlyWrongAnswerPenalty,
  showResetTimer,
  showWrongAnswerPenalty,
  startingPoints,
  tossUpEnabled,
  tossUpPoints,
  wrongAnswerPenaltyPoints,
  playerCount,
  selectedTeams,
  teamQuestionEnabled,
  teamDeliberationMinutes,
  teamDeliberationSeconds,
  bonusQuestionEnabled,
  bonusPointsPerPart,
  bonusAnswerTimerEnabled,
  bonusAnswerSeconds,
  bonusDeliberationTimerEnabled,
  bonusDeliberationSeconds,
  bonusComputationTimerEnabled,
  bonusComputationSeconds,
  timeoutsEnabled,
  timeouts,
  tossUpAnswerSeconds,
  tossUpAnswerEnabled,
}) => {
  const errors = {};

  const matchNameError = validateMatchName(matchName);
  if (matchNameError) errors.matchName = matchNameError;

  const matchDateError = validateMatchDate(matchDate);
  if (matchDateError) errors.matchDate = matchDateError;

  const startingPointsError = validatePoints(startingPoints);
  if (startingPointsError) errors.startingPoints = startingPointsError;

  if (roundTimerEnabled) {
    const roundMinutesError = validateMinutes(roundMinutes);
    const roundSecondsError = validateSeconds(roundSeconds);
    if (roundMinutesError) errors.roundMinutes = roundMinutesError;
    if (roundSecondsError) errors.roundSeconds = roundSecondsError;
  }

  if (
    !tossUpEnabled &&
    !teamQuestionEnabled &&
    !checkTruthy(bonusQuestionEnabled)
  ) {
    errors.tossUpEnabled = 'Please enable at least one Question Type';
    errors.teamQuestionEnabled = 'Please enable at least one Question Type';
    errors.bonusQuestionEnabled = 'Please enable at least one Question Type';
  }

  if (teamQuestionEnabled) {
    const deliberationMinutesError = validateMinutes(teamDeliberationMinutes);
    if (deliberationMinutesError)
      errors.teamDeliberationMinutes = deliberationMinutesError;

    const deliberationSecondsError = validateSeconds(teamDeliberationSeconds);
    if (deliberationSecondsError)
      errors.teamDeliberationSeconds = deliberationSecondsError;
  }

  if (tossUpEnabled) {
    const tossUpPointsError = validatePoints(tossUpPoints);
    if (tossUpPointsError) errors.tossUpPoints = tossUpPointsError;

    const earlyCorrectAnswerPointsError = validatePoints(
      earlyCorrectAnswerPoints,
      showEarlyCorrectAnswerBonus === 'yes',
    );
    if (earlyCorrectAnswerPointsError)
      errors.earlyCorrectAnswerPoints = earlyCorrectAnswerPointsError;

    // TODO: Update validation for wrong answer penalty given the new dropdown values
    const wrongAnswerPenaltyPointsError = validatePoints(
      wrongAnswerPenaltyPoints,
      showWrongAnswerPenalty === 'custom',
    );
    if (wrongAnswerPenaltyPointsError)
      errors.wrongAnswerPenaltyPoints = wrongAnswerPenaltyPointsError;

    const earlyWrongAnswerPointsError = validatePoints(
      earlyWrongAnswerPoints,
      showEarlyWrongAnswerPenalty === 'yes',
    );
    if (earlyWrongAnswerPointsError)
      errors.earlyWrongAnswerPoints = earlyWrongAnswerPointsError;

    const ringInTimerError = validateSecondsUnlimited(ringInTimer);
    if (ringInTimerError) errors.ringInTimer = ringInTimerError;

    const computationTimeError = validateSecondsUnlimited(
      computationTime,
      showComputationTime === 'yes',
    );
    if (computationTimeError) errors.computationTime = computationTimeError;

    const resetTimerError = validateSeconds(
      resetTimerDuration,
      showResetTimer === 'yes' && resetTimerOption === 'custom',
    );

    if (resetTimerError) errors.resetTimerDuration = resetTimerError;

    const tossUpAnswerTimerError = validateSeconds(
      tossUpAnswerSeconds,
      tossUpAnswerEnabled === 'yes',
    );

    if (tossUpAnswerTimerError)
      errors.tossUpAnswerSeconds = tossUpAnswerTimerError;

    const preserveMinTimeSecondsError = validateSeconds(
      preserveMinTimeSeconds,
      preserveMinTimeEnabled === 'yes',
    );

    if (preserveMinTimeSecondsError)
      errors.preserveMinTimeSeconds = preserveMinTimeSecondsError;
  }

  if (checkTruthy(bonusQuestionEnabled)) {
    const bonusPointsPerPartError = validatePoints(bonusPointsPerPart, true);
    if (bonusPointsPerPartError)
      errors.bonusPointsPerPartError = bonusPointsPerPartError;

    const bonusAnswerSecondsError = validateSecondsUnlimited(
      bonusAnswerSeconds,
      bonusAnswerTimerEnabled,
    );
    if (bonusAnswerSecondsError)
      errors.bonusAnswerSecondsError = bonusAnswerSecondsError;

    const bonusDeliberationSecondsError = validateSecondsUnlimited(
      bonusDeliberationSeconds,
      bonusDeliberationTimerEnabled,
    );
    if (bonusDeliberationSecondsError)
      errors.bonusDeliberationSecondsError = bonusDeliberationSecondsError;

    const bonusComputationSecondsError = validateSeconds(
      bonusComputationSeconds,
      bonusComputationTimerEnabled,
    );
    if (bonusComputationSecondsError)
      errors.bonusComputationSecondsError = bonusComputationSecondsError;
  }

  const teamsError = validateTeams(playerCount, selectedTeams);
  if (teamsError) errors.teamsError = teamsError;

  const timeoutsError = validateTimeouts(timeoutsEnabled, timeouts);
  if (timeoutsError) errors.timeouts = timeoutsError;

  return errors;
};

export const isEmpty = (value) => !value || value === '';

const validateTimeouts = (timeoutsEnabled, timeouts) => {
  if (!checkTruthy(timeoutsEnabled)) return null;
  let timeoutsError = null;
  (timeouts || []).forEach((timeout) => {
    const { duration, name } = timeout || {};
    if (duration === '' || duration === null) {
      timeoutsError = 'Please enter a numeric seconds value';
    }
    if (duration <= 0) {
      timeoutsError = 'Please enter a positive seconds value';
    }
    if (isEmpty(name)) {
      timeoutsError = 'Please enter a name for every timeout';
    }
  });
  return timeoutsError;
};

const validateTeams = (playerCount, selectedTeams) => {
  const allTeamsHaveId = selectedTeams.reduce(
    (isTrue, team) => isTrue && !isEmpty(team.teamID),
    true,
  );
  // all teams have teamID that is not an empty string
  if (!allTeamsHaveId) {
    return 'Please fill all teams';
  }
  const allTeamsHavePlayers = selectedTeams.reduce((isTrue, team) => {
    let teamHasPlayers = true;
    for (let k = 0; k < playerCount; k++) {
      teamHasPlayers = teamHasPlayers && !isEmpty(team.players[k]);
    }
    return isTrue && teamHasPlayers;
  }, true);
  if (!allTeamsHavePlayers) {
    return `Please select ${playerCount} players for each team`;
  }
  // teamIDs are unique
  const uniqueTeamIds = new Set(selectedTeams.map((team) => team.teamID));
  if (uniqueTeamIds.size !== selectedTeams.length) {
    return 'Please make sure all teams are different';
  }
  // all playerIDs are unique
  const playerIds = selectedTeams.reduce((ids, team) => {
    return [
      ...ids,
      ...Object.values(team.players).map((player) => player.playerID),
    ];
  }, []);
  const uniquePlayerIds = new Set(playerIds);
  if (playerIds.length !== uniquePlayerIds.size) {
    return 'Please select unique players';
  }
  // all deviceIDs are unique
  const deviceIds = selectedTeams.reduce((ids, team) => {
    return [
      ...ids,
      ...Object.values(team.players).map((player) => player.deviceID),
    ];
  }, []);
  const uniqueDeviceIds = new Set(deviceIds);
  if (deviceIds.length !== uniqueDeviceIds.size) {
    return 'Please select unique devices';
  }
};

const validateMatchName = (matchName) => {
  if (matchName === '' || matchName === null) {
    return 'Please enter a match name';
  }
};

const validateMatchDate = (matchDate) => {
  if (matchDate === '' || matchDate === null) {
    return 'Please enter a valid date';
  }
};

const validatePoints = (points, isEnabled = true) => {
  if (!isEnabled) return;
  if (points === '' || points === null) {
    return 'Please enter a valid point value';
  }
  if (isNaN(points)) {
    return 'Please enter a number point value';
  }
};

const validateMinutes = (minutes) => {
  if (minutes === '' || minutes === null) {
    return 'Please enter a numeric minute value';
  }
  if (minutes < 0) {
    return 'Please enter a positive minute value';
  }
};

const validateSeconds = (seconds, isEnabled = true) => {
  if (!isEnabled) return;
  if (seconds === '' || seconds === null) {
    return 'Please enter a numeric second value';
  }
  if (seconds < 0) {
    return 'Please enter a positive second value';
  }
  if (seconds > 59) {
    return 'Please enter a second value less than 60';
  }
};

const validateSecondsUnlimited = (seconds, isEnabled = true) => {
  if (!isEnabled) return;
  if (seconds === '' || seconds === null) {
    return 'Please enter a numeric second value';
  }
  if (seconds < 0) {
    return 'Please enter a positive second value';
  }
};
