import React from 'react';
import styled from 'styled-components';
import SettingsHeader from '../../../../../../Components/SettingsHeader';
import { get } from '../../../../../../Helpers/UtilHelper';
import TeamListItem from '../TeamListItem';

const TeamList = ({
  className,
  teamList,
  setTeamList,
  fetchTeamsComplete,
  teamListToken,
  fetchTeams,
  resetAndRefetchTeams,
  headerText,
  isSharedTeams = false,
  teamGroups = [],
  selectedGroupID,
  sharedTeamsList,
  setSelectedGroupID,
  getSharedTeams,
  setSharedTeamsList,
  isLoadingSharedGroups,
}) => {
  const teamsDisplay =
    teamList.length === 0 || !teamList ? (
      <tr>
        <td colSpan="3" className="team-error">
          No Teams Available
        </td>
      </tr>
    ) : (
      teamList.map((team) => {
        const matches = get(team, 'matches.items');
        return (
          <TeamListItem
            key={team.id}
            team={team}
            setTeamList={setTeamList}
            resetAndRefetchTeams={resetAndRefetchTeams}
            matches={matches}
          />
        );
      })
    );

  let sharedTeamsDisplay;
  if (isLoadingSharedGroups) {
    sharedTeamsDisplay = (
      <tr>
        <td colSpan="3" className="team-error">
          Loading...
        </td>
      </tr>
    );
  } else if (
    !sharedTeamsList ||
    sharedTeamsList.length === 0 ||
    selectedGroupID === ''
  ) {
    sharedTeamsDisplay = (
      <tr>
        <td colSpan="3" className="team-error">
          No Teams Available
        </td>
      </tr>
    );
  } else {
    sharedTeamsDisplay = sharedTeamsList.map((team) => {
      const matches = get(team, `team.matches.items`);
      const key = get(team, `team.id`);
      const teamObj = get(team, `team`);
      return (
        <TeamListItem
          key={key}
          team={teamObj}
          setTeamList={setSharedTeamsList}
          resetAndRefetchTeams={resetAndRefetchTeams}
          matches={matches}
          isSharedTeams={isSharedTeams}
          setSharedTeamsList={setSharedTeamsList}
          sharedTeamsList={sharedTeamsList}
        />
      );
    });
  }

  const handleGroupChange = (e) => {
    const selectedGroupID = e.currentTarget.value;
    setSelectedGroupID(selectedGroupID);
    if (selectedGroupID !== '') {
      getSharedTeams(selectedGroupID);
    }
  };

  return (
    <div className={`teamlist-container ${className}`}>
      <SettingsHeader text={headerText} />
      {isSharedTeams && teamGroups && (
        <select
          className="dropdown"
          onChange={handleGroupChange}
          value={selectedGroupID}
        >
          <option value={''}>Select a Group</option>
          {teamGroups.map(({ id, name } = {}) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </select>
      )}
      {fetchTeamsComplete ? (
        <table className="teamlist-table">
          <tbody>
            <tr>
              <th>Team Name</th>
              <th>Number of Players</th>
              <th>Actions</th>
            </tr>
            {isSharedTeams ? sharedTeamsDisplay : teamsDisplay}
          </tbody>
        </table>
      ) : (
        <p className="loading-text">Loading...</p>
      )}
      {teamListToken ? (
        <button
          className="loadmore-button"
          onClick={() => fetchTeams(teamListToken)}
        >
          Load More
        </button>
      ) : null}
    </div>
  );
};

const StyledTeamList = styled(TeamList)`
  width: 100%;
  line-height: 2rem;
  font-size: 1.6rem;
  margin: 0 auto 1rem;
  text-align: center;

  .teamlist-table {
    width: 100%;
  }

  th,
  td {
    text-align: center;
  }

  .loadmore-button {
    margin-top: 1rem;
  }

  .loading-text {
    text-align: center;
    font-weight: bold;
    margin: 5rem;
  }

  .team-error {
    width: 100%;
    font-weight: bold;
    text-align: center;
  }

  @media (min-width: 900px) {
    ${({ isAdmin }) =>
      isAdmin &&
      `
     margin: 0 1rem;
    `}
    max-width: 80%;
  }
`;

export default StyledTeamList;
