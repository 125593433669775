export const SUBMIT_BUTTON_TEXT = 'Subscribe';
export const SIGN_UP_FORM_COOKIE_NAME = 'SIGN_UP_FORM_COOKIE_NAME';

const SIGN_UP_FORM_COOKIE_DURATION_MS = 1000 * 60 * 15; // 15 min

export const getSignUpFormCookieExpiration = () => {
  const expires = new Date();
  expires.setTime(expires.getTime() + SIGN_UP_FORM_COOKIE_DURATION_MS);
  return expires;
};

export const validateFields = ({
  bypassCode,
  firstName,
  lastName,
  organization,
  level,
  state,
  email,
  password,
  passwordConfirm,
  selectedProductId,
  setError,
  setFieldErrors,
}) => {
  const newFieldErrors = [];
  const validate = (value, label) => {
    if (!value) newFieldErrors.push(label);
  };
  validate(firstName, 'firstName');
  validate(lastName, 'lastName');
  validate(organization, 'organization');
  validate(level, 'level');
  validate(state, 'state');
  validate(email, 'email');
  validate(password, 'password');
  validate(passwordConfirm, 'passwordConfirm');
  if (!bypassCode) validate(selectedProductId, 'selectedProductId');
  if (newFieldErrors.length > 0) {
    setError('Please complete the required fields.');
    setFieldErrors(newFieldErrors);
    return false;
  }
  if (password !== passwordConfirm) {
    setError('Passwords must match.');
    setFieldErrors(['password', 'passwordConfirm']);
    return false;
  }
  if (password.length < 6) {
    setError('Password must contain at least 6 characters.');
    setFieldErrors(['password', 'passwordConfirm']);
    return false;
  }
  return true;
};

export const STATES = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];
