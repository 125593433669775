import React, { createContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import {
  fetchMatch as fetchMatchHelper,
  subscribeToMatchPlayerUpdates,
  updateMatchPlayersInRoom,
  updateNewMatchSettings,
} from '../Helpers/Api/Match';
import {
  subscribeToDeletedScores,
  subscribeToNewScores,
} from '../Helpers/Api/Score';
import { SocketContext } from '../Hooks/SocketContext';

export const MatchClientContext = createContext(undefined);
export const { Provider, Consumer: MatchClientConsumer } = MatchClientContext;

export const MatchClientProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [match, setMatch] = useState(null);
  const [playerData, setPlayerData] = useState(null);
  const [isFetchingMatch, setFetchingMatch] = useState(true);
  const [isFetchingPlayerData, setFetchingPlayerData] = useState(true);
  const [isPlayerDuplicate, setIsPlayerDuplicate] = useState(false);

  const { leaveRoom } = React.useContext(SocketContext);

  React.useEffect(() => {
    const fetchMatchAndPlayerDataFromCookies = async () => {
      const { matchID: cookieMatchID, playerID: cookiePlayerID } =
        cookies || {};
      if (cookieMatchID && cookiePlayerID) {
        const match = await fetchMatch({ matchID: cookieMatchID });
        const { players: { items: playerList = [] } = {} } = match || {};
        const player = playerList.find(
          ({ playerID }) => playerID === cookiePlayerID,
        );
        if (player) setPlayerData(player);
      }
      setFetchingMatch(false);
      setFetchingPlayerData(false);
    };
    fetchMatchAndPlayerDataFromCookies();
  }, []);

  const { playerID: playerDataID } = playerData || {};
  const { id: matchID, user: matchUser, scores: { items: scoreItems } = {} } =
    match || {};
  const [scoreList, setScoreList] = React.useState([]);
  const [scoreSubscriber, setScoreSubscriber] = React.useState(null);
  const [scoreDeleteSubscriber, setScoreDeleteSubscriber] = useState(null);
  const [matchPlayerSubscriber, setMatchPlayerSubscriber] = React.useState(
    null,
  );
  React.useEffect(() => {
    const init = async () => {
      // save initial scores to state
      setScoreList(scoreItems);

      // subscribe to score creates to receive new scores
      if (scoreSubscriber) scoreSubscriber.unsubscribe();
      const newScoreSubscriber = await subscribeToNewScores({
        handleUpdate: handleNewScore,
        matchID,
      });
      setScoreSubscriber(newScoreSubscriber);

      // subscribe to score deletes to receive new scores
      if (scoreDeleteSubscriber) scoreDeleteSubscriber.unsubscribe();
      const newScoreDeleteSubscriber = await subscribeToDeletedScores({
        handleUpdate: handleDeleteScore,
        matchID,
      });
      setScoreDeleteSubscriber(newScoreDeleteSubscriber);

      // subscribe to matchPlayer updates to see who is in the match
      if (matchPlayerSubscriber) matchPlayerSubscriber.unsubscribe();
      const newPlayerSubscriber = await subscribeToMatchPlayerUpdates({
        handleUpdate: handleMatchPlayerUpdate,
        matchID,
        user: matchUser,
      });
      setMatchPlayerSubscriber(newPlayerSubscriber);
    };
    if (matchID) {
      init();
      // TODO: TEST / REFACTOR THIS!!!
      // fix scores after phone sleeps:
      // document.addEventListener('visibilitychange', () => {
      //   if (!document.hidden) {
      //     console.log('Not hidden');
      //     init();
      //     fetchMatch({ matchID });
      //   }
      // });
    }

    return () => {
      if (scoreSubscriber) scoreSubscriber.unsubscribe();
      if (scoreDeleteSubscriber) scoreDeleteSubscriber.unsubscribe();
      if (matchPlayerSubscriber) matchPlayerSubscriber.unsubscribe();
    };
  }, [matchID]);

  const handleNewScore = (data) => {
    // save new score to state
    const {
      value: { data: { onCreateScorePublic: newScore = {} } = {} },
    } = data || {};
    setScoreList((prev) => [...prev, newScore]);
  };

  const handleDeleteScore = (data) => {
    const {
      value: { data: { onDeleteScorePublic: deletedScore = {} } = {} },
    } = data || {};
    // save deleted score to state
    setScoreList((prev) => {
      return prev.filter((score) => {
        return score.id !== deletedScore.id;
      });
    });
  };

  const handleMatchPlayerUpdate = (data) => {
    // update which players are in the match
    const {
      value: {
        data: { onUpdateMatchPlayerPublic: updatedMatchPlayer = {} } = {},
      } = {},
    } = data || {};
    if (updatedMatchPlayer.matchID !== matchID) return;
    updateMatchPlayersInRoom({ updatedMatchPlayer, setMatchCb: setMatch });
  };

  const handleMatchSettingsUpdate = (settings) => {
    updateNewMatchSettings({ settings, setMatchCb: setMatch });
  };

  const fetchMatch = async ({ matchID }) => {
    const newMatch = await fetchMatchHelper({ matchID });
    if (!newMatch) return null;
    setMatch(newMatch);
    setCookie('matchID', matchID, { path: '/' });
    return newMatch;
  };

  const playerLogin = ({ selectedPlayerID }) => {
    const { players: { items: playerList = [] } = {} } = match || {};
    const player = playerList.find(
      ({ playerID }) => playerID === selectedPlayerID,
    );
    if (!player) return;
    setCookie('playerID', selectedPlayerID, { path: '/' });
    setPlayerData(player);

    // TODO: update MatchPlayer record to save to DB that this player has joined (ADD ASYNC-AWAIT to prevent navigating too soon where playerLogin is called)
  };

  const playerLogout = (skipMessage = false) => {
    if (match && match.id && !skipMessage) {
      leaveRoom({
        matchID: match.id,
        playerID: playerDataID,
      });
    }
    removeCookie('matchID', { path: '/' });
    removeCookie('playerID', { path: '/' });
  };

  const value = {
    fetchMatch,
    isFetchingMatch,
    isFetchingPlayerData,
    isPlayerDuplicate,
    match,
    playerData,
    playerLogin,
    playerLogout,
    setIsPlayerDuplicate,
    setPlayerData,
    scoreList,
    handleMatchSettingsUpdate,
  };
  return <Provider value={value}>{children && children}</Provider>;
};
