import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LinkButton } from '../../Components/Button';
import ConnectionTest from '../../Components/ConnectionTest';
import IFrameModal from '../../Components/IFrameModal';
import { InfoModal } from '../../Components/Modal';

const HeaderUnauthed = ({
  className,
  disableHomeLink = false,
  hideHostLogin = false,
  showPlayerLogin = false,
  showContactUs = false,
  showTestConnection = false,
}) => {
  const [showContactModal, setShowContactModal] = React.useState(false);
  const [showTestConnectionModal, setShowTestConnectionModal] =
    React.useState(false);

  let logo = (
    <Link className="home" to="/">
      <img
        alt="QuizBowl PRO Logo"
        className="logo-image"
        src="/quizbowl-pro-cowboys-logo.png"
      />
    </Link>
  );
  if (disableHomeLink) {
    logo = (
      <img
        alt="QuizBowl PRO Logo"
        className="logo-image"
        src="/quizbowl-pro-cowboys-logo.png"
      />
    );
  }

  const toggleContactModal = () => {
    setShowContactModal((prev) => !prev);
  };

  const toggleTestConnectionModal = () => {
    setShowTestConnectionModal((prev) => !prev);
  };

  return (
    <div className={className}>
      {logo}
      <div>
        {showTestConnection && (
          <LinkButton onClick={toggleTestConnectionModal}>
            Test Connection
          </LinkButton>
        )}
        {showContactUs && (
          <LinkButton className="contact-button" onClick={toggleContactModal}>
            Contact Us
          </LinkButton>
        )}
        {!hideHostLogin && (
          <Link className="host-login" to="host/sign-in">
            Host Login
          </Link>
        )}
        {showPlayerLogin && (
          <Link className="player-login" to="/">
            Join a Match
          </Link>
        )}
      </div>
      {showContactModal && (
        <IFrameModal
          toggleModal={toggleContactModal}
          link={'https://www.quizbowlsystems.com/contact.html'}
        />
      )}
      {showTestConnectionModal && (
        <InfoModal toggleModal={toggleTestConnectionModal}>
          <ConnectionTest />
        </InfoModal>
      )}
    </div>
  );
};

const StyledHeaderUnauthed = styled(HeaderUnauthed)`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 5rem;
  width: 100%;

  .logo-image {
    max-width: 100%;
  }

  .home {
    max-width: 50%;
    margin: 3rem;
  }

  .host-login {
    font-size: 1.75rem;
  }

  .player-login {
    position: absolute;
    top: 7rem;
    right: 1.5rem;
    font-size: 1.75rem;
  }

  .contact-button {
    display: none;
  }

  @media (min-width: 900px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .contact-button {
      display: block;
      margin: 5px auto;
    }

    .logo-image {
      max-width: 100%;
    }

    .host-login {
      position: absolute;
      top: 5rem;
      right: 5rem;
    }

    .player-login {
      position: absolute: 
      top: 7rem;
      right: 4.5rem;
    }
  }
`;

export default StyledHeaderUnauthed;
