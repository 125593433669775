import React from 'react';
import styled from 'styled-components';

const IconButton = ({ className, onClick, disabled, Icon }) => (
  <button
    className={`trash-button ${className}`}
    onClick={onClick}
    disabled={disabled}
  >
    <Icon />
  </button>
);

const styledIconButton = styled(IconButton)`
  border: 0px;
  margin-left: 0.5rem;
  font-size: 2rem;
  color: steelblue;
  background-color: white;
  padding: 0 1rem;
  margin-bottom: 0;

  &:hover {
    scale: 1.15;
    color: red;
    transition: all 0.25s;
  }

  &:focus {
    scale: 1.15;
    color: red;
    outline: 1px solid #000;
    border: none;
  }

  &:active {
    scale: 1.05;
  }

  &:disabled {
    color: gray;
    pointer-events: none;
  }
`;

export default styledIconButton;
