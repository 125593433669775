import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import BonusQuestions from '../../../../../../Components/MatchSettings/Components/BonusQuestions';
import MatchTemplateOptions from '../../../../../../Components/MatchSettings/Components/MatchTemplateOptions';
import RoundTimer from '../../../../../../Components/MatchSettings/Components/RoundTimer';
import Sounds from '../../../../../../Components/MatchSettings/Components/Sounds';
import StartingPoints from '../../../../../../Components/MatchSettings/Components/StartingPoints';
import TeamQuestions from '../../../../../../Components/MatchSettings/Components/TeamQuestions';
import Timeouts from '../../../../../../Components/MatchSettings/Components/Timeouts';
import TossUpQuestions from '../../../../../../Components/MatchSettings/Components/TossUpQuestions';
import { WindowClose } from '../../../../../../Helpers/IconHelper';

const ViewMatchTemplateModal = ({
  className,
  toggleViewMatchTemplateModal,
}) => {
  return ReactDOM.createPortal(
    <div className={`modal-container ${className}`}>
      <div
        className="modal-overlay"
        onClick={() => toggleViewMatchTemplateModal()}
      ></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-text">View Match Settings</h2>
          <button
            className="modal-close-button"
            onClick={() => toggleViewMatchTemplateModal()}
          >
            <WindowClose />
          </button>
        </div>
        <MatchTemplateOptions
          headerText="Match Setting Name"
          showNameInput
          disabledEdits
        />
        <RoundTimer disabledEdits />
        <StartingPoints disabledEdits />
        <TossUpQuestions disabledEdits />
        <TeamQuestions disabledEdits />
        <BonusQuestions disabledEdits />
        <Timeouts disabledEdits />
        <Sounds disabledEdits />
      </div>
    </div>,
    document.body,
  );
};

const StyledViewMatchTemplateModall = styled(ViewMatchTemplateModal)`
  overflow-y: hidden;

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80vh;
    width: 80vw;
    max-width: 80rem;
    margin: 0 auto;
    z-index: 3;
    background: white;
    border-radius: 3px;
    padding: 2rem;
    overflow: scroll;
  }

  .modal-text {
    font-weight: bold;
    font-size: 3rem;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
  }

  .modal-close-button {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    color: steelblue;
    background-color: white;
    cursor: pointer;
    border: none;
    padding: 0;
    border-radius: 15%;
    border: 0px;
    margin: 0;
    height: 2rem;

    &:hover {
      background-color: steelblue;
      color: white;
    }
  }

  .modal-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .match-error-container {
    margin-top: 3rem;
    border: 2px solid red;
    border-radius: 3px;
    background-color: #ffdede;
  }

  .match-error-text {
    color: red;
    margin: 1rem;
  }

  .button-create {
    margin: 3rem;
    font-size: 2rem;
  }

  @media (min-width: 900px) {
  }
`;

export default StyledViewMatchTemplateModall;
