import React from 'react';
import { classNames } from 'Helpers/UtilHelper';
import styled from 'styled-components';
import { getOrderedTeamList } from '../../Helpers/MatchHelper';
import TeamItem from './Components/TeamItem';

const Scoreboard = ({
  className,
  currentPlayerID,
  teamIdWithBonusControl = null,
  match,
  matchState,
  scoreList = [],
  startingPoints,
  teamsThatDidRingIn = [],
  isHost,
}) => {
  const {
    id: matchID,
    players: { items: playerList = [] } = {},
    teams: { items: teamList = [] } = {},
  } = match || {};
  if (!matchID) return null;

  const orderedTeamList = getOrderedTeamList(teamList);
  const numberOfTeams = teamList.length;

  return (
    <div className={classNames(`scoreboard`, {
      'grid-cols-2': numberOfTeams === 2,
      'grid-cols-3': numberOfTeams === 3,
      'grid-cols-4': [4, 7, 8].includes(numberOfTeams),
      'grid-cols-5': [5, 9, 10].includes(numberOfTeams),
      'grid-cols-6': numberOfTeams === 6,
    }, className)}>
      {orderedTeamList.map((team, index) => (
        <TeamItem
          currentPlayerID={currentPlayerID}
          playerList={playerList}
          matchState={matchState}
          scoreList={scoreList}
          startingPoints={startingPoints}
          teamsThatDidRingIn={teamsThatDidRingIn}
          team={team}
          index={index}
          key={team.id}
          isHost={isHost}
          match={match}
          teamIdWithBonusControl={teamIdWithBonusControl}
        />
      ))}
    </div>
  );
};

const StyledScoreboard = styled(Scoreboard)`
  display: grid;
  justify-content: center;
`;

export default StyledScoreboard;
