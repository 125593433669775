import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import HeaderUnauthed from '../../Components/HeaderUnauthed';
import { resetPassword, sendResetPasswordCode } from '../../Helpers/AuthHelper';
import useFormFields from '../../Hooks/useFormFields';
import ResetPasswordForm from './Components/ResetPasswordForm';
import SendCodeForm from './Components/SendCodeForm';

const ResetPassword = ({ className }) => {
  const history = useHistory();
  const [fields, handleFieldChange] = useFormFields({
    code: '',
    email: '',
    password: '',
    passwordConfirm: '',
  });
  const [error, setError] = React.useState(null);
  const [codeSent, setCodeSent] = React.useState(false);
  const [isResetting, setIsResetting] = React.useState(false);

  const validateSendCodeFields = () => {
    return fields.email.length > 0;
  };

  const validateResetPasswordFields = () => {
    const { code, email, password, passwordConfirm } = fields;
    if (email.length <= 0) return false;
    if (code.length <= 0) return false;
    if (
      password.length <= 0 ||
      password.length <= 0 ||
      password !== passwordConfirm
    )
      return false;
    return true;
  };

  const onSendCodeSubmit = async () => {
    if (!validateSendCodeFields()) return;
    setError(null);
    try {
      await sendResetPasswordCode(fields);
      setCodeSent(true);
    } catch (error) {
      setError(error.message);
      console.log('Send Code Error:', error.message);
    }
  };

  const onResetPasswordSubmit = async () => {
    if (!validateResetPasswordFields()) return;
    setError(null);
    try {
      setIsResetting(true);
      await resetPassword(fields);
      history.push('/host/sign-in');
    } catch (error) {
      setIsResetting(false);
      setError(error.message);
      console.log('Reset Password Error:', error.message);
    }
  };

  return (
    <div className={`reset-password ${className}`}>
      <HeaderUnauthed showContactUs />
      {codeSent ? (
        <ResetPasswordForm
          fields={fields}
          handleFieldChange={handleFieldChange}
          isSubmitting={isResetting}
          onSubmit={onResetPasswordSubmit}
          validateFields={validateResetPasswordFields}
        />
      ) : (
        <SendCodeForm
          fields={fields}
          handleFieldChange={handleFieldChange}
          onSubmit={onSendCodeSubmit}
          validateFields={validateSendCodeFields}
        />
      )}
      {error && <div className="error-container">{error}</div>}
    </div>
  );
};

const StyledResetPassword = styled(ResetPassword)`
  font-size: 2rem;
  line-height: 1.75rem;
  text-align: center;

  .description {
    font-weight: bold;
    font-size: 2rem;
    line-height: 3rem;
    max-width: 50rem;
    margin: 0 auto 2rem;
  }

  .form-container {
    max-width: 100%;
    width: 50rem;
    margin: 0 auto;
  }

  .input-container {
    display: flex;
    align-items: center;
    margin: 1.25rem;

    &.plans {
      min-height: 89px;
    }
  }

  .label {
    min-width: 10rem;
    text-align: end;
    line-height: 2.8rem;
    flex: 0;
  }

  .input-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (min-width: 900px) {
      flex-direction: row;
    }
  }

  .input {
    height: 30px;
    margin: 0.3rem 0 0.3rem 1rem;
    flex: 1;
    text-align: center;

    &.error {
      border-color: red;
    }
  }

  .reset-password-submit {
    margin-top: 1rem;
  }

  .error-container {
    display: block;
    font-size: 1.5rem;
    text-align: center;
    margin: 1rem;
  }
`;

export default StyledResetPassword;
