import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { createScore } from '../../Helpers/Api/Score';
import { WindowClose } from '../../Helpers/IconHelper';
import { getOtherPlayersString } from '../../Helpers/MatchHelper';

const EditScoresModal = ({
  className,
  matchID,
  teamList = [],
  toggleModal = () => {},
  scoreHeader = '',
  handleScoreSubmit = () => {},
  type = '',
  currentQuestion = null,
  playerList,
  setRecentlyEditedScores = () => {},
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [scoresToUpdate, setScoresToUpdate] = React.useState(null);
  const [error, setError] = React.useState(null);
  /*
    scoresToUpdate = {
      [teamID]: points,
      [teamID]: points,
    }
  */

  const teams = React.useMemo(() => {
    return teamList.map(({ team: { id, name } = {} }) => ({ id, name }));
  }, [teamList]);

  React.useEffect(() => {
    // init score values for controlled inputs
    const defaultScores = {};
    teams.forEach(({ id }) => (defaultScores[id] = '0'));
    setScoresToUpdate(defaultScores);

    return () => {
      setIsSubmitting(false);
    };
  }, [teams]);

  const getValidPoints = () =>
    teams
      .map(({ id: teamID }) => {
        const points = parseInt(scoresToUpdate[teamID], 10);
        return { teamID, points };
      })
      .filter(({ points }) => !isNaN(points));

  const onSave = async () => {
    setIsSubmitting(true);
    const pointsArray = getValidPoints();
    if (pointsArray.length === 0) {
      setError('There are no valid scores to save');
      setIsSubmitting(false);
      return;
    }
    const promises = pointsArray.map(({ points, teamID }) => {
      return saveScore({ points, teamID });
    });
    await Promise.all(promises);
    setRecentlyEditedScores(true);
    setIsSubmitting(false);
    toggleModal();
    handleScoreSubmit();
  };

  const saveScore = async ({ points, teamID }) => {
    const otherPlayers = getOtherPlayersString(playerList, null);

    try {
      await createScore({
        currentQuestion,
        matchID,
        otherPlayers,
        playerID: 0,
        points,
        teamID,
        type,
      });
    } catch (err) {
      console.log('Save Score Error:', err);
    }
  };

  const onInputChange = (e, id) => {
    e.persist();
    setScoresToUpdate((prev) => ({ ...prev, [id]: e.target.value }));
    if (error) setError(null);
  };

  if (!scoresToUpdate) return null;

  return ReactDOM.createPortal(
    <div className={`edit-scores-modal ${className}`}>
      <div className="modal-overlay" onClick={toggleModal}></div>
      <div className="modal">
        <div className="modal-header">
          <p className="modal-text">{scoreHeader}</p>
          <button className="modal-close-button" onClick={toggleModal}>
            <WindowClose />
          </button>
        </div>
        <div className="input-container">
          {teams.map(({ id, name }, idx) => (
            <div key={id}>
              <label className="team-container">
                <div className="team-name">{name || `Team ${idx + 1}`}</div>
                <input
                  className="team-score"
                  type="text"
                  value={scoresToUpdate[id]}
                  onChange={(e) => onInputChange(e, id)}
                />
              </label>
            </div>
          ))}
        </div>
        {error && <div className="error-display">{error}</div>}
        <div className="modal-button-container">
          <button
            disabled={isSubmitting}
            className="button-yes"
            onClick={onSave}
          >
            {isSubmitting ? 'Loading...' : 'Save'}
          </button>
          <button className="button-no" onClick={toggleModal}>
            Cancel
          </button>
        </div>
      </div>
    </div>,
    document.body,
  );
};

const StyledEditScoresModal = styled(EditScoresModal)`
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    background: white;
    margin: 0 auto;
    border-radius: 3px;
    width: 40rem;
    padding: 2rem;
    transition: all 0.5s;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .modal-text {
    font-size: 1.5em;
  }

  .modal-close-button {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    background-color: white;
    color: steelblue;
    cursor: pointer;
    border: none;
    padding: 0;
    border-radius: 15%;
    border: 0px;
    margin: 0;
    height: 2rem;

    &:hover {
      background-color: steelblue;
      color: white;
    }
  }

  .team-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 30rem;
    margin: 0 auto;
  }

  .team-name {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 30px;
  }

  .team-score {
    width: 5rem;
  }

  .modal-button-container {
    display: flex;
    justify-content: center;
    margin: 1rem;
  }

  .button-yes,
  .button-no {
    margin: 1rem;
  }

  .error-display {
    margin: 1rem;
    text-align: center;
    color: red;
    font-size: 1.25rem;
  }
`;

export default StyledEditScoresModal;
