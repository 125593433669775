import { get } from './UtilHelper';

// MATCH STATES:
export const MATCH_STATE_RING_IN_TEST_OPEN = 'MATCH_STATE_RING_IN_TEST_OPEN';
export const MATCH_STATE_RING_IN_TEST_CLOSED =
  'MATCH_STATE_RING_IN_TEST_CLOSED';
export const MATCH_STATE_TIMEOUT_OPEN = 'MATCH_STATE_TIMEOUT_OPEN';

export const MATCH_STATE_CHOOSE_QUESTION = 'MATCH_STATE_CHOOSE_QUESTION';
export const MATCH_STATE_ASKING_QUESTION = 'MATCH_STATE_ASKING_QUESTION';
export const MATCH_STATE_RING_IN_OPEN = 'MATCH_STATE_RING_IN_OPEN';
export const MATCH_STATE_RING_IN_OPEN_LIMITED_TEAMS =
  'MATCH_STATE_RING_IN_OPEN_LIMITED_TEAMS';
export const MATCH_STATE_RING_IN_PAUSED = 'MATCH_STATE_RING_IN_PAUSED';
export const MATCH_STATE_RING_IN_PAUSED_LIMITED =
  'MATCH_STATE_RING_IN_PAUSED_LIMITED';
export const MATCH_STATE_ANSWER_AND_SCORING = 'MATCH_STATE_ANSWER_AND_SCORING';

// MATCH STATES - TEAM QUESTIONS:
export const MATCH_STATE_TEAM_QUESTION_OPEN = 'MATCH_STATE_TEAM_QUESTION_OPEN';
export const MATCH_STATE_TEAM_QUESTION_STARTED =
  'MATCH_STATE_TEAM_QUESTION_STARTED';
export const MATCH_STATE_TEAM_QUESTION_PAUSED =
  'MATCH_STATE_TEAM_QUESTION_PAUSED';
export const MATCH_STATE_TEAM_QUESTION_CLOSED =
  'MATCH_STATE_TEAM_QUESTION_CLOSED';

// MATCH STATES - BONUS QUESTIONS:
export const MATCH_STATE_BONUS_QUESTION_OPEN =
  'MATCH_STATE_BONUS_QUESTION_OPEN';

// MESSAGE TYPES
export const MESSAGE_TYPE_JOIN_ROOM = 'MESSAGE_TYPE_JOIN_ROOM';
export const MESSAGE_TYPE_LEAVE_ROOM = 'MESSAGE_TYPE_LEAVE_ROOM';
export const MESSAGE_TYPE_PLAYER_DISCONNECT = 'MESSAGE_TYPE_PLAYER_DISCONNECT';
export const MESSAGE_TYPE_SYNC_PLAYER_STATE = 'MESSAGE_TYPE_SYNC_PLAYER_STATE';
export const MESSAGE_TYPE_SYNC_HOST_STATE = 'MESSAGE_TYPE_SYNC_HOST_STATE';
export const MESSAGE_TYPE_PLAYER_SWAP = 'MESSAGE_TYPE_PLAYER_SWAP';
export const MESSAGE_TYPE_SHARE_CLIENT_LIST = 'MESSAGE_TYPE_SHARE_CLIENT_LIST';
export const MESSAGE_TYPE_SET_STATE = 'MESSAGE_TYPE_SET_STATE';

export const MESSAGE_TYPE_START_RING_IN_TEST =
  'MESSAGE_TYPE_START_RING_IN_TEST';
export const MESSAGE_TYPE_RING_IN_TEST = 'MESSAGE_TYPE_RING_IN_TEST';
export const MESSAGE_TYPE_SET_FIRST_RING_IN_TEST =
  'MESSAGE_TYPE_SET_FIRST_RING_IN_TEST';
export const MESSAGE_TYPE_RESET_RING_IN_TEST =
  'MESSAGE_TYPE_RESET_RING_IN_TEST';
export const MESSAGE_TYPE_END_RING_IN_TEST = 'MESSAGE_TYPE_END_RING_IN_TEST';

export const MESSAGE_TYPE_START_TIMEOUT = 'MESSAGE_TYPE_START_TIMEOUT';
export const MESSAGE_TYPE_END_TIMEOUT = 'MESSAGE_TYPE_END_TIMEOUT';

export const MESSAGE_TYPE_CHECK_HOST = 'MESSAGE_TYPE_CHECK_HOST';
export const MESSAGE_TYPE_CONFIRM_HOST = 'MESSAGE_TYPE_CONFIRM_HOST';

export const MESSAGE_TYPE_START_QUESTION = 'MESSAGE_TYPE_START_QUESTION';
export const MESSAGE_TYPE_OPEN_RING_IN = 'MESSAGE_TYPE_OPEN_RING_IN';
export const MESSAGE_TYPE_OPEN_RING_IN_LIMITED_TEAMS =
  'MESSAGE_TYPE_OPEN_RING_IN_LIMITED_TEAMS';
export const MESSAGE_TYPE_PAUSE_RING_IN = 'MESSAGE_TYPE_PAUSE_RING_IN';
export const MESSAGE_TYPE_UNPAUSE_RING_IN = 'MESSAGE_TYPE_UNPAUSE_RING_IN';
export const MESSAGE_TYPE_RING_IN = 'MESSAGE_TYPE_RING_IN';
export const MESSAGE_TYPE_SET_FIRST_RING_IN = 'MESSAGE_TYPE_SET_FIRST_RING_IN';
export const MESSAGE_TYPE_END_QUESTION = 'MESSAGE_TYPE_END_QUESTION';
export const MESSAGE_TYPE_RESET = 'MESSAGE_TYPE_RESET';
export const MESSAGE_TYPE_UNDO = 'MESSAGE_TYPE_UNDO';
export const MESSAGE_TYPE_PAUSE_RING_IN_LIMITED_TEAMS =
  'MESSAGE_TYPE_PAUSE_RING_IN_LIMITED_TEAMS';
export const MESSAGE_TYPE_UNPAUSE_RING_IN_LIMITED_TEAMS =
  'MESSAGE_TYPE_UNPAUSE_RING_IN_LIMITED_TEAMS';
export const MESSAGE_TYPE_SET_CURRENT_QUESTION =
  'MESSAGE_TYPE_SET_CURRENT_QUESTION';
export const MESSAGE_TYPE_ROUND_TIMER_ENDED = 'MESSAGE_TYPE_ROUND_TIMER_ENDED';
export const MESSAGE_TYPE_PAUSE_ROUND_TIMER = 'MESSAGE_TYPE_PAUSE_ROUND_TIMER';
export const MESSAGE_TYPE_EDIT_ROUND_TIMER = 'MESSAGE_TYPE_EDIT_ROUND_TIMER';
export const MESSAGE_TYPE_PLAY_ROUND_TIMER = 'MESSAGE_TYPE_PLAY_ROUND_TIMER';

// MESSAGE TYPES - TEAM QUESTIONS:
export const MESSAGE_TYPE_TEAM_START_QUESTION =
  'MESSAGE_TYPE_TEAM_START_QUESTION';
export const MESSAGE_TYPE_TEAM_START_DELIBERATION_TIMER =
  'MESSAGE_TYPE_TEAM_START_DELIBERATION_TIMER';
export const MESSAGE_TYPE_TEAM_PAUSE_DELIBERATION_TIMER =
  'MESSAGE_TYPE_TEAM_PAUSE_DELIBERATION_TIMER';
export const MESSAGE_TYPE_TEAM_END_DELIBERATION =
  'MESSAGE_TYPE_TEAM_END_DELIBERATION';

// MESSAGE TYPES - BONUS QUESTIONS:
export const MESSAGE_TYPE_BONUS_START_QUESTION_PART =
  'MESSAGE_TYPE_BONUS_START_QUESTION_PART';
export const MESSAGE_TYPE_BONUS_TEAM_CONTROL =
  'MESSAGE_TYPE_BONUS_TEAM_CONTROL';
export const MESSAGE_TYPE_BONUS_PART_DISPLAY =
  'MESSAGE_TYPE_BONUS_PART_DISPLAY';

// TIMER STATES
export const TIMER_STATE_HIDDEN = 'TIMER_STATE_HIDDEN';
export const TIMER_STATE_PAUSED = 'TIMER_STATE_PAUSED';
export const TIMER_STATE_RESET_AND_START = 'TIMER_STATE_RESET_AND_START';
export const TIMER_STATE_RUNNING = 'TIMER_STATE_RUNNING';
export const TIMER_STATE_VISIBLE = 'TIMER_STATE_VISIBLE';
export const TIMER_STATE_RESET = 'TIMER_STATE_RESET';
export const TIMER_STATE_EDIT_TIME = 'TIMER_STATE_EDIT_TIME';

// DEFAULT STATE
export const messageState = {
  // General Match State
  activeQuestionNumber: null,
  clients: {},
  currentQuestion: 0,
  matchState: MATCH_STATE_CHOOSE_QUESTION,
  roundTimerState: TIMER_STATE_VISIBLE,
  questionType: undefined,
  showPointButtons: false,
  timeoutDuration: null,
  timeoutTimerState: TIMER_STATE_HIDDEN,

  // Toss-up Q State
  activePlayerID: null,
  ringInTimerState: TIMER_STATE_HIDDEN,
  didStartRingInTimer: false,
  teamsThatDidRingIn: [],
  useComputationTime: false,
  tossUpAnswerTimerState: TIMER_STATE_HIDDEN,
  tossUpAnswerTimerStarted: false,
  tossUpAnswerTimerComplete: false,

  // Bonus Q State
  bonusTeamControlID: null,
  currentBonusPart: 1,
  bonusDeliberationTimerState: TIMER_STATE_HIDDEN,
  bonusAnswerTimerState: TIMER_STATE_HIDDEN,
  useBonusComputationTime: false,
  bonusDeliberationTimerStarted: false,
  bonusAnswerTimerStarted: false,
  bonusDeliberationComplete: false,
  bouncebackTeamEnabled: false,
  persistBonusComputationTimerHidden: false,
  persistBonusDeliberationTimerVisible: false,
  bonusAnswerTimerComplete: false,
  bonusIncorrectParts: [],

  // Team Q State
  teamDeliberationTimerState: TIMER_STATE_HIDDEN,
  teamDeliberationTimeLeft: null,
};

// REDUCER
export const messageReducer = (state, message) => {
  const { type, payload } = message;
  const { roundTimerState } = state;
  switch (type) {
    case MESSAGE_TYPE_START_RING_IN_TEST: {
      // on click Ring-In Test
      return {
        ...state,
        matchState: MATCH_STATE_RING_IN_TEST_OPEN,
        ...(roundTimerState === TIMER_STATE_RUNNING && {
          roundTimerState: TIMER_STATE_PAUSED,
        }),
      };
    }
    case MESSAGE_TYPE_RING_IN_TEST: {
      // on player ring in during ring-in test
      return state;
    }
    case MESSAGE_TYPE_SET_FIRST_RING_IN_TEST: {
      // on host calculate first ring in during ring-in test
      const { playerID } = payload;
      return {
        ...state,
        activePlayerID: playerID,
        matchState: MATCH_STATE_RING_IN_TEST_CLOSED,
      };
    }
    case MESSAGE_TYPE_RESET_RING_IN_TEST: {
      // on click Reset
      return {
        ...state,
        activePlayerID: null,
        matchState: MATCH_STATE_RING_IN_TEST_OPEN,
      };
    }
    case MESSAGE_TYPE_END_RING_IN_TEST: {
      // on click End Ring-In Test
      return {
        ...state,
        activePlayerID: null,
        matchState: MATCH_STATE_CHOOSE_QUESTION,
      };
    }
    case MESSAGE_TYPE_START_TIMEOUT: {
      const { duration } = payload;
      // on submit Timeout
      return {
        ...state,
        timeoutDuration: duration,
        matchState: MATCH_STATE_TIMEOUT_OPEN,
        timeoutTimerState: TIMER_STATE_RUNNING,
      };
    }
    case MESSAGE_TYPE_END_TIMEOUT: {
      // on Timeout End
      return {
        ...state,
        timeoutDuration: null,
        matchState: MATCH_STATE_CHOOSE_QUESTION,
        timeoutTimerState: TIMER_STATE_HIDDEN,
      };
    }
    case MESSAGE_TYPE_START_QUESTION: {
      const { roundTimerExpired } = payload;
      // on click Play Q
      return {
        ...state,
        matchState: MATCH_STATE_ASKING_QUESTION,
        ringInTimerState: TIMER_STATE_VISIBLE,
        ...(!roundTimerExpired && { roundTimerState: TIMER_STATE_RUNNING }),
      };
    }
    case MESSAGE_TYPE_OPEN_RING_IN: {
      // on click Start Ring In Timer
      return {
        ...state,
        didStartRingInTimer: true,
        matchState: MATCH_STATE_RING_IN_OPEN,
        ringInTimerState: TIMER_STATE_RUNNING,
      };
    }
    case MESSAGE_TYPE_UNDO: {
      // on click Undo
      const { didStartRingInTimer, teamsThatDidRingIn } = state;
      // remove last item from the array
      const newTeamsThatDidRingIn = teamsThatDidRingIn.slice(
        0,
        teamsThatDidRingIn.length - 1,
      );
      return {
        ...state,
        activePlayerID: null,
        teamsThatDidRingIn: newTeamsThatDidRingIn,
        showPointButtons: false,
        ...(didStartRingInTimer && {
          matchState: MATCH_STATE_RING_IN_OPEN,
          ringInTimerState: TIMER_STATE_RESET_AND_START,
        }),
      };
    }
    case MESSAGE_TYPE_OPEN_RING_IN_LIMITED_TEAMS: {
      const { didStartRingInTimer } = state;
      const { resetRingInTimer } = payload;
      const nextRingInState = resetRingInTimer
        ? TIMER_STATE_RESET_AND_START
        : TIMER_STATE_RUNNING;
      return {
        ...state,
        activePlayerID: null,
        matchState: MATCH_STATE_RING_IN_OPEN_LIMITED_TEAMS,
        showPointButtons: false,
        ...(didStartRingInTimer && { ringInTimerState: nextRingInState }),
      };
    }
    case MESSAGE_TYPE_PAUSE_RING_IN: {
      // on click Pause Ring In Timer
      return {
        ...state,
        matchState: MATCH_STATE_RING_IN_PAUSED,
        ringInTimerState: TIMER_STATE_PAUSED,
      };
    }
    case MESSAGE_TYPE_PAUSE_RING_IN_LIMITED_TEAMS: {
      // on click Pause Ring In Timer
      return {
        ...state,
        matchState: MATCH_STATE_RING_IN_PAUSED_LIMITED,
        ringInTimerState: TIMER_STATE_PAUSED,
      };
    }
    case MESSAGE_TYPE_UNPAUSE_RING_IN: {
      // on click Start Ring In Timer while in paused state
      return {
        ...state,
        matchState: MATCH_STATE_RING_IN_OPEN,
        ringInTimerState: TIMER_STATE_RUNNING,
      };
    }
    case MESSAGE_TYPE_UNPAUSE_RING_IN_LIMITED_TEAMS: {
      // on click Start Ring In Timer while in paused state
      return {
        ...state,
        didStartRingInTimer: true,
        matchState: MATCH_STATE_RING_IN_OPEN_LIMITED_TEAMS,
        ringInTimerState: TIMER_STATE_RUNNING,
      };
    }
    case MESSAGE_TYPE_RING_IN: {
      // on player ring in
      return {
        ...state,
      };
    }
    case MESSAGE_TYPE_SET_FIRST_RING_IN: {
      // on host calculate first ring in
      const { teamsThatDidRingIn } = state;
      const { playerID, teamID } = payload;
      return {
        ...state,
        activePlayerID: playerID,
        showPointButtons: true,
        teamsThatDidRingIn: [...teamsThatDidRingIn, teamID],
      };
    }
    case MESSAGE_TYPE_PAUSE_ROUND_TIMER: {
      return {
        ...state,
        roundTimerState: TIMER_STATE_PAUSED,
      };
    }
    case MESSAGE_TYPE_PLAY_ROUND_TIMER: {
      return {
        ...state,
        roundTimerState: TIMER_STATE_RUNNING,
      };
    }
    case MESSAGE_TYPE_END_QUESTION: {
      // on click End Q or point button or ring in timer expiration
      // Notes: on click End Q: pause round timer & do NOT increment question number
      const { incrementQuestionNumber, pauseRoundTimer } = payload;
      return {
        ...state,
        activePlayerID: null,
        didStartRingInTimer: false,
        matchState: MATCH_STATE_CHOOSE_QUESTION,
        teamsThatDidRingIn: messageState.teamsThatDidRingIn,
        ringInTimerState: TIMER_STATE_HIDDEN,
        tossUpAnswerTimerState: TIMER_STATE_HIDDEN,
        teamDeliberationTimerState: TIMER_STATE_HIDDEN,
        showPointButtons: false,
        ...(incrementQuestionNumber && {
          currentQuestion: get(state, 'currentQuestion', 0) + 1,
        }),
        ...(pauseRoundTimer && { roundTimerState: TIMER_STATE_PAUSED }),
        currentBonusPart: 1,
        bonusTeamControlID: null,
      };
    }
    case MESSAGE_TYPE_ROUND_TIMER_ENDED: {
      // on round timer end - necessary to change roundTimerState so it will reset properly when clicking point buttons
      return {
        ...state,
        roundTimerState: TIMER_STATE_PAUSED,
      };
    }

    case MESSAGE_TYPE_JOIN_ROOM: {
      const { playerID, isHost, socketID } = payload;
      const { clients } = state;
      const newClients = {
        ...clients,
        [socketID]: { playerID, isHost },
      };
      return {
        ...state,
        clients: newClients,
      };
    }
    case MESSAGE_TYPE_LEAVE_ROOM:
    case MESSAGE_TYPE_PLAYER_DISCONNECT:
    case MESSAGE_TYPE_PLAYER_SWAP: {
      let { socketID, playerID } = payload;
      const { clients } = state;
      if (playerID) {
        for (const socket in clients) {
          if (clients[socket].playerID === playerID) socketID = socket;
        }
      }
      const { [socketID]: socketDataToDelete, ...newClients } = clients;
      return {
        ...state,
        clients: newClients,
      };
    }
    case MESSAGE_TYPE_SHARE_CLIENT_LIST: {
      const { clients } = payload;
      return {
        ...state,
        clients,
      };
    }
    case MESSAGE_TYPE_RESET: {
      return messageState;
    }
    case MESSAGE_TYPE_SET_CURRENT_QUESTION: {
      const { currentQuestion } = payload;
      return {
        ...state,
        currentQuestion,
      };
    }
    case MESSAGE_TYPE_TEAM_START_QUESTION: {
      const { teamDeliberationTimeLeft, roundTimerExpired } = payload;
      // on click Play Q
      return {
        ...state,
        teamDeliberationTimeLeft,
        matchState: MATCH_STATE_TEAM_QUESTION_OPEN,
        teamDeliberationTimerState: TIMER_STATE_VISIBLE,
        ...(!roundTimerExpired && { roundTimerState: TIMER_STATE_RUNNING }),
      };
    }
    case MESSAGE_TYPE_TEAM_START_DELIBERATION_TIMER: {
      return {
        ...state,
        matchState: MATCH_STATE_TEAM_QUESTION_STARTED,
        teamDeliberationTimerState: TIMER_STATE_RUNNING,
      };
    }
    case MESSAGE_TYPE_TEAM_PAUSE_DELIBERATION_TIMER: {
      return {
        ...state,
        matchState: MATCH_STATE_TEAM_QUESTION_PAUSED,
        teamDeliberationTimerState: TIMER_STATE_PAUSED,
      };
    }
    case MESSAGE_TYPE_TEAM_END_DELIBERATION: {
      return {
        ...state,
        matchState: MATCH_STATE_TEAM_QUESTION_CLOSED,
        teamDeliberationTimerState: TIMER_STATE_PAUSED,
      };
    }
    case MESSAGE_TYPE_BONUS_START_QUESTION_PART: {
      const { roundTimerExpired } = payload;
      return {
        ...state,
        matchState: MATCH_STATE_BONUS_QUESTION_OPEN,
        ...(!roundTimerExpired && { roundTimerState: TIMER_STATE_RUNNING }),
      };
    }
    case MESSAGE_TYPE_BONUS_TEAM_CONTROL: {
      const { bonusTeamControlID, currentBonusPart } = payload;
      return {
        ...state,
        bonusTeamControlID,
        matchState: MATCH_STATE_BONUS_QUESTION_OPEN,
        ...(currentBonusPart !== undefined && { currentBonusPart }),
      };
    }
    case MESSAGE_TYPE_BONUS_PART_DISPLAY: {
      const { currentBonusPart } = payload;
      return {
        ...state,
        currentBonusPart,
      };
    }
    case MESSAGE_TYPE_SYNC_PLAYER_STATE:
    case MESSAGE_TYPE_SYNC_HOST_STATE: {
      const {
        activeQuestionNumber,
        matchState,
        currentBonusPart,
        bonusTeamControlID,
        teamsThatDidRingIn,
        activePlayerID,
        showPointButtons,
        questionType,
        ringInTimerState,
        bonusDeliberationTimerState,
        bonusAnswerTimerState,
        useComputationTime,
        useBonusComputationTime,
        bonusDeliberationComplete,
        bouncebackTeamEnabled,
        persistBonusComputationTimerHidden,
        persistBonusDeliberationTimerVisible,
        bonusAnswerTimerComplete,
        bonusIncorrectParts,
        teamDeliberationTimerState,
        teamDeliberationTimeLeft,
        tossUpAnswerTimerState,
        tossUpAnswerTimerComplete,
      } = payload;
      return {
        ...state,
        activeQuestionNumber,
        matchState,
        currentBonusPart,
        bonusTeamControlID,
        teamsThatDidRingIn,
        activePlayerID,
        showPointButtons,
        questionType,
        ringInTimerState,
        bonusDeliberationTimerState,
        bonusAnswerTimerState,
        useComputationTime,
        useBonusComputationTime,
        bonusDeliberationComplete,
        bouncebackTeamEnabled,
        persistBonusComputationTimerHidden,
        persistBonusDeliberationTimerVisible,
        bonusAnswerTimerComplete,
        bonusIncorrectParts,
        teamDeliberationTimerState,
        teamDeliberationTimeLeft,
        tossUpAnswerTimerState,
        tossUpAnswerTimerComplete,
      };
    }
    case MESSAGE_TYPE_SET_STATE: {
      return { ...state, ...(payload || {}) };
    }
    default: {
      console.log(`Message with type ${type} is not supported.`);
      return state;
    }
  }
};
