import {
  createScore as createScoreMutation,
  updateScore as updateScoreMutation,
} from '../../graphql/custom/mutations';
import {
  onCreateScorePublic,
  onUpdateScorePublic,
} from '../../graphql/custom/subscriptions';
import { deleteScore as deleteScoreMutation } from '../../graphql/mutations';
import { onDeleteScorePublic } from '../../graphql/subscriptions';
import { getUserId } from '../AuthHelper';
import { mutate, subscribe } from './index';

export const createScore = async ({
  currentQuestion,
  matchID,
  otherPlayers,
  playerID,
  playerName,
  points,
  teamID,
  type,
}) => {
  let score = null;
  try {
    const user = await getUserId();
    if (!user) throw new Error('No Current User');

    const variables = {
      // historical: custom score ids no longer needed
      // id: `${matchID}_${currentQuestion}_${type}_${teamID}`,
      matchID,
      otherPlayers,
      playerID,
      playerName,
      points,
      questionNumber: currentQuestion,
      teamID,
      type,
      user,
    };
    if (type === 'hostEdit') {
      variables.id = undefined;
      delete variables.questionNumber;
    }

    const response = await mutate(createScoreMutation, variables);
    //  {
    //   id: `${matchID}_${currentQuestion}_${type}_${teamID}`,
    //   matchID,
    //   otherPlayers,
    //   playerID,
    //   points,
    //   questionNumber: currentQuestion,
    //   teamID,
    //   type,
    //   user,
    // });
    if (response) score = response.data.createScore;
  } catch (err) {
    console.log(`Create Score Error: ${err}`);
  }
  return score;
};

export const updateScore = async ({
  scoreID,
  otherPlayers,
  playerID,
  playerName,
  points,
  teamID,
}) => {
  let updateScore = null;
  try {
    const user = await getUserId();
    if (!user) throw new Error('No Current User');

    const response = await mutate(updateScoreMutation, {
      id: scoreID,
      otherPlayers,
      playerID,
      playerName,
      points,
      teamID,
    });

    if (response) updateScore = response.data.updateScore;
  } catch (err) {
    console.log('Update Score Error:', err);
  }
  return updateScore;
};

export const deleteScore = async ({ scoreID }) => {
  let deleteScore = null;
  try {
    const user = await getUserId();
    if (!user) throw new Error('No Current User');

    const response = await mutate(deleteScoreMutation, {
      id: scoreID,
    });

    if (response) deleteScore = response.data.deleteScore;
  } catch (err) {
    console.log('Delete Score Error:', err);
  }
  return deleteScore;
};

export const subscribeToNewScores = async ({
  handleUpdate = () => {},
  matchID,
}) => {
  return subscribe(onCreateScorePublic, { matchID }, handleUpdate);
};

export const subscribeToDeletedScores = async ({
  handleUpdate = () => {},
  matchID,
}) => {
  return subscribe(onDeleteScorePublic, { matchID }, handleUpdate);
};

export const subscribeToEditedScores = async ({
  handleUpdate = () => {},
  matchID,
}) => {
  return subscribe(onUpdateScorePublic, { matchID }, handleUpdate);
};
