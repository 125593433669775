import React from 'react';
import { AiOutlineClose, AiFillEyeInvisible } from 'react-icons/ai';
import {
  FaClipboardList,
  FaExchangeAlt,
  FaEye,
  FaPen,
  FaSortDown,
  FaSortUp,
  FaTrash,
} from 'react-icons/fa';
export const Edit = () => <FaPen />;
export const Trash = () => <FaTrash />;
export const WindowClose = () => <AiOutlineClose />;
export const SortAsc = () => <FaSortUp />;
export const SortDesc = () => <FaSortDown />;
export const SwapIcon = () => <FaExchangeAlt />;
export const ViewIcon = () => <FaEye />;
export const ScoresClipboard = () => <FaClipboardList />;
export const HiddenIcon = () => <AiFillEyeInvisible />;

// export const WindowClose = ({ color }) => {
//   return (
//     <IconContext.Provider value={{ color: color }}>
//       <FaWindowClose />
//     </IconContext.Provider>
//   );
// };
