import React from 'react';
import styled from 'styled-components';
import {
  ScoreSheetContext,
  SET_CELL,
} from '../../../../Contexts/ScoreSheetContext';

const ScoreSheetCell = ({
  className,
  cellId,
  points,
  key,
  matchID,
  type,
  isExportingScoreSheet,
}) => {
  const [pointsDisplay, setPointsDisplay] = React.useState(points);
  const { dispatch, errors } = React.useContext(ScoreSheetContext);

  const handleKeyDownScore = (e) => {
    const keycode = e.keyCode;
    switch (keycode) {
      case 13: {
        let shouldClearErrors = false;
        if (Object.keys(errors).length > 0) shouldClearErrors = true;
        if (type !== 'playerTossUp' && pointsDisplay === '-') {
          dispatch({
            type: SET_CELL,
            payload: { cellId, pointsDisplay: '', shouldClearErrors },
          });
        } else {
          dispatch({
            type: SET_CELL,
            payload: { cellId, pointsDisplay, shouldClearErrors },
          });
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleScoreInputOnBlur = () => {
    let shouldClearErrors = false;
    if (Object.keys(errors).length > 0) shouldClearErrors = true;
    if (type !== 'playerTossUp' && pointsDisplay === '-') {
      dispatch({
        type: SET_CELL,
        payload: { cellId, pointsDisplay: '', shouldClearErrors },
      });
    } else {
      dispatch({
        type: SET_CELL,
        payload: { cellId, pointsDisplay, shouldClearErrors },
      });
    }
  };

  const handleScoreChange = (e) => {
    let isScoreValid = false;
    if (
      e.target.value == '' ||
      e.target.value == '-' ||
      !isNaN(parseInt(e.target.value))
    )
      isScoreValid = true;
    if (!isScoreValid) {
      e.preventDefault();
    } else {
      setPointsDisplay(e.target.value);
    }
  };

  return (
    <td className={className} key={key}>
      {isExportingScoreSheet ? (
        <span>{pointsDisplay}</span>
      ) : (
        <input
          type="text"
          value={pointsDisplay}
          onChange={handleScoreChange}
          onKeyDown={handleKeyDownScore}
          onBlur={handleScoreInputOnBlur}
          className={`question-score-input ${errors[cellId] && 'error'}`}
          title={`${
            errors[cellId] ? errors[cellId].description : 'Enter Score'
          }`}
          name="score-input-value"
          min={-100}
          max={100}
          pattern="[0-9\.\-]*"
        ></input>
      )}
    </td>
  );
};

const StyledScoreSheetCell = styled(ScoreSheetCell)`
  &&& {
    padding: 0;
    text-align: center;
    width: 4rem;
  }

  .question-score-input {
    padding: 0;
    width: 100%;
    font-size: 11px;
    border: 1px solid transparent;
    white-space: nowrap;
    -moz-appearance: textfield;
    margin: 0;
    height: 100%;
    text-align: center;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .error {
    border: 1px solid red;
  }
`;

export default StyledScoreSheetCell;
