import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { DETAILED_SCORES, MANUAL_SCORES } from '../../Helpers/HostRoomHelper';
import { WindowClose } from '../../Helpers/IconHelper';
import { enableViewScores } from '../../Helpers/ScoreSheetHelper';
const EditScoreTypeModal = ({
  className,
  toggleModal = () => {},
  handleSubmit = () => {},
  text = '',
  createdAt,
  isReplayingQuestion = false,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [chosenScoreType, setChosenScoreType] = React.useState(MANUAL_SCORES);

  const handleChange = (e) => {
    setChosenScoreType(e.target.value);
  };

  const onSave = () => {
    setIsSubmitting(true);
    handleSubmit(chosenScoreType);
    setIsSubmitting(false);
    toggleModal();
  };

  return ReactDOM.createPortal(
    <div className={`edit-scores-modal ${className}`}>
      <div className="modal-overlay" onClick={toggleModal}></div>
      <div className="modal">
        <div className="modal-header">
          <p className="modal-text">{text}</p>
          <button className="modal-close-button" onClick={toggleModal}>
            <WindowClose />
          </button>
        </div>
        <label className="radio-buttons">
          <input
            key="manual-edits"
            name="edit-score-type"
            type="radio"
            value={MANUAL_SCORES}
            onChange={handleChange}
            checked={chosenScoreType === MANUAL_SCORES}
          />
          Overall - Team Level
        </label>
        {enableViewScores(createdAt) && !isReplayingQuestion && (
          <label className="radio-buttons">
            <input
              key="detailed-edits"
              name="edit-score-type"
              type="radio"
              value={DETAILED_SCORES}
              onChange={handleChange}
              checked={chosenScoreType === DETAILED_SCORES}
            />
            Detailed - Using a Spreadsheet
          </label>
        )}
        <div className="modal-button-container">
          <button
            disabled={isSubmitting}
            className="button-yes"
            onClick={onSave}
          >
            {isSubmitting ? 'Loading...' : 'Ok'}
          </button>
          <button className="button-no" onClick={toggleModal}>
            Cancel
          </button>
        </div>
      </div>
    </div>,
    document.body,
  );
};

const StyledEditScoreTypeModal = styled(EditScoreTypeModal)`
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    background: white;
    margin: 0 auto;
    border-radius: 3px;
    width: 40rem;
    padding: 2rem;
    transition: all 0.5s;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .modal-text {
    font-size: 1.5em;
  }

  .modal-close-button {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    background-color: white;
    color: steelblue;
    cursor: pointer;
    border: none;
    padding: 0;
    border-radius: 15%;
    border: 0px;
    margin: 0;
    height: 2rem;

    &:hover {
      background-color: steelblue;
      color: white;
    }
  }

  .radio-buttons {
    display: flex;
    align-items: center;
  }

  .modal-button-container {
    display: flex;
    justify-content: center;
    margin: 1rem;
  }

  .button-yes,
  .button-no {
    margin: 1rem;
  }
`;

export default StyledEditScoreTypeModal;
