import { Auth } from 'aws-amplify';

export const ERROR_TYPE_SIGNIN_EMAIL = 'signin_invalid_email';
export const ERROR_TYPE_SIGNIN_PASSWORD = 'signin_invalid_password';
export const ERROR_TYPE_SIGNIN_DEFAULT = 'signin_default';
export const ERROR_TYPE_SIGNUP_NAME = 'signup_invalid_name';
export const ERROR_TYPE_SIGNUP_EMAIL_INVALID = 'signup_invalid_email';
export const ERROR_TYPE_SIGNUP_EMAIL_FOUND = 'signup_email_found';
export const ERROR_TYPE_SIGNUP_PASSWORD = 'signup_invalid_password';
export const ERROR_TYPE_SIGNUP_DEFAULT = 'signup_default';

export const isValidEmail = (email) => {
  const regex = /\S+@\S+\.\S+/;
  const emailIsCorrectFormat = regex.test(email);
  return email.length > 0 && emailIsCorrectFormat;
};

export const isValidName = (name) => name.length > 0;

export const isValidPassword = (password) => password.length >= 6;

export const validateInputs = ({
  email,
  password,
  handleEmailError,
  handlePasswordError,
}) => {
  let valid = true;
  if (!isValidEmail(email)) {
    handleEmailError();
    valid = false;
  }

  if (!isValidPassword(password)) {
    handlePasswordError();
    valid = false;
  }
  return valid;
};

export const getCurrentUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user;
  } catch (err) {
    console.log(`Get Current user Error: ${err.toString()}`);
  }
  return null;
};

export const getUserId = async () => {
  const user = await getCurrentUser();
  return user ? user.username : null;
};

export const checkUserIsAdmin = (userGroupsArr) => {
  if (userGroupsArr) {
    return userGroupsArr.includes('admin');
  }
  return false;
};

export const getJwtToken = async () =>
  (await Auth.currentSession()).getIdToken().getJwtToken();

export const handleSignUp = async (opts) => Auth.signUp(opts);

export const handleUpdateUserAttributes = async (user, attributes) =>
  Auth.updateUserAttributes(user, attributes);

export const handleSignIn = async (opts) => Auth.signIn(opts);

export const handleSignOut = async () => Auth.signOut();

export const sendResetPasswordCode = async ({ email }) =>
  Auth.forgotPassword(email);

export const resetPassword = async ({ email, code, password }) =>
  Auth.forgotPasswordSubmit(email, code, password);

export const sendChangeEmailCode = async (user, attributes) =>
  Auth.updateUserAttributes(user, attributes);

export const changeEmail = async ({ code }) =>
  Auth.verifyCurrentUserAttributeSubmit('email', code);
