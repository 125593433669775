const deviceIDToKeyMap = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '0',
  11: 'A',
  12: 'B',
  13: 'C',
  14: 'D',
  15: 'E',
  16: 'F',
  17: 'G',
  18: 'H',
  19: 'I',
  20: 'J',
  21: 'K',
  22: 'L',
  23: 'M',
  24: 'N',
  25: 'O',
  26: 'P',
  27: 'Q',
  28: 'R',
  29: 'S',
  30: 'T',
  31: 'U',
  32: 'V',
  33: 'W',
  34: 'X',
  35: 'Y',
  36: 'Z',
  37: 'f',
  38: 'g',
  39: 'h',
  40: 'i',
  41: 'j',
  42: 'k',
  43: 'l',
  44: 'n',
  45: 'o',
  46: 'p',
  47: 'q',
  48: 't',
  49: 'u',
  50: 'v',
};

export const deviceIDs = Object.keys(deviceIDToKeyMap);

export function keyFromDeviceID(deviceID) {
  return deviceIDToKeyMap[deviceID];
}
