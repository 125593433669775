import Amplify from 'aws-amplify';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import App from './App';
import awsConfig from './aws-exports';
import { ScoreSheetProvider } from './Contexts/ScoreSheetContext';
import { MatchTemplateProvider } from './Hooks//MatchTemplateContext';
import { AuthProvider } from './Hooks/AuthContext';
import { CustomerProvider } from './Hooks/CustomerContext';
import { MatchClientProvider } from './Hooks/MatchClientContext';
import { MatchSettingsProvider } from './Hooks/MatchSettingsContext';
import { SocketProvider } from './Hooks/SocketContext';
import { TimerProvider } from './Hooks/TimerContext';

const config = {
  ...awsConfig,
};
Amplify.configure(config);

const AppWithCookies = () => (
  <CookiesProvider>
    <App />
  </CookiesProvider>
);

const AppWithProviders = (
  <SocketProvider>
    <CustomerProvider>
      <AuthProvider>
        <ScoreSheetProvider>
          <MatchSettingsProvider>
            <MatchTemplateProvider>
              <MatchClientProvider>
                <TimerProvider>
                  <AppWithCookies />
                </TimerProvider>
              </MatchClientProvider>
            </MatchTemplateProvider>
          </MatchSettingsProvider>
        </ScoreSheetProvider>
      </AuthProvider>
    </CustomerProvider>
  </SocketProvider>
);

ReactDOM.render(AppWithProviders, document.getElementById('root'));
