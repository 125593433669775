import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { updateMatchPlayer } from '../../Helpers/Api/Match';
import { WindowClose } from '../../Helpers/IconHelper';
import { MESSAGE_TYPE_PLAYER_SWAP } from '../../Helpers/MessageHelper';
import { get } from '../../Helpers/UtilHelper';
import { SocketContext } from '../../Hooks/SocketContext';

const SubstitutionModal = ({
  className,
  toggleModal = () => {},
  playerList,
  teamList,
  matchID,
}) => {
  const [loading, setLoading] = React.useState(false);
  const { sendMessage } = React.useContext(SocketContext);

  const defaultPlayerLists = React.useMemo(() => {
    const lists = teamList.map(({ team }) => ({
      id: team.id,
      players: playerList
        .filter(({ player }) => player.teamID === team.id)
        .sort((a, b) => a.order - b.order)
        .map(({ id: matchPlayerID, playerID }) => ({
          matchPlayerID,
          playerID,
        })),
    }));
    return lists.reduce(
      (obj, team) => ({
        ...obj,
        [team.id]: team.players,
      }),
      {},
    );
  }, [playerList, teamList]);
  const [newPlayerLists, setNewPlayerLists] =
    React.useState(defaultPlayerLists);

  const getActivePlayerList = (teamId) => {
    return newPlayerLists[teamId];
  };

  const setNewPlayerList = (teamId, list) => {
    setNewPlayerLists((existing) => ({ ...existing, [teamId]: list }));
  };

  const handleSelectedActivePlayerChange = (e, teamID, matchPlayerID) => {
    const activePlayerList = getActivePlayerList(teamID);
    const newPlayerID = e.target.value;
    const newPlayerList = activePlayerList.map((activePlayer) => {
      if (activePlayer.matchPlayerID === matchPlayerID) {
        return { ...activePlayer, playerID: newPlayerID };
      }
      return activePlayer;
    });
    setNewPlayerList(teamID, newPlayerList);
  };

  const getTeamPlayers = (teamId) => {
    const selectedTeam = teamList.find(({ team }) => team.id === teamId);
    let players = get(selectedTeam, 'team.players.items', []);
    return players;
  };

  const getAllActivePlayers = (teamId) => {
    const teamPlayers = getTeamPlayers(teamId);
    const activePlayerList = getActivePlayerList(teamId);
    const result = activePlayerList.map(({ matchPlayerID, playerID }) => {
      const matchPlayer = playerList.find(({ id }) => id === matchPlayerID);
      const player = teamPlayers.find(({ id }) => id === playerID);
      return { ...matchPlayer, playerID, player };
    });
    return result;
  };

  const onSave = async () => {
    setLoading(true);
    const playersToUpdate = [];
    const savePlayerToUpdate = (playerReference) => {
      const { matchPlayerID, playerID } = playerReference;
      const originalMatchPlayer = playerList.find(
        ({ id }) => id === matchPlayerID,
      );
      if (originalMatchPlayer.playerID !== playerID) {
        playersToUpdate.push(playerReference);
      }
    };
    Object.entries(newPlayerLists).forEach(([id, players]) => {
      players.forEach(savePlayerToUpdate);
    });

    playersToUpdate.forEach(({ matchPlayerID }) => {
      const matchPlayer = playerList.find(({ id }) => id === matchPlayerID);
      if (matchPlayer.active) {
        sendMessage({
          matchID,
          type: MESSAGE_TYPE_PLAYER_SWAP,
          payload: { playerID: matchPlayer.playerID },
        });
      }
    });
    const promises = playersToUpdate.map(({ matchPlayerID, playerID }) => {
      const updateMatchPlayerInput = {
        id: matchPlayerID,
        active: 0,
        matchID,
        playerID,
      };
      return updateMatchPlayer({ input: updateMatchPlayerInput });
    });
    await Promise.all(promises);
    toggleModal();
  };

  const benchPlayersDisplay = (teamId) => {
    const activePlayerList = getActivePlayerList(teamId);
    let teamPlayers = getTeamPlayers(teamId);
    /*
      Filter:
      - active: has not been deleted from team
      - !activePlayerList.find( ... ): not already active matchPlayer
    */
    return teamPlayers.filter(
      ({ active, id }) =>
        active && !activePlayerList.find(({ playerID }) => playerID === id),
    );
  };

  return ReactDOM.createPortal(
    <div className={`modal-container ${className}`}>
      <div className="modal-overlay" onClick={toggleModal}></div>
      <div className="modal">
        <div className="modal-header">
          <p className="modal-text">Player Substitution</p>
          <button className="modal-close-button" onClick={toggleModal}>
            <WindowClose />
          </button>
        </div>
        <div className="roster-container">
          {teamList.map(({ team }, teamIndex) => {
            return (
              <div key={teamIndex} className="team-item-container">
                <p className="team-text">
                  <span className="team-label">Team {teamIndex + 1}</span>:{' '}
                  {team.name}
                </p>
                {getAllActivePlayers(team.id).map((player, playerIndex) => {
                  return (
                    <label key={playerIndex} className="label-container">
                      <span className="select-label">
                        Player {playerIndex + 1}
                      </span>
                      <select
                        className="player-dropdown"
                        onChange={(e) =>
                          handleSelectedActivePlayerChange(
                            e,
                            team.id,
                            player.id,
                          )
                        }
                      >
                        <option value={player.playerID}>
                          {player.player?.name}
                        </option>
                        {benchPlayersDisplay(team.id).map(({ id, name }) => {
                          return (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          );
                        })}
                      </select>
                    </label>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="modal-button-container">
          <button disabled={loading} className="button-yes" onClick={onSave}>
            {loading ? 'Loading...' : 'Save'}
          </button>
          <button className="button-no" onClick={toggleModal}>
            Cancel
          </button>
        </div>
      </div>
    </div>,
    document.body,
  );
};

const StyledSubstitutionModal = styled(SubstitutionModal)`
  overflow-y: hidden;

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    background: white;
    margin: 0 auto;
    border-radius: 3px;
    width: 30rem;
    padding: 2rem;
    transition: all 0.5s;
    max-height: 80vh;
    overflow: scroll;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
  }

  .modal-close-button {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    background-color: white;
    color: steelblue;
    cursor: pointer;
    border: none;
    padding: 0;
    border-radius: 15%;
    border: 0px;
    margin: 0;
    height: 2rem;

    &:hover {
      background-color: steelblue;
      color: white;
    }
  }

  .modal-text {
    font-size: 1.5em;
  }

  .modal-button-container {
    display: flex;
    justify-content: center;
    margin: 1rem;
  }

  .button-yes,
  .button-no {
    margin: 1rem;
  }

  .select-label {
    display: block;
    margin: 1rem;
  }

  .player-dropdown {
    margin: 0;
    text-overflow: ellipsis;
    margin: 1rem;
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .label-container {
    display: flex;
  }

  .roster-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
  }

  .team-item-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .team-text {
    font-weight: bold;
    border-bottom: 1px solid #e1e1e1;
    margin: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .team-label {
    color: steelblue;
  }

  @media (min-width: 900px) {
    .team-item-container {
      width: 100%;
    }

    .roster-container {
      flex-direction: row;
    }

    .modal {
      width: 80rem;
    }
  }
`;

export default StyledSubstitutionModal;
