import { createScore, updateScore } from '../Helpers/Api/Score';
import { TOSS_UP_QUESTION } from '../Helpers/HostRoomHelper';
import { scoreQuestionTypes } from '../Helpers/MatchHelper';
import { get, pickBy } from './UtilHelper';
export const ADD_PLAYER = 'add-player';
export const REMOVE_PLAYER = 'remove-player';
export const CREATE_SCORE = 'create-score';
export const UPDATE_SCORE = 'update-score';
export const getNumberOfQuestionPoints = ({ cellList, scoreList }) => {
  let questionNumber = 1;
  let scores = [];
  if (cellList) {
    scores = Object.values(cellList);
  } else {
    scores = scoreList;
  }
  scores.forEach((score) => {
    if (score.questionNumber > questionNumber)
      questionNumber = score.questionNumber;
  });
  return questionNumber;
};

export const getUniquePointValues = ({ cellList }) => {
  let uniquePointValues = [];
  const scores = Object.values(cellList);
  const scoresToCheck = scores.filter((score) => {
    return score.points !== '' && score.points !== '-';
  });
  scoresToCheck.forEach((score) => {
    if (
      score.type === scoreQuestionTypes[TOSS_UP_QUESTION] &&
      !uniquePointValues.includes(score.points)
    ) {
      uniquePointValues.push(score.points);
    }
  });
  const sortedPointValues = uniquePointValues.sort((a, b) => {
    return b - a;
  });
  return sortedPointValues;
};

// used for init only to read scorelist data
export const getTeamPlayers = ({ scoreList, teamId }) => {
  if (!teamId) return;

  let participatedPlayerArray = [];

  scoreList.forEach((score) => {
    if (score.playerName && score.teamID === teamId)
      participatedPlayerArray.push({
        playerName: score.playerName,
        playerID: score.playerID,
        teamId: score.teamID,
      });

    if (score.otherPlayers) {
      try {
        let otherPlayersArray = JSON.parse(score.otherPlayers);
        otherPlayersArray.forEach((player) => {
          if (player.teamID === teamId) {
            participatedPlayerArray.push({
              playerName: player.playerName,
              playerID: player.playerID,
              teamId: player.teamID,
            });
            return;
          }
        });
      } catch (err) {
        console.log('Error parsing other players array: ', err);
      }
    }
  });

  // filter the array to remove player objects where the player names are duplicate
  participatedPlayerArray = participatedPlayerArray.filter(
    (player, index, prevArr) => {
      return (
        index === prevArr.findIndex((p) => p.playerName === player.playerName)
      );
    },
  );

  return participatedPlayerArray;
};

export const getTeamPlayersFromCellData = ({ cellList, teamId }) => {
  let cellData = Object.values(cellList);
  cellData = cellData.filter((data) => {
    return (
      data.teamId === teamId &&
      data.playerName !== '' &&
      data.type === 'playerTossUp'
    );
  });

  let teamPlayers = cellData.map((cell) => {
    return cell.playerName;
  });
  teamPlayers = [...new Set(teamPlayers)];
  return teamPlayers;
};

export const getQuestionPoints = ({
  playerName = '',
  questionNumber,
  scoreList,
  teamId,
  type,
}) => {
  let scoresToCheck = [];
  if (type === 'playerTossUp') {
    scoresToCheck = scoreList.filter((score) => {
      let scoreType = score.type;
      scoreType.split(',');

      return (
        score.questionNumber === questionNumber && scoreType.includes(type)
      );
    });
    if (scoresToCheck.length > 1) {
      scoresToCheck = scoresToCheck.filter((score) => {
        return score.teamID === teamId;
      });
    }
  } else {
    scoresToCheck = scoreList.filter((score) => {
      let scoreType = score.type;
      scoreType.split(',');

      return (
        score.questionNumber === questionNumber &&
        scoreType.includes(type) &&
        score.teamID === teamId
      );
    });
  }

  let points = '';
  if (type === 'playerTossUp') {
    let pointFound = false;
    scoresToCheck.forEach((score) => {
      const otherPlayersString = get(score, 'otherPlayers', '');
      if (
        score.playerName &&
        score.playerName === playerName &&
        score.points !== null &&
        score.teamID === teamId
      ) {
        points = score.points;
        pointFound = true;
        return;
      } else if (otherPlayersString && !pointFound) {
        const otherPlayersArray = JSON.parse(otherPlayersString);
        if (otherPlayersArray.length === 0) {
          points = '-';
          return;
        }
        if (
          otherPlayersArray.some((player) => player.playerName === playerName)
        ) {
          points = '';
          return;
        } else {
          points = '-';
          return;
        }
      } else if (!pointFound) {
        points = '-';
      }
    });
  } else {
    scoresToCheck.forEach((score) => {
      if (score && score.points) {
        points = score.points;
        return;
      }
    });
  }
  return points.toString();
};

export const getNumberTossUpsHeard = ({ cellList, playerName, teamId }) => {
  let cellData = Object.values(cellList);
  let tossUpsHeard = 0;
  cellData = cellData.filter((data) => {
    return (
      data.playerName === playerName &&
      data.type === 'playerTossUp' &&
      data.teamId === teamId
    );
  });
  cellData.forEach((data) => {
    if (data.points !== '-') {
      tossUpsHeard += 1;
    }
  });
  return tossUpsHeard;
};

export const getSumPlayerUniquePointsScored = ({
  playerName,
  pointValue,
  cellList,
  teamId,
}) => {
  let cellData = Object.values(cellList);
  let uniquePointsScored = 0;
  cellData.forEach((data) => {
    if (
      data.type === 'playerTossUp' &&
      data.points === pointValue &&
      data.playerName === playerName &&
      data.teamId === teamId
    ) {
      uniquePointsScored += 1;
    }
  });
  return uniquePointsScored;
};

export const getSumPlayerTossUpPoints = ({ playerName, cellList, teamId }) => {
  let cellData = Object.values(cellList);
  cellData = cellData.filter(
    (data) =>
      data.type === 'playerTossUp' &&
      data.playerName === playerName &&
      data.teamId === teamId,
  );

  let playerTossUpPoints = 0;
  cellData.forEach((data) => {
    if (data.points) {
      playerTossUpPoints += convertPointsToNum(data.points);
    }
  });
  return playerTossUpPoints;
};

export const getSumTotalPointsByType = ({ cellList, teamId, type }) => {
  let cellData = Object.values(cellList);
  let totalPoints = 0;
  cellData.forEach((data) => {
    let scoreType = data.type;
    scoreType.split(',');
    if (scoreType.includes(type) && data.teamId === teamId) {
      if (data.points) {
        totalPoints += convertPointsToNum(data.points);
      }
    }
  });
  return totalPoints;
};

export const getSumTotalHostEditPoints = ({ scoreList, teamId }) => {
  let totalHostEditPoints = 0;
  const scoresToCheck = scoreList.filter((score) => {
    let scoreType = score.type;
    scoreType.split(',');
    return scoreType.includes('hostEdit') && score.teamID === teamId;
  });

  scoresToCheck.forEach((score) => {
    totalHostEditPoints += convertPointsToNum(score.points);
  });
  return totalHostEditPoints;
};

export const getTotalBonusParts = ({ scoreList, cellList }) => {
  let scores = [];
  if (cellList) {
    scores = Object.values(cellList);
  } else {
    scores = scoreList;
  }
  let totalBonusParts = 0;
  const scoresToCheck = scores.filter((score) => {
    let scoreType = score.type;
    scoreType.split(',');
    return scoreType.includes('bonusPart');
  });

  scoresToCheck.forEach((score) => {
    let bonusPart = score.type;
    const currentBonusPart = bonusPart.slice(-1);
    if (currentBonusPart > totalBonusParts) totalBonusParts = currentBonusPart;
  });
  return totalBonusParts;
};

export const getSumTotalPointsByTypeAndQNum = ({
  questionNumber,
  cellList,
  teamId,
  type,
}) => {
  const scores = Object.values(cellList);
  const scoresToCheck = scores.filter((score) => {
    let scoreType = score.type;
    scoreType.split(',');

    return (
      score.questionNumber === questionNumber &&
      scoreType.includes(type) &&
      score.teamId === teamId
    );
  });

  let totalPoints = 0;
  scoresToCheck.forEach((score) => {
    if (score.points) {
      totalPoints += convertPointsToNum(score.points);
    }
  });
  return totalPoints;
};

export const getSumTotalPointsByQnum = ({
  questionNumber,
  cellList,
  teamId,
}) => {
  const scores = Object.values(cellList);

  const scoresToCheck = scores.filter(
    (score) =>
      score.questionNumber === questionNumber && score.teamId === teamId,
  );
  let totalPoints = 0;
  scoresToCheck.forEach((score) => {
    if (score.points) {
      totalPoints += convertPointsToNum(score.points);
    }
  });
  return totalPoints;
};

export const getTeams = ({ scoreList }) => {
  const teamIds = [];
  scoreList.forEach((score) => {
    if (!teamIds.includes(score.teamID)) teamIds.push(score.teamID);
    if (score.otherPlayers) {
      const otherPlayers = JSON.parse(score.otherPlayers);
      otherPlayers.forEach((player) => {
        if (!teamIds.includes(player.teamID)) teamIds.push(player.teamID);
      });
    }
  });
  return teamIds;
};

export const getCellDataPerPlayer = ({ cellList, playerName, teamId }) => {
  let cellData = Object.values(cellList);
  cellData = cellData.filter((data) => {
    return data.playerName === playerName && data.teamId === teamId;
  });
  return cellData.sort((a, b) => a.questionNumber - b.questionNumber);
};

export const getCellDataPerType = ({ cellList, type, teamId }) => {
  let cellData = Object.values(cellList);
  cellData = cellData.filter((data) => {
    let scoreType = data.type;
    scoreType.split(',');
    return scoreType.includes(type) && data.teamId === teamId;
  });
  return cellData.sort((a, b) => a.questionNumber - b.questionNumber);
};

export const convertPointsToNum = (points) => {
  if (points === '' || points === '-' || !points) {
    return 0;
  } else {
    return parseInt(points);
  }
};

export const enableViewScores = (createdAt) => {
  if (!createdAt) return false;
  const playerOrderReleaseDate = '2021-05-08T23:30:00.000Z';
  return new Date(createdAt) > new Date(playerOrderReleaseDate);
};

export const getOtherPlayersFromScoreList = ({
  scoreList,
  type,
  questionNumber,
  playerName,
}) => {
  let selectedScores = scoreList.filter(
    (score) => score.type === type && score.questionNumber === questionNumber,
  );
  if (playerName)
    selectedScores = selectedScores.filter(
      (score) => score.playerName === playerName,
    );
  if (selectedScores.length === 0) return [];
  return JSON.parse(selectedScores[0].otherPlayers);
};

export const getScoreIDFromScoreList = ({
  scoreList,
  type,
  questionNumber,
  playerName,
  teamId,
}) => {
  let selectedScores = scoreList.filter(
    (score) => score.type === type && score.questionNumber === questionNumber,
  );
  if (teamId)
    selectedScores = selectedScores.filter((score) => score.teamID === teamId);
  if (selectedScores.length === 0) return '';
  return selectedScores[0].id;
};

export const getCellEditsObj = ({
  cellList,
  originalCellList,
  totalNumberQuestions,
  teamList,
  matchID,
}) => {
  let editsObj = {};
  for (let i = 0; i < totalNumberQuestions; i++) {
    teamList.forEach(({ teamID: teamId }) => {
      const tossUpCellData = getCellDataPerTypeAndQNumAndTeam({
        cellList,
        questionNumber: i + 1,
        type: 'playerTossUp',
        teamId,
      });

      const bonusCellData = getCellDataPerTypeAndQNumAndTeam({
        cellList,
        questionNumber: i + 1,
        type: 'bonus',
        teamId,
      });

      const teamCellData = getCellDataPerTypeAndQNumAndTeam({
        cellList,
        questionNumber: i + 1,
        type: 'team',
        teamId,
      });

      // create toss up scores
      let foundTossUpChange = false;
      if (tossUpCellData && !foundTossUpChange) {
        for (const key in tossUpCellData) {
          if (tossUpCellData[key].points !== originalCellList[key].points) {
            foundTossUpChange = true;
            let apiChangeType = UPDATE_SCORE;
            const points = getTossUpCellPoints(tossUpCellData);
            let scoreId = getScoreID(tossUpCellData);
            if (!scoreId) {
              scoreId = `${teamId}-${i + 1}-$playerTossUp`;
              apiChangeType = CREATE_SCORE;
            }
            const apiPlayerId = getPlayerId(tossUpCellData);
            const allTossUpCellData = getAllTossUpCellDataByQNum({
              cellList,
              questionNumber: i + 1,
            });
            const apiPlayerName = getPlayerName(tossUpCellData);
            const apiOtherPlayers = getOtherPlayers({
              allTossUpCellData,
              apiPlayerName,
            });

            editsObj = {
              ...editsObj,
              [scoreId]: {
                questionNumber: i + 1,
                matchID,
                otherPlayers: apiOtherPlayers,
                playerID: apiPlayerId,
                playerName: apiPlayerName,
                points,
                teamID: teamId,
                type: 'playerTossUp',
                apiChangeType,
              },
            };
          }
        }
      }

      // create bonus scores
      if (bonusCellData) {
        for (const key in bonusCellData) {
          if (bonusCellData[key].points !== originalCellList[key].points) {
            let apiChangeType = UPDATE_SCORE;
            const points = getBonusOrTeamPoints(bonusCellData[key].points);
            let scoreId = bonusCellData[key].scoreId;
            const type = bonusCellData[key].type;
            if (!scoreId) {
              scoreId = `${teamId}-${i + 1}-${type}`;
              apiChangeType = CREATE_SCORE;
            }
            const allTossUpCellData = getAllTossUpCellDataByQNum({
              cellList,
              questionNumber: i + 1,
            });
            const apiOtherPlayers = getOtherPlayers({ allTossUpCellData });

            editsObj = {
              ...editsObj,
              [scoreId]: {
                questionNumber: i + 1,
                matchID,
                otherPlayers: apiOtherPlayers,
                playerID: 0,
                points,
                teamID: teamId,
                type,
                apiChangeType,
              },
            };
          }
        }
      }

      // create team scores
      if (teamCellData) {
        for (const key in teamCellData) {
          if (teamCellData[key].points !== originalCellList[key].points) {
            let apiChangeType = UPDATE_SCORE;
            const points = getBonusOrTeamPoints(teamCellData[key].points);
            let scoreId = teamCellData[key].scoreId;
            const type = teamCellData[key].type;
            if (!scoreId) {
              scoreId = `${teamId}-${i + 1}-${type}`;
              apiChangeType = CREATE_SCORE;
            }
            const allTossUpCellData = getAllTossUpCellDataByQNum({
              cellList,
              questionNumber: i + 1,
            });
            const apiOtherPlayers = getOtherPlayers({ allTossUpCellData });

            editsObj = {
              ...editsObj,
              [scoreId]: {
                questionNumber: i + 1,
                matchID,
                otherPlayers: apiOtherPlayers,
                playerID: 0,
                points,
                teamID: teamId,
                type,
                apiChangeType,
              },
            };
          }
        }
      }
    });
  }
  return editsObj;
};

export const getTossUpCellPoints = (tossUpCellData) => {
  let cellData = Object.values(tossUpCellData);
  const selectedCellWithPoints = cellData.find(
    (data) => !isNaN(parseInt(data.points)),
  );
  if (selectedCellWithPoints) return parseInt(selectedCellWithPoints.points);
  return null;
};

export const getBonusOrTeamPoints = (points) => {
  if (points === '' || points === '-') {
    return null;
  } else {
    return parseInt(points);
  }
};

export const getScoreID = (tossUpCellData) => {
  let cellData = Object.values(tossUpCellData);
  let selectedCellWithScoreId = cellData.find((data) => data.scoreId);
  if (selectedCellWithScoreId) return selectedCellWithScoreId.scoreId;
  return '';
};

export const getPlayerId = (tossUpCellData) => {
  let cellData = Object.values(tossUpCellData);
  let playerId = 0;
  const playerCell = cellData.find((data) => !isNaN(parseInt(data.points)));
  if (playerCell) playerId = playerCell.playerId;
  return playerId;
};

export const getPlayerName = (tossUpCellData) => {
  let cellData = Object.values(tossUpCellData);
  let playerName = null;
  const playerCell = cellData.find((data) => !isNaN(parseInt(data.points)));
  if (playerCell) playerName = playerCell.playerName;
  return playerName;
};

export const getOtherPlayers = ({ allTossUpCellData, apiPlayerName }) => {
  let cellData = Object.values(allTossUpCellData);
  let otherPlayers = [];
  let otherPlayersCellData = cellData.filter((data) => {
    return data.points !== '-';
  });
  if (apiPlayerName) {
    otherPlayersCellData = otherPlayersCellData.filter((data) => {
      return data.playerName !== apiPlayerName;
    });
  }
  otherPlayersCellData.forEach((player) => {
    otherPlayers.push({
      playerName: player.playerName,
      playerID: player.playerId,
      teamID: player.teamId,
    });
  });
  return JSON.stringify(otherPlayers);
};

export const getCellDataPerTypeAndQNumAndTeam = ({
  cellList,
  questionNumber,
  type,
  teamId,
}) => {
  const cellData = pickBy(cellList, (cellData) => {
    let scoreType = cellData.type;
    scoreType.split(',');
    return (
      scoreType.includes(type) &&
      cellData.teamId === teamId &&
      cellData.questionNumber === questionNumber
    );
  });
  return cellData;
};

export const getAllTossUpCellDataByQNum = ({ cellList, questionNumber }) => {
  const cellData = pickBy(cellList, (cellData) => {
    let scoreType = cellData.type;
    scoreType.split(',');
    return (
      scoreType.includes('playerTossUp') &&
      cellData.questionNumber === questionNumber
    );
  });
  return cellData;
};

export const submitEditScores = async ({ scoreEdits, matchID }) => {
  let scorePromises = [];
  for (const key in scoreEdits) {
    if (scoreEdits[key].apiChangeType === UPDATE_SCORE) {
      const scoreID = key;
      const otherPlayers = scoreEdits[key].otherPlayers;
      const playerID = scoreEdits[key].playerID;
      const playerName = scoreEdits[key].playerName;
      const points = scoreEdits[key].points;
      const teamID = scoreEdits[key].teamID;
      scorePromises.push(
        updateScore({
          scoreID,
          playerID,
          playerName,
          points,
          otherPlayers,
          teamID,
        }),
      );
    } else if (scoreEdits[key].apiChangeType === CREATE_SCORE) {
      const currentQuestion = scoreEdits[key].questionNumber;
      const otherPlayers = scoreEdits[key].otherPlayers;
      const playerID = scoreEdits[key].playerID;
      const playerName = scoreEdits[key].playerName;
      const points = scoreEdits[key].points;
      const teamID = scoreEdits[key].teamID;
      const type = scoreEdits[key].type;
      scorePromises.push(
        createScore({
          currentQuestion,
          matchID,
          otherPlayers,
          playerID,
          playerName,
          points,
          teamID,
          type,
        }),
      );
    }
  }
  await Promise.all(scorePromises);
};
