import React from 'react';
import styled from 'styled-components';

const RingInSettingItem = ({
  className,
  text,
  onChange,
  value,
  disabledEdits = false,
}) => (
  <div className={`setting-item-container ${className}`}>
    <label className="label-container">
      <span className="select-label">{text}</span>
      <select disabled={disabledEdits} onChange={onChange} value={value}>
        <option value="host">Host Only</option>
        <option value="player">Players Only</option>
        <option value="both">Both</option>
        <option value="none">None</option>
      </select>
    </label>
  </div>
);

const StyledRingInSettingItem = styled(RingInSettingItem)`
  border: 0.5px solid #f5f5f5;
  margin: 0.25rem;

  .select-label {
    margin: 0.5rem;
    display: block;
  }

  .label-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 900px) {
    border: none;
    margin: 0;

    .select-label {
      margin: 1rem;
      display: inline;
    }

    .label-container {
      display: inline;
    }
  }
`;

export default StyledRingInSettingItem;
