import React from 'react';
import styled from 'styled-components';

const ResetPasswordForm = ({
  className,
  fields,
  handleFieldChange,
  isSubmitting,
  onSubmit,
  validateFields,
}) => {
  const submitDisabled = isSubmitting || !validateFields();

  const handleKeyDown = (e) => {
    const keycode = e.keyCode;
    if (keycode === 13 && !submitDisabled) onSubmit();
  };

  return (
    <div className={`send-code-form ${className}`}>
      <div className="description">
        Check your email for your verification code. Enter this code below along
        with a new password:
      </div>
      <div className="form-container">
        <label className="input-container">
          <div className="label">Code</div>
          <input
            className="input"
            id="code"
            type="text"
            value={fields.code}
            onChange={handleFieldChange}
            onKeyDown={handleKeyDown}
            placeholder="verification code"
          />
        </label>
        <label className="input-container">
          <div className="label">Email</div>
          <input
            className="input"
            id="email"
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
            onKeyDown={handleKeyDown}
            placeholder="e-mail address"
          />
        </label>
        <label className="input-container">
          <div className="label">Password</div>
          <div className="input-wrapper">
            <input
              className="input"
              id="password"
              type="password"
              value={fields.password}
              onChange={handleFieldChange}
              onKeyDown={handleKeyDown}
              placeholder="password"
            />
            <input
              className="input"
              id="passwordConfirm"
              type="password"
              value={fields.passwordConfirm}
              onChange={handleFieldChange}
              onKeyDown={handleKeyDown}
              placeholder="confirm password"
            />
          </div>
        </label>
      </div>
      <button
        className="reset-password-submit"
        disabled={submitDisabled}
        onClick={onSubmit}
      >
        {isSubmitting ? 'Loading...' : 'Reset Password'}
      </button>
    </div>
  );
};

const StyledResetPasswordForm = styled(ResetPasswordForm)`
  font-size: 2rem;
  line-height: 1.75rem;
  text-align: center;
`;

export default StyledResetPasswordForm;
