import React, { createContext } from 'react';
import { fetchActiveTemplatesByType } from '../Helpers/Api/Match';
import { getUserId } from '../Helpers/AuthHelper';
import { get } from '../Helpers/UtilHelper';
import { MatchSettingsContext } from './MatchSettingsContext';
export const MatchTemplateContext = createContext(undefined);
export const {
  Provider,
  Consumer: MatchTemplateConsumer,
} = MatchTemplateContext;

export const MatchTemplateProvider = ({ children }) => {
  const [publicMatchTemplates, setPublicMatchTemplates] = React.useState([]);
  const [privateMatchTemplates, setPrivateMatchTemplates] = React.useState([]);
  const [
    fetchMatchTemplatesComplete,
    setFetchMatchTemplatesComplete,
  ] = React.useState(false);
  const { setMatchSetting } = React.useContext(MatchSettingsContext);

  const fetchMatchTemplates = async () => {
    const userId = await getUserId();
    if (!userId) return;
    try {
      setFetchMatchTemplatesComplete(false);
      const publicTemplatesResponse = await fetchActiveTemplatesByType({
        templateType: 'public',
      });
      const publicMatchTemplates = get(publicTemplatesResponse, 'items', []);
      publicMatchTemplates.sort((a, b) => {
        return a.templateName.localeCompare(b.templateName);
      });
      setPublicMatchTemplates(publicMatchTemplates);
      const privateTemplatesResponse = await fetchActiveTemplatesByType({
        templateType: 'private',
        user: userId,
      });
      const privateMatchTemplates = get(privateTemplatesResponse, 'items', []);
      privateMatchTemplates.sort((a, b) => {
        return a.templateName.localeCompare(b.templateName);
      });
      setPrivateMatchTemplates(privateMatchTemplates);
      setFetchMatchTemplatesComplete(true);
      return;
    } catch (err) {
      console.log(`Get match templates error: ${err.toString()}`);
    }
    return null;
  };

  const setMatchTemplateSettings = (
    templateID,
    excludeTemplateDetails = false,
    isEditingMatch = false,
  ) => {
    const allMatchTemplates = [
      ...publicMatchTemplates,
      ...privateMatchTemplates,
    ];
    const selectedMatchTemplateSettings = allMatchTemplates.find(
      ({ id }) => id === templateID,
    );
    if (selectedMatchTemplateSettings === undefined) return;
    const {
      computationTime,
      earlyCorrectAnswerPoints,
      earlyWrongAnswerPoints,
      templateName,
      templateType,
      templateDescription,
      resetTimerDuration,
      resetTimerOption,
      ringInTimer,
      roundMinutes,
      roundSeconds,
      roundTimerEnabled,
      showComputationTime,
      showEarlyCorrectAnswerBonus,
      showEarlyWrongAnswerPenalty,
      showResetTimer,
      showWrongAnswerPenalty,
      startingPoints,
      tossUpEnabled,
      tossUpPoints,
      wrongAnswerPenaltyPoints,
      teamQuestionEnabled,
      teamDeliberationMinutes,
      teamDeliberationSeconds,
      bonusQuestionEnabled,
      bonusTotalParts,
      bonusPointsPerPart,
      bonusAnswerTimerEnabled,
      bonusAnswerSeconds,
      bonusBouncebackEnabled,
      bonusImmediateBouncebackEnabled,
      bonusDeliberationTimerEnabled,
      bonusDeliberationSeconds,
      bonusComputationTimerEnabled,
      bonusComputationSeconds,
      bonusControl,
      bonusQuestionsFollowTossUps,
      ringInLimit,
      soundRoundTimerEnabled,
      soundRingInEnabled,
      soundRingInTimerEnabled,
      soundTeamDeliberationTimerEnabled,
      soundBonusAnswerTimerEnabled,
      soundBonusDeliberationTimerEnabled,
      soundTimeoutTimerEnabled,
      playerTimersEnabled,
      timeoutsEnabled,
      timeouts,
      tossUpAnswerEnabled,
      tossUpAnswerSeconds,
      soundTossUpAnswerEnabled,
      preserveMinTimeEnabled,
      preserveMinTimeSeconds,
    } = selectedMatchTemplateSettings;
    setMatchSetting({
      payload: {
        computationTime,
        earlyCorrectAnswerPoints,
        earlyWrongAnswerPoints,
        ...(!excludeTemplateDetails && { templateName }),
        ...(!excludeTemplateDetails && { templateType }),
        ...(!excludeTemplateDetails && { templateDescription }),
        resetTimerDuration,
        resetTimerOption,
        ringInTimer,
        roundMinutes,
        roundSeconds,
        roundTimerEnabled,
        showComputationTime,
        showEarlyCorrectAnswerBonus,
        showEarlyWrongAnswerPenalty,
        showResetTimer,
        showWrongAnswerPenalty,
        ...(!isEditingMatch && { startingPoints }),
        tossUpEnabled,
        tossUpPoints,
        wrongAnswerPenaltyPoints,
        teamQuestionEnabled,
        teamDeliberationMinutes,
        teamDeliberationSeconds,
        bonusQuestionEnabled,
        bonusTotalParts,
        bonusPointsPerPart,
        bonusAnswerTimerEnabled,
        bonusAnswerSeconds,
        bonusBouncebackEnabled,
        bonusImmediateBouncebackEnabled,
        bonusDeliberationTimerEnabled,
        bonusDeliberationSeconds,
        bonusComputationTimerEnabled,
        bonusComputationSeconds,
        bonusControl,
        bonusQuestionsFollowTossUps,
        ringInLimit,
        soundRoundTimerEnabled,
        soundRingInEnabled,
        soundRingInTimerEnabled,
        soundTeamDeliberationTimerEnabled,
        soundBonusAnswerTimerEnabled,
        soundBonusDeliberationTimerEnabled,
        soundTimeoutTimerEnabled,
        playerTimersEnabled,
        timeoutsEnabled,
        timeouts,
        tossUpAnswerEnabled,
        tossUpAnswerSeconds,
        soundTossUpAnswerEnabled,
        preserveMinTimeEnabled,
        preserveMinTimeSeconds,
      },
    });
  };

  React.useEffect(() => {
    fetchMatchTemplates();
  }, []);

  return (
    <Provider
      value={{
        fetchMatchTemplates,
        setMatchTemplateSettings,
        publicMatchTemplates,
        privateMatchTemplates,
        fetchMatchTemplatesComplete,
      }}
    >
      {children && children}
    </Provider>
  );
};
