import BonusQuestions from 'Components/MatchSettings/Components/BonusQuestions';
import MatchTemplateOptions from 'Components/MatchSettings/Components/MatchTemplateOptions';
import MatchTemplateSettings from 'Components/MatchSettings/Components/MatchTemplateSettings';
import NameAndDate from 'Components/MatchSettings/Components/NameAndDate';
import Rosters from 'Components/MatchSettings/Components/Rosters';
import RoundTimer from 'Components/MatchSettings/Components/RoundTimer';
import Sounds from 'Components/MatchSettings/Components/Sounds';
import StartingPoints from 'Components/MatchSettings/Components/StartingPoints';
import TeamQuestions from 'Components/MatchSettings/Components/TeamQuestions';
import Timeouts from 'Components/MatchSettings/Components/Timeouts';
import TossUpQuestions from 'Components/MatchSettings/Components/TossUpQuestions';
import Modal from 'Components/Modal';
import { WindowClose } from 'Helpers/IconHelper';
import { MatchSettingsContext } from 'Hooks/MatchSettingsContext';
import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const CreateMatchModal = ({
  className,
  toggleCreateMatchModal,
  fetchMatches,
}) => {
  const [showConfirmNewMatchModal, setShowConfirmNewMatchModal] =
    React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [createMatchError, setCreateMatchError] = React.useState(false);
  const {
    saveTemplate,
    validateAndCreateMatch,
    validateAndCreateMatchTemplate,
    errors,
  } = React.useContext(MatchSettingsContext);

  const toggleConfirmNewMatchModal = () => {
    setShowConfirmNewMatchModal((prev) => !prev);
  };

  const hasValidationErrors = React.useMemo(() => {
    return Object.keys(errors).length !== 0;
  }, [errors]);

  const onCreateMatch = async () => {
    setIsSubmitting(true);
    setCreateMatchError(false);
    let hasTemplateErrors;
    const { match, hasErrors: hasMatchErrors } =
      (await validateAndCreateMatch()) || {};
    if (saveTemplate === 'yes' && !hasMatchErrors) {
      const { hasErrors: hasOGTemplateErrors } =
        (await validateAndCreateMatchTemplate()) || {};
      hasTemplateErrors = hasOGTemplateErrors;
    }
    if (hasMatchErrors || hasTemplateErrors) {
      // handle validation error
      setIsSubmitting(false);
      setShowConfirmNewMatchModal(false);
      return;
    }
    if (!match) {
      // handle API error
      setIsSubmitting(false);
      setShowConfirmNewMatchModal(false);
      setCreateMatchError(true);
      return;
    }
    // handle success - show dashboard view and refresh matches
    fetchMatches({ ignoreToken: true });
    setShowConfirmNewMatchModal((prev) => !prev);
    toggleCreateMatchModal();
  };

  let errorDisplay = null;
  if (hasValidationErrors) {
    errorDisplay = (
      <div className="match-error-container">
        <p className="match-error-text">Please correct the errors above.</p>
      </div>
    );
  } else if (createMatchError) {
    errorDisplay = (
      <div className="match-error-container">
        <p className="match-error-text">Something went wrong.</p>
      </div>
    );
  }

  return ReactDOM.createPortal(
    <div className={`modal-container ${className}`}>
      <div className="modal-overlay" onClick={toggleCreateMatchModal}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-text">Create a Match</h2>
          <button
            className="modal-close-button"
            onClick={toggleCreateMatchModal}
          >
            <WindowClose />
          </button>
        </div>
        <NameAndDate />
        <MatchTemplateSettings excludeTemplateDetails />
        <Rosters />
        <RoundTimer />
        <StartingPoints />
        <TossUpQuestions />
        <TeamQuestions />
        <BonusQuestions />
        <Timeouts />
        <Sounds />
        <MatchTemplateOptions headerText="Save Match Settings" showSaveInput />
        <div className="modal-button-container">
          {errorDisplay}
          <button
            className="button-create"
            disabled={isSubmitting}
            onClick={toggleConfirmNewMatchModal}
          >
            {isSubmitting ? 'Loading...' : 'Create Match'}
          </button>
          {showConfirmNewMatchModal ? (
            <Modal
              confirmAction={onCreateMatch}
              confirmLoading={isSubmitting}
              toggleModal={toggleConfirmNewMatchModal}
              text="Are you sure you want to create this match?"
            />
          ) : null}
        </div>
      </div>
    </div>,
    document.body,
  );
};

const StyledCreateMatchModal = styled(CreateMatchModal)`
  overflow-y: hidden;

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80vh;
    width: 80vw;
    max-width: 80rem;
    margin: 0 auto;
    z-index: 3;
    background: white;
    border-radius: 3px;
    padding: 2rem;
    overflow: scroll;
  }

  .modal-text {
    font-weight: bold;
    font-size: 3rem;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
  }

  .modal-close-button {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    color: steelblue;
    background-color: white;
    cursor: pointer;
    border: none;
    padding: 0;
    border-radius: 15%;
    border: 0px;
    margin: 0;
    height: 2rem;

    &:hover {
      background-color: steelblue;
      color: white;
    }
  }

  .modal-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .match-error-container {
    margin-top: 3rem;
    border: 2px solid red;
    border-radius: 3px;
    background-color: #ffdede;
  }

  .match-error-text {
    color: red;
    margin: 1rem;
  }

  .button-create {
    margin: 3rem;
    font-size: 2rem;
  }

  @media (min-width: 900px) {
  }
`;

export default StyledCreateMatchModal;
