import SettingsHeader from 'Components/SettingsHeader';
import ShareTeamsForm from 'Components/ShareTeamsForm';
import { createTeamWithPlayers } from 'Helpers/Api/Team';
import { WindowClose } from 'Helpers/IconHelper';
import { AuthContext } from 'Hooks/AuthContext';
import useShareTeams from 'Hooks/useShareTeams';
import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import CreatePlayerItem from '../CreatePlayerItem';

const CreateTeamModal = ({ className, toggleModal, resetAndRefetchTeams }) => {
  const [teamName, setTeamName] = React.useState('');
  const [players, setPlayers] = React.useState([]);
  const [playerError, setPlayerError] = React.useState(null);
  const [teamError, setTeamError] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { isAdmin } = React.useContext(AuthContext);

  const {
    enableShareTeams,
    groupErrorText,
    handleRemoveGroupError,
    newGroup,
    onChangeNewGroup,
    onChangeSelectedGroup,
    selectedGroup,
    setEnableShareTeams,
    setGroupErrorText,
    teamGroups,
  } = useShareTeams();

  React.useEffect(() => {
    const initialPlayers = [];
    for (let i = 1; i < 16; i++) {
      initialPlayers.push(`Player ${i}`);
    }
    setPlayers(initialPlayers);
  }, []);

  const validateTeamAndPlayers = (teamName, players) => {
    let hasError = false;
    if (teamName === '') {
      setTeamError('Please enter a team name.');
      hasError = true;
    }
    const playersFilteredArray = players.filter((player) => player !== '');
    if (playersFilteredArray.length === 0) {
      setPlayerError('Please enter at least one player.');
      hasError = true;
    }
    if (enableShareTeams && newGroup === '' && selectedGroup === '') {
      setGroupErrorText('Please select a group or enter a new group name.');
      hasError = true;
    }
    return hasError;
  };

  const handleEditPlayer = (name, index) => {
    const newArr = [...players];
    newArr[index] = name;
    setPlayers(newArr);
  };

  const handleAddNewPlayer = () => {
    setPlayers((prev) => [...prev, '']);
    setPlayerError(null);
  };

  const handleRemovePlayer = (index) => {
    setPlayers(players.filter((player, playerIndex) => index !== playerIndex));
  };

  const handleTeamNameChange = (e) => {
    setTeamName(e.target.value);
  };

  const onCreateTeam = async () => {
    setIsSubmitting(true);
    const hasError = validateTeamAndPlayers(teamName, players);
    if (hasError) {
      setIsSubmitting(false);
      return;
    }
    await createTeamWithPlayers({
      players,
      teamName,
      ...(isAdmin && {
        enableShareTeams,
        newGroup,
        selectedGroup,
      }),
    });
    setIsSubmitting(false);
    toggleModal();
    resetAndRefetchTeams();
  };

  const handleRemoveTeamError = () => {
    setTeamError(null);
  };

  const handleRemovePlayerError = () => {
    setPlayerError(null);
  };

  const playersDisplay = players.map((player, index) => {
    return (
      <CreatePlayerItem
        player={player}
        index={index}
        key={index}
        deleteDisabled={players.length === 1}
        handleEditPlayer={handleEditPlayer}
        handleRemovePlayer={handleRemovePlayer}
        handleRemovePlayerError={handleRemovePlayerError}
        players={players}
      />
    );
  });

  return ReactDOM.createPortal(
    <div className={`modal-container ${className}`}>
      <div className="modal-overlay" onClick={toggleModal}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-text">Create a Team</h2>
          <button className="modal-close-button" onClick={toggleModal}>
            <WindowClose />
          </button>
        </div>
        <SettingsHeader text="Team Name" />
        <div>
          <div className="input-container">
            <label className="label-container">
              <span className="text-label">Team Name</span>
              <input
                type="text"
                className="input-name"
                value={teamName}
                onChange={handleTeamNameChange}
                onKeyDown={handleRemoveTeamError}
                maxLength="80"
              />
            </label>
            {teamError && (
              <div className="team-error-message">
                <p>{teamError}</p>
              </div>
            )}
          </div>
          <SettingsHeader text="Players" />
          {playerError && (
            <div className="player-error-message">
              <p>{playerError}</p>
            </div>
          )}
          {playersDisplay}
          <button
            className="add-player-button"
            onClick={handleAddNewPlayer}
            disabled={players.length >= 30}
          >
            Add Player
          </button>
        </div>
        {isAdmin && (
          <ShareTeamsForm
            enableShareTeams={enableShareTeams}
            newGroup={newGroup}
            onChangeNewGroup={onChangeNewGroup}
            onChangeSelectedGroup={onChangeSelectedGroup}
            selectedGroup={selectedGroup}
            setEnableShareTeams={setEnableShareTeams}
            teamGroups={teamGroups}
            handleRemoveGroupError={handleRemoveGroupError}
          />
        )}
        {groupErrorText && (
          <div className="player-error-message">
            <p>{groupErrorText}</p>
          </div>
        )}
        <div className="modal-button-container">
          <button
            className="button-create"
            disabled={isSubmitting}
            onClick={onCreateTeam}
          >
            {isSubmitting ? 'Loading...' : 'Save'}
          </button>
          <button className="button-create" onClick={toggleModal}>
            Cancel
          </button>
        </div>
      </div>
    </div>,
    document.body,
  );
};

const StyledCreateTeamModal = styled(CreateTeamModal)`
  overflow-y: hidden;

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80vh;
    width: 80vw;
    max-width: 80rem;
    margin: 0 auto;
    z-index: 3;
    background: white;
    border-radius: 3px;
    padding: 2rem;
    overflow: scroll;
  }

  .modal-text {
    font-weight: bold;
    font-size: 3rem;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
  }

  .modal-close-button {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    color: steelblue;
    background-color: white;
    cursor: pointer;
    border: none;
    padding: 0;
    border-radius: 15%;
    border: 0px;
    margin: 0;
    height: 2rem;

    &:hover {
      background-color: steelblue;
      color: white;
    }
  }

  .modal-button-container {
    display: flex;
    justify-content: center;
    margin: 3rem 0;
  }

  .button-create {
    margin: 0 .5rem;
    font-size: 1.5rem;
    width: 13rem;
  }

  .add-player-button {
    margin: 1rem auto;
    display: block;

    &:disabled {
      pointer-events: none;
      background-color: gray;
    }
  }

  .no-players-text {
    text-align: center;
    color: Gray;
  }

  .input-container {
    width: 100%;
  }

  .input-name {
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;
    flex: 1;
    text-overflow: ellipsis;
  }

  .text-label {
    margin: 0 1rem;
  }

  .label-container {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .team-error-message {
    color: red;
    font-size: 1.25rem;
    margin: 1rem;
  }

  .player-error-message {
    color: red;
    text-align: center;
    font-size: 1.25rem;
  }

  @media (min-width: 900px) {
    .input-container {
      width: 65%;
      margin 0 auto;
    }

    .input-name {
      width: 100%;
    }
  }
`;

export default StyledCreateTeamModal;
