import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import SettingsHeader from '../../../../../../Components/SettingsHeader';
import ShareTeamsForm from '../../../../../../Components/ShareTeamsForm';
import {
  ADD_PLAYER,
  ADD_TEAM_TO_GROUP,
  CREATE_GROUP_AND_ADD_TEAM_TO_GROUP,
  CREATE_GROUP_AND_UPDATE_TEAM_TO_GROUP,
  DELETE_PLAYER,
  editTeamWithPlayers,
  REMOVE_TEAM_FROM_GROUP,
  updateTeamGroup,
  UPDATE_PLAYER,
  UPDATE_TEAM_NAME,
  UPDATE_TEAM_TO_GROUP,
} from '../../../../../../Helpers/Api/Team';
import { WindowClose } from '../../../../../../Helpers/IconHelper';
import { get } from '../../../../../../Helpers/UtilHelper';
import { AuthContext } from '../../../../../../Hooks/AuthContext';
import useShareTeams from '../../../../../../Hooks/useShareTeams';
import EditPlayerItem from '../EditPlayerItem';

const EditTeamModal = ({
  className,
  resetAndRefetchTeams,
  toggleModal,
  team,
  sharedTeamsList = [],
}) => {
  const [teamName, setTeamName] = React.useState('');
  const [players, setPlayers] = React.useState([]);
  const [edits, setEdits] = React.useState({});
  const [playerError, setPlayerError] = React.useState(null);
  const [teamError, setTeamError] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSettingDefaultValues, setIsSettingDefaultValues] = React.useState(
    true,
  );
  const [newPlayerIdx, setNewPlayerIdx] = React.useState(1);
  const { isAdmin } = React.useContext(AuthContext);
  const teamID = get(team, 'id');
  const teamGroupLinkID = get(team, 'groups.items.0.id');
  const previousGroupID = get(team, 'groups.items.0.group.id');
  const previousGroupName = get(team, 'groups.items.0.group.name');
  const hasEdits = Object.keys(edits).length > 0;
  const isOnlyTeamInGroup = sharedTeamsList.length === 1;

  const {
    enableShareTeams,
    groupErrorText,
    handleRemoveGroupError,
    newGroup,
    onChangeNewGroup,
    onChangeSelectedGroup,
    selectedGroup,
    setEnableShareTeams,
    setGroupErrorText,
    setSelectedGroup,
    teamGroups,
  } = useShareTeams();

  const postUpdateCallback = React.useCallback(() => {
    if (previousGroupID && isOnlyTeamInGroup) {
      return updateTeamGroup({
        active: 0,
        id: previousGroupID,
        name: previousGroupName,
      });
    }
  }, [previousGroupID, isOnlyTeamInGroup]);

  const UPDATE_GROUP_KEY = `${teamID}-group`;

  React.useEffect(() => {
    const setDefaultValues = () => {
      setTeamName(get(team, 'name', ''));
      const playerList = get(team, 'players.items', []);
      const sortedArray = playerList.sort((a, b) => {
        return a.order - b.order;
      });
      setPlayers(
        sortedArray.map(({ id, name, order }) => ({ id, name, order })),
      );
      if (previousGroupID) {
        setEnableShareTeams(true);
        setSelectedGroup(previousGroupID);
      }
      setIsSettingDefaultValues(false);
    };
    setDefaultValues();
    return () => {
      setDefaultValues();
      setIsSubmitting(false);
    };
  }, []);

  React.useEffect(() => {
    if (!isSettingDefaultValues && !enableShareTeams && previousGroupID) {
      setEdits((prev) => ({
        ...prev,
        [UPDATE_GROUP_KEY]: {
          postUpdateCallback,
          teamGroupLinkID,
          type: REMOVE_TEAM_FROM_GROUP,
        },
      }));
    }
  }, [enableShareTeams, isSettingDefaultValues]);

  const onChangeSelectedGroupWithEdits = React.useCallback(
    (e) => {
      onChangeSelectedGroup(e);
      const groupID = e.target.value;
      const type =
        !previousGroupID && enableShareTeams
          ? ADD_TEAM_TO_GROUP
          : UPDATE_TEAM_TO_GROUP;
      setEdits((prev) => ({
        ...prev,
        [UPDATE_GROUP_KEY]: {
          groupID,
          postUpdateCallback,
          teamGroupLinkID,
          teamID,
          type,
        },
      }));
    },
    [enableShareTeams, previousGroupID, teamID, teamName],
  );

  const onChangeNewGroupWithEdits = React.useCallback(
    (e) => {
      onChangeNewGroup(e);
      const groupName = e.target.value;
      const type =
        !previousGroupID && enableShareTeams
          ? CREATE_GROUP_AND_ADD_TEAM_TO_GROUP
          : CREATE_GROUP_AND_UPDATE_TEAM_TO_GROUP;
      setEdits((prev) => ({
        ...prev,
        [UPDATE_GROUP_KEY]: {
          groupName,
          postUpdateCallback,
          teamGroupLinkID,
          teamID,
          type,
        },
      }));
    },
    [enableShareTeams, previousGroupID, teamID, teamName],
  );

  const confirmCloseModal = () => {
    if (window.confirm('You have unsaved changes. Proceed without saving?')) {
      toggleModal();
    }
  };

  const tryCloseModal = () => {
    if (hasEdits) {
      confirmCloseModal();
      return;
    }
    toggleModal();
  };

  const validateTeamAndPlayers = (teamName, players) => {
    let hasError = false;
    if (teamName === '') {
      setTeamError('Please enter a team name.');
      hasError = true;
    }
    const playersFilteredArray = players.filter(({ name }) => name !== '');
    if (playersFilteredArray.length === 0) {
      setPlayerError('Please enter at least one player.');
      hasError = true;
    }
    const isMissingGroup = checkMissingGroup();
    if (isMissingGroup) {
      setGroupErrorText('Please select a group or enter a new group name.');
      hasError = true;
    }
    return hasError;
  };

  const checkMissingGroup = () => {
    return enableShareTeams && newGroup === '' && selectedGroup === '';
  };

  const handleAddNewPlayer = () => {
    const newID = `new-player-${newPlayerIdx}`;
    // update players array to display in UI
    const order = players.length + 1;
    setPlayers((prev) => [...prev, { id: newID, name: '', order }]);
    // track new player to be saved
    setEdits((prev) => ({
      ...prev,
      [newID]: {
        isNew: true,
        name: '',
        playerID: newID,
        teamID,
        order,
        type: ADD_PLAYER,
      },
    }));
    // increment new player index
    setNewPlayerIdx(newPlayerIdx + 1);
    // clear errors
    setPlayerError(null);
  };

  const handleEditPlayer = (name, playerID, order) => {
    // update players array to display in UI
    setPlayers((prev) =>
      prev.map((player) => {
        if (playerID === player.id) {
          const newOrder = order !== undefined ? order : player.order;
          return { id: playerID, name, order: newOrder };
        }
        return player;
      }),
    );
    // track new player to be saved
    setEdits((prev) => {
      const type = get(prev, `${playerID}.type`) || UPDATE_PLAYER;
      const newOrder =
        order !== undefined ? order : get(prev, `${playerID}.order`);
      return {
        ...prev,
        [playerID]: {
          ...(prev[playerID] || {}),
          name,
          order: newOrder,
          playerID,
          teamID,
          type,
        },
      };
    });
  };

  const handleRemovePlayer = (playerID) => {
    // update players array to display in UI
    const newPlayers = players.filter(({ id }) => id !== playerID);
    setPlayers(newPlayers);
    // update order of remaining players
    newPlayers.forEach(({ id, name }, index) =>
      handleEditPlayer(name, id, index + 1),
    );
    // track deleted player to be saved
    setEdits((prev) => {
      const isNew = get(prev, `${playerID}.isNew`);
      if (isNew) {
        let { [playerID]: omit, ...remaining } = prev;
        return remaining;
      }
      return {
        ...prev,
        [playerID]: {
          ...(prev[playerID] || {}),
          playerID,
          teamID,
          type: DELETE_PLAYER,
        },
      };
    });
  };

  const handleTeamNameChange = (e) => {
    const name = e.target.value;
    // update team name to display in UI
    setTeamName(name);
    // track team name to be saved
    setEdits((prev) => ({
      ...prev,
      [`${teamID}-name`]: {
        name,
        teamID,
        type: UPDATE_TEAM_NAME,
      },
    }));
  };

  const onSubmit = async () => {
    if (!hasEdits && !checkMissingGroup()) {
      // if nothing to save, close the modal
      tryCloseModal();
      return;
    }
    setIsSubmitting(true);
    // check for errors
    const hasError = validateTeamAndPlayers(teamName, players);
    if (hasError) {
      setIsSubmitting(false);
      return;
    }
    // save changes, close modal, and refetch
    await editTeamWithPlayers(edits);
    setIsSubmitting(false);
    toggleModal();
    resetAndRefetchTeams();
  };

  const handleRemoveTeamError = () => {
    setTeamError(null);
  };

  const handleRemovePlayerError = () => {
    setPlayerError(null);
  };

  const playersDisplay = players.map((player, index) => {
    return (
      <EditPlayerItem
        key={player.id}
        player={player}
        index={index}
        deleteDisabled={players.length === 1}
        handleEditPlayer={handleEditPlayer}
        handleRemovePlayer={handleRemovePlayer}
        handleRemovePlayerError={handleRemovePlayerError}
      />
    );
  });

  return ReactDOM.createPortal(
    <div className={`modal-container ${className}`}>
      <div className="modal-overlay" onClick={tryCloseModal}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-text">Edit a Team</h2>
          <button className="modal-close-button" onClick={tryCloseModal}>
            <WindowClose />
          </button>
        </div>
        <SettingsHeader text="Team Name" />
        <div>
          <div className="input-container">
            <label className="label-container">
              <span className="text-label">Team Name</span>
              <input
                type="text"
                className="input-name"
                value={teamName}
                onChange={handleTeamNameChange}
                onKeyDown={handleRemoveTeamError}
              />
            </label>
            {teamError && (
              <div className="team-error-message">
                <p>{teamError}</p>
              </div>
            )}
          </div>
          <SettingsHeader text="Players" />
          {playerError && (
            <div className="player-error-message">
              <p>{playerError}</p>
            </div>
          )}
          {playersDisplay}
          <button
            className="add-player-button"
            disabled={players.length >= 15}
            onClick={handleAddNewPlayer}
          >
            Add Player
          </button>
        </div>
        {isAdmin && (
          <ShareTeamsForm
            enableShareTeams={enableShareTeams}
            newGroup={newGroup}
            onChangeNewGroup={onChangeNewGroupWithEdits}
            onChangeSelectedGroup={onChangeSelectedGroupWithEdits}
            selectedGroup={selectedGroup}
            setEnableShareTeams={setEnableShareTeams}
            teamGroups={teamGroups}
            handleRemoveGroupError={handleRemoveGroupError}
          />
        )}
        {groupErrorText && (
          <div className="player-error-message">
            <p>{groupErrorText}</p>
          </div>
        )}
        <div className="modal-button-container">
          <button
            className="button-create"
            disabled={isSubmitting}
            onClick={onSubmit}
          >
            Save
          </button>
          <button className="button-create" onClick={tryCloseModal}>
            Cancel
          </button>
        </div>
      </div>
    </div>,
    document.body,
  );
};

const StyledEditTeamModal = styled(EditTeamModal)`
  overflow-y: hidden;

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80vh;
    width: 80vw;
    max-width: 80rem;
    margin: 0 auto;
    z-index: 3;
    background: white;
    border-radius: 3px;
    padding: 2rem;
    overflow: scroll;
  }

  .modal-text {
    font-weight: bold;
    font-size: 3rem;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
  }

  .modal-close-button {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    color: steelblue;
    background-color: white;
    cursor: pointer;
    border: none;
    padding: 0;
    border-radius: 15%;
    border: 0px;
    margin: 0;
    height: 2rem;

    &:hover {
      background-color: steelblue;
      color: white;
    }
  }

  .modal-button-container {
    display: flex;
    justify-content: center;
    margin: 3rem 0;
  }

  .button-create {
    margin: 0 .5rem;
    font-size: 1.5rem;
    width: 13rem;
  }

  .add-player-button {
    margin: 1rem auto;
    display: block;
  }

  .no-players-text {
    text-align: center;
    color: Gray;
  }

  .input-container {
    width: 100%;
  }

  .input-name {
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;
    flex: 1;
    text-overflow: ellipsis;
  }

  .text-label {
    margin: 0 1rem;
  }

  .label-container {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .team-error-message {
    color: red;
    font-size: 1.25rem;
    margin: 1rem;
  }

  .player-error-message {
    color: red;
    text-align: center;
    font-size: 1.25rem;
  }

  @media (min-width: 900px) {
    .input-container {
      width: 65%;
      margin 0 auto;
    }

    .input-name {
      width: 100%;
    }
  }
`;

export default StyledEditTeamModal;
