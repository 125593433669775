import {
  getBonusOrTeamPoints,
  getCellDataPerTypeAndQNumAndTeam,
} from './ScoreSheetHelper';
import { pickBy } from './UtilHelper';

export const validateCellData = ({
  cellList,
  totalNumberQuestions,
  teamList,
}) => {
  let errors = {};
  for (let i = 0; i < totalNumberQuestions; i++) {
    teamList.forEach(({ teamID: teamId }) => {
      const tossUpCellData = getCellDataPerTypeAndQNumAndTeam({
        cellList,
        questionNumber: i + 1,
        type: 'playerTossUp',
        teamId,
      });
      let tossUpCellsWithPoints = pickBy(tossUpCellData, (cellData) => {
        const points = getBonusOrTeamPoints(cellData.points);
        return !isNaN(parseInt(points));
      });

      if (Object.keys(tossUpCellsWithPoints).length > 1) {
        for (const key in tossUpCellsWithPoints) {
          const cellId = tossUpCellData[key].cellId;
          const questionNumber = tossUpCellData[key].questionNumber;
          errors = {
            ...errors,
            [cellId]: {
              description: `Please only enter one score per team for Toss Up Question ${questionNumber}`,
            },
          };
        }
      }
    });
  }
  return errors;
};
