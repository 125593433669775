import React from 'react';
import styled from 'styled-components';

const NumberInput = ({
  className = '',
  shouldRender = false,
  value,
  onChange = () => {},
  label = '',
  disabledEdits = false,
}) => {
  if (!shouldRender) return null;
  return (
    <div className={`number-input ${className}`}>
      <label>
        <input
          disabled={disabledEdits}
          type="number"
          value={value}
          onChange={onChange}
          className="input"
          required
          title=""
        />
        <span className="select-label">{label}</span>
      </label>
    </div>
  );
};

const styledNumberInput = styled(NumberInput)`
  .input {
    width: 6rem;
  }

  &.inline-display {
    display: inline;
  }

  .select-label {
    display: inline;
  }
`;

export default styledNumberInput;
