import { clone, toPath } from 'lodash';

export const get = (obj, path, defaultValue) => {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj,
      );
  const result = travel(/[,[\]]+?/) || travel(/[,'[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

export const checkTruthy = (text) => !!text && text.toLowerCase() === 'yes';

export const parseMinutesAndSeconds = (durationInSeconds) => {
  if (durationInSeconds !== 0 && !durationInSeconds)
    return { minutes: 0, seconds: 0 };
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = Math.floor(durationInSeconds - minutes * 60);
  return { minutes, seconds };
};

// callback has two params: 1. value, 2. key (the key-value pair of each item in the object)
export const objectMap = (object = {}, callback = () => {}) =>
  Object.keys(object).map((key) => callback(object[key], key));

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

// offset the timezone to prevent cross-timezone date changes
export function offsetDateByTimezone(dateObject) {
  if (!dateObject) return dateObject;
  const timezoneOffset = dateObject.getTimezoneOffset() * 60 * 1000;
  return new Date(dateObject.valueOf() + timezoneOffset);
}

export const getSignUpEmailUrl = ({
  email = '',
  firstName = '',
  lastName = '',
  path = '/',
}) => {
  const encodedEmail = encodeURIComponent(email);
  const origin = get(window, 'location.origin');
  const redirectUrl = `${origin}${path}`;
  const encodedUrl = encodeURIComponent(redirectUrl);
  return `http://www.quizbowlsystems.com/paypal/simpemail.php?email=${encodedEmail}&fname=${firstName}&lname=${lastName}&redirect_url=${encodedUrl}`;
};

export const pickBy = (object = {}, cb = () => {}) => {
  const obj = {};
  for (const key in object) {
    if (cb(object[key])) {
      obj[key] = object[key];
    }
  }
  return obj;
};

/** is the given object an integer? */
export const isInteger = (obj) => String(Math.floor(Number(obj))) === obj;

/** is the given object an Object? */
export const isObject = (obj) => obj !== null && typeof obj === 'object';

/**
 * Deeply get a value from an object via its path.
 */
export function getIn(obj, key, def, p = 0) {
  const path = toPath(key);
  while (obj && p < path.length) {
    obj = obj[path[p++]];
  }
  return obj === undefined ? def : obj;
}

export function setIn(obj, path, value) {
  const res = clone(obj); // this keeps inheritance when obj is a class
  let resVal = res;
  let i = 0;
  const pathArray = toPath(path);

  for (; i < pathArray.length - 1; i++) {
    const currentPath = pathArray[i];
    const currentObj = getIn(obj, pathArray.slice(0, i + 1));

    if (currentObj && (isObject(currentObj) || Array.isArray(currentObj))) {
      resVal = resVal[currentPath] = clone(currentObj);
    } else {
      const nextPath = pathArray[i + 1];
      resVal = resVal[currentPath] =
        isInteger(nextPath) && Number(nextPath) >= 0 ? [] : {};
    }
  }

  // Return original object if new value is the same as current
  if ((i === 0 ? obj : resVal)[pathArray[i]] === value) {
    return obj;
  }

  if (value === undefined) {
    delete resVal[pathArray[i]];
  } else {
    resVal[pathArray[i]] = value;
  }

  // If the path array has a single element, the loop did not run.
  // Deleting on `resVal` had no effect in this scenario, so we delete on the result instead.
  if (i === 0 && value === undefined) {
    delete res[pathArray[i]];
  }

  return res;
}

const hasOwn = {}.hasOwnProperty;

export function classNames() {
  let classes = '';

  for (let i = 0; i < arguments.length; i++) {
    const arg = arguments[i];
    if (arg) {
      classes = appendClass(classes, parseValue(arg));
    }
  }

  return classes;
}

function parseValue(arg) {
  if (typeof arg === 'string') {
    return arg;
  }

  if (typeof arg !== 'object') {
    return '';
  }

  if (Array.isArray(arg)) {
    return classNames.apply(null, arg);
  }

  if (
    arg.toString !== Object.prototype.toString &&
    !arg.toString.toString().includes('[native code]')
  ) {
    return arg.toString();
  }

  let classes = '';

  for (const key in arg) {
    if (hasOwn.call(arg, key) && arg[key]) {
      classes = appendClass(classes, key);
    }
  }

  return classes;
}

function appendClass(value, newClass) {
  if (!newClass) {
    return value;
  }

  return value ? value + ' ' + newClass : newClass;
}
