import React from 'react';
import styled from 'styled-components';

const TimerButton = ({
  className,
  enabled = false,
  currentlyActive = false,
  text = '',
  onPlay = () => {},
  onPause = () => {},
  key = '',
}) => {
  if (!enabled) {
    return <div key={key} className={`${className} timer-button-spacer`}></div>;
  } else if (currentlyActive) {
    return (
      <button
        key={key}
        className={`${className} round-button negative`}
        onClick={onPause}
      >
        Pause {text}
      </button>
    );
  } else {
    return (
      <button
        key={key}
        className={`${className} round-button positive`}
        onClick={onPlay}
      >
        Start {text}
      </button>
    );
  }
};

const StyledTimerButton = styled(TimerButton)`
  &.timer-button-spacer {
    width: 15rem;
  }

  &.round-button {
    font-size: 1.25rem;
    margin: 0.5rem;
    text-transform: uppercase;
    padding: 0 10px;
    min-height: 3.8rem;
    max-width: 23rem;
    white-space: normal;
    overflow-wrap: break-word;
    line-height: 1.4rem;

    &:hover {
      text-decoration: underline;
    }

    &.positive {
      background-color: green;
      color: white;
    }

    &.negative {
      background-color: red;
      color: white;
    }
  }
`;

export default StyledTimerButton;
