import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import DuplicatePlayerModal from '../../Components/DuplicatePlayerModal';
import HeaderUnauthed from '../../Components/HeaderUnauthed';
import { get } from '../../Helpers/UtilHelper';
import { MatchClientContext } from '../../Hooks/MatchClientContext';

const SignInClient = ({ className }) => {
  const history = useHistory();
  const location = useLocation();
  const { hostCheckFailed } = location.state || {};
  const { fetchMatch, isPlayerDuplicate, setIsPlayerDuplicate } =
    React.useContext(MatchClientContext);
  const defaultError = hostCheckFailed
    ? 'Please wait for the host to join the match'
    : null;
  const [roomCodeError, setRoomCodeError] = useState(defaultError);
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(['roomCode']);
  const [roomCode, setRoomCode] = useState(cookies.roomCode || '');

  const onSubmit = async () => {
    setLoading(true);
    const match = await fetchMatch({ matchID: roomCode });
    if (!match) {
      setRoomCodeError('That match ID was not found. Try again.');
      setLoading(false);
      return;
    }
    const matchStatus = get(match, 'matchStatus');
    if (matchStatus === 'completed') {
      setRoomCodeError('That match is already complete.');
      setLoading(false);
      return;
    }
    history.push('choose-team');
  };

  const buttonDisabled = roomCode.length !== 6;

  const handleChange = (e) => {
    const text = e.target.value;
    if (text.length > 6) return;
    setCookie('roomCode', text.toUpperCase(), { path: '/' });
    if (roomCodeError) setRoomCodeError(null);
    setRoomCode(text.toUpperCase());
  };

  const handleKeyDown = (e) => {
    const keycode = e.keyCode;
    switch (keycode) {
      case 13: {
        if (!buttonDisabled) onSubmit();
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <div className={`client-sign-in ${className}`}>
      <HeaderUnauthed showTestConnection />
      <label className="room-code-container">
        Enter Match ID:
        <input
          className={`room-code ${roomCodeError ? 'error' : ''}`}
          type="text"
          value={roomCode}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        {roomCodeError && (
          <div className="room-code-error">{roomCodeError}</div>
        )}
      </label>
      <button disabled={buttonDisabled || loading} onClick={onSubmit}>
        {loading ? 'Loading...' : 'Submit'}
      </button>
      {isPlayerDuplicate ? (
        <DuplicatePlayerModal
          confirmAction={() => setIsPlayerDuplicate(false)}
        />
      ) : (
        ''
      )}
    </div>
  );
};

const StyledSignInClient = styled(SignInClient)`
  font-size: 2rem;
  line-height: 1.75rem;
  text-align: center;

  .room-code-container {
    display: block;
    margin: 2rem;
    font-weight: bold;
    font-size: 3rem;
  }

  .room-code {
    display: block;
    margin: 2rem auto 0;
    line-height: 3rem;
    font-size: 3rem;
    text-align: center;
    max-width: 25rem;
    height: 38px;

    &.error {
      border: 1px solid red;
    }
  }

  .room-code-error {
    font-size: 1.5rem;
    color: red;
    margin-top: 1rem;
  }
`;

export default StyledSignInClient;
