import { useState } from 'react';

function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  function handleFieldChange(event) {
    setValues({
      ...fields,
      [event.target.id]: event.target.value,
    });
  }

  return [fields, handleFieldChange];
}

export default useFormFields;
