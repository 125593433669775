import React from 'react';
import styled from 'styled-components';
import SettingsHeader from '../../../../Components/SettingsHeader';
import { MatchSettingsContext } from '../../../../Hooks/MatchSettingsContext';

const TeamQuestions = ({ className, disabledEdits = false }) => {
  const {
    errors,
    setMatchSetting,
    teamQuestionEnabled,
    teamDeliberationMinutes,
    teamDeliberationSeconds,
    teamEndDeliberationEnabled,
  } = React.useContext(MatchSettingsContext);

  const handleteamQuestionEnabledChange = (e) => {
    setMatchSetting({
      payload: { teamQuestionEnabled: e.target.checked },
      errorsToClear: ['teamQuestionEnabled'],
    });
  };

  const handleTeamDeliberationMinutesChange = (e) => {
    setMatchSetting({
      payload: { teamDeliberationMinutes: e.target.value },
      errorsToClear: ['teamDeliberationMinutes'],
    });
  };

  const handleTeamDeliberationSecondsChange = (e) => {
    setMatchSetting({
      payload: { teamDeliberationSeconds: e.target.value },
      errorsToClear: ['teamDeliberationSeconds'],
    });
  };

  const handleEndDeliberationButtonChange = (e) => {
    setMatchSetting({
      payload: { teamEndDeliberationEnabled: e.target.value },
      errorsToClear: ['teamEndDeliberationEnabled'],
    });
  };

  return (
    <div className={`team-question-container ${className}`}>
      <SettingsHeader text="Team Questions" />
      <label className="checkbox-container">
        <input
          disabled={disabledEdits}
          type="checkbox"
          checked={teamQuestionEnabled}
          onChange={handleteamQuestionEnabledChange}
        />
        <span className="select-label">Activate Team Questions</span>
      </label>
      {teamQuestionEnabled && (
        <>
          <div className="timer-fields-container">
            <span className="select-label block">
              Default Deliberation Time:
            </span>
            <div className="minutes-and-seconds-container">
              <div className="minutes-container">
                <label>
                  <input
                    disabled={disabledEdits}
                    type="number"
                    min="0"
                    className="input-time"
                    value={teamDeliberationMinutes}
                    onChange={handleTeamDeliberationMinutesChange}
                  />
                  <span className="select-label">Minutes</span>
                </label>
                {errors.teamDeliberationMinutes && (
                  <div className="error-container">
                    <p>{errors.teamDeliberationMinutes}</p>
                  </div>
                )}
              </div>
              <div className="seconds-container">
                <label>
                  <input
                    disabled={disabledEdits}
                    type="number"
                    min="0"
                    max="59"
                    className="input-time"
                    value={teamDeliberationSeconds}
                    onChange={handleTeamDeliberationSecondsChange}
                  />
                  <span className="select-label">Seconds</span>
                </label>
                {errors.teamDeliberationSeconds && (
                  <div className="error-container">
                    <p>{errors.teamDeliberationSeconds}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <label className="label-container">
              <span className="select-label">Enable End Deliberation</span>
              <select
                disabled={disabledEdits}
                onChange={handleEndDeliberationButtonChange}
                value={teamEndDeliberationEnabled}
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </label>
          </div>
        </>
      )}
      {errors.teamQuestionEnabled && (
        <div className="error-container">
          <p>{errors.tossUpEnabled}</p>
        </div>
      )}
    </div>
  );
};

const StyledTeamQuestions = styled(TeamQuestions)`
  .setting-container {
    display: flex;
  }

  .select-label {
    margin: 1rem;
    font-weight: 600;
  }

  .block {
    display: block;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
  }

  .timer-fields-container {
    display: block;
  }

  .minutes-and-seconds-container {
    display: flex;
    flex-direction: row;
  }

  .input-time {
    width: 6rem;
  }

  .minutes-container,
  .seconds-container {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 900px) {
    .select-label {
      display: inline;
    }
    .timer-fields-container {
      display: flex;
      align-items: baseline;
    }
  }
`;

export default StyledTeamQuestions;
