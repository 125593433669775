import React from 'react';
import styled from 'styled-components';

const ResetPasswordForm = ({
  className,
  fields,
  handleFieldChange,
  isSubmitting,
  onSubmit,
  validateFields,
}) => {
  const submitDisabled = isSubmitting || !validateFields();

  const handleKeyDown = (e) => {
    const keycode = e.keyCode;
    if (keycode === 13 && !submitDisabled) onSubmit();
  };

  return (
    <div className={`send-code-form ${className}`}>
      <div className="description">
        Check your email for your verification code. Enter this code below:
      </div>
      <div className="form-container">
        <label className="input-container">
          <div className="label">Code</div>
          <input
            className="input"
            id="code"
            type="text"
            value={fields.code}
            onChange={handleFieldChange}
            onKeyDown={handleKeyDown}
            placeholder="verification code"
          />
        </label>
      </div>
      <button
        className="change-email-submit"
        disabled={submitDisabled}
        onClick={onSubmit}
      >
        {isSubmitting ? 'Loading...' : 'Update Email'}
      </button>
    </div>
  );
};

const StyledResetPasswordForm = styled(ResetPasswordForm)`
  font-size: 2rem;
  line-height: 1.75rem;
  text-align: center;
`;

export default StyledResetPasswordForm;
