/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMatch = `mutation CreateMatch(
  $input: CreateMatchInput!
  $condition: ModelMatchConditionInput
) {
  createMatch(input: $input, condition: $condition) {
    id
    active
    activePlayerID
    bonusRoundOwner
    createdAt
    matchDate
    matchName
    matchStatus
    updatedAt
    user
    teams {
      items {
        id
        active
        score
        matchID
        teamID
        user
        order
      }
      nextToken
    }
    players {
      items {
        id
        active
        score
        matchID
        playerID
        deviceID
        order
        user
      }
      nextToken
    }
    messages {
      items {
        id
        matchID
        messageType
        playerID
        createdAt
        updatedAt
      }
      nextToken
    }
    settings {
      items {
        id
        matchID
        user
        templateID
        startingPoints
        tossUpEnabled
        tossUpPoints
        showWrongAnswerPenalty
        wrongAnswerPenaltyPoints
        showEarlyCorrectAnswerBonus
        earlyCorrectAnswerPoints
        showEarlyWrongAnswerPenalty
        earlyWrongAnswerPoints
        roundTimerEnabled
        roundMinutes
        roundSeconds
        ringInTimer
        showResetTimer
        resetTimerOption
        resetTimerDuration
        showComputationTime
        computationTime
        ringInLimit
        soundRoundTimerEnabled
        soundRingInEnabled
        soundRingInTimerEnabled
        soundDeliberationTimerEnabled
        teamEndDeliberationEnabled
        teamDeliberationMinutes
        teamDeliberationSeconds
        teamQuestionEnabled
        soundTeamDeliberationTimerEnabled
        bonusQuestionEnabled
        bonusTotalParts
        bonusPointsPerPart
        bonusBouncebackEnabled
        bonusImmediateBouncebackEnabled
        bonusQuestionsFollowTossUps
        bonusControl
        bonusAnswerTimerEnabled
        bonusAnswerSeconds
        bonusDeliberationTimerEnabled
        bonusDeliberationSeconds
        bonusComputationTimerEnabled
        bonusComputationSeconds
        soundBonusAnswerTimerEnabled
        soundBonusDeliberationTimerEnabled
        playerTimersEnabled
        tossUpAnswerEnabled
        tossUpAnswerSeconds
        soundTossUpAnswerEnabled
        timeoutsEnabled
        soundTimeoutTimerEnabled
        preserveMinTimeEnabled
        preserveMinTimeSeconds
      }
      nextToken
    }
    scores {
      items {
        id
        type
        questionNumber
        points
        otherPlayers
        matchID
        playerID
        teamID
        user
      }
      nextToken
    }
  }
}
`;
export const updateMatch = `mutation UpdateMatch(
  $input: UpdateMatchInput!
  $condition: ModelMatchConditionInput
) {
  updateMatch(input: $input, condition: $condition) {
    id
    active
    activePlayerID
    bonusRoundOwner
    createdAt
    matchDate
    matchName
    matchStatus
    updatedAt
    user
    teams {
      items {
        id
        active
        score
        matchID
        teamID
        user
        order
      }
      nextToken
    }
    players {
      items {
        id
        active
        score
        matchID
        playerID
        deviceID
        order
        user
      }
      nextToken
    }
    messages {
      items {
        id
        matchID
        messageType
        playerID
        createdAt
        updatedAt
      }
      nextToken
    }
    settings {
      items {
        id
        matchID
        user
        templateID
        startingPoints
        tossUpEnabled
        tossUpPoints
        showWrongAnswerPenalty
        wrongAnswerPenaltyPoints
        showEarlyCorrectAnswerBonus
        earlyCorrectAnswerPoints
        showEarlyWrongAnswerPenalty
        earlyWrongAnswerPoints
        roundTimerEnabled
        roundMinutes
        roundSeconds
        ringInTimer
        showResetTimer
        resetTimerOption
        resetTimerDuration
        showComputationTime
        computationTime
        ringInLimit
        soundRoundTimerEnabled
        soundRingInEnabled
        soundRingInTimerEnabled
        soundDeliberationTimerEnabled
        teamEndDeliberationEnabled
        teamDeliberationMinutes
        teamDeliberationSeconds
        teamQuestionEnabled
        soundTeamDeliberationTimerEnabled
        bonusQuestionEnabled
        bonusTotalParts
        bonusPointsPerPart
        bonusBouncebackEnabled
        bonusImmediateBouncebackEnabled
        bonusQuestionsFollowTossUps
        bonusControl
        bonusAnswerTimerEnabled
        bonusAnswerSeconds
        bonusDeliberationTimerEnabled
        bonusDeliberationSeconds
        bonusComputationTimerEnabled
        bonusComputationSeconds
        soundBonusAnswerTimerEnabled
        soundBonusDeliberationTimerEnabled
        playerTimersEnabled
        tossUpAnswerEnabled
        tossUpAnswerSeconds
        soundTossUpAnswerEnabled
        timeoutsEnabled
        soundTimeoutTimerEnabled
        preserveMinTimeEnabled
        preserveMinTimeSeconds
      }
      nextToken
    }
    scores {
      items {
        id
        type
        questionNumber
        points
        otherPlayers
        matchID
        playerID
        teamID
        user
      }
      nextToken
    }
  }
}
`;
export const deleteMatch = `mutation DeleteMatch(
  $input: DeleteMatchInput!
  $condition: ModelMatchConditionInput
) {
  deleteMatch(input: $input, condition: $condition) {
    id
    active
    activePlayerID
    bonusRoundOwner
    createdAt
    matchDate
    matchName
    matchStatus
    updatedAt
    user
    teams {
      items {
        id
        active
        score
        matchID
        teamID
        user
        order
      }
      nextToken
    }
    players {
      items {
        id
        active
        score
        matchID
        playerID
        deviceID
        order
        user
      }
      nextToken
    }
    messages {
      items {
        id
        matchID
        messageType
        playerID
        createdAt
        updatedAt
      }
      nextToken
    }
    settings {
      items {
        id
        matchID
        user
        templateID
        startingPoints
        tossUpEnabled
        tossUpPoints
        showWrongAnswerPenalty
        wrongAnswerPenaltyPoints
        showEarlyCorrectAnswerBonus
        earlyCorrectAnswerPoints
        showEarlyWrongAnswerPenalty
        earlyWrongAnswerPoints
        roundTimerEnabled
        roundMinutes
        roundSeconds
        ringInTimer
        showResetTimer
        resetTimerOption
        resetTimerDuration
        showComputationTime
        computationTime
        ringInLimit
        soundRoundTimerEnabled
        soundRingInEnabled
        soundRingInTimerEnabled
        soundDeliberationTimerEnabled
        teamEndDeliberationEnabled
        teamDeliberationMinutes
        teamDeliberationSeconds
        teamQuestionEnabled
        soundTeamDeliberationTimerEnabled
        bonusQuestionEnabled
        bonusTotalParts
        bonusPointsPerPart
        bonusBouncebackEnabled
        bonusImmediateBouncebackEnabled
        bonusQuestionsFollowTossUps
        bonusControl
        bonusAnswerTimerEnabled
        bonusAnswerSeconds
        bonusDeliberationTimerEnabled
        bonusDeliberationSeconds
        bonusComputationTimerEnabled
        bonusComputationSeconds
        soundBonusAnswerTimerEnabled
        soundBonusDeliberationTimerEnabled
        playerTimersEnabled
        tossUpAnswerEnabled
        tossUpAnswerSeconds
        soundTossUpAnswerEnabled
        timeoutsEnabled
        soundTimeoutTimerEnabled
        preserveMinTimeEnabled
        preserveMinTimeSeconds
      }
      nextToken
    }
    scores {
      items {
        id
        type
        questionNumber
        points
        otherPlayers
        matchID
        playerID
        teamID
        user
      }
      nextToken
    }
  }
}
`;
export const createTeam = `mutation CreateTeam(
  $input: CreateTeamInput!
  $condition: ModelTeamConditionInput
) {
  createTeam(input: $input, condition: $condition) {
    id
    active
    name
    updatedAt
    user
    groups {
      items {
        id
        teamGroupID
        teamID
      }
      nextToken
    }
    matches {
      items {
        id
        active
        score
        matchID
        teamID
        user
        order
      }
      nextToken
    }
    players {
      items {
        id
        active
        name
        order
        teamID
        user
      }
      nextToken
    }
    scores {
      items {
        id
        type
        questionNumber
        points
        otherPlayers
        matchID
        playerID
        teamID
        user
      }
      nextToken
    }
  }
}
`;
export const updateTeam = `mutation UpdateTeam(
  $input: UpdateTeamInput!
  $condition: ModelTeamConditionInput
) {
  updateTeam(input: $input, condition: $condition) {
    id
    active
    name
    updatedAt
    user
    groups {
      items {
        id
        teamGroupID
        teamID
      }
      nextToken
    }
    matches {
      items {
        id
        active
        score
        matchID
        teamID
        user
        order
      }
      nextToken
    }
    players {
      items {
        id
        active
        name
        order
        teamID
        user
      }
      nextToken
    }
    scores {
      items {
        id
        type
        questionNumber
        points
        otherPlayers
        matchID
        playerID
        teamID
        user
      }
      nextToken
    }
  }
}
`;
export const deleteTeam = `mutation DeleteTeam(
  $input: DeleteTeamInput!
  $condition: ModelTeamConditionInput
) {
  deleteTeam(input: $input, condition: $condition) {
    id
    active
    name
    updatedAt
    user
    groups {
      items {
        id
        teamGroupID
        teamID
      }
      nextToken
    }
    matches {
      items {
        id
        active
        score
        matchID
        teamID
        user
        order
      }
      nextToken
    }
    players {
      items {
        id
        active
        name
        order
        teamID
        user
      }
      nextToken
    }
    scores {
      items {
        id
        type
        questionNumber
        points
        otherPlayers
        matchID
        playerID
        teamID
        user
      }
      nextToken
    }
  }
}
`;
export const createTeamGroup = `mutation CreateTeamGroup(
  $input: CreateTeamGroupInput!
  $condition: ModelTeamGroupConditionInput
) {
  createTeamGroup(input: $input, condition: $condition) {
    id
    active
    name
    updatedAt
    user
    teams {
      items {
        id
        teamGroupID
        teamID
      }
      nextToken
    }
  }
}
`;
export const updateTeamGroup = `mutation UpdateTeamGroup(
  $input: UpdateTeamGroupInput!
  $condition: ModelTeamGroupConditionInput
) {
  updateTeamGroup(input: $input, condition: $condition) {
    id
    active
    name
    updatedAt
    user
    teams {
      items {
        id
        teamGroupID
        teamID
      }
      nextToken
    }
  }
}
`;
export const deleteTeamGroup = `mutation DeleteTeamGroup(
  $input: DeleteTeamGroupInput!
  $condition: ModelTeamGroupConditionInput
) {
  deleteTeamGroup(input: $input, condition: $condition) {
    id
    active
    name
    updatedAt
    user
    teams {
      items {
        id
        teamGroupID
        teamID
      }
      nextToken
    }
  }
}
`;
export const createTeamGroupLink = `mutation CreateTeamGroupLink(
  $input: CreateTeamGroupLinkInput!
  $condition: ModelTeamGroupLinkConditionInput
) {
  createTeamGroupLink(input: $input, condition: $condition) {
    id
    teamGroupID
    teamID
    team {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
    group {
      id
      active
      name
      updatedAt
      user
      teams {
        nextToken
      }
    }
  }
}
`;
export const updateTeamGroupLink = `mutation UpdateTeamGroupLink(
  $input: UpdateTeamGroupLinkInput!
  $condition: ModelTeamGroupLinkConditionInput
) {
  updateTeamGroupLink(input: $input, condition: $condition) {
    id
    teamGroupID
    teamID
    team {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
    group {
      id
      active
      name
      updatedAt
      user
      teams {
        nextToken
      }
    }
  }
}
`;
export const deleteTeamGroupLink = `mutation DeleteTeamGroupLink(
  $input: DeleteTeamGroupLinkInput!
  $condition: ModelTeamGroupLinkConditionInput
) {
  deleteTeamGroupLink(input: $input, condition: $condition) {
    id
    teamGroupID
    teamID
    team {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
    group {
      id
      active
      name
      updatedAt
      user
      teams {
        nextToken
      }
    }
  }
}
`;
export const createMatchTeam = `mutation CreateMatchTeam(
  $input: CreateMatchTeamInput!
  $condition: ModelMatchTeamConditionInput
) {
  createMatchTeam(input: $input, condition: $condition) {
    id
    active
    score
    matchID
    teamID
    user
    order
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    team {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;
export const updateMatchTeam = `mutation UpdateMatchTeam(
  $input: UpdateMatchTeamInput!
  $condition: ModelMatchTeamConditionInput
) {
  updateMatchTeam(input: $input, condition: $condition) {
    id
    active
    score
    matchID
    teamID
    user
    order
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    team {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;
export const deleteMatchTeam = `mutation DeleteMatchTeam(
  $input: DeleteMatchTeamInput!
  $condition: ModelMatchTeamConditionInput
) {
  deleteMatchTeam(input: $input, condition: $condition) {
    id
    active
    score
    matchID
    teamID
    user
    order
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    team {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;
export const createPlayer = `mutation CreatePlayer(
  $input: CreatePlayerInput!
  $condition: ModelPlayerConditionInput
) {
  createPlayer(input: $input, condition: $condition) {
    id
    active
    name
    order
    teamID
    user
    team {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
    matches {
      items {
        id
        active
        score
        matchID
        playerID
        deviceID
        order
        user
      }
      nextToken
    }
    messages {
      items {
        id
        matchID
        messageType
        playerID
        createdAt
        updatedAt
      }
      nextToken
    }
    scores {
      items {
        id
        type
        questionNumber
        points
        otherPlayers
        matchID
        playerID
        teamID
        user
      }
      nextToken
    }
  }
}
`;
export const updatePlayer = `mutation UpdatePlayer(
  $input: UpdatePlayerInput!
  $condition: ModelPlayerConditionInput
) {
  updatePlayer(input: $input, condition: $condition) {
    id
    active
    name
    order
    teamID
    user
    team {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
    matches {
      items {
        id
        active
        score
        matchID
        playerID
        deviceID
        order
        user
      }
      nextToken
    }
    messages {
      items {
        id
        matchID
        messageType
        playerID
        createdAt
        updatedAt
      }
      nextToken
    }
    scores {
      items {
        id
        type
        questionNumber
        points
        otherPlayers
        matchID
        playerID
        teamID
        user
      }
      nextToken
    }
  }
}
`;
export const deletePlayer = `mutation DeletePlayer(
  $input: DeletePlayerInput!
  $condition: ModelPlayerConditionInput
) {
  deletePlayer(input: $input, condition: $condition) {
    id
    active
    name
    order
    teamID
    user
    team {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
    matches {
      items {
        id
        active
        score
        matchID
        playerID
        deviceID
        order
        user
      }
      nextToken
    }
    messages {
      items {
        id
        matchID
        messageType
        playerID
        createdAt
        updatedAt
      }
      nextToken
    }
    scores {
      items {
        id
        type
        questionNumber
        points
        otherPlayers
        matchID
        playerID
        teamID
        user
      }
      nextToken
    }
  }
}
`;
export const createMatchPlayer = `mutation CreateMatchPlayer(
  $input: CreateMatchPlayerInput!
  $condition: ModelMatchPlayerConditionInput
) {
  createMatchPlayer(input: $input, condition: $condition) {
    id
    active
    score
    matchID
    playerID
    deviceID
    order
    user
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    player {
      id
      active
      name
      order
      teamID
      user
      team {
        id
        active
        name
        updatedAt
        user
      }
      matches {
        nextToken
      }
      messages {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;
export const updateMatchPlayer = `mutation UpdateMatchPlayer(
  $input: UpdateMatchPlayerInput!
  $condition: ModelMatchPlayerConditionInput
) {
  updateMatchPlayer(input: $input, condition: $condition) {
    id
    active
    score
    matchID
    playerID
    deviceID
    order
    user
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    player {
      id
      active
      name
      order
      teamID
      user
      team {
        id
        active
        name
        updatedAt
        user
      }
      matches {
        nextToken
      }
      messages {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;
export const deleteMatchPlayer = `mutation DeleteMatchPlayer(
  $input: DeleteMatchPlayerInput!
  $condition: ModelMatchPlayerConditionInput
) {
  deleteMatchPlayer(input: $input, condition: $condition) {
    id
    active
    score
    matchID
    playerID
    deviceID
    order
    user
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    player {
      id
      active
      name
      order
      teamID
      user
      team {
        id
        active
        name
        updatedAt
        user
      }
      matches {
        nextToken
      }
      messages {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;
export const createMatchSettings = `mutation CreateMatchSettings(
  $input: CreateMatchSettingsInput!
  $condition: ModelMatchSettingsConditionInput
) {
  createMatchSettings(input: $input, condition: $condition) {
    id
    matchID
    user
    templateID
    startingPoints
    tossUpEnabled
    tossUpPoints
    showWrongAnswerPenalty
    wrongAnswerPenaltyPoints
    showEarlyCorrectAnswerBonus
    earlyCorrectAnswerPoints
    showEarlyWrongAnswerPenalty
    earlyWrongAnswerPoints
    roundTimerEnabled
    roundMinutes
    roundSeconds
    ringInTimer
    showResetTimer
    resetTimerOption
    resetTimerDuration
    showComputationTime
    computationTime
    ringInLimit
    soundRoundTimerEnabled
    soundRingInEnabled
    soundRingInTimerEnabled
    soundDeliberationTimerEnabled
    teamEndDeliberationEnabled
    teamDeliberationMinutes
    teamDeliberationSeconds
    teamQuestionEnabled
    soundTeamDeliberationTimerEnabled
    bonusQuestionEnabled
    bonusTotalParts
    bonusPointsPerPart
    bonusBouncebackEnabled
    bonusImmediateBouncebackEnabled
    bonusQuestionsFollowTossUps
    bonusControl
    bonusAnswerTimerEnabled
    bonusAnswerSeconds
    bonusDeliberationTimerEnabled
    bonusDeliberationSeconds
    bonusComputationTimerEnabled
    bonusComputationSeconds
    soundBonusAnswerTimerEnabled
    soundBonusDeliberationTimerEnabled
    playerTimersEnabled
    tossUpAnswerEnabled
    tossUpAnswerSeconds
    soundTossUpAnswerEnabled
    timeoutsEnabled
    soundTimeoutTimerEnabled
    preserveMinTimeEnabled
    preserveMinTimeSeconds
    timeouts {
      name
      duration
      order
    }
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;
export const updateMatchSettings = `mutation UpdateMatchSettings(
  $input: UpdateMatchSettingsInput!
  $condition: ModelMatchSettingsConditionInput
) {
  updateMatchSettings(input: $input, condition: $condition) {
    id
    matchID
    user
    templateID
    startingPoints
    tossUpEnabled
    tossUpPoints
    showWrongAnswerPenalty
    wrongAnswerPenaltyPoints
    showEarlyCorrectAnswerBonus
    earlyCorrectAnswerPoints
    showEarlyWrongAnswerPenalty
    earlyWrongAnswerPoints
    roundTimerEnabled
    roundMinutes
    roundSeconds
    ringInTimer
    showResetTimer
    resetTimerOption
    resetTimerDuration
    showComputationTime
    computationTime
    ringInLimit
    soundRoundTimerEnabled
    soundRingInEnabled
    soundRingInTimerEnabled
    soundDeliberationTimerEnabled
    teamEndDeliberationEnabled
    teamDeliberationMinutes
    teamDeliberationSeconds
    teamQuestionEnabled
    soundTeamDeliberationTimerEnabled
    bonusQuestionEnabled
    bonusTotalParts
    bonusPointsPerPart
    bonusBouncebackEnabled
    bonusImmediateBouncebackEnabled
    bonusQuestionsFollowTossUps
    bonusControl
    bonusAnswerTimerEnabled
    bonusAnswerSeconds
    bonusDeliberationTimerEnabled
    bonusDeliberationSeconds
    bonusComputationTimerEnabled
    bonusComputationSeconds
    soundBonusAnswerTimerEnabled
    soundBonusDeliberationTimerEnabled
    playerTimersEnabled
    tossUpAnswerEnabled
    tossUpAnswerSeconds
    soundTossUpAnswerEnabled
    timeoutsEnabled
    soundTimeoutTimerEnabled
    preserveMinTimeEnabled
    preserveMinTimeSeconds
    timeouts {
      name
      duration
      order
    }
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;
export const deleteMatchSettings = `mutation DeleteMatchSettings(
  $input: DeleteMatchSettingsInput!
  $condition: ModelMatchSettingsConditionInput
) {
  deleteMatchSettings(input: $input, condition: $condition) {
    id
    matchID
    user
    templateID
    startingPoints
    tossUpEnabled
    tossUpPoints
    showWrongAnswerPenalty
    wrongAnswerPenaltyPoints
    showEarlyCorrectAnswerBonus
    earlyCorrectAnswerPoints
    showEarlyWrongAnswerPenalty
    earlyWrongAnswerPoints
    roundTimerEnabled
    roundMinutes
    roundSeconds
    ringInTimer
    showResetTimer
    resetTimerOption
    resetTimerDuration
    showComputationTime
    computationTime
    ringInLimit
    soundRoundTimerEnabled
    soundRingInEnabled
    soundRingInTimerEnabled
    soundDeliberationTimerEnabled
    teamEndDeliberationEnabled
    teamDeliberationMinutes
    teamDeliberationSeconds
    teamQuestionEnabled
    soundTeamDeliberationTimerEnabled
    bonusQuestionEnabled
    bonusTotalParts
    bonusPointsPerPart
    bonusBouncebackEnabled
    bonusImmediateBouncebackEnabled
    bonusQuestionsFollowTossUps
    bonusControl
    bonusAnswerTimerEnabled
    bonusAnswerSeconds
    bonusDeliberationTimerEnabled
    bonusDeliberationSeconds
    bonusComputationTimerEnabled
    bonusComputationSeconds
    soundBonusAnswerTimerEnabled
    soundBonusDeliberationTimerEnabled
    playerTimersEnabled
    tossUpAnswerEnabled
    tossUpAnswerSeconds
    soundTossUpAnswerEnabled
    timeoutsEnabled
    soundTimeoutTimerEnabled
    preserveMinTimeEnabled
    preserveMinTimeSeconds
    timeouts {
      name
      duration
      order
    }
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;
export const createMatchTemplate = `mutation CreateMatchTemplate(
  $input: CreateMatchTemplateInput!
  $condition: ModelMatchTemplateConditionInput
) {
  createMatchTemplate(input: $input, condition: $condition) {
    id
    active
    templateDescription
    templateName
    templateType
    updatedAt
    user
    startingPoints
    tossUpEnabled
    tossUpPoints
    showWrongAnswerPenalty
    wrongAnswerPenaltyPoints
    showEarlyCorrectAnswerBonus
    earlyCorrectAnswerPoints
    showEarlyWrongAnswerPenalty
    earlyWrongAnswerPoints
    roundTimerEnabled
    roundMinutes
    roundSeconds
    ringInTimer
    showResetTimer
    resetTimerOption
    resetTimerDuration
    showComputationTime
    computationTime
    ringInLimit
    soundRoundTimerEnabled
    soundRingInEnabled
    soundRingInTimerEnabled
    soundDeliberationTimerEnabled
    teamEndDeliberationEnabled
    teamDeliberationMinutes
    teamDeliberationSeconds
    teamQuestionEnabled
    soundTeamDeliberationTimerEnabled
    bonusQuestionEnabled
    bonusTotalParts
    bonusPointsPerPart
    bonusBouncebackEnabled
    bonusImmediateBouncebackEnabled
    bonusQuestionsFollowTossUps
    bonusControl
    bonusAnswerTimerEnabled
    bonusAnswerSeconds
    bonusDeliberationTimerEnabled
    bonusDeliberationSeconds
    bonusComputationTimerEnabled
    bonusComputationSeconds
    soundBonusAnswerTimerEnabled
    soundBonusDeliberationTimerEnabled
    playerTimersEnabled
    tossUpAnswerEnabled
    tossUpAnswerSeconds
    soundTossUpAnswerEnabled
    timeoutsEnabled
    soundTimeoutTimerEnabled
    preserveMinTimeEnabled
    preserveMinTimeSeconds
    timeouts {
      name
      duration
      order
    }
  }
}
`;
export const updateMatchTemplate = `mutation UpdateMatchTemplate(
  $input: UpdateMatchTemplateInput!
  $condition: ModelMatchTemplateConditionInput
) {
  updateMatchTemplate(input: $input, condition: $condition) {
    id
    active
    templateDescription
    templateName
    templateType
    updatedAt
    user
    startingPoints
    tossUpEnabled
    tossUpPoints
    showWrongAnswerPenalty
    wrongAnswerPenaltyPoints
    showEarlyCorrectAnswerBonus
    earlyCorrectAnswerPoints
    showEarlyWrongAnswerPenalty
    earlyWrongAnswerPoints
    roundTimerEnabled
    roundMinutes
    roundSeconds
    ringInTimer
    showResetTimer
    resetTimerOption
    resetTimerDuration
    showComputationTime
    computationTime
    ringInLimit
    soundRoundTimerEnabled
    soundRingInEnabled
    soundRingInTimerEnabled
    soundDeliberationTimerEnabled
    teamEndDeliberationEnabled
    teamDeliberationMinutes
    teamDeliberationSeconds
    teamQuestionEnabled
    soundTeamDeliberationTimerEnabled
    bonusQuestionEnabled
    bonusTotalParts
    bonusPointsPerPart
    bonusBouncebackEnabled
    bonusImmediateBouncebackEnabled
    bonusQuestionsFollowTossUps
    bonusControl
    bonusAnswerTimerEnabled
    bonusAnswerSeconds
    bonusDeliberationTimerEnabled
    bonusDeliberationSeconds
    bonusComputationTimerEnabled
    bonusComputationSeconds
    soundBonusAnswerTimerEnabled
    soundBonusDeliberationTimerEnabled
    playerTimersEnabled
    tossUpAnswerEnabled
    tossUpAnswerSeconds
    soundTossUpAnswerEnabled
    timeoutsEnabled
    soundTimeoutTimerEnabled
    preserveMinTimeEnabled
    preserveMinTimeSeconds
    timeouts {
      name
      duration
      order
    }
  }
}
`;
export const deleteMatchTemplate = `mutation DeleteMatchTemplate(
  $input: DeleteMatchTemplateInput!
  $condition: ModelMatchTemplateConditionInput
) {
  deleteMatchTemplate(input: $input, condition: $condition) {
    id
    active
    templateDescription
    templateName
    templateType
    updatedAt
    user
    startingPoints
    tossUpEnabled
    tossUpPoints
    showWrongAnswerPenalty
    wrongAnswerPenaltyPoints
    showEarlyCorrectAnswerBonus
    earlyCorrectAnswerPoints
    showEarlyWrongAnswerPenalty
    earlyWrongAnswerPoints
    roundTimerEnabled
    roundMinutes
    roundSeconds
    ringInTimer
    showResetTimer
    resetTimerOption
    resetTimerDuration
    showComputationTime
    computationTime
    ringInLimit
    soundRoundTimerEnabled
    soundRingInEnabled
    soundRingInTimerEnabled
    soundDeliberationTimerEnabled
    teamEndDeliberationEnabled
    teamDeliberationMinutes
    teamDeliberationSeconds
    teamQuestionEnabled
    soundTeamDeliberationTimerEnabled
    bonusQuestionEnabled
    bonusTotalParts
    bonusPointsPerPart
    bonusBouncebackEnabled
    bonusImmediateBouncebackEnabled
    bonusQuestionsFollowTossUps
    bonusControl
    bonusAnswerTimerEnabled
    bonusAnswerSeconds
    bonusDeliberationTimerEnabled
    bonusDeliberationSeconds
    bonusComputationTimerEnabled
    bonusComputationSeconds
    soundBonusAnswerTimerEnabled
    soundBonusDeliberationTimerEnabled
    playerTimersEnabled
    tossUpAnswerEnabled
    tossUpAnswerSeconds
    soundTossUpAnswerEnabled
    timeoutsEnabled
    soundTimeoutTimerEnabled
    preserveMinTimeEnabled
    preserveMinTimeSeconds
    timeouts {
      name
      duration
      order
    }
  }
}
`;
export const createScore = `mutation CreateScore(
  $input: CreateScoreInput!
  $condition: ModelScoreConditionInput
) {
  createScore(input: $input, condition: $condition) {
    id
    type
    questionNumber
    points
    otherPlayers
    matchID
    playerID
    teamID
    user
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    team {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
    player {
      id
      active
      name
      order
      teamID
      user
      team {
        id
        active
        name
        updatedAt
        user
      }
      matches {
        nextToken
      }
      messages {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;
export const updateScore = `mutation UpdateScore(
  $input: UpdateScoreInput!
  $condition: ModelScoreConditionInput
) {
  updateScore(input: $input, condition: $condition) {
    id
    type
    questionNumber
    points
    otherPlayers
    matchID
    playerID
    teamID
    user
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    team {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
    player {
      id
      active
      name
      order
      teamID
      user
      team {
        id
        active
        name
        updatedAt
        user
      }
      matches {
        nextToken
      }
      messages {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;
export const deleteScore = `mutation DeleteScore(
  $input: DeleteScoreInput!
  $condition: ModelScoreConditionInput
) {
  deleteScore(input: $input, condition: $condition) {
    id
    type
    questionNumber
    points
    otherPlayers
    matchID
    playerID
    teamID
    user
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    team {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
    player {
      id
      active
      name
      order
      teamID
      user
      team {
        id
        active
        name
        updatedAt
        user
      }
      matches {
        nextToken
      }
      messages {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;
export const createMessage = `mutation CreateMessage(
  $input: CreateMessageInput!
  $condition: ModelMessageConditionInput
) {
  createMessage(input: $input, condition: $condition) {
    id
    matchID
    messageType
    playerID
    createdAt
    updatedAt
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    player {
      id
      active
      name
      order
      teamID
      user
      team {
        id
        active
        name
        updatedAt
        user
      }
      matches {
        nextToken
      }
      messages {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;
export const updateMessage = `mutation UpdateMessage(
  $input: UpdateMessageInput!
  $condition: ModelMessageConditionInput
) {
  updateMessage(input: $input, condition: $condition) {
    id
    matchID
    messageType
    playerID
    createdAt
    updatedAt
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    player {
      id
      active
      name
      order
      teamID
      user
      team {
        id
        active
        name
        updatedAt
        user
      }
      matches {
        nextToken
      }
      messages {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;
export const deleteMessage = `mutation DeleteMessage(
  $input: DeleteMessageInput!
  $condition: ModelMessageConditionInput
) {
  deleteMessage(input: $input, condition: $condition) {
    id
    matchID
    messageType
    playerID
    createdAt
    updatedAt
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    player {
      id
      active
      name
      order
      teamID
      user
      team {
        id
        active
        name
        updatedAt
        user
      }
      matches {
        nextToken
      }
      messages {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;
