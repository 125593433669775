import React from 'react';

export default function useRingInKeyPress(onRingIn, keys) {
  const handleRingInKeyPress = React.useCallback(
    (e) => {
      if (keys.includes(e.key)) {
        onRingIn();
      }
    },
    [onRingIn],
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handleRingInKeyPress);
    return () => {
      window.removeEventListener('keydown', handleRingInKeyPress);
    };
  }, [handleRingInKeyPress]);
}
