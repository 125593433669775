// same response body as graphql/custom/queries > getMatch
export const updateMatch = `mutation UpdateMatch(
  $input: UpdateMatchInput!
  $condition: ModelMatchConditionInput
) {
  updateMatch(input: $input, condition: $condition) {
    id
    active
    activePlayerID
    bonusRoundOwner
    matchDate
    matchName
    matchStatus
    updatedAt
    user
    teams {
      items {
        id
        active
        score
        matchID
        teamID
        team {
          id
          name
          updatedAt
          user
          players {
            items {
              id
              name
              order
              teamID
              user
            }
            nextToken
          }
        }
        user
      }
      nextToken
    }
    players {
      items {
        id
        active
        score
        matchID
        order
        playerID
        player {
          id
          name
          order
          teamID
          user
        }
        user
      }
      nextToken
    }
    messages {
      items {
        id
        matchID
        messageType
        playerID
        updatedAt
      }
      nextToken
    }
  }
}
`;

export const createScore = `mutation CreateScore(
  $input: CreateScoreInput!
  $condition: ModelScoreConditionInput
) {
  createScore(input: $input, condition: $condition) {
    id
    type
    questionNumber
    points
    otherPlayers
    playerName
    matchID
    playerID
    teamID
    user
  }
}
`;

export const updateScore = `mutation UpdateScore(
  $input: UpdateScoreInput!
  $condition: ModelScoreConditionInput
) {
  updateScore(input: $input, condition: $condition) {
    id
    type
    questionNumber
    points
    otherPlayers
    playerName
    matchID
    playerID
    teamID
    user
  }
}
`;
