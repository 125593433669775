import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import ApiPlayground from './Screens/ApiPlayground';
import ChangeEmail from './Screens/ChangeEmail';
import ChooseTeam from './Screens/ChooseTeam';
import ClientRoom from './Screens/ClientRoom';
import ConfirmPurchase from './Screens/ConfirmPurchase';
import ConnectionTest from './Screens/ConnectionTest';
import Dashboard from './Screens/Dashboard';
import HostAccount from './Screens/HostAccount';
import HostRoom from './Screens/HostRoom';
import ResetPassword from './Screens/ResetPassword';
import SignInClient from './Screens/SignInClient';
import SignInHost from './Screens/SignInHost';
import SignUpHost from './Screens/SignUpHost';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/api-playground" component={ApiPlayground} />
          <Route path="/confirm-purchase" component={ConfirmPurchase} />
          <Route path="/host/sign-up/:bypassCode" component={SignUpHost} />
          <Route path="/host/sign-up" component={SignUpHost} />
          <Route path="/host/sign-in" component={SignInHost} />
          <Route path="/host/reset-password" component={ResetPassword} />
          <Route path="/host/change-email" component={ChangeEmail} />
          <Route path="/host/account" component={HostAccount} />
          <Route path="/host/:matchID" component={HostRoom} />
          <Route path="/host" component={Dashboard} />
          <Route path="/choose-team" component={ChooseTeam} />
          <Route path="/game" component={ClientRoom} />
          <Route path="/connection-test" component={ConnectionTest} />
          <Route path="/" component={SignInClient} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
