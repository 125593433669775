import React from 'react';
import styled from 'styled-components';
import { MatchSettingsContext } from '../../../../Hooks/MatchSettingsContext';
import { MatchTemplateContext } from '../../../../Hooks/MatchTemplateContext';
import CreateMatchTemplateModal from './Components/CreateMatchTemplateModal';
import MatchTemplatesList from './Components/MatchTemplatesList';

const MatchTemplateView = ({ className }) => {
  const {
    publicMatchTemplates,
    privateMatchTemplates,
    fetchMatchTemplates,
    fetchMatchTemplatesComplete,
  } = React.useContext(MatchTemplateContext);
  const { handleResetState } = React.useContext(MatchSettingsContext);
  const [
    showCreateMatchTemplateModal,
    setShowCreateMatchTemplateModal,
  ] = React.useState(false);

  React.useEffect(() => {
    fetchMatchTemplates();
  }, []);

  const toggleCreateMatchTemplateModal = () => {
    setShowCreateMatchTemplateModal((prev) => !prev);
    handleResetState();
  };

  return (
    <div className={`your-matches ${className}`}>
      <button onClick={toggleCreateMatchTemplateModal}>
        Create Match Settings
      </button>
      {showCreateMatchTemplateModal && (
        <CreateMatchTemplateModal
          toggleCreateMatchTemplateModal={toggleCreateMatchTemplateModal}
          fetchMatchTemplates={fetchMatchTemplates}
        />
      )}
      <div className="template-container">
        <MatchTemplatesList
          fetchMatchTemplatesComplete={fetchMatchTemplatesComplete}
          matchTemplates={privateMatchTemplates}
          headerText="Your Match Settings"
        />
        <MatchTemplatesList
          fetchMatchTemplatesComplete={fetchMatchTemplatesComplete}
          matchTemplates={publicMatchTemplates}
          headerText="Shared Match Settings"
        />
      </div>
    </div>
  );
};

const StyledMatchTemplateView = styled(MatchTemplateView)`
  width: 100%;
  font-size: 2rem;
  line-height: 1.75rem;
  text-align: center;

  .template-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  @media (min-width: 900px) {
    .button-container {
      margin-top: 5rem;
    }

    .template-container {
      flex-direction: row;
      width: 80%;
      margin: 0 auto;
    }
  }
`;

export default StyledMatchTemplateView;
