import React, { useContext } from 'react';
import styled from 'styled-components';
import { MatchSettingsContext } from '../../../../Hooks/MatchSettingsContext';
import SettingsHeader from '../../../SettingsHeader';

const SettingsStartingPoints = ({ className, disabledEdits }) => {
  const { errors, setMatchSetting, startingPoints } = useContext(
    MatchSettingsContext,
  );

  const handleStartingPointsChange = (e) => {
    setMatchSetting({
      payload: { startingPoints: e.target.value },
      errorsToClear: ['startingPoints'],
    });
  };

  return (
    <div className={`settings-round-timer-container ${className}`}>
      <SettingsHeader text="Starting Points" />
      <label>
        <input
          disabled={disabledEdits}
          type="number"
          value={startingPoints}
          onChange={handleStartingPointsChange}
          className="input-points"
          required
        />
        <span className="select-label">Points</span>
      </label>
      {errors.startingPoints && (
        <div className="error-container">
          <p>{errors.startingPoints}</p>
        </div>
      )}
    </div>
  );
};

const StyledSettingsStartingPoints = styled(SettingsStartingPoints)`
  .input-points {
    width: 6rem;
  }

  .select-label {
    margin: 1rem;
  }

  @media (min-width: 900px) {
    .select-label {
      display: inline;
    }
  }
`;

export default StyledSettingsStartingPoints;
