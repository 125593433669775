import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import HeaderUnauthed from '../../Components/HeaderUnauthed';
import {
  ERROR_TYPE_SIGNIN_EMAIL,
  ERROR_TYPE_SIGNIN_PASSWORD,
} from '../../Helpers/AuthHelper';
import { AuthContext } from '../../Hooks/AuthContext';
import useHostLoader from '../../Hooks/useHostLoader';

const SignInHost = ({ className }) => {
  useHostLoader();
  const history = useHistory();
  const { signIn } = React.useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signInError, setSignInError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    if (!email || !password) {
      setSignInError('Email or password is missing');
      return;
    }
    setLoading(true);
    const signInResponse = await signIn({ username: email, password });
    if (signInResponse.error) {
      setLoading(false);
      switch (signInResponse.error) {
        case ERROR_TYPE_SIGNIN_EMAIL:
          setSignInError('Incorrect email');
          break;
        case ERROR_TYPE_SIGNIN_PASSWORD:
          setSignInError('Incorrect password');
          break;
        default:
          setSignInError('Something went wrong, try again');
          break;
      }
      return;
    }
    history.push('/host');
  };

  const handleKeyDown = (e) => {
    if (signInError) setSignInError(null);
    const keycode = e.keyCode;
    switch (keycode) {
      case 13: {
        onSubmit();
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <div className={`host-sign-in ${className}`}>
      <HeaderUnauthed hideHostLogin showContactUs showTestConnection />
      <div className="container">
        <h1 className="title">Host Login</h1>
        <div className="signup-link">
          Don't have an account?{' '}
          <Link className="link" to="/host/sign-up">
            Subscribe here
          </Link>
        </div>
        <label className="input-container">
          <div className="label">Email</div>
          <input
            className="input"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="e-mail address"
          />
        </label>
        <label className="input-container">
          <div className="label">Password</div>
          <input
            className="input"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="password"
          />
        </label>
        {signInError && <div className="signin-code-error">{signInError}</div>}
        <button disabled={loading} onClick={onSubmit}>
          {loading ? 'Loading...' : 'Sign In'}
        </button>
        <div className="other-links">
          <Link className="link" to="/host/reset-password">
            Forgot Password?
          </Link>
        </div>
      </div>
    </div>
  );
};

const StyledSignInHost = styled(SignInHost)`
  font-size: 2rem;
  line-height: 1.75rem;
  text-align: center;

  .container {
    width: 40rem;
    max-width: 100%;
    margin: -2rem auto 0;
  }

  .title {
    font-size: 3.5rem;
    margin-bottom: 3rem;
  }

  .input-container {
    display: flex;
    align-items: center;
    margin: 1.25rem;

    &.plans {
      min-height: 89px;
    }
  }

  .label {
    min-width: 10rem;
    text-align: end;
    line-height: 2.8rem;
    flex: 0;
  }

  .input-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (min-width: 900px) {
      flex-direction: row;
    }
  }

  .input {
    height: 30px;
    margin: 0.3rem 0 0.3rem 1rem;
    flex: 1;
    text-align: center;

    &.error {
      border-color: red;
    }
  }

  .signup-link {
    font-size: 1.75rem;
    margin: 3rem;
  }

  .signin-code-error {
    font-size: 1.5rem;
    color: red;
    margin: 1rem 0;
  }

  .other-links {
    font-size: 1.75rem;
    margin: 1rem;
  }

  .link {
    text-decoration: none;
  }
`;

export default StyledSignInHost;
