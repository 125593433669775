import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { WindowClose } from '../../Helpers/IconHelper';
import { AuthContext } from '../../Hooks/AuthContext';
import ShareTeamsForm from '../ShareTeamsForm';

const UploadTeamModal = ({
  className,
  toggleModal = () => {},
  descriptionText = '',
  uploadAction = () => {},
  createTeam = () => {},
  fileInput,
  fileSelected = false,
  fileUploadError = false,
  uploadErrorText = 'Upload error.',
  isSubmitting = false,
  closeUploadSuccessModal = () => {},
  fileUploadSuccess = true,
  duplicateListOfTeams = [],
  getTeamsToUpload = () => {},
  uniqueListOfTeams = [],
  enableShareTeams,
  groupErrorText,
  handleRemoveGroupError,
  newGroup,
  onChangeNewGroup,
  onChangeSelectedGroup,
  selectedGroup,
  setEnableShareTeams,
  teamGroups,
}) => {
  const { isAdmin } = React.useContext(AuthContext);

  const preUploadContent = (
    <div className={`modal ${fileUploadError && 'modal-error'}`}>
      <div className="modal-header">
        <p className="modal-text">{descriptionText}</p>
        <button className="modal-close-button" onClick={toggleModal}>
          <WindowClose />
        </button>
      </div>
      <div className="upload-container">
        <p>Please upload a CSV or XLS file with teams.</p>
        <p>
          Download a{' '}
          <Link to="/Template_Team_Upload.xlsx" target="_blank" download>
            XLS Template
          </Link>{' '}
          or a{' '}
          <Link to="/Template_Team_Upload.csv" target="_blank" download>
            CSV Template
          </Link>
          .
        </p>
        <input
          className="file-input"
          ref={fileInput}
          type="file"
          onChange={uploadAction}
        ></input>
      </div>
      {fileUploadError && (
        <div className="error-container">
          <p className="error-text">{uploadErrorText}</p>
        </div>
      )}
      <div className="modal-button-container">
        <button
          className="button-yes"
          disabled={!fileSelected || fileUploadError}
          onClick={getTeamsToUpload}
          title="Upload a file"
        >
          Upload
        </button>
        <button className="button-yes" onClick={toggleModal}>
          Cancel
        </button>
      </div>
    </div>
  );

  const postUploadContent = (
    <div className={`modal`}>
      <div className="modal-header">
        <p className="modal-text">{descriptionText}</p>
        <button
          className="modal-close-button"
          onClick={closeUploadSuccessModal}
        >
          <WindowClose />
        </button>
      </div>
      <div className="upload-container">
        {uniqueListOfTeams.length > 0 && (
          <p>
            The following team(s) will be uploaded:{' '}
            <span className="duplicate-team-text">
              {uniqueListOfTeams.join(', ')}
            </span>
          </p>
        )}
        {duplicateListOfTeams.length > 0 && (
          <p className="duplicate-team-warning">
            Warning: The following team(s) were found as duplicates and will not
            be uploaded:{' '}
            <span className="duplicate-team-text">
              {duplicateListOfTeams.join(', ')}
            </span>
          </p>
        )}
        {isAdmin && (
          <ShareTeamsForm
            enableShareTeams={enableShareTeams}
            newGroup={newGroup}
            onChangeNewGroup={onChangeNewGroup}
            onChangeSelectedGroup={onChangeSelectedGroup}
            selectedGroup={selectedGroup}
            setEnableShareTeams={setEnableShareTeams}
            teamGroups={teamGroups}
            handleRemoveGroupError={handleRemoveGroupError}
          />
        )}
        {groupErrorText && (
          <div className="error-message">
            <p>{groupErrorText}</p>
          </div>
        )}
        <p>Would you like to proceed?</p>
      </div>
      <div className="modal-button-container">
        <button
          className="button-yes"
          onClick={createTeam}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Loading...' : 'Yes'}
        </button>
        <button
          className="button-yes"
          onClick={closeUploadSuccessModal}
          disabled={isSubmitting}
        >
          No
        </button>
      </div>
    </div>
  );

  return ReactDOM.createPortal(
    <div className={`modal-container ${className}`}>
      <div
        className="modal-overlay"
        onClick={fileUploadSuccess ? closeUploadSuccessModal : toggleModal}
      ></div>
      {fileUploadSuccess ? postUploadContent : preUploadContent}
    </div>,
    document.body,
  );
};

const StyledUploadTeamModal = styled(UploadTeamModal)`
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    background: white;
    margin: 0 auto;
    border-radius: 3px;
    width: 80vw;
    max-width: 40rem;
    min-height: 22rem;
    padding: 2rem;
  }

  .error-message {
    color: red;
    text-align: center;
    font-size: 1.25rem;
  }

  .file-input {
    margin-top: 1.5rem;
    max-width: 23rem;
  }

  .modal-error {
    height: 27rem;
  }

  .error-text {
    text-align: center;
    font-size: 1.2rem;
    color: red;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
  }

  .modal-text {
    font-size: 1.5em;
  }

  .duplicate-team-warning {
    border: 1px solid black;
    border-radius: 3px;
    background: #ffffcc;
    margin: 0.5rem;
  }

  .duplicate-team-text {
    font-weight: bold;
  }

  .modal-close-button {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    background-color: white;
    color: steelblue;
    cursor: pointer;
    border: none;
    padding: 0;
    border-radius: 15%;
    border: 0px;
    margin: 0;
    height: 2rem;

    &:hover {
      background-color: steelblue;
      color: white;
    }
  }

  .modal-button-container {
    display: flex;
    justify-content: center;
    margin: 1rem;
  }

  .button-yes,
  .button-no {
    margin: 1rem;
  }

  .error-container {
    margin-top: 1rem;
    border: 2px solid red;
    border-radius: 3px;
    background-color: #ffdede;
  }

  .error-text {
    color: red;
    margin: 1rem;
  }

  .upload-container {
    text-align: center;
    margin: 1rem;
  }

  @media (min-width: 900px) {
    .modal {
      max-width: 40rem;
    }
    .file-input {
      max-width: 35rem;
    }
  }
`;

export default StyledUploadTeamModal;
