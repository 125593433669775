import React from 'react';

export const SET_CURRENT_SECONDS_LEFT = 'SET_CURRENT_SECONDS_LEFT';
export const SET_TIMER_ENABLED = 'SET_TIMER_ENABLED';
export const START_TIMER = 'START_TIMER';
export const PAUSE_TIMER = 'PAUSE_TIMER';
export const EDIT_TIMER = 'EDIT_TIMER';
export const TIMER_EXPIRED = 'TIMER_EXPIRED';
export const RESET_TIMER = 'RESET_TIMER';
export const RESET_TIMER_STATE = 'RESET_TIMER_STATE';

export const TimerContext = React.createContext(null);
export const { Provider, Consumer: TimerConsumer } = TimerContext;

const defaultTimerState = {
  currentSecondsLeft: null,
  timerEnabled: false,
  timerExpiration: null,
  timerExpired: false,
  timerRunning: false,
  timerSecondsLeft: null,
};

const timerReducer = (state, message) => {
  const { type, payload } = message;

  switch (type) {
    case SET_CURRENT_SECONDS_LEFT: {
      const { currentSecondsLeft, timerSecondsLeft } = payload;
      return {
        ...state,
        currentSecondsLeft,
        ...(timerSecondsLeft && { timerSecondsLeft }),
      };
    }
    case SET_TIMER_ENABLED: {
      const { duration, timerEnabled } = payload;
      return {
        ...state,
        timerEnabled,
        timerExpiration: null,
        timerSecondsLeft: duration,
        ...(timerEnabled && { timerExpired: false }),
      };
    }
    case START_TIMER: {
      const { timerExpiration } = payload;
      return {
        ...state,
        timerExpiration,
        timerExpired: false,
        timerRunning: true,
        timerEnabled: true,
      };
    }
    case PAUSE_TIMER: {
      const { timerSecondsLeft } = payload;
      return {
        ...state,
        timerRunning: false,
        timerSecondsLeft,
        timerEnabled: true,
      };
    }
    case TIMER_EXPIRED: {
      const { duration } = payload;
      return {
        ...state,
        currentSecondsLeft: null,
        timerExpired: true,
        timerRunning: false,
        timerSecondsLeft: duration,
      };
    }
    case EDIT_TIMER: {
      const { timerSecondsLeft, timerExpiration } = payload;
      return {
        ...state,
        timerSecondsLeft,
        timerExpiration,
        timerExpired: false,
        timerRunning: false,
      };
    }
    case RESET_TIMER: {
      const { timerSecondsLeft } = payload;
      return {
        ...state,
        timerSecondsLeft,
        timerExpired: false,
        timerRunning: false,
      };
    }
    case RESET_TIMER_STATE: {
      return defaultTimerState;
    }
    default: {
      console.log(`Timer reducer type ${type} is not supported.`);
      return state;
    }
  }
};

export const TimerProvider = ({ children }) => {
  const [stateTimerRound, dispatchTimerRound] = React.useReducer(
    timerReducer,
    defaultTimerState,
  );
  const [stateTimerRingIn, dispatchTimerRingIn] = React.useReducer(
    timerReducer,
    defaultTimerState,
  );
  const [
    stateTimerTeamDeliberation,
    dispatchTimerTeamDeliberation,
  ] = React.useReducer(timerReducer, defaultTimerState);
  const [
    stateTimerBonusDeliberation,
    dispatchTimerBonusDeliberation,
  ] = React.useReducer(timerReducer, defaultTimerState);
  const [stateTimerBonusAnswer, dispatchTimerBonusAnswer] = React.useReducer(
    timerReducer,
    defaultTimerState,
  );
  const [stateTimerTimeout, dispatchTimerTimeout] = React.useReducer(
    timerReducer,
    defaultTimerState,
  );
  const [stateTimerTossUpAnswer, dispatchTimerTossUpAnswer] = React.useReducer(
    timerReducer,
    defaultTimerState,
  );

  const resetTimerState = () => {
    dispatchTimerRound({ type: RESET_TIMER_STATE });
    dispatchTimerRingIn({ type: RESET_TIMER_STATE });
    dispatchTimerTeamDeliberation({ type: RESET_TIMER_STATE });
    dispatchTimerBonusDeliberation({ type: RESET_TIMER_STATE });
    dispatchTimerBonusAnswer({ type: RESET_TIMER_STATE });
    dispatchTimerTimeout({ type: RESET_TIMER_STATE });
    dispatchTimerTossUpAnswer({ type: RESET_TIMER_STATE });
  };

  const value = {
    stateTimerRound,
    dispatchTimerRound,
    stateTimerRingIn,
    dispatchTimerRingIn,
    stateTimerTeamDeliberation,
    dispatchTimerTeamDeliberation,
    stateTimerBonusDeliberation,
    dispatchTimerBonusDeliberation,
    stateTimerBonusAnswer,
    dispatchTimerBonusAnswer,
    stateTimerTimeout,
    dispatchTimerTimeout,
    resetTimerState,
    stateTimerTossUpAnswer,
    dispatchTimerTossUpAnswer,
  };
  return <Provider value={value}>{children && children}</Provider>;
};
