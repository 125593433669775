import { useEffect, useState } from 'react';
import { getAllTeamGroups } from '../Helpers/Api/Team';

function useShareTeams() {
  const [enableShareTeams, setEnableShareTeams] = useState(false);
  const [teamGroups, setTeamGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [newGroup, setNewGroup] = useState('');
  const [groupErrorText, setGroupErrorText] = useState(null);

  useEffect(() => {
    fetchTeamGroups();
  }, []);

  useEffect(() => {
    if (!enableShareTeams) {
      setSelectedGroup('');
      setNewGroup('');
    }
  }, [enableShareTeams]);

  const fetchTeamGroups = async () => {
    const teamGroups = await getAllTeamGroups();
    const sortedTeamGroups = teamGroups.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });
    setTeamGroups(sortedTeamGroups);
    return teamGroups;
  };

  const onChangeNewGroup = (e) => {
    const value = e.target.value;
    if (value.length > 0) setSelectedGroup('');
    setNewGroup(value);
    handleRemoveGroupError();
  };

  const onChangeSelectedGroup = (e) => {
    const groupID = e.target.value;
    if (groupID) setNewGroup('');
    setSelectedGroup(groupID || '');
    handleRemoveGroupError();
  };

  const handleRemoveGroupError = () => {
    setGroupErrorText(null);
  };

  return {
    enableShareTeams,
    groupErrorText,
    handleRemoveGroupError,
    newGroup,
    onChangeNewGroup,
    onChangeSelectedGroup,
    selectedGroup,
    setEnableShareTeams,
    setGroupErrorText,
    setSelectedGroup,
    teamGroups,
    fetchTeamGroups,
  };
}

export default useShareTeams;
