import React from 'react';
import styled from 'styled-components';
import { checkTruthy } from '../../../../../../Helpers/UtilHelper';
import { MatchSettingsContext } from '../../../../../../Hooks/MatchSettingsContext';
import NumberInput from '../../../../../NumberInput';

const TimerSettings = ({ className, disabledEdits = false }) => {
  const {
    preserveMinTimeEnabled,
    preserveMinTimeSeconds,
    resetTimerDuration,
    resetTimerOption,
    ringInLimit,
    setMatchSetting,
    showResetTimer,
    tossUpAnswerEnabled,
    tossUpAnswerSeconds,
    errors,
  } = React.useContext(MatchSettingsContext);

  const handleResetTimerOptionChange = (e) => {
    setMatchSetting({
      payload: {
        resetTimerOption: e.target.value,
      },
      errorsToClear: ['resetTimerDuration'],
    });
  };

  const handleResetTimerDurationChange = (e) => {
    setMatchSetting({
      payload: {
        resetTimerDuration: e.target.value,
      },
      errorsToClear: ['resetTimerDuration'],
    });
  };

  const handleTossUpAnswerSecondsChange = (e) => {
    setMatchSetting({
      payload: {
        tossUpAnswerSeconds: e.target.value,
      },
      errorsToClear: ['tossUpAnswerSeconds'],
    });
  };

  const handleTossUpAnswerEnabledChange = (e) => {
    const value = e.target.value;
    let defaultTossUpAnswerSeconds;
    if (value && tossUpAnswerSeconds === null) {
      // set a default value for old records that have null seconds
      defaultTossUpAnswerSeconds = 5;
    }
    setMatchSetting({
      payload: {
        tossUpAnswerEnabled: value,
        ...(defaultTossUpAnswerSeconds && {
          tossUpAnswerSeconds: defaultTossUpAnswerSeconds,
        }),
      },
    });
  };

  const resetTimerDisplay = checkTruthy(showResetTimer) && (
    <>
      <label>
        <select
          disabled={disabledEdits}
          value={resetTimerOption}
          onChange={handleResetTimerOptionChange}
        >
          <option value="custom">Custom</option>
          <option value="original">Original</option>
        </select>
      </label>
      <NumberInput
        disabledEdits={disabledEdits}
        shouldRender={resetTimerOption === 'custom'}
        value={resetTimerDuration}
        onChange={handleResetTimerDurationChange}
        label="Seconds"
      />
    </>
  );

  const tossUpAnswerSecondsDisplay = (
    <>
      <NumberInput
        disabledEdits={disabledEdits}
        shouldRender={tossUpAnswerEnabled === 'yes'}
        value={tossUpAnswerSeconds}
        onChange={handleTossUpAnswerSecondsChange}
        label="Seconds"
      />
    </>
  );

  const handleShowResetTimerChange = (e) => {
    setMatchSetting({
      payload: {
        showResetTimer: e.target.value,
        ...(checkTruthy(e.target.value) && { preserveMinTimeEnabled: 'no' }),
      },
      errorsToClear: ['resetTimerDuration', 'preserveMinTimeSeconds'],
    });
  };

  const handleRingInLimitChange = (e) => {
    setMatchSetting({
      payload: {
        ringInLimit: e.target.value,
      },
    });
  };

  const handlePreserveMinTimeEnabledChange = (e) => {
    setMatchSetting({
      payload: {
        preserveMinTimeEnabled: e.target.value,
        ...(checkTruthy(e.target.value) && { showResetTimer: 'no' }),
      },
      errorsToClear: ['preserveMinTimeSeconds', 'resetTimerDuration'],
    });
  };

  const handlePreserveMinTimeSecondsChange = (e) => {
    setMatchSetting({
      payload: {
        preserveMinTimeSeconds: e.target.value,
      },
      errorsToClear: ['preserveMinTimeSeconds'],
    });
  };

  const preserveMinTimeDisplay = checkTruthy(preserveMinTimeEnabled) && (
    <>
      <NumberInput
        disabledEdits={disabledEdits}
        shouldRender={true}
        value={preserveMinTimeSeconds || 3}
        onChange={handlePreserveMinTimeSecondsChange}
        label="Seconds"
      />
    </>
  );

  return (
    <div className={`timer-settings-container ${className}`}>
      <div className="timer-error-container">
        <div className="setting-container">
          <label>
            <span className="select-label">
              Reset Ring-In Timer After Incorrect Answers
            </span>
            <select
              disabled={disabledEdits}
              value={showResetTimer}
              onChange={handleShowResetTimerChange}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </label>
          {resetTimerDisplay}
        </div>
        {errors.resetTimerDuration && (
          <div className="error-container">
            <p>{errors.resetTimerDuration}</p>
          </div>
        )}
      </div>
      <div className="timer-error-container">
        <div className="setting-container">
          <label>
            <span className="select-label">
              Preserve Minimum Ring-In Time
            </span>
            <select
              disabled={disabledEdits}
              value={preserveMinTimeEnabled || 'no'} // or statement for backwards compatibility
              onChange={handlePreserveMinTimeEnabledChange}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </label>
          {preserveMinTimeDisplay}
        </div>
        {errors.preserveMinTimeSeconds && (
          <div className="error-container">
            <p>{errors.preserveMinTimeSeconds}</p>
          </div>
        )}
      </div>
      <div className="setting-container">
        <label>
          <span className="select-label">Ring-In Limit</span>
          <select
            disabled={disabledEdits}
            value={ringInLimit}
            onChange={handleRingInLimitChange}
          >
            <option value="oncePerTeam">Once Per Team</option>
            <option value="oncePerQuestion">One Team Per Question</option>
          </select>
        </label>
      </div>
      <div className="timer-error-container">
        <div className="setting-container">
          <label>
            <span className="select-label">Enable Toss-Up Answer Timer?</span>
            <select
              disabled={disabledEdits}
              value={tossUpAnswerEnabled || 'no'} // or statement for backwards compatibility
              onChange={handleTossUpAnswerEnabledChange}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </label>
          {tossUpAnswerSecondsDisplay}
        </div>
        {errors.tossUpAnswerSeconds && (
          <div className="error-container">
            <p>{errors.tossUpAnswerSeconds}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const StyledTimerSettings = styled(TimerSettings)`
  .setting-container {
    border: 0.5px solid #f5f5f5;
    margin: 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }

  .timer-input {
    width: 5rem;
  }

  @media (min-width: 900px) {
    .setting-container {
      border: none;
      margin: 0;
      flex-direction: row;
    }
  }
`;

export default StyledTimerSettings;
