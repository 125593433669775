import React from 'react';
import styled from 'styled-components';
import { checkTruthy } from '../../../../../../Helpers/UtilHelper';
import { MatchSettingsContext } from '../../../../../../Hooks/MatchSettingsContext';
import NumberInput from '../../../../../NumberInput';

const PointSettings = ({ className, disabledEdits = false }) => {
  const {
    earlyCorrectAnswerPoints,
    earlyWrongAnswerPoints,
    errors,
    setMatchSetting,
    showEarlyCorrectAnswerBonus,
    showEarlyWrongAnswerPenalty,
    showWrongAnswerPenalty,
    tossUpPoints,
    wrongAnswerPenaltyPoints,
  } = React.useContext(MatchSettingsContext);

  const handleTossUpPointsChange = (e) => {
    setMatchSetting({
      payload: { tossUpPoints: e.target.value },
      errorsToClear: ['tossUpPoints'],
    });
  };

  const handleShowEarlyCorrectAnswerBonusChange = (e) => {
    setMatchSetting({
      payload: { showEarlyCorrectAnswerBonus: e.target.value },
      errorsToClear: ['earlyCorrectAnswerPoints'],
    });
  };

  const handleEarlyCorrectAnswerPointsChange = (e) => {
    setMatchSetting({
      payload: { earlyCorrectAnswerPoints: e.target.value },
      errorsToClear: ['earlyCorrectAnswerPoints'],
    });
  };

  const handleShowWrongAnswerPenaltyChange = (e) => {
    setMatchSetting({
      payload: { showWrongAnswerPenalty: e.target.value },
      errorsToClear: ['wrongAnswerPenaltyPoints'],
    });
  };

  const handleWrongAnswerPenaltyPointsChange = (e) => {
    setMatchSetting({
      payload: { wrongAnswerPenaltyPoints: e.target.value },
      errorsToClear: ['wrongAnswerPenaltyPoints'],
    });
  };

  const handleShowEarlyWrongAnswerPenaltyChange = (e) => {
    setMatchSetting({
      payload: { showEarlyWrongAnswerPenalty: e.target.value },
      errorsToClear: ['earlyWrongAnswerPoints'],
    });
  };

  const handleEarlyWrongAnswerPointsChange = (e) => {
    setMatchSetting({
      payload: { earlyWrongAnswerPoints: e.target.value },
      errorsToClear: ['earlyWrongAnswerPoints'],
    });
  };

  return (
    <div className={`point-settings-container ${className}`}>
      <div className="setting-container">
        <div className="points-error-container">
          <label>
            <span className="select-label">Toss-Up Points:</span>
            <input
              disabled={disabledEdits}
              type="number"
              value={tossUpPoints}
              onChange={handleTossUpPointsChange}
              className="point-input"
              required
              title=""
            />
          </label>
          {errors.tossUpPoints && (
            <div className="error-container">
              <p>{errors.tossUpPoints}</p>
            </div>
          )}
        </div>
      </div>
      <div className="points-error-container">
        <div className="setting-container">
          <label>
            <span className="select-label">Early Correct Answer Bonus:</span>
            <select
              disabled={disabledEdits}
              value={showEarlyCorrectAnswerBonus}
              onChange={handleShowEarlyCorrectAnswerBonusChange}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </label>
          <NumberInput
            disabledEdits={disabledEdits}
            shouldRender={checkTruthy(showEarlyCorrectAnswerBonus)}
            value={earlyCorrectAnswerPoints}
            onChange={handleEarlyCorrectAnswerPointsChange}
            label="Points"
          />
        </div>
        {errors.earlyCorrectAnswerPoints && (
          <div className="error-container">
            <p>{errors.earlyCorrectAnswerPoints}</p>
          </div>
        )}
      </div>
      <div className="points-error-container">
        <div className="setting-container">
          <label>
            <span className="select-label">Wrong Answer Penalty</span>
            <select
              disabled={disabledEdits}
              value={showWrongAnswerPenalty}
              onChange={handleShowWrongAnswerPenaltyChange}
            >
              <option value="no">None</option>
              <option value="custom">Custom Value</option>
              <option value="question">Question Value</option>
            </select>
          </label>
          <NumberInput
            disabledEdits={disabledEdits}
            shouldRender={showWrongAnswerPenalty === 'custom'}
            value={wrongAnswerPenaltyPoints}
            onChange={handleWrongAnswerPenaltyPointsChange}
            label="Points"
          />
        </div>
        {errors.wrongAnswerPenaltyPoints && (
          <div className="error-container">
            <p>{errors.wrongAnswerPenaltyPoints}</p>
          </div>
        )}
      </div>
      <div className="points-error-container">
        <div className="setting-container">
          <label>
            <span className="select-label">
              Early Incorrect Answer Penalty:
            </span>
            <select
              disabled={disabledEdits}
              value={showEarlyWrongAnswerPenalty}
              onChange={handleShowEarlyWrongAnswerPenaltyChange}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </label>
          <NumberInput
            disabledEdits={disabledEdits}
            shouldRender={checkTruthy(showEarlyWrongAnswerPenalty)}
            value={earlyWrongAnswerPoints}
            onChange={handleEarlyWrongAnswerPointsChange}
            label="Points"
          />
        </div>
        {errors.earlyWrongAnswerPoints && (
          <div className="error-container">
            <p>{errors.earlyWrongAnswerPoints}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const StyledPointSettings = styled(PointSettings)`
  .setting-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0.5px solid #f5f5f5;
    margin: 0.25rem;
  }

  .point-input {
    width: 6rem;
  }

  .select-label {
    display: inline;
  }

  .points-error-container {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 900px) {
    .setting-container {
      border: none;
      margin: 0;
      flex-direction: row;
    }
  }
`;

export default StyledPointSettings;
