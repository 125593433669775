import React from 'react';
import styled from 'styled-components';
import SettingsHeader from '../../../../Components/SettingsHeader';
import { checkTruthy } from '../../../../Helpers/UtilHelper';
import { MatchSettingsContext } from '../../../../Hooks/MatchSettingsContext';
import PointSettings from './PointSettings';
import TimerSettings from './TimerSettings';

const BonusQuestions = ({ className, disabledEdits = false }) => {
  const { errors, setMatchSetting, bonusQuestionEnabled } = React.useContext(
    MatchSettingsContext,
  );

  const handleBonusEnabledChange = (e) => {
    const isEnabled = e.target.checked ? 'yes' : 'no';
    setMatchSetting({
      payload: { bonusQuestionEnabled: isEnabled },
      errorsToClear: ['bonusQuestionEnabled'],
    });
  };

  const settingsOptions = checkTruthy(bonusQuestionEnabled) && (
    <>
      <PointSettings disabledEdits={disabledEdits} />
      <TimerSettings disabledEdits={disabledEdits} />
    </>
  );

  return (
    <div className={`toss-up-question-container ${className}`}>
      <SettingsHeader text="Bonus Questions" />
      <label className="checkbox-container">
        <input
          disabled={disabledEdits}
          type="checkbox"
          checked={checkTruthy(bonusQuestionEnabled)}
          onChange={handleBonusEnabledChange}
        />
        <span className="select-label">Activate Bonus Questions</span>
      </label>
      {settingsOptions}
      {errors.bonusQuestionEnabled && (
        <div className="error-container">
          <p>{errors.bonusQuestionEnabled}</p>
        </div>
      )}
    </div>
  );
};

const StyledBonusQuestions = styled(BonusQuestions)`
  .setting-container {
    display: flex;
  }

  .select-label {
    margin: 1rem;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
  }

  @media (min-width: 900px) {
    .select-label {
      display: inline;
    }
  }
`;

export default StyledBonusQuestions;
