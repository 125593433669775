/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMatchPublic = /* GraphQL */ `
  subscription OnCreateMatchPublic($matchID: ID) {
    onCreateMatchPublic(matchID: $matchID) {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        items {
          id
          active
          score
          matchID
          teamID
          user
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      players {
        items {
          id
          active
          score
          deviceID
          matchID
          playerID
          order
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          matchID
          messageType
          playerID
          createdAt
          updatedAt
        }
        nextToken
      }
      settings {
        items {
          id
          matchID
          user
          templateID
          startingPoints
          tossUpEnabled
          tossUpPoints
          showWrongAnswerPenalty
          wrongAnswerPenaltyPoints
          showEarlyCorrectAnswerBonus
          earlyCorrectAnswerPoints
          showEarlyWrongAnswerPenalty
          earlyWrongAnswerPoints
          roundTimerEnabled
          roundMinutes
          roundSeconds
          ringInTimer
          showResetTimer
          resetTimerOption
          resetTimerDuration
          showComputationTime
          computationTime
          ringInLimit
          soundRoundTimerEnabled
          soundRingInEnabled
          soundRingInTimerEnabled
          soundDeliberationTimerEnabled
          teamEndDeliberationEnabled
          teamDeliberationMinutes
          teamDeliberationSeconds
          teamQuestionEnabled
          soundTeamDeliberationTimerEnabled
          bonusQuestionEnabled
          bonusTotalParts
          bonusPointsPerPart
          bonusBouncebackEnabled
          bonusImmediateBouncebackEnabled
          bonusQuestionsFollowTossUps
          bonusControl
          bonusAnswerTimerEnabled
          bonusAnswerSeconds
          bonusDeliberationTimerEnabled
          bonusDeliberationSeconds
          bonusComputationTimerEnabled
          bonusComputationSeconds
          soundBonusAnswerTimerEnabled
          soundBonusDeliberationTimerEnabled
          playerTimersEnabled
          tossUpAnswerEnabled
          tossUpAnswerSeconds
          soundTossUpAnswerEnabled
          timeoutsEnabled
          preserveMinTimeEnabled
          preserveMinTimeSeconds
          soundTimeoutTimerEnabled
          createdAt
          updatedAt
        }
        nextToken
      }
      scores {
        items {
          id
          type
          questionNumber
          points
          otherPlayers
          matchID
          playerID
          playerName
          teamID
          user
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateMatchPublic = /* GraphQL */ `
  subscription OnUpdateMatchPublic($matchID: ID) {
    onUpdateMatchPublic(matchID: $matchID) {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        items {
          id
          active
          score
          matchID
          teamID
          user
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      players {
        items {
          id
          active
          score
          deviceID
          matchID
          playerID
          order
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          matchID
          messageType
          playerID
          createdAt
          updatedAt
        }
        nextToken
      }
      settings {
        items {
          id
          matchID
          user
          templateID
          startingPoints
          tossUpEnabled
          tossUpPoints
          showWrongAnswerPenalty
          wrongAnswerPenaltyPoints
          showEarlyCorrectAnswerBonus
          earlyCorrectAnswerPoints
          showEarlyWrongAnswerPenalty
          earlyWrongAnswerPoints
          roundTimerEnabled
          roundMinutes
          roundSeconds
          ringInTimer
          showResetTimer
          resetTimerOption
          resetTimerDuration
          showComputationTime
          computationTime
          ringInLimit
          soundRoundTimerEnabled
          soundRingInEnabled
          soundRingInTimerEnabled
          soundDeliberationTimerEnabled
          teamEndDeliberationEnabled
          teamDeliberationMinutes
          teamDeliberationSeconds
          teamQuestionEnabled
          soundTeamDeliberationTimerEnabled
          bonusQuestionEnabled
          bonusTotalParts
          bonusPointsPerPart
          bonusBouncebackEnabled
          bonusImmediateBouncebackEnabled
          bonusQuestionsFollowTossUps
          bonusControl
          bonusAnswerTimerEnabled
          bonusAnswerSeconds
          bonusDeliberationTimerEnabled
          bonusDeliberationSeconds
          bonusComputationTimerEnabled
          bonusComputationSeconds
          soundBonusAnswerTimerEnabled
          soundBonusDeliberationTimerEnabled
          playerTimersEnabled
          tossUpAnswerEnabled
          tossUpAnswerSeconds
          soundTossUpAnswerEnabled
          timeoutsEnabled
          preserveMinTimeEnabled
          preserveMinTimeSeconds
          soundTimeoutTimerEnabled
          createdAt
          updatedAt
        }
        nextToken
      }
      scores {
        items {
          id
          type
          questionNumber
          points
          otherPlayers
          matchID
          playerID
          playerName
          teamID
          user
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteMatchPublic = /* GraphQL */ `
  subscription OnDeleteMatchPublic($matchID: ID) {
    onDeleteMatchPublic(matchID: $matchID) {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        items {
          id
          active
          score
          matchID
          teamID
          user
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      players {
        items {
          id
          active
          score
          deviceID
          matchID
          playerID
          order
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          matchID
          messageType
          playerID
          createdAt
          updatedAt
        }
        nextToken
      }
      settings {
        items {
          id
          matchID
          user
          templateID
          startingPoints
          tossUpEnabled
          tossUpPoints
          showWrongAnswerPenalty
          wrongAnswerPenaltyPoints
          showEarlyCorrectAnswerBonus
          earlyCorrectAnswerPoints
          showEarlyWrongAnswerPenalty
          earlyWrongAnswerPoints
          roundTimerEnabled
          roundMinutes
          roundSeconds
          ringInTimer
          showResetTimer
          resetTimerOption
          resetTimerDuration
          showComputationTime
          computationTime
          ringInLimit
          soundRoundTimerEnabled
          soundRingInEnabled
          soundRingInTimerEnabled
          soundDeliberationTimerEnabled
          teamEndDeliberationEnabled
          teamDeliberationMinutes
          teamDeliberationSeconds
          teamQuestionEnabled
          soundTeamDeliberationTimerEnabled
          bonusQuestionEnabled
          bonusTotalParts
          bonusPointsPerPart
          bonusBouncebackEnabled
          bonusImmediateBouncebackEnabled
          bonusQuestionsFollowTossUps
          bonusControl
          bonusAnswerTimerEnabled
          bonusAnswerSeconds
          bonusDeliberationTimerEnabled
          bonusDeliberationSeconds
          bonusComputationTimerEnabled
          bonusComputationSeconds
          soundBonusAnswerTimerEnabled
          soundBonusDeliberationTimerEnabled
          playerTimersEnabled
          tossUpAnswerEnabled
          tossUpAnswerSeconds
          soundTossUpAnswerEnabled
          timeoutsEnabled
          preserveMinTimeEnabled
          preserveMinTimeSeconds
          soundTimeoutTimerEnabled
          createdAt
          updatedAt
        }
        nextToken
      }
      scores {
        items {
          id
          type
          questionNumber
          points
          otherPlayers
          matchID
          playerID
          playerName
          teamID
          user
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateMatchTeamPublic = /* GraphQL */ `
  subscription OnCreateMatchTeamPublic($matchID: ID) {
    onCreateMatchTeamPublic(matchID: $matchID) {
      id
      active
      score
      matchID
      teamID
      user
      order
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      team {
        id
        active
        name
        updatedAt
        user
        createdAt
        groups {
          nextToken
        }
        matches {
          nextToken
        }
        players {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateMatchTeamPublic = /* GraphQL */ `
  subscription OnUpdateMatchTeamPublic($matchID: ID) {
    onUpdateMatchTeamPublic(matchID: $matchID) {
      id
      active
      score
      matchID
      teamID
      user
      order
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      team {
        id
        active
        name
        updatedAt
        user
        createdAt
        groups {
          nextToken
        }
        matches {
          nextToken
        }
        players {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteMatchTeamPublic = /* GraphQL */ `
  subscription OnDeleteMatchTeamPublic($matchID: ID) {
    onDeleteMatchTeamPublic(matchID: $matchID) {
      id
      active
      score
      matchID
      teamID
      user
      order
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      team {
        id
        active
        name
        updatedAt
        user
        createdAt
        groups {
          nextToken
        }
        matches {
          nextToken
        }
        players {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onCreateMatchPlayerPublic = /* GraphQL */ `
  subscription OnCreateMatchPlayerPublic($matchID: ID) {
    onCreateMatchPlayerPublic(matchID: $matchID) {
      id
      active
      score
      deviceID
      matchID
      playerID
      order
      user
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      player {
        id
        active
        name
        order
        teamID
        user
        createdAt
        updatedAt
        team {
          id
          active
          name
          updatedAt
          user
          createdAt
        }
        matches {
          nextToken
        }
        messages {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateMatchPlayerPublic = /* GraphQL */ `
  subscription OnUpdateMatchPlayerPublic($matchID: ID) {
    onUpdateMatchPlayerPublic(matchID: $matchID) {
      id
      active
      score
      deviceID
      matchID
      playerID
      order
      user
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      player {
        id
        active
        name
        order
        teamID
        user
        createdAt
        updatedAt
        team {
          id
          active
          name
          updatedAt
          user
          createdAt
        }
        matches {
          nextToken
        }
        messages {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteMatchPlayerPublic = /* GraphQL */ `
  subscription OnDeleteMatchPlayerPublic($matchID: ID) {
    onDeleteMatchPlayerPublic(matchID: $matchID) {
      id
      active
      score
      deviceID
      matchID
      playerID
      order
      user
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      player {
        id
        active
        name
        order
        teamID
        user
        createdAt
        updatedAt
        team {
          id
          active
          name
          updatedAt
          user
          createdAt
        }
        matches {
          nextToken
        }
        messages {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onCreateMessagePublic = /* GraphQL */ `
  subscription OnCreateMessagePublic {
    onCreateMessagePublic {
      id
      matchID
      messageType
      playerID
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      player {
        id
        active
        name
        order
        teamID
        user
        createdAt
        updatedAt
        team {
          id
          active
          name
          updatedAt
          user
          createdAt
        }
        matches {
          nextToken
        }
        messages {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateMessagePublic = /* GraphQL */ `
  subscription OnUpdateMessagePublic {
    onUpdateMessagePublic {
      id
      matchID
      messageType
      playerID
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      player {
        id
        active
        name
        order
        teamID
        user
        createdAt
        updatedAt
        team {
          id
          active
          name
          updatedAt
          user
          createdAt
        }
        matches {
          nextToken
        }
        messages {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteMessagePublic = /* GraphQL */ `
  subscription OnDeleteMessagePublic {
    onDeleteMessagePublic {
      id
      matchID
      messageType
      playerID
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      player {
        id
        active
        name
        order
        teamID
        user
        createdAt
        updatedAt
        team {
          id
          active
          name
          updatedAt
          user
          createdAt
        }
        matches {
          nextToken
        }
        messages {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onCreateScorePublic = /* GraphQL */ `
  subscription OnCreateScorePublic($matchID: ID) {
    onCreateScorePublic(matchID: $matchID) {
      id
      type
      questionNumber
      points
      otherPlayers
      matchID
      playerID
      playerName
      teamID
      user
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      team {
        id
        active
        name
        updatedAt
        user
        createdAt
        groups {
          nextToken
        }
        matches {
          nextToken
        }
        players {
          nextToken
        }
        scores {
          nextToken
        }
      }
      player {
        id
        active
        name
        order
        teamID
        user
        createdAt
        updatedAt
        team {
          id
          active
          name
          updatedAt
          user
          createdAt
        }
        matches {
          nextToken
        }
        messages {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateScorePublic = /* GraphQL */ `
  subscription OnUpdateScorePublic($matchID: ID) {
    onUpdateScorePublic(matchID: $matchID) {
      id
      type
      questionNumber
      points
      otherPlayers
      matchID
      playerID
      playerName
      teamID
      user
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      team {
        id
        active
        name
        updatedAt
        user
        createdAt
        groups {
          nextToken
        }
        matches {
          nextToken
        }
        players {
          nextToken
        }
        scores {
          nextToken
        }
      }
      player {
        id
        active
        name
        order
        teamID
        user
        createdAt
        updatedAt
        team {
          id
          active
          name
          updatedAt
          user
          createdAt
        }
        matches {
          nextToken
        }
        messages {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteScorePublic = /* GraphQL */ `
  subscription OnDeleteScorePublic($matchID: ID) {
    onDeleteScorePublic(matchID: $matchID) {
      id
      type
      questionNumber
      points
      otherPlayers
      matchID
      playerID
      playerName
      teamID
      user
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      team {
        id
        active
        name
        updatedAt
        user
        createdAt
        groups {
          nextToken
        }
        matches {
          nextToken
        }
        players {
          nextToken
        }
        scores {
          nextToken
        }
      }
      player {
        id
        active
        name
        order
        teamID
        user
        createdAt
        updatedAt
        team {
          id
          active
          name
          updatedAt
          user
          createdAt
        }
        matches {
          nextToken
        }
        messages {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onCreateMatchTemplate = /* GraphQL */ `
  subscription OnCreateMatchTemplate($user: String) {
    onCreateMatchTemplate(user: $user) {
      id
      active
      templateDescription
      templateName
      templateType
      updatedAt
      user
      startingPoints
      tossUpEnabled
      tossUpPoints
      showWrongAnswerPenalty
      wrongAnswerPenaltyPoints
      showEarlyCorrectAnswerBonus
      earlyCorrectAnswerPoints
      showEarlyWrongAnswerPenalty
      earlyWrongAnswerPoints
      roundTimerEnabled
      roundMinutes
      roundSeconds
      ringInTimer
      showResetTimer
      resetTimerOption
      resetTimerDuration
      showComputationTime
      computationTime
      ringInLimit
      soundRoundTimerEnabled
      soundRingInEnabled
      soundRingInTimerEnabled
      soundDeliberationTimerEnabled
      teamEndDeliberationEnabled
      teamDeliberationMinutes
      teamDeliberationSeconds
      teamQuestionEnabled
      soundTeamDeliberationTimerEnabled
      bonusQuestionEnabled
      bonusTotalParts
      bonusPointsPerPart
      bonusBouncebackEnabled
      bonusImmediateBouncebackEnabled
      bonusQuestionsFollowTossUps
      bonusControl
      bonusAnswerTimerEnabled
      bonusAnswerSeconds
      bonusDeliberationTimerEnabled
      bonusDeliberationSeconds
      bonusComputationTimerEnabled
      bonusComputationSeconds
      soundBonusAnswerTimerEnabled
      soundBonusDeliberationTimerEnabled
      playerTimersEnabled
      tossUpAnswerEnabled
      tossUpAnswerSeconds
      soundTossUpAnswerEnabled
      timeoutsEnabled
      timeouts {
        name
        duration
        order
      }
      preserveMinTimeEnabled
      preserveMinTimeSeconds
      soundTimeoutTimerEnabled
      createdAt
    }
  }
`;
export const onUpdateMatchTemplate = /* GraphQL */ `
  subscription OnUpdateMatchTemplate($user: String) {
    onUpdateMatchTemplate(user: $user) {
      id
      active
      templateDescription
      templateName
      templateType
      updatedAt
      user
      startingPoints
      tossUpEnabled
      tossUpPoints
      showWrongAnswerPenalty
      wrongAnswerPenaltyPoints
      showEarlyCorrectAnswerBonus
      earlyCorrectAnswerPoints
      showEarlyWrongAnswerPenalty
      earlyWrongAnswerPoints
      roundTimerEnabled
      roundMinutes
      roundSeconds
      ringInTimer
      showResetTimer
      resetTimerOption
      resetTimerDuration
      showComputationTime
      computationTime
      ringInLimit
      soundRoundTimerEnabled
      soundRingInEnabled
      soundRingInTimerEnabled
      soundDeliberationTimerEnabled
      teamEndDeliberationEnabled
      teamDeliberationMinutes
      teamDeliberationSeconds
      teamQuestionEnabled
      soundTeamDeliberationTimerEnabled
      bonusQuestionEnabled
      bonusTotalParts
      bonusPointsPerPart
      bonusBouncebackEnabled
      bonusImmediateBouncebackEnabled
      bonusQuestionsFollowTossUps
      bonusControl
      bonusAnswerTimerEnabled
      bonusAnswerSeconds
      bonusDeliberationTimerEnabled
      bonusDeliberationSeconds
      bonusComputationTimerEnabled
      bonusComputationSeconds
      soundBonusAnswerTimerEnabled
      soundBonusDeliberationTimerEnabled
      playerTimersEnabled
      tossUpAnswerEnabled
      tossUpAnswerSeconds
      soundTossUpAnswerEnabled
      timeoutsEnabled
      timeouts {
        name
        duration
        order
      }
      preserveMinTimeEnabled
      preserveMinTimeSeconds
      soundTimeoutTimerEnabled
      createdAt
    }
  }
`;
export const onDeleteMatchTemplate = /* GraphQL */ `
  subscription OnDeleteMatchTemplate($user: String) {
    onDeleteMatchTemplate(user: $user) {
      id
      active
      templateDescription
      templateName
      templateType
      updatedAt
      user
      startingPoints
      tossUpEnabled
      tossUpPoints
      showWrongAnswerPenalty
      wrongAnswerPenaltyPoints
      showEarlyCorrectAnswerBonus
      earlyCorrectAnswerPoints
      showEarlyWrongAnswerPenalty
      earlyWrongAnswerPoints
      roundTimerEnabled
      roundMinutes
      roundSeconds
      ringInTimer
      showResetTimer
      resetTimerOption
      resetTimerDuration
      showComputationTime
      computationTime
      ringInLimit
      soundRoundTimerEnabled
      soundRingInEnabled
      soundRingInTimerEnabled
      soundDeliberationTimerEnabled
      teamEndDeliberationEnabled
      teamDeliberationMinutes
      teamDeliberationSeconds
      teamQuestionEnabled
      soundTeamDeliberationTimerEnabled
      bonusQuestionEnabled
      bonusTotalParts
      bonusPointsPerPart
      bonusBouncebackEnabled
      bonusImmediateBouncebackEnabled
      bonusQuestionsFollowTossUps
      bonusControl
      bonusAnswerTimerEnabled
      bonusAnswerSeconds
      bonusDeliberationTimerEnabled
      bonusDeliberationSeconds
      bonusComputationTimerEnabled
      bonusComputationSeconds
      soundBonusAnswerTimerEnabled
      soundBonusDeliberationTimerEnabled
      playerTimersEnabled
      tossUpAnswerEnabled
      tossUpAnswerSeconds
      soundTossUpAnswerEnabled
      timeoutsEnabled
      timeouts {
        name
        duration
        order
      }
      preserveMinTimeEnabled
      preserveMinTimeSeconds
      soundTimeoutTimerEnabled
      createdAt
    }
  }
`;
export const onCreateMatch = /* GraphQL */ `
  subscription OnCreateMatch {
    onCreateMatch {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        items {
          id
          active
          score
          matchID
          teamID
          user
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      players {
        items {
          id
          active
          score
          deviceID
          matchID
          playerID
          order
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          matchID
          messageType
          playerID
          createdAt
          updatedAt
        }
        nextToken
      }
      settings {
        items {
          id
          matchID
          user
          templateID
          startingPoints
          tossUpEnabled
          tossUpPoints
          showWrongAnswerPenalty
          wrongAnswerPenaltyPoints
          showEarlyCorrectAnswerBonus
          earlyCorrectAnswerPoints
          showEarlyWrongAnswerPenalty
          earlyWrongAnswerPoints
          roundTimerEnabled
          roundMinutes
          roundSeconds
          ringInTimer
          showResetTimer
          resetTimerOption
          resetTimerDuration
          showComputationTime
          computationTime
          ringInLimit
          soundRoundTimerEnabled
          soundRingInEnabled
          soundRingInTimerEnabled
          soundDeliberationTimerEnabled
          teamEndDeliberationEnabled
          teamDeliberationMinutes
          teamDeliberationSeconds
          teamQuestionEnabled
          soundTeamDeliberationTimerEnabled
          bonusQuestionEnabled
          bonusTotalParts
          bonusPointsPerPart
          bonusBouncebackEnabled
          bonusImmediateBouncebackEnabled
          bonusQuestionsFollowTossUps
          bonusControl
          bonusAnswerTimerEnabled
          bonusAnswerSeconds
          bonusDeliberationTimerEnabled
          bonusDeliberationSeconds
          bonusComputationTimerEnabled
          bonusComputationSeconds
          soundBonusAnswerTimerEnabled
          soundBonusDeliberationTimerEnabled
          playerTimersEnabled
          tossUpAnswerEnabled
          tossUpAnswerSeconds
          soundTossUpAnswerEnabled
          timeoutsEnabled
          preserveMinTimeEnabled
          preserveMinTimeSeconds
          soundTimeoutTimerEnabled
          createdAt
          updatedAt
        }
        nextToken
      }
      scores {
        items {
          id
          type
          questionNumber
          points
          otherPlayers
          matchID
          playerID
          playerName
          teamID
          user
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateMatch = /* GraphQL */ `
  subscription OnUpdateMatch {
    onUpdateMatch {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        items {
          id
          active
          score
          matchID
          teamID
          user
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      players {
        items {
          id
          active
          score
          deviceID
          matchID
          playerID
          order
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          matchID
          messageType
          playerID
          createdAt
          updatedAt
        }
        nextToken
      }
      settings {
        items {
          id
          matchID
          user
          templateID
          startingPoints
          tossUpEnabled
          tossUpPoints
          showWrongAnswerPenalty
          wrongAnswerPenaltyPoints
          showEarlyCorrectAnswerBonus
          earlyCorrectAnswerPoints
          showEarlyWrongAnswerPenalty
          earlyWrongAnswerPoints
          roundTimerEnabled
          roundMinutes
          roundSeconds
          ringInTimer
          showResetTimer
          resetTimerOption
          resetTimerDuration
          showComputationTime
          computationTime
          ringInLimit
          soundRoundTimerEnabled
          soundRingInEnabled
          soundRingInTimerEnabled
          soundDeliberationTimerEnabled
          teamEndDeliberationEnabled
          teamDeliberationMinutes
          teamDeliberationSeconds
          teamQuestionEnabled
          soundTeamDeliberationTimerEnabled
          bonusQuestionEnabled
          bonusTotalParts
          bonusPointsPerPart
          bonusBouncebackEnabled
          bonusImmediateBouncebackEnabled
          bonusQuestionsFollowTossUps
          bonusControl
          bonusAnswerTimerEnabled
          bonusAnswerSeconds
          bonusDeliberationTimerEnabled
          bonusDeliberationSeconds
          bonusComputationTimerEnabled
          bonusComputationSeconds
          soundBonusAnswerTimerEnabled
          soundBonusDeliberationTimerEnabled
          playerTimersEnabled
          tossUpAnswerEnabled
          tossUpAnswerSeconds
          soundTossUpAnswerEnabled
          timeoutsEnabled
          preserveMinTimeEnabled
          preserveMinTimeSeconds
          soundTimeoutTimerEnabled
          createdAt
          updatedAt
        }
        nextToken
      }
      scores {
        items {
          id
          type
          questionNumber
          points
          otherPlayers
          matchID
          playerID
          playerName
          teamID
          user
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteMatch = /* GraphQL */ `
  subscription OnDeleteMatch {
    onDeleteMatch {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        items {
          id
          active
          score
          matchID
          teamID
          user
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      players {
        items {
          id
          active
          score
          deviceID
          matchID
          playerID
          order
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          matchID
          messageType
          playerID
          createdAt
          updatedAt
        }
        nextToken
      }
      settings {
        items {
          id
          matchID
          user
          templateID
          startingPoints
          tossUpEnabled
          tossUpPoints
          showWrongAnswerPenalty
          wrongAnswerPenaltyPoints
          showEarlyCorrectAnswerBonus
          earlyCorrectAnswerPoints
          showEarlyWrongAnswerPenalty
          earlyWrongAnswerPoints
          roundTimerEnabled
          roundMinutes
          roundSeconds
          ringInTimer
          showResetTimer
          resetTimerOption
          resetTimerDuration
          showComputationTime
          computationTime
          ringInLimit
          soundRoundTimerEnabled
          soundRingInEnabled
          soundRingInTimerEnabled
          soundDeliberationTimerEnabled
          teamEndDeliberationEnabled
          teamDeliberationMinutes
          teamDeliberationSeconds
          teamQuestionEnabled
          soundTeamDeliberationTimerEnabled
          bonusQuestionEnabled
          bonusTotalParts
          bonusPointsPerPart
          bonusBouncebackEnabled
          bonusImmediateBouncebackEnabled
          bonusQuestionsFollowTossUps
          bonusControl
          bonusAnswerTimerEnabled
          bonusAnswerSeconds
          bonusDeliberationTimerEnabled
          bonusDeliberationSeconds
          bonusComputationTimerEnabled
          bonusComputationSeconds
          soundBonusAnswerTimerEnabled
          soundBonusDeliberationTimerEnabled
          playerTimersEnabled
          tossUpAnswerEnabled
          tossUpAnswerSeconds
          soundTossUpAnswerEnabled
          timeoutsEnabled
          preserveMinTimeEnabled
          preserveMinTimeSeconds
          soundTimeoutTimerEnabled
          createdAt
          updatedAt
        }
        nextToken
      }
      scores {
        items {
          id
          type
          questionNumber
          points
          otherPlayers
          matchID
          playerID
          playerName
          teamID
          user
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateTeam = /* GraphQL */ `
  subscription OnCreateTeam {
    onCreateTeam {
      id
      active
      name
      updatedAt
      user
      createdAt
      groups {
        items {
          id
          teamGroupID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      matches {
        items {
          id
          active
          score
          matchID
          teamID
          user
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      players {
        items {
          id
          active
          name
          order
          teamID
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      scores {
        items {
          id
          type
          questionNumber
          points
          otherPlayers
          matchID
          playerID
          playerName
          teamID
          user
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateTeam = /* GraphQL */ `
  subscription OnUpdateTeam {
    onUpdateTeam {
      id
      active
      name
      updatedAt
      user
      createdAt
      groups {
        items {
          id
          teamGroupID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      matches {
        items {
          id
          active
          score
          matchID
          teamID
          user
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      players {
        items {
          id
          active
          name
          order
          teamID
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      scores {
        items {
          id
          type
          questionNumber
          points
          otherPlayers
          matchID
          playerID
          playerName
          teamID
          user
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteTeam = /* GraphQL */ `
  subscription OnDeleteTeam {
    onDeleteTeam {
      id
      active
      name
      updatedAt
      user
      createdAt
      groups {
        items {
          id
          teamGroupID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      matches {
        items {
          id
          active
          score
          matchID
          teamID
          user
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      players {
        items {
          id
          active
          name
          order
          teamID
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      scores {
        items {
          id
          type
          questionNumber
          points
          otherPlayers
          matchID
          playerID
          playerName
          teamID
          user
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateTeamGroup = /* GraphQL */ `
  subscription OnCreateTeamGroup {
    onCreateTeamGroup {
      id
      active
      name
      updatedAt
      user
      createdAt
      teams {
        items {
          id
          teamGroupID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateTeamGroup = /* GraphQL */ `
  subscription OnUpdateTeamGroup {
    onUpdateTeamGroup {
      id
      active
      name
      updatedAt
      user
      createdAt
      teams {
        items {
          id
          teamGroupID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteTeamGroup = /* GraphQL */ `
  subscription OnDeleteTeamGroup {
    onDeleteTeamGroup {
      id
      active
      name
      updatedAt
      user
      createdAt
      teams {
        items {
          id
          teamGroupID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateTeamGroupLink = /* GraphQL */ `
  subscription OnCreateTeamGroupLink {
    onCreateTeamGroupLink {
      id
      teamGroupID
      teamID
      createdAt
      updatedAt
      team {
        id
        active
        name
        updatedAt
        user
        createdAt
        groups {
          nextToken
        }
        matches {
          nextToken
        }
        players {
          nextToken
        }
        scores {
          nextToken
        }
      }
      group {
        id
        active
        name
        updatedAt
        user
        createdAt
        teams {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateTeamGroupLink = /* GraphQL */ `
  subscription OnUpdateTeamGroupLink {
    onUpdateTeamGroupLink {
      id
      teamGroupID
      teamID
      createdAt
      updatedAt
      team {
        id
        active
        name
        updatedAt
        user
        createdAt
        groups {
          nextToken
        }
        matches {
          nextToken
        }
        players {
          nextToken
        }
        scores {
          nextToken
        }
      }
      group {
        id
        active
        name
        updatedAt
        user
        createdAt
        teams {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteTeamGroupLink = /* GraphQL */ `
  subscription OnDeleteTeamGroupLink {
    onDeleteTeamGroupLink {
      id
      teamGroupID
      teamID
      createdAt
      updatedAt
      team {
        id
        active
        name
        updatedAt
        user
        createdAt
        groups {
          nextToken
        }
        matches {
          nextToken
        }
        players {
          nextToken
        }
        scores {
          nextToken
        }
      }
      group {
        id
        active
        name
        updatedAt
        user
        createdAt
        teams {
          nextToken
        }
      }
    }
  }
`;
export const onCreateMatchTeam = /* GraphQL */ `
  subscription OnCreateMatchTeam {
    onCreateMatchTeam {
      id
      active
      score
      matchID
      teamID
      user
      order
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      team {
        id
        active
        name
        updatedAt
        user
        createdAt
        groups {
          nextToken
        }
        matches {
          nextToken
        }
        players {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateMatchTeam = /* GraphQL */ `
  subscription OnUpdateMatchTeam {
    onUpdateMatchTeam {
      id
      active
      score
      matchID
      teamID
      user
      order
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      team {
        id
        active
        name
        updatedAt
        user
        createdAt
        groups {
          nextToken
        }
        matches {
          nextToken
        }
        players {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteMatchTeam = /* GraphQL */ `
  subscription OnDeleteMatchTeam {
    onDeleteMatchTeam {
      id
      active
      score
      matchID
      teamID
      user
      order
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      team {
        id
        active
        name
        updatedAt
        user
        createdAt
        groups {
          nextToken
        }
        matches {
          nextToken
        }
        players {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onCreatePlayer = /* GraphQL */ `
  subscription OnCreatePlayer {
    onCreatePlayer {
      id
      active
      name
      order
      teamID
      user
      createdAt
      updatedAt
      team {
        id
        active
        name
        updatedAt
        user
        createdAt
        groups {
          nextToken
        }
        matches {
          nextToken
        }
        players {
          nextToken
        }
        scores {
          nextToken
        }
      }
      matches {
        items {
          id
          active
          score
          deviceID
          matchID
          playerID
          order
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          matchID
          messageType
          playerID
          createdAt
          updatedAt
        }
        nextToken
      }
      scores {
        items {
          id
          type
          questionNumber
          points
          otherPlayers
          matchID
          playerID
          playerName
          teamID
          user
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdatePlayer = /* GraphQL */ `
  subscription OnUpdatePlayer {
    onUpdatePlayer {
      id
      active
      name
      order
      teamID
      user
      createdAt
      updatedAt
      team {
        id
        active
        name
        updatedAt
        user
        createdAt
        groups {
          nextToken
        }
        matches {
          nextToken
        }
        players {
          nextToken
        }
        scores {
          nextToken
        }
      }
      matches {
        items {
          id
          active
          score
          deviceID
          matchID
          playerID
          order
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          matchID
          messageType
          playerID
          createdAt
          updatedAt
        }
        nextToken
      }
      scores {
        items {
          id
          type
          questionNumber
          points
          otherPlayers
          matchID
          playerID
          playerName
          teamID
          user
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeletePlayer = /* GraphQL */ `
  subscription OnDeletePlayer {
    onDeletePlayer {
      id
      active
      name
      order
      teamID
      user
      createdAt
      updatedAt
      team {
        id
        active
        name
        updatedAt
        user
        createdAt
        groups {
          nextToken
        }
        matches {
          nextToken
        }
        players {
          nextToken
        }
        scores {
          nextToken
        }
      }
      matches {
        items {
          id
          active
          score
          deviceID
          matchID
          playerID
          order
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          matchID
          messageType
          playerID
          createdAt
          updatedAt
        }
        nextToken
      }
      scores {
        items {
          id
          type
          questionNumber
          points
          otherPlayers
          matchID
          playerID
          playerName
          teamID
          user
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateMatchPlayer = /* GraphQL */ `
  subscription OnCreateMatchPlayer {
    onCreateMatchPlayer {
      id
      active
      score
      deviceID
      matchID
      playerID
      order
      user
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      player {
        id
        active
        name
        order
        teamID
        user
        createdAt
        updatedAt
        team {
          id
          active
          name
          updatedAt
          user
          createdAt
        }
        matches {
          nextToken
        }
        messages {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateMatchPlayer = /* GraphQL */ `
  subscription OnUpdateMatchPlayer {
    onUpdateMatchPlayer {
      id
      active
      score
      deviceID
      matchID
      playerID
      order
      user
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      player {
        id
        active
        name
        order
        teamID
        user
        createdAt
        updatedAt
        team {
          id
          active
          name
          updatedAt
          user
          createdAt
        }
        matches {
          nextToken
        }
        messages {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteMatchPlayer = /* GraphQL */ `
  subscription OnDeleteMatchPlayer {
    onDeleteMatchPlayer {
      id
      active
      score
      deviceID
      matchID
      playerID
      order
      user
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      player {
        id
        active
        name
        order
        teamID
        user
        createdAt
        updatedAt
        team {
          id
          active
          name
          updatedAt
          user
          createdAt
        }
        matches {
          nextToken
        }
        messages {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      matchID
      messageType
      playerID
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      player {
        id
        active
        name
        order
        teamID
        user
        createdAt
        updatedAt
        team {
          id
          active
          name
          updatedAt
          user
          createdAt
        }
        matches {
          nextToken
        }
        messages {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      matchID
      messageType
      playerID
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      player {
        id
        active
        name
        order
        teamID
        user
        createdAt
        updatedAt
        team {
          id
          active
          name
          updatedAt
          user
          createdAt
        }
        matches {
          nextToken
        }
        messages {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      matchID
      messageType
      playerID
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      player {
        id
        active
        name
        order
        teamID
        user
        createdAt
        updatedAt
        team {
          id
          active
          name
          updatedAt
          user
          createdAt
        }
        matches {
          nextToken
        }
        messages {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onCreateMatchSettings = /* GraphQL */ `
  subscription OnCreateMatchSettings {
    onCreateMatchSettings {
      id
      matchID
      user
      templateID
      startingPoints
      tossUpEnabled
      tossUpPoints
      showWrongAnswerPenalty
      wrongAnswerPenaltyPoints
      showEarlyCorrectAnswerBonus
      earlyCorrectAnswerPoints
      showEarlyWrongAnswerPenalty
      earlyWrongAnswerPoints
      roundTimerEnabled
      roundMinutes
      roundSeconds
      ringInTimer
      showResetTimer
      resetTimerOption
      resetTimerDuration
      showComputationTime
      computationTime
      ringInLimit
      soundRoundTimerEnabled
      soundRingInEnabled
      soundRingInTimerEnabled
      soundDeliberationTimerEnabled
      teamEndDeliberationEnabled
      teamDeliberationMinutes
      teamDeliberationSeconds
      teamQuestionEnabled
      soundTeamDeliberationTimerEnabled
      bonusQuestionEnabled
      bonusTotalParts
      bonusPointsPerPart
      bonusBouncebackEnabled
      bonusImmediateBouncebackEnabled
      bonusQuestionsFollowTossUps
      bonusControl
      bonusAnswerTimerEnabled
      bonusAnswerSeconds
      bonusDeliberationTimerEnabled
      bonusDeliberationSeconds
      bonusComputationTimerEnabled
      bonusComputationSeconds
      soundBonusAnswerTimerEnabled
      soundBonusDeliberationTimerEnabled
      playerTimersEnabled
      tossUpAnswerEnabled
      tossUpAnswerSeconds
      soundTossUpAnswerEnabled
      timeoutsEnabled
      timeouts {
        name
        duration
        order
      }
      preserveMinTimeEnabled
      preserveMinTimeSeconds
      soundTimeoutTimerEnabled
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateMatchSettings = /* GraphQL */ `
  subscription OnUpdateMatchSettings {
    onUpdateMatchSettings {
      id
      matchID
      user
      templateID
      startingPoints
      tossUpEnabled
      tossUpPoints
      showWrongAnswerPenalty
      wrongAnswerPenaltyPoints
      showEarlyCorrectAnswerBonus
      earlyCorrectAnswerPoints
      showEarlyWrongAnswerPenalty
      earlyWrongAnswerPoints
      roundTimerEnabled
      roundMinutes
      roundSeconds
      ringInTimer
      showResetTimer
      resetTimerOption
      resetTimerDuration
      showComputationTime
      computationTime
      ringInLimit
      soundRoundTimerEnabled
      soundRingInEnabled
      soundRingInTimerEnabled
      soundDeliberationTimerEnabled
      teamEndDeliberationEnabled
      teamDeliberationMinutes
      teamDeliberationSeconds
      teamQuestionEnabled
      soundTeamDeliberationTimerEnabled
      bonusQuestionEnabled
      bonusTotalParts
      bonusPointsPerPart
      bonusBouncebackEnabled
      bonusImmediateBouncebackEnabled
      bonusQuestionsFollowTossUps
      bonusControl
      bonusAnswerTimerEnabled
      bonusAnswerSeconds
      bonusDeliberationTimerEnabled
      bonusDeliberationSeconds
      bonusComputationTimerEnabled
      bonusComputationSeconds
      soundBonusAnswerTimerEnabled
      soundBonusDeliberationTimerEnabled
      playerTimersEnabled
      tossUpAnswerEnabled
      tossUpAnswerSeconds
      soundTossUpAnswerEnabled
      timeoutsEnabled
      timeouts {
        name
        duration
        order
      }
      preserveMinTimeEnabled
      preserveMinTimeSeconds
      soundTimeoutTimerEnabled
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteMatchSettings = /* GraphQL */ `
  subscription OnDeleteMatchSettings {
    onDeleteMatchSettings {
      id
      matchID
      user
      templateID
      startingPoints
      tossUpEnabled
      tossUpPoints
      showWrongAnswerPenalty
      wrongAnswerPenaltyPoints
      showEarlyCorrectAnswerBonus
      earlyCorrectAnswerPoints
      showEarlyWrongAnswerPenalty
      earlyWrongAnswerPoints
      roundTimerEnabled
      roundMinutes
      roundSeconds
      ringInTimer
      showResetTimer
      resetTimerOption
      resetTimerDuration
      showComputationTime
      computationTime
      ringInLimit
      soundRoundTimerEnabled
      soundRingInEnabled
      soundRingInTimerEnabled
      soundDeliberationTimerEnabled
      teamEndDeliberationEnabled
      teamDeliberationMinutes
      teamDeliberationSeconds
      teamQuestionEnabled
      soundTeamDeliberationTimerEnabled
      bonusQuestionEnabled
      bonusTotalParts
      bonusPointsPerPart
      bonusBouncebackEnabled
      bonusImmediateBouncebackEnabled
      bonusQuestionsFollowTossUps
      bonusControl
      bonusAnswerTimerEnabled
      bonusAnswerSeconds
      bonusDeliberationTimerEnabled
      bonusDeliberationSeconds
      bonusComputationTimerEnabled
      bonusComputationSeconds
      soundBonusAnswerTimerEnabled
      soundBonusDeliberationTimerEnabled
      playerTimersEnabled
      tossUpAnswerEnabled
      tossUpAnswerSeconds
      soundTossUpAnswerEnabled
      timeoutsEnabled
      timeouts {
        name
        duration
        order
      }
      preserveMinTimeEnabled
      preserveMinTimeSeconds
      soundTimeoutTimerEnabled
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onCreateScore = /* GraphQL */ `
  subscription OnCreateScore {
    onCreateScore {
      id
      type
      questionNumber
      points
      otherPlayers
      matchID
      playerID
      playerName
      teamID
      user
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      team {
        id
        active
        name
        updatedAt
        user
        createdAt
        groups {
          nextToken
        }
        matches {
          nextToken
        }
        players {
          nextToken
        }
        scores {
          nextToken
        }
      }
      player {
        id
        active
        name
        order
        teamID
        user
        createdAt
        updatedAt
        team {
          id
          active
          name
          updatedAt
          user
          createdAt
        }
        matches {
          nextToken
        }
        messages {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateScore = /* GraphQL */ `
  subscription OnUpdateScore {
    onUpdateScore {
      id
      type
      questionNumber
      points
      otherPlayers
      matchID
      playerID
      playerName
      teamID
      user
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      team {
        id
        active
        name
        updatedAt
        user
        createdAt
        groups {
          nextToken
        }
        matches {
          nextToken
        }
        players {
          nextToken
        }
        scores {
          nextToken
        }
      }
      player {
        id
        active
        name
        order
        teamID
        user
        createdAt
        updatedAt
        team {
          id
          active
          name
          updatedAt
          user
          createdAt
        }
        matches {
          nextToken
        }
        messages {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteScore = /* GraphQL */ `
  subscription OnDeleteScore {
    onDeleteScore {
      id
      type
      questionNumber
      points
      otherPlayers
      matchID
      playerID
      playerName
      teamID
      user
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
        teams {
          nextToken
        }
        players {
          nextToken
        }
        messages {
          nextToken
        }
        settings {
          nextToken
        }
        scores {
          nextToken
        }
      }
      team {
        id
        active
        name
        updatedAt
        user
        createdAt
        groups {
          nextToken
        }
        matches {
          nextToken
        }
        players {
          nextToken
        }
        scores {
          nextToken
        }
      }
      player {
        id
        active
        name
        order
        teamID
        user
        createdAt
        updatedAt
        team {
          id
          active
          name
          updatedAt
          user
          createdAt
        }
        matches {
          nextToken
        }
        messages {
          nextToken
        }
        scores {
          nextToken
        }
      }
    }
  }
`;
