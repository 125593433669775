/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:0c1d7ec4-e3fb-4dc0-80fb-27d9f00db7c3",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_gF18dw7em",
    "aws_user_pools_web_client_id": "61j2piq11nc3nte4tidb8dilvq",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://bakw6jkobbc6rhuj4hyjwv4eca.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "stripeapi",
            "endpoint": "https://kl7ge4vhw1.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
