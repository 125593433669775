import { API, graphqlOperation } from 'aws-amplify';

export const query = async (query, variables = {}) =>
  API.graphql({
    query,
    variables,
    authMode: 'AWS_IAM',
    fetchPolicy: 'network-only',
  });

export const queryAuthed = async (query, variables = {}) =>
  API.graphql({ query, variables, fetchPolicy: 'network-only' });

export const mutate = async (mutation, params = {}) =>
  API.graphql(graphqlOperation(mutation, { input: params }));

export const mutateAnonymous = async (query, params = {}) =>
  API.graphql({ query, variables: { input: params }, authMode: 'AWS_IAM' });

// export const subscribe = (subscription, cb) => API.graphql(
//   graphqlOperation(subscription)).subscribe({ next: cb });

export const subscribe = (query, variables = {}, cb = () => {}) =>
  API.graphql({ query, variables, authMode: 'AWS_IAM' }).subscribe({
    next: cb,
  });
