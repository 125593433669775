import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import EditBonusControlModal from '../../../../Components/EditBonusControlModal';
import EditScoresModal from '../../../../Components/EditScoresModal';
import EditScoreTypeModal from '../../../../Components/EditScoreTypeModal';
import EditTimerModal from '../../../../Components/EditTimerModal';
import Modal from '../../../../Components/Modal';
import ScoreSheetModal from '../../../../Components/ScoreSheetModal';
import SubstitutionModal from '../../../../Components/SubstitutionModal';
import TimeoutsModal from '../../../../Components/TimeoutsModal';
import {
  MATCH_COMPLETED,
  MATCH_NEW,
  MATCH_STARTED,
  updateMatch,
} from '../../../../Helpers/Api/Match';
import { createScore } from '../../../../Helpers/Api/Score';
import {
  BONUS_QUESTION,
  DETAILED_SCORES,
  MANUAL_SCORES,
  TEAM_QUESTION,
  TOSS_UP_QUESTION,
} from '../../../../Helpers/HostRoomHelper';
import {
  deletePreviousScores,
  getActivePlayerName,
  getActivePlayerTeamID,
  getNewReplayQuestionArray,
  getOtherPlayersString,
  getPointValues,
  getQuestionNumberFromScores,
  getRemainingReplayQuestions,
  scoreQuestionTypes,
} from '../../../../Helpers/MatchHelper';
import {
  MATCH_STATE_ASKING_QUESTION,
  MATCH_STATE_BONUS_QUESTION_OPEN,
  MATCH_STATE_CHOOSE_QUESTION,
  MATCH_STATE_RING_IN_OPEN,
  MATCH_STATE_RING_IN_OPEN_LIMITED_TEAMS,
  MATCH_STATE_RING_IN_PAUSED,
  MATCH_STATE_RING_IN_PAUSED_LIMITED,
  MATCH_STATE_RING_IN_TEST_CLOSED,
  MATCH_STATE_RING_IN_TEST_OPEN,
  MATCH_STATE_TEAM_QUESTION_CLOSED,
  MATCH_STATE_TEAM_QUESTION_OPEN,
  MATCH_STATE_TEAM_QUESTION_PAUSED,
  MATCH_STATE_TEAM_QUESTION_STARTED,
  MATCH_STATE_TIMEOUT_OPEN,
  MESSAGE_TYPE_BONUS_PART_DISPLAY,
  MESSAGE_TYPE_BONUS_START_QUESTION_PART,
  MESSAGE_TYPE_BONUS_TEAM_CONTROL,
  MESSAGE_TYPE_END_RING_IN_TEST,
  MESSAGE_TYPE_OPEN_RING_IN,
  MESSAGE_TYPE_OPEN_RING_IN_LIMITED_TEAMS,
  MESSAGE_TYPE_PAUSE_RING_IN,
  MESSAGE_TYPE_PAUSE_RING_IN_LIMITED_TEAMS,
  MESSAGE_TYPE_PAUSE_ROUND_TIMER,
  MESSAGE_TYPE_RESET_RING_IN_TEST,
  MESSAGE_TYPE_START_QUESTION,
  MESSAGE_TYPE_START_RING_IN_TEST,
  MESSAGE_TYPE_START_TIMEOUT,
  MESSAGE_TYPE_TEAM_PAUSE_DELIBERATION_TIMER,
  MESSAGE_TYPE_TEAM_START_DELIBERATION_TIMER,
  MESSAGE_TYPE_TEAM_START_QUESTION,
  MESSAGE_TYPE_UNDO,
  MESSAGE_TYPE_UNPAUSE_RING_IN,
  MESSAGE_TYPE_UNPAUSE_RING_IN_LIMITED_TEAMS,
  TIMER_STATE_HIDDEN,
  TIMER_STATE_PAUSED,
  TIMER_STATE_RESET,
  TIMER_STATE_RUNNING,
  TIMER_STATE_VISIBLE,
} from '../../../../Helpers/MessageHelper';
import { checkTruthy, get } from '../../../../Helpers/UtilHelper';
import { SocketContext } from '../../../../Hooks/SocketContext';
import QuestionNumberInput from '../QuestionNumberInput';
import TimerButton from '../TimerButton';

const statesToShowEndQ = [
  MATCH_STATE_ASKING_QUESTION,
  MATCH_STATE_RING_IN_OPEN,
  MATCH_STATE_RING_IN_PAUSED,
  MATCH_STATE_RING_IN_OPEN_LIMITED_TEAMS,
  MATCH_STATE_RING_IN_PAUSED_LIMITED,
];

const MatchControls = (props) => {
  const {
    className,
    endMatch,
    endQuestion,
    endTimeout,
    handleBonusDeliberationEnd,
    handleEndMatch,
    handleTeamDeliberationEnd,
    isReplayingQuestion,
    match,
    matchID,
    nextQuestionNumberToPlay,
    playerRangIn,
    questionNumberInputDisplay,
    replayQuestionNumber,
    scoreList,
    setIsReplayingQuestion,
    setNextQuestionNumberToPlay,
    setPlayerRangIn,
    setQuestionNumberInputDisplay,
    setReplayQuestionNumber,
    setRingIns,
    setShowModalError,
    setState,
    setTeamDeliberationDuration,
    setUseComputationTime,
    showModalError,
    teamDeliberationDuration,
    roundTimerExpired,
  } = props;
  const [savingScore, setSavingScore] = useState(false);
  const [showEditDeliberationTimerModal, setShowEditDeliberationTimerModal] =
    useState(false);
  const [showTeamQuestionScoreModal, setShowTeamQuestionScoreModal] =
    useState(false);
  const [
    persistTeamEndDeliberationButton,
    setPersistTeamEndDeliberationButton,
  ] = useState(false);
  const [showEndMatchModal, setShowEndMatchModal] = useState(false);
  const [showBonusControlModal, setShowBonusControlModal] = useState(false);
  const [takeTurnsPreviousControl, setTakeTurnsPreviousControl] =
    useState(null);
  const [tossUpWinningTeamId, setTossUpWinningTeamId] = useState(null);
  const [skipToBonus, setSkipToBonus] = useState(false);
  const [showSubstitutionModal, setShowSubstitutionModal] = useState(false);
  const [showTimeoutsModal, setShowTimeoutsModal] = React.useState(false);
  const [disableQuestionNumberInput, setDisableQuestionNumberInput] =
    useState(true);
  const [replayQuestionArray, setReplayQuestionArray] = useState([]);
  const [recentlyEditedScores, setRecentlyEditedScores] = useState(false);
  const [showEditScoreTypeModal, setShowEditScoreTypeModal] = useState(false);
  const [editScoreType, setEditScoreType] = useState(null);

  const socketContext = useContext(SocketContext);
  const {
    activeQuestionNumber,
    bonusTeamControlID,
    activePlayerID,
    currentBonusPart,
    didStartRingInTimer,
    dispatch,
    matchState,
    questionType,
    sendMessage,
    showPointButtons,
    teamsThatDidRingIn,
    bonusDeliberationTimerStarted,
    bonusAnswerTimerStarted,
    bonusDeliberationComplete,
    bouncebackTeamEnabled,
    persistBonusComputationTimerHidden,
    persistBonusDeliberationTimerVisible,
    bonusAnswerTimerComplete,
    bonusIncorrectParts,
    tossUpAnswerTimerStarted,
    tossUpAnswerTimerComplete,
    roundTimerState,
  } = socketContext;

  const setQuestionType = (value) => {
    setState({ questionType: value });
  };

  const setUseBonusComputationTime = (value) => {
    setState({ useBonusComputationTime: value });
  };

  const {
    createdAt,
    matchDate,
    matchName,
    matchStatus: currentMatchStatus,
    players: { items: playerList = [] } = {},
    teams: { items: teamList = [] } = {},
  } = match || {};

  const teamOneId = get(teamList, '0.team.id', null);
  const teamTwoId = get(teamList, '1.team.id', null);

  const settings = get(match, 'settings.items.0', {});
  const {
    ringInLimit,
    showComputationTime,
    showEarlyCorrectAnswerBonus,
    showEarlyWrongAnswerPenalty,
    showResetTimer,
    teamQuestionEnabled,
    tossUpEnabled,
    teamEndDeliberationEnabled,
    bonusQuestionEnabled,
    bonusAnswerTimerEnabled,
    bonusTotalParts,
    bonusPointsPerPart,
    bonusBouncebackEnabled,
    bonusImmediateBouncebackEnabled,
    bonusQuestionsFollowTossUps,
    bonusControl,
    bonusDeliberationTimerEnabled,
    bonusComputationTimerEnabled,
    timeoutsEnabled,
    timeouts,
    tossUpAnswerEnabled,
    preserveMinTimeEnabled,
  } = settings;

  const oneRingInPerQuestion = useMemo(
    () => ringInLimit === 'oncePerQuestion',
    [ringInLimit],
  );

  const saveTossUpScore = async (points) => {
    try {
      if (!activePlayerID) throw new Error('Missing activePlayerID');
      const teamID = getActivePlayerTeamID(activePlayerID, playerList);
      const otherPlayers = getOtherPlayersString(playerList, activePlayerID);
      const playerName = getActivePlayerName(activePlayerID, playerList);
      setTossUpWinningTeamId(teamID);
      await createScore({
        currentQuestion: activeQuestionNumber,
        matchID,
        otherPlayers,
        playerID: activePlayerID,
        playerName,
        points,
        teamID,
        type: 'playerTossUp',
      });
    } catch (err) {
      console.log('Save Score Error:', err);
    }
  };

  const saveBonusScore = async ({ points, teamID }) => {
    const otherPlayers = getOtherPlayersString(playerList);
    try {
      await createScore({
        currentQuestion: activeQuestionNumber,
        matchID,
        otherPlayers,
        playerID: 0,
        points,
        teamID,
        type: `bonusPart-${currentBonusPart}`,
      });
    } catch (err) {
      console.log('Save Bonus Score Error:', err);
    }
  };

  const onTossUpAnswerPlay = () => {
    setState({
      tossUpAnswerTimerState: TIMER_STATE_RUNNING,
      tossUpAnswerTimerStarted: (prev) => !prev,
    });
  };

  const onTossUpAnswerPause = () => {
    setState({
      tossUpAnswerTimerState: TIMER_STATE_PAUSED,
      tossUpAnswerTimerStarted: (prev) => !prev,
    });
  };

  const handleShowAnswerTimer = () => {
    setState({
      bonusAnswerTimerState: TIMER_STATE_VISIBLE,
    });
  };

  const handleShowBonusDeliberationTimer = () => {
    setState({
      bonusDeliberationTimerState: TIMER_STATE_VISIBLE,
    });
  };

  const onBonusAnswerTimerPlay = () => {
    setState({
      bonusAnswerTimerState: TIMER_STATE_RUNNING,
      bonusAnswerTimerStarted: (prev) => !prev,
      persistBonusComputationTimerHidden: true,
    });
  };
  const onBonusAnswerTimerPause = () => {
    setState({
      bonusAnswerTimerState: TIMER_STATE_PAUSED,
      bonusAnswerTimerStarted: (prev) => !prev,
    });
  };

  const questionTypeToStart = (type, isReplayingCurrentQuestion = false) => {
    // determine which type from the dropdown
    if (type === TOSS_UP_QUESTION) {
      sendMessage({
        matchID,
        type: MESSAGE_TYPE_START_QUESTION,
        payload: { roundTimerExpired },
      });
      return;
    } else if (type === TEAM_QUESTION) {
      toggleDeliberationTimeModal();
      return;
      // TODO: Move this BONUS QUESTION branching logic into separate functions
    } else if (type === BONUS_QUESTION) {
      if (
        (!skipToBonus && isReplayingCurrentQuestion) ||
        recentlyEditedScores
      ) {
        toggleEditBonusControlModal();
        return;
      }
      if (bonusControl === 'takeTurns') {
        if (!takeTurnsPreviousControl) {
          setTakeTurnsPreviousControl(teamOneId);
          sendMessage({
            matchID,
            type: MESSAGE_TYPE_BONUS_TEAM_CONTROL,
            payload: { bonusTeamControlID: teamOneId },
          });
        } else {
          const nextTeamId = getNextTeamID(takeTurnsPreviousControl);
          sendMessage({
            matchID,
            type: MESSAGE_TYPE_BONUS_TEAM_CONTROL,
            payload: { bonusTeamControlID: nextTeamId },
          });
          setTakeTurnsPreviousControl(nextTeamId);
        }
      } else if (bonusControl === 'prompt') {
        toggleEditBonusControlModal();
        return;
      } else if (bonusControl === 'tossUpWinner') {
        if (!tossUpWinningTeamId) {
          toggleEditBonusControlModal();
          return;
        } else {
          sendMessage({
            matchID,
            type: MESSAGE_TYPE_BONUS_TEAM_CONTROL,
            payload: { bonusTeamControlID: tossUpWinningTeamId },
          });
        }
      }
      // TODO: Move checktruthy inside of handleShowAnswerTimer
      if (checkTruthy(bonusAnswerTimerEnabled)) handleShowAnswerTimer();
      if (checkTruthy(bonusDeliberationTimerEnabled))
        handleShowBonusDeliberationTimer();
      sendMessage({
        matchID,
        type: MESSAGE_TYPE_BONUS_START_QUESTION_PART,
        payload: {
          roundTimerExpired,
        },
      });
    }
  };

  const startQuestion = async () => {
    const isReplayingCurrentQuestion =
      validateQuestionNumberInput(replayQuestionNumber);
    if (isReplayingCurrentQuestion && replayQuestionArray.length === 0) {
      setState({ activeQuestionNumber: replayQuestionNumber });
      const newReplayQuestionArray = getNewReplayQuestionArray({
        bonusQuestionEnabled,
        bonusQuestionsFollowTossUps,
        questionType,
        tossUpEnabled,
      });
      setReplayQuestionArray(newReplayQuestionArray);
      await deletePreviousScores({
        questionNumber: replayQuestionNumber,
        questionTypeArray: newReplayQuestionArray,
        scoreList,
        scoreQuestionTypes,
      });
    } else if (isReplayingCurrentQuestion && replayQuestionArray.length > 0) {
      if (!replayQuestionArray.includes(questionType)) {
        setState({ activeQuestionNumber: nextQuestionNumberToPlay });
        setNextQuestionNumberToPlay(null);
        setIsReplayingQuestion(false);
        setReplayQuestionArray([]);
        setReplayQuestionNumber(null);
      }
    }
    questionTypeToStart(questionType, isReplayingCurrentQuestion);
    if (currentMatchStatus === MATCH_NEW || currentMatchStatus === null) {
      await updateMatch({
        matchID,
        matchDate,
        matchName,
        propsToUpdate: { matchStatus: MATCH_STARTED },
      });
    }
  };

  const nextQuestionType = () => {
    if (questionType === TOSS_UP_QUESTION) {
      if (checkTruthy(bonusQuestionEnabled)) {
        setQuestionType(BONUS_QUESTION);
      }
    } else if (questionType === BONUS_QUESTION) {
      if (tossUpEnabled) {
        setQuestionType(TOSS_UP_QUESTION);
      }
    } else if (questionType === TEAM_QUESTION) {
      if (tossUpEnabled) {
        setQuestionType(TOSS_UP_QUESTION);
      }
    }
  };

  const clearBonusState = () => {
    setState({
      ...(bonusDeliberationTimerStarted && {
        bonusDeliberationTimerStarted: false,
      }),
      ...(bonusAnswerTimerStarted && { bonusAnswerTimerStarted: false }),
      ...(bonusDeliberationComplete && { bonusDeliberationComplete: false }),
      ...(bouncebackTeamEnabled && { bouncebackTeamEnabled: false }),
      ...(bonusDeliberationTimerEnabled && {
        bonusDeliberationTimerState: TIMER_STATE_HIDDEN,
      }),
      ...(bonusAnswerTimerEnabled && {
        bonusAnswerTimerState: TIMER_STATE_HIDDEN,
      }),
      ...(persistBonusComputationTimerHidden && {
        persistBonusComputationTimerHidden: false,
      }),
      ...(persistBonusDeliberationTimerVisible && {
        persistBonusDeliberationTimerVisible: false,
      }),
      ...(bonusAnswerTimerComplete && { bonusAnswerTimerComplete: false }),
      bonusIncorrectParts: [],
    });
  };

  const clearTossUpAnswerTimerState = () => {
    setPlayerRangIn(false);
    if (checkTruthy(tossUpAnswerEnabled)) {
      setState({
        tossUpAnswerTimerStarted: false,
        tossUpAnswerTimerComplete: false,
        tossUpAnswerTimerState: TIMER_STATE_HIDDEN,
      });
    }
  };

  const handleEndQButton = async () => {
    if (questionType === TOSS_UP_QUESTION) {
      clearTossUpAnswerTimerState();
    }
    if (questionType === BONUS_QUESTION) clearBonusState();
    if (questionType === TEAM_QUESTION) {
      setPersistTeamEndDeliberationButton(false);
    }
    if (questionType !== TEAM_QUESTION) {
      await deletePreviousScores({
        questionNumber: questionNumberInputDisplay,
        questionTypeArray: [questionType],
        scoreList,
        scoreQuestionTypes,
      });
    }
    endQuestion({ canIncrementQuestionNumber: false, pauseRoundTimer: true });
  };

  const handleEndTeam = () => {
    let shouldEndReplayQuestion = false;
    if (isReplayingQuestion) {
      const remainingQuestionArray = getRemainingReplayQuestions({
        questionType,
        replayQuestionArray,
      });
      if (remainingQuestionArray.length === 0) shouldEndReplayQuestion = true;
      setReplayQuestionArray(remainingQuestionArray);
    }
    nextQuestionType();
    setPersistTeamEndDeliberationButton(false);
    endQuestion({ shouldEndReplayQuestion, pauseRoundTimer: false });
  };

  const handleEndBonus = () => {
    let shouldEndReplayQuestion = false;
    if (isReplayingQuestion) {
      const remainingQuestionArray = getRemainingReplayQuestions({
        questionType,
        replayQuestionArray,
      });
      if (remainingQuestionArray.length === 0) shouldEndReplayQuestion = true;
      setReplayQuestionArray(remainingQuestionArray);
    }
    nextQuestionType();
    clearBonusState();
    if (recentlyEditedScores) setRecentlyEditedScores(false);
    endQuestion({ shouldEndReplayQuestion, pauseRoundTimer: false });
  };

  const setCurrentBonusPart = (partNumber) => {
    // Handle No More Parts
    if (!partNumber || partNumber > bonusTotalParts) {
      if (
        checkTruthy(bonusBouncebackEnabled) &&
        !checkTruthy(bonusImmediateBouncebackEnabled)
      ) {
        if (bouncebackTeamEnabled) {
          handleEndBonus();
          return;
        } else {
          const nextBonusPart = bonusIncorrectParts[0];
          if (nextBonusPart) {
            setState({
              bouncebackTeamEnabled: true,
            });
            const nextTeamId = getNextTeamID(bonusTeamControlID);
            sendMessage({
              matchID,
              type: MESSAGE_TYPE_BONUS_TEAM_CONTROL,
              payload: {
                bonusTeamControlID: nextTeamId,
                currentBonusPart: nextBonusPart,
              },
            });
            return;
          }
        }
      }
      handleEndBonus();
      return;
    }
    sendMessage({
      matchID,
      type: MESSAGE_TYPE_BONUS_PART_DISPLAY,
      payload: { currentBonusPart: partNumber },
    });
  };

  React.useEffect(() => {
    if (
      matchState === MATCH_STATE_BONUS_QUESTION_OPEN &&
      bonusIncorrectParts.length > 0
    ) {
      setCurrentBonusPart(currentBonusPart + 1);
    }
  }, [bonusIncorrectParts]);

  React.useEffect(() => {
    if (
      matchState === MATCH_STATE_CHOOSE_QUESTION &&
      skipToBonus &&
      checkTruthy(bonusQuestionEnabled)
    ) {
      setQuestionType(BONUS_QUESTION);
      questionTypeToStart(BONUS_QUESTION);
      setSkipToBonus(false);
    }
  }, [matchState, skipToBonus]);

  const toggleTimeoutsModal = () => {
    setShowTimeoutsModal((prev) => {
      if (!prev && roundTimerState === TIMER_STATE_RUNNING)
        sendMessage({ matchID, type: MESSAGE_TYPE_PAUSE_ROUND_TIMER });
      return !prev;
    });
  };

  const startTimeout = (duration) => {
    dispatch({
      type: MESSAGE_TYPE_START_TIMEOUT,
      payload: { duration },
    });
  };

  const toggleSubstitutionModal = () =>
    setShowSubstitutionModal((prev) => {
      if (!prev && roundTimerState === TIMER_STATE_RUNNING)
        sendMessage({ matchID, type: MESSAGE_TYPE_PAUSE_ROUND_TIMER });
      return !prev;
    });
  const toggleEditScoresModal = () => {
    if (editScoreType) setEditScoreType(null);
  };
  const toggleDeliberationTimeModal = () =>
    setShowEditDeliberationTimerModal((prev) => !prev);
  const toggleTeamQuestionScoreModal = () =>
    setShowTeamQuestionScoreModal((prev) => !prev);
  const toggleEndMatchModal = () => {
    setShowEndMatchModal((prev) => {
      if (!prev && roundTimerState === TIMER_STATE_RUNNING)
        sendMessage({ matchID, type: MESSAGE_TYPE_PAUSE_ROUND_TIMER });
      return !prev;
    });
    setShowModalError(false);
  };
  const toggleEditBonusControlModal = () => {
    setShowBonusControlModal((prev) => !prev);
  };

  const startRingIn = () => {
    sendMessage({ matchID, type: MESSAGE_TYPE_OPEN_RING_IN });
  };

  const startLimitedRingIn = () => {
    const resetRingInTimerAfterIncorrectAnswer =
      (showResetTimer && checkTruthy(showResetTimer)) || (preserveMinTimeEnabled && checkTruthy(preserveMinTimeEnabled));
    sendMessage({
      matchID,
      type: MESSAGE_TYPE_OPEN_RING_IN_LIMITED_TEAMS,
      payload: { resetRingInTimer: resetRingInTimerAfterIncorrectAnswer },
    });
  };

  const pauseRingIn = () => {
    sendMessage({ matchID, type: MESSAGE_TYPE_PAUSE_RING_IN });
  };

  const pauseRingInLimited = () => {
    sendMessage({ matchID, type: MESSAGE_TYPE_PAUSE_RING_IN_LIMITED_TEAMS });
  };

  const unpauseRingIn = () => {
    sendMessage({ matchID, type: MESSAGE_TYPE_UNPAUSE_RING_IN });
  };

  const unpauseRingInLimited = () => {
    sendMessage({ matchID, type: MESSAGE_TYPE_UNPAUSE_RING_IN_LIMITED_TEAMS });
  };

  const undoRingIn = () => {
    sendMessage({ matchID, type: MESSAGE_TYPE_UNDO });
    setRingIns([]);
    clearTossUpAnswerTimerState();
  };

  const handleBonusControlChange = (teamID) => {
    // TODO: REFACTOR THE OVERLAY CLOSING TO AVOID DUPLICATE SEND MESSAGE
    sendMessage({
      matchID,
      type: MESSAGE_TYPE_BONUS_TEAM_CONTROL,
      payload: { bonusTeamControlID: teamID },
    });

    sendMessage({
      matchID,
      type: MESSAGE_TYPE_BONUS_START_QUESTION_PART,
      payload: {
        roundTimerExpired,
      },
    });

    if (checkTruthy(bonusAnswerTimerEnabled)) handleShowAnswerTimer();
    if (checkTruthy(bonusDeliberationTimerEnabled))
      handleShowBonusDeliberationTimer();
  };

  const handleTossUpPoints = async (points, isCorrect) => {
    if (savingScore) return;
    setSavingScore(true);
    await saveTossUpScore(points);
    setSavingScore(false);
    setRingIns([]);
    clearTossUpAnswerTimerState();
    if (recentlyEditedScores) setRecentlyEditedScores(false);
    let shouldEndReplayQuestion = false;
    const allTeamsDidRingIn = teamsThatDidRingIn.length === teamList.length;
    const shouldEndQuestion =
      oneRingInPerQuestion || isCorrect || allTeamsDidRingIn;
    const shouldContinueToBonus =
      bonusQuestionsFollowTossUps === 'ifAnsweredCorrectly' && isCorrect;
    if (shouldEndQuestion) {
      if (shouldContinueToBonus) setSkipToBonus(true);
      if (isReplayingQuestion) {
        const remainingQuestionArray = getRemainingReplayQuestions({
          questionType,
          replayQuestionArray,
        });
        if (remainingQuestionArray.length === 0 || !isCorrect)
          shouldEndReplayQuestion = true;
        setReplayQuestionArray(remainingQuestionArray);
      }
      endQuestion({ shouldEndReplayQuestion });
      return;
    }
    startLimitedRingIn();
  };

  const getNextBounceBackBonusPart = () => {
    const index = bonusIncorrectParts.indexOf(currentBonusPart) + 1;
    return bonusIncorrectParts[index];
  };

  const getNextTeamID = (prevTeamID) => {
    return prevTeamID === teamOneId ? teamTwoId : teamOneId;
  };

  const handleBonusPoints = async (points, teamID) => {
    // positive score

    if (savingScore) return;
    setState({
      bonusAnswerTimerComplete: false,
      persistBonusComputationTimerHidden: false,
      bonusAnswerTimerState: TIMER_STATE_RESET,
      bonusAnswerTimerStarted: false,
    });

    // If the bounced team is active
    if (bouncebackTeamEnabled) {
      // If immediate bounceback
      if (checkTruthy(bonusImmediateBouncebackEnabled)) {
        setCurrentBonusPart(currentBonusPart + 1);
        setState({
          bouncebackTeamEnabled: false,
        });
        if (currentBonusPart + 1 > bonusTotalParts) {
          setSavingScore(true);
          await saveBonusScore({ points, teamID });
          setSavingScore(false);
          return;
        }
        const nextTeamId = getNextTeamID(bonusTeamControlID);
        sendMessage({
          matchID,
          type: MESSAGE_TYPE_BONUS_TEAM_CONTROL,
          payload: { bonusTeamControlID: nextTeamId },
        });
        setSavingScore(true);
        await saveBonusScore({ points, teamID });
        setSavingScore(false);
        // If bounceback without immediate bounceback
      } else {
        // SEND MESSAGE TO UPDATE PART IN PHASE 2
        const nextBonusPart = getNextBounceBackBonusPart();
        setCurrentBonusPart(nextBonusPart);

        setSavingScore(true);
        await saveBonusScore({ points, teamID });
        setSavingScore(false);
      }
      return;
    }

    // If no bounced team, and original team got it right
    setCurrentBonusPart(currentBonusPart + 1);
    setSavingScore(true);
    await saveBonusScore({ points, teamID });
    setSavingScore(false);
  };

  const handleBonusIncorrect = async (teamID) => {
    // incorrect score
    setSavingScore(true);
    await saveBonusScore({ points: 0, teamID });
    setSavingScore(false);
    if (savingScore) return;
    setState({
      bonusAnswerTimerComplete: false,
      bonusAnswerTimerState: TIMER_STATE_RESET,
      bonusAnswerTimerStarted: false,
      persistBonusComputationTimerHidden: false,
    });

    // If immediate bounceback enabled
    if (checkTruthy(bonusImmediateBouncebackEnabled)) {
      // if bounced team active
      if (bouncebackTeamEnabled) {
        setCurrentBonusPart(currentBonusPart + 1);
        setState({
          bouncebackTeamEnabled: false,
        });
        if (currentBonusPart + 1 > bonusTotalParts) return;
        // Set Team ID for control
        const nextTeamId = getNextTeamID(bonusTeamControlID);
        sendMessage({
          matchID,
          type: MESSAGE_TYPE_BONUS_TEAM_CONTROL,
          payload: { bonusTeamControlID: nextTeamId },
        });
        // if not bounced team active
      } else {
        // Set Team ID for control
        const nextTeamId = getNextTeamID(bonusTeamControlID);
        sendMessage({
          matchID,
          type: MESSAGE_TYPE_BONUS_TEAM_CONTROL,
          payload: { bonusTeamControlID: nextTeamId },
        });
        setState({
          bouncebackTeamEnabled: true,
        });
      }
      return;
    }

    // If bounceback enabled
    if (
      checkTruthy(bonusBouncebackEnabled) &&
      !checkTruthy(bonusImmediateBouncebackEnabled)
    ) {
      // if bounced team active
      if (bouncebackTeamEnabled) {
        const nextBonusPart = getNextBounceBackBonusPart();
        setCurrentBonusPart(nextBonusPart);

        // if not bounced team active
      } else {
        setState({
          bonusIncorrectParts: (prev) => [...prev, currentBonusPart],
        });
      }
      return;
    }

    // If no bounceback and no immediate bounceback
    setCurrentBonusPart(currentBonusPart + 1);
  };

  const handleQuestionTypeChange = (e) => {
    setQuestionType(e.target.value);
    if (isReplayingQuestion) {
      if (replayQuestionArray.includes(e.target.value)) {
        setQuestionNumberInputDisplay(replayQuestionNumber);
        return;
      }
    }
    setQuestionNumberInputDisplay(nextQuestionNumberToPlay);
  };

  const handleEditDeliberationTimerSubmit = (totalSecondsLeft) => {
    if (totalSecondsLeft === 0) {
      return;
    }
    setTeamDeliberationDuration(totalSecondsLeft);
    sendMessage({
      matchID,
      type: MESSAGE_TYPE_TEAM_START_QUESTION,
      payload: {
        teamDeliberationTimeLeft: totalSecondsLeft,
        roundTimerExpired,
      },
    });
  };

  const handleTeamDeliberationStart = () => {
    sendMessage({ matchID, type: MESSAGE_TYPE_TEAM_START_DELIBERATION_TIMER });
    setPersistTeamEndDeliberationButton(true);
  };

  const handleTeamDeliberationPause = () => {
    sendMessage({ matchID, type: MESSAGE_TYPE_TEAM_PAUSE_DELIBERATION_TIMER });
  };

  const onBonusDeliberationPlay = () => {
    setState({
      bonusDeliberationTimerState: TIMER_STATE_RUNNING,
      bonusDeliberationTimerStarted: (prev) => !prev,
      persistBonusDeliberationTimerVisible: true,
    });
  };

  const onBonusDeliberationPause = () => {
    setState({
      bonusDeliberationTimerState: TIMER_STATE_PAUSED,
      bonusDeliberationTimerStarted: (prev) => !prev,
    });
  };

  const handleBonusComputationTimeChange = () => {
    setUseBonusComputationTime((prev) => !prev);
  };

  const toggleScoreSheetModal = () => {
    if (editScoreType) setEditScoreType(null);
  };

  const validateQuestionNumberInput = (number) => {
    const questionNumberFromScores = getQuestionNumberFromScores(scoreList);
    if (!number || number == 0) {
      setQuestionNumberInputDisplay(activeQuestionNumber);
      setIsReplayingQuestion(false);
      return false;
    }
    if (number == questionNumberFromScores) {
      setIsReplayingQuestion(false);
      return false;
    }
    setIsReplayingQuestion(true);
    setNextQuestionNumberToPlay(questionNumberFromScores);
    return true;
  };

  const toggleEditScoreTypeModal = async () => {
    setShowEditScoreTypeModal((prev) => {
      if (!prev && roundTimerState === TIMER_STATE_RUNNING)
        sendMessage({ matchID, type: MESSAGE_TYPE_PAUSE_ROUND_TIMER });
      return !prev;
    });
  };

  const handleEditScoreTypeChange = (editScoreType) => {
    setEditScoreType(editScoreType);
  };

  /*
    TOSS-UP BUTTONS:
    Row 1 = pointButtons (power, right, wrong, neg, undo)
    Row 2 = ringInButtons (computation, start/pause ring-in timer) 
    Row 3 = questionButtons (play q, choose q select, end q)
    Row 4 = matchButtons (ring-in test, edit scores, [end match])
  */

  /*
    TEAM Q BUTTONS:
    Row 1 = pointButtons: NOT USED / NO CHANGE
    Row 2 = ringInButtons (end deliberation, enter scores)
    Row 3 = questionButtons (start/pause deliberation timer, end q)
    Row 4 = matchButtons: NO CHANGE
  */

  /* 
    BONUS BUTTONS:
    Row 1 = pointButtons (Right Score Button, Wrong Score Button)
    Row 2 = ringInButtons (computation, start/pause deliberation timer, start/pause answer timer, end deliberation)
    Row 3 = questionButtons (end q)
    Row 4 = NO CHANGE
  */

  let pointButtons = [];
  let ringInButtons = [];
  let questionButtons = [];
  let matchButtons = [];

  if (matchState === MATCH_STATE_CHOOSE_QUESTION) {
    questionButtons = [
      <button
        key="round-button-start"
        className="round-button positive start"
        onClick={startQuestion}
      >
        Play Q
      </button>,
      <label className="question-label" key="question-type-select">
        <select
          className="question-type-select"
          onChange={handleQuestionTypeChange}
          value={questionType}
        >
          {tossUpEnabled && <option value="Toss-Up">Toss-Up</option>}
          {teamQuestionEnabled && <option value="Team">Team</option>}
          {checkTruthy(bonusQuestionEnabled) && (
            <option value="Bonus">Bonus</option>
          )}
        </select>
      </label>,
      <QuestionNumberInput
        key="question-number-input-value"
        activeQuestionNumber={activeQuestionNumber}
        disableQuestionNumberInput={disableQuestionNumberInput}
        isReplayingQuestion={isReplayingQuestion}
        questionNumberInputDisplay={questionNumberInputDisplay}
        scoreList={scoreList}
        setDisableQuestionNumberInput={setDisableQuestionNumberInput}
        setNextQuestionNumberToPlay={setNextQuestionNumberToPlay}
        setQuestionNumberInputDisplay={setQuestionNumberInputDisplay}
        setReplayQuestionNumber={setReplayQuestionNumber}
        validateQuestionNumberInput={validateQuestionNumberInput}
      />,
    ];
  } else if (statesToShowEndQ.includes(matchState) && !activePlayerID) {
    questionButtons = [
      <button
        key="round-button-end"
        className="round-button negative stop"
        onClick={handleEndQButton}
      >
        End Q
      </button>,
    ];
  }

  if (
    matchState === MATCH_STATE_TEAM_QUESTION_OPEN ||
    matchState === MATCH_STATE_TEAM_QUESTION_PAUSED
  ) {
    ringInButtons = [
      checkTruthy(teamEndDeliberationEnabled) &&
        persistTeamEndDeliberationButton && (
          <button
            key="pause-team-deliberation-timer"
            className="round-button neutral"
            onClick={handleTeamDeliberationEnd}
          >
            End Deliberation
          </button>
        ),
    ];
    questionButtons = [
      <button
        key="start-team-deliberation-timer"
        className="round-button positive"
        onClick={handleTeamDeliberationStart}
      >
        Start Deliberation Timer
      </button>,
      <button
        key="round-button-end"
        className="round-button negative stop"
        onClick={handleEndQButton}
      >
        End Q
      </button>,
    ];
  }

  if (matchState === MATCH_STATE_TEAM_QUESTION_STARTED) {
    ringInButtons = [
      checkTruthy(teamEndDeliberationEnabled) &&
        persistTeamEndDeliberationButton && (
          <button
            key="pause-team-deliberation-timer"
            className="round-button neutral"
            onClick={handleTeamDeliberationEnd}
          >
            End Deliberation
          </button>
        ),
    ];
    questionButtons = [
      <button
        key="pause-team-deliberation-timer"
        className="round-button negative"
        onClick={handleTeamDeliberationPause}
      >
        Pause Deliberation Timer
      </button>,
      <button
        key="round-button-end"
        className="round-button negative stop"
        onClick={handleEndQButton}
      >
        End Q
      </button>,
    ];
  }

  if (matchState === MATCH_STATE_TEAM_QUESTION_CLOSED) {
    ringInButtons = [
      <button
        key="enter-scores"
        className="round-button positive"
        onClick={toggleTeamQuestionScoreModal}
      >
        Enter Scores
      </button>,
    ];
    questionButtons = [
      <button
        key="round-button-end"
        className="round-button negative stop"
        onClick={handleEndQButton}
      >
        End Q
      </button>,
    ];
  }

  if (matchState === MATCH_STATE_BONUS_QUESTION_OPEN) {
    pointButtons = [
      (bonusDeliberationComplete ||
        !checkTruthy(bonusDeliberationTimerEnabled)) && (
        <div key="bonus-point-buttons">
          <button
            key="bonus-button-right"
            className="round-button positive"
            onClick={() =>
              handleBonusPoints(bonusPointsPerPart, bonusTeamControlID)
            }
          >
            Right
          </button>
          <button
            key="bonus-button-wrong"
            className="round-button neutral"
            onClick={() => handleBonusIncorrect(bonusTeamControlID)}
          >
            Wrong
          </button>
        </div>
      ),
    ];
    ringInButtons = [
      checkTruthy(bonusComputationTimerEnabled) &&
      !bonusAnswerTimerStarted &&
      !persistBonusComputationTimerHidden ? (
        <button
          key="bonus-computation-time"
          className="round-button other"
          onClick={handleBonusComputationTimeChange}
        >
          Computation
        </button>
      ) : (
        <div key="bonus-computation-time" className="computation-spacer"></div>
      ),
      <TimerButton
        key={'bonus-deliberation-button'}
        enabled={
          checkTruthy(bonusDeliberationTimerEnabled) &&
          !bonusDeliberationComplete
        }
        currentlyActive={bonusDeliberationTimerStarted}
        onPlay={onBonusDeliberationPlay}
        onPause={onBonusDeliberationPause}
        text="Deliberation Timer"
      />,
      <TimerButton
        key={'bonus-answer-button'}
        enabled={
          checkTruthy(bonusAnswerTimerEnabled) && !bonusAnswerTimerComplete
        }
        currentlyActive={bonusAnswerTimerStarted}
        onPlay={onBonusAnswerTimerPlay}
        onPause={onBonusAnswerTimerPause}
        text="Answer Timer"
      />,
      (bonusDeliberationTimerStarted && !bonusDeliberationComplete) ||
      persistBonusDeliberationTimerVisible ? (
        <button
          key="pause-bonus-deliberation-timer"
          className="round-button neutral"
          onClick={handleBonusDeliberationEnd}
        >
          End Deliberation
        </button>
      ) : (
        <div
          key="pause-bonus-deliberation-timer"
          className="end-deliberation-spacer"
        ></div>
      ),
    ];

    questionButtons = [
      <button
        key="round-button-end"
        className="round-button negative stop"
        onClick={handleEndQButton}
      >
        End Q
      </button>,
    ];
  }

  if (matchState === MATCH_STATE_ASKING_QUESTION) {
    ringInButtons = [
      ...(checkTruthy(showComputationTime)
        ? [
            <button
              key="round-button-computation-time"
              className="round-button other"
              onClick={() => setUseComputationTime((prev) => !prev)}
            >
              Computation
            </button>,
          ]
        : []),
      <button
        key="round-button-start"
        className="round-button positive start"
        onClick={startRingIn}
      >
        Start Ring-in Timer
      </button>,
      <TimerButton
        key={'toss-up-answer'}
        enabled={
          checkTruthy(tossUpAnswerEnabled) &&
          !tossUpAnswerTimerComplete &&
          playerRangIn
        }
        currentlyActive={tossUpAnswerTimerStarted}
        onPlay={onTossUpAnswerPlay}
        onPause={onTossUpAnswerPause}
        text="Answer Timer"
      />,
    ];
  } else if (matchState === MATCH_STATE_RING_IN_OPEN) {
    ringInButtons = [
      <button
        key="round-button-start"
        className="round-button negative start"
        onClick={pauseRingIn}
      >
        Pause Ring-in Timer
      </button>,
      <TimerButton
        key={'toss-up-answer'}
        enabled={
          checkTruthy(tossUpAnswerEnabled) &&
          !tossUpAnswerTimerComplete &&
          playerRangIn
        }
        currentlyActive={tossUpAnswerTimerStarted}
        onPlay={onTossUpAnswerPlay}
        onPause={onTossUpAnswerPause}
        text="Answer Timer"
      />,
    ];
  } else if (matchState === MATCH_STATE_RING_IN_PAUSED) {
    ringInButtons = [
      <button
        key="round-button-start"
        className="round-button positive start"
        onClick={unpauseRingIn}
      >
        Start Ring-in Timer
      </button>,
    ];
  } else if (matchState === MATCH_STATE_RING_IN_OPEN_LIMITED_TEAMS) {
    if (didStartRingInTimer) {
      ringInButtons = [
        <button
          key="round-button-start"
          className="round-button negative start"
          onClick={pauseRingInLimited}
        >
          Pause Ring-in Timer
        </button>,
        <TimerButton
          key={'toss-up-answer'}
          enabled={
            checkTruthy(tossUpAnswerEnabled) &&
            !tossUpAnswerTimerComplete &&
            playerRangIn
          }
          currentlyActive={tossUpAnswerTimerStarted}
          onPlay={onTossUpAnswerPlay}
          onPause={onTossUpAnswerPause}
          text="Answer Timer"
        />,
      ];
    } else {
      ringInButtons = [
        <button
          key="round-button-start"
          className="round-button positive start"
          onClick={unpauseRingInLimited}
        >
          Start Ring-in Timer
        </button>,
        <TimerButton
          key={'toss-up-answer'}
          enabled={
            checkTruthy(tossUpAnswerEnabled) &&
            !tossUpAnswerTimerComplete &&
            playerRangIn
          }
          currentlyActive={tossUpAnswerTimerStarted}
          onPlay={onTossUpAnswerPlay}
          onPause={onTossUpAnswerPause}
          text="Answer Timer"
        />,
      ];
    }
  } else if (matchState === MATCH_STATE_RING_IN_PAUSED_LIMITED) {
    ringInButtons = [
      <button
        key="round-button-start"
        className="round-button positive start"
        onClick={unpauseRingInLimited}
      >
        Start Ring-in Timer
      </button>,
    ];
  }

  if (showPointButtons && activePlayerID) {
    const { powerPoints, correctPoints, wrongPoints, negPoints } =
      getPointValues(settings);
    pointButtons = [
      checkTruthy(showEarlyCorrectAnswerBonus) && (
        <button
          key="round-button-correct-early"
          className="round-button power"
          onClick={() => handleTossUpPoints(powerPoints, true)}
        >
          Power
        </button>
      ),
      <button
        key="round-button-correct"
        className="round-button positive"
        onClick={() => handleTossUpPoints(correctPoints, true)}
      >
        Right
      </button>,
      <button
        key="round-button-wrong"
        className="round-button neutral"
        onClick={() => handleTossUpPoints(wrongPoints, false)}
      >
        Wrong
      </button>,
      checkTruthy(showEarlyWrongAnswerPenalty) && (
        <button
          key="round-button-wrong-early"
          className="round-button negative"
          onClick={() => handleTossUpPoints(negPoints, false)}
        >
          Neg
        </button>
      ),
      <button
        key="round-button-undo"
        className="round-button other"
        onClick={undoRingIn}
      >
        Undo
      </button>,
    ];
  }

  const startRingInTest = () => {
    sendMessage({ matchID, type: MESSAGE_TYPE_START_RING_IN_TEST });
  };

  const resetRingInTest = () => {
    sendMessage({ matchID, type: MESSAGE_TYPE_RESET_RING_IN_TEST });
    setRingIns([]);
  };

  const endRingInTest = () => {
    sendMessage({ matchID, type: MESSAGE_TYPE_END_RING_IN_TEST });
    setRingIns([]);
  };

  if (matchState === MATCH_STATE_CHOOSE_QUESTION) {
    matchButtons = [
      <button
        key="round-button-ring-in-test"
        className="round-button positive"
        onClick={startRingInTest}
      >
        Ring-In Test
      </button>,
      ...(!timeoutsEnabled || checkTruthy(timeoutsEnabled)
        ? [
            <button
              key="round-button-timeouts"
              className="round-button negative"
              onClick={toggleTimeoutsModal}
              timeouts={timeouts}
            >
              Timeout
            </button>,
          ]
        : []),
      <button
        key="round-button-edit-scores"
        className="round-button neutral"
        onClick={toggleEditScoreTypeModal}
      >
        Edit Scores
      </button>,
      <button
        key="round-button-sub-players"
        className="round-button other"
        onClick={toggleSubstitutionModal}
      >
        Sub Players
      </button>,
      <button
        key="round-button-end-match"
        className="round-button negative"
        onClick={toggleEndMatchModal}
      >
        End Match
      </button>,
    ];
  } else if (matchState === MATCH_STATE_RING_IN_TEST_OPEN) {
    matchButtons = [
      <button
        key="round-button-end-ring-in-test"
        className="round-button negative start"
        onClick={endRingInTest}
      >
        End Ring-In Test
      </button>,
    ];
  } else if (matchState === MATCH_STATE_RING_IN_TEST_CLOSED) {
    matchButtons = [
      <button
        key="round-button-end-ring-in-test"
        className="round-button negative"
        onClick={endRingInTest}
      >
        End Ring-In Test
      </button>,
      <button
        key="round-button-reset-ring-in-test"
        className="round-button negative start"
        onClick={resetRingInTest}
      >
        Reset
      </button>,
    ];
  } else if (matchState === MATCH_STATE_TIMEOUT_OPEN) {
    matchButtons = [
      <button
        key="round-button-end-timeout"
        className="round-button negative"
        onClick={endTimeout}
      >
        End Timeout
      </button>,
    ];
  }

  // if the match is already completed, hide most buttons
  if (currentMatchStatus === MATCH_COMPLETED) {
    matchButtons = [
      <button
        key="round-button-edit-scores"
        className="round-button neutral"
        onClick={toggleEditScoreTypeModal}
      >
        Edit Scores
      </button>,
    ];
    pointButtons = [];
    ringInButtons = [];
    questionButtons = [];
  }

  return (
    <>
      <div className={`button-container ${className}`}>
        {matchState === MATCH_STATE_CHOOSE_QUESTION ? null :
          (
            <div className={`button-container-row center`}>{pointButtons.length ? pointButtons : ''}</div>
          )}
        <div
          className={`button-container-row bottom ${
            matchState === MATCH_STATE_TEAM_QUESTION_CLOSED ? 'right' : ''
          }`}
        >
          {ringInButtons}
        </div>
        <div className="button-container-row center">{questionButtons}</div>
        {matchButtons.length > 0 && (
          <>
            <div className="divider" />
            <div className="bottom button-container-row">{matchButtons}</div>
          </>
        )}
      </div>
      {showTimeoutsModal && (
        <TimeoutsModal
          toggleModal={toggleTimeoutsModal}
          descriptionText={'Choose a Timeout'}
          timeouts={timeouts}
          startTimeout={startTimeout}
        />
      )}
      {showEditDeliberationTimerModal && (
        <EditTimerModal
          currentTime={teamDeliberationDuration}
          toggleModal={toggleDeliberationTimeModal}
          handleEditTimerSubmit={handleEditDeliberationTimerSubmit}
          timerHeading={'Deliberation Timer'}
        />
      )}
      {showTeamQuestionScoreModal && (
        <EditScoresModal
          matchID={matchID}
          teamList={teamList}
          toggleModal={toggleTeamQuestionScoreModal}
          scoreHeader={'Enter Team Question Scores'}
          handleScoreSubmit={handleEndTeam}
          type="team"
          currentQuestion={activeQuestionNumber}
          playerList={playerList}
        />
      )}
      {showBonusControlModal && (
        <EditBonusControlModal
          teamList={teamList}
          handleSubmit={handleBonusControlChange}
          toggleModal={toggleEditBonusControlModal}
          text="Which team has bonus control?"
        />
      )}
      {showEndMatchModal && (
        <Modal
          confirmAction={handleEndMatch}
          confirmLoading={endMatch}
          toggleModal={toggleEndMatchModal}
          showModalError={showModalError}
          text="Are you sure you want to end the match?"
        />
      )}
      {showSubstitutionModal && (
        <SubstitutionModal
          toggleModal={toggleSubstitutionModal}
          playerList={playerList}
          teamList={teamList}
          matchID={matchID}
        />
      )}
      {showEditScoreTypeModal && (
        <EditScoreTypeModal
          handleSubmit={handleEditScoreTypeChange}
          toggleModal={toggleEditScoreTypeModal}
          createdAt={createdAt}
          isReplayingQuestion={isReplayingQuestion}
          text="How would you like to edit scores?"
        />
      )}
      {editScoreType === MANUAL_SCORES && (
        <EditScoresModal
          matchID={matchID}
          teamList={teamList}
          toggleModal={toggleEditScoresModal}
          scoreHeader={'Adjust Scores +/-'}
          type="hostEdit"
          playerList={playerList}
          setRecentlyEditedScores={setRecentlyEditedScores}
        />
      )}
      {editScoreType === DETAILED_SCORES && (
        <ScoreSheetModal
          scoreListProp={scoreList}
          text="Detailed Score Sheet"
          toggleModal={toggleScoreSheetModal}
          match={match}
          matchID={matchID}
        />
      )}
    </>
  );
};

const StyledMatchControls = styled(MatchControls)`
  .button-container {
    position: relative;
    width: 95%;
    max-width: 62rem;
    margin: 0 auto;

    .divider {
      width: 100%;
      border-bottom: 3px solid #555;
      margin: 0.4rem;
    }
  }

  .button-container-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 48px;
  }

  .center {
    justify-content: center;
    align-items: baseline;
  }

  .right {
    justify-content: end;
  }

  .computation-spacer {
    width: 12rem;
  }

  .end-deliberation-spacer {
    width: 12.1rem;
  }

  .bonus-deliberation-timer {
    margin-bottom: 1rem;
  }

  .question-label {
    height: 3.8rem;
    display: flex;
    align-items: baseline;
    margin: 0.5rem;
  }

  .question-type-select {
    margin-right: 1rem;
    font-weight: bold;
    height: 3.8rem;
  }

  .bottom {
    display: inline-block;
  }

  .question-container {
    margin-bottom: 1rem;
  }

  .question-type-text {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.5rem;
  }

  .round-button {
    font-size: 1.25rem;
    margin: 0.5rem;
    text-transform: uppercase;
    padding: 0 10px;
    min-height: 3.8rem;
    max-width: 23rem;
    white-space: normal;
    overflow-wrap: break-word;
    line-height: 1.4rem;

    &:hover {
      text-decoration: underline;
    }

    &.stop {
      margin-left: auto;
    }

    &.power {
      background-color: blue;
      color: white;
    }

    &.positive {
      background-color: green;
      color: white;
    }

    &.negative {
      background-color: red;
      color: white;
    }

    &.neutral {
      background-color: yellow;
      color: black;
    }

    &.other {
      background-color: orange;
      color: black;
    }
  }

  @media (min-width: 900px) {
    .button-container {
      width: 62rem;
    }

    .bottom {
      display: flex;
    }

    .question-type-text {
      font-size: 2rem;
    }
  }
`;

export default StyledMatchControls;
