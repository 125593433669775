export const listMatchs = `query ListMatchs(
  $filter: ModelMatchFilterInput
  $limit: Int
  $nextToken: String
) {
  listMatchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      active
      bonusRoundOwner
      createdAt
      messages {
        items {
          id
          matchID
          messageType
          playerID
          updatedAt
        }
        nextToken
      }
      players(limit: 50) {
        items {
          id
          active
          score
          matchID
          playerID
          deviceID
          order
          user
        }
        nextToken
      }
      matchDate
      matchName
      matchStatus
      teams {
        items {
          id
          active
          score
          matchID
          teamID
          user
        }
        nextToken
      }
      updatedAt
      user
      settings {
        items {
          id
          matchID
          user
          startingPoints
          tossUpEnabled
          tossUpPoints
          showWrongAnswerPenalty
          wrongAnswerPenaltyPoints
          showEarlyCorrectAnswerBonus
          earlyCorrectAnswerPoints
          showEarlyWrongAnswerPenalty
          earlyWrongAnswerPoints
          roundTimerEnabled
          roundMinutes
          roundSeconds
          ringInTimer
          showResetTimer
          resetTimerOption
          resetTimerDuration
          showComputationTime
          computationTime
          ringInLimit
          soundRoundTimerEnabled
          soundRingInEnabled
          soundRingInTimerEnabled
          soundTeamDeliberationTimerEnabled
          tossUpAnswerEnabled
          tossUpAnswerSeconds
          soundTossUpAnswerEnabled
          soundTimeoutTimerEnabled
          preserveMinTimeEnabled
          preserveMinTimeSeconds
        }
        nextToken
      }
      scores(limit: 10000) {
        items {
          id
          type
          questionNumber
          points
          otherPlayers
          playerName
          matchID
          playerID
          teamID
          user
        }
        nextToken
      }
    }
    nextToken
  }
}
`;

export const listMatchsTest = `query ListMatchs {
  listMatchs(limit: 1) {
    items {
      id
    }
  }
}
`;

export const getMatch = `query GetMatch($id: ID!) {
  getMatch(id: $id) {
    id
    active
    activePlayerID
    bonusRoundOwner
    createdAt
    matchDate
    matchName
    matchStatus
    updatedAt
    user
    teams {
      items {
        id
        active
        score
        matchID
        order
        teamID
        team {
          id
          name
          updatedAt
          user
          players(limit: 50) {
            items {
              active
              id
              name
              order
              teamID
              user
            }
            nextToken
          }
        }
        user
      }
      nextToken
    }
    players(limit: 50) {
      items {
        id
        active
        score
        matchID
        order
        playerID
        deviceID
        player {
          id
          name
          order
          teamID
          user
        }
        user
      }
      nextToken
    }
    messages {
      items {
        id
        matchID
        messageType
        playerID
        updatedAt
      }
      nextToken
    }
    settings {
      items {
        id
        matchID
        user
        startingPoints
        tossUpEnabled
        tossUpPoints
        showWrongAnswerPenalty
        wrongAnswerPenaltyPoints
        showEarlyCorrectAnswerBonus
        earlyCorrectAnswerPoints
        showEarlyWrongAnswerPenalty
        earlyWrongAnswerPoints
        roundTimerEnabled
        roundMinutes
        roundSeconds
        ringInTimer
        showResetTimer
        resetTimerOption
        resetTimerDuration
        showComputationTime
        computationTime
        ringInLimit
        soundRoundTimerEnabled
        soundRingInEnabled
        soundRingInTimerEnabled
        soundTeamDeliberationTimerEnabled
        soundBonusAnswerTimerEnabled
        soundBonusDeliberationTimerEnabled
        teamEndDeliberationEnabled
        teamDeliberationMinutes
        teamDeliberationSeconds
        teamQuestionEnabled
        bonusQuestionEnabled
        bonusAnswerTimerEnabled
        bonusAnswerSeconds
        bonusTotalParts
        bonusPointsPerPart
        bonusBouncebackEnabled
        bonusImmediateBouncebackEnabled
        bonusQuestionsFollowTossUps
        bonusControl
        bonusDeliberationTimerEnabled
        bonusDeliberationSeconds
        bonusComputationTimerEnabled
        bonusComputationSeconds
        playerTimersEnabled
        timeoutsEnabled
        timeouts {
          duration
          name
          order
        }
        tossUpAnswerEnabled
        tossUpAnswerSeconds
        soundTossUpAnswerEnabled
        soundTimeoutTimerEnabled
        preserveMinTimeEnabled
        preserveMinTimeSeconds
      }
      nextToken
    }
    scores(limit: 10000) {
      items {
        id
        type
        questionNumber
        points
        otherPlayers
        playerName
        matchID
        playerID
        teamID
        user
      }
      nextToken
    }
  }
}
`;

// Include teams
export const matchesByUser = `query MatchesByUser(
  $user: String
  $activeUpdatedAt: ModelMatchMatchesByUserCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMatchFilterInput
  $limit: Int
  $nextToken: String
) {
  matchesByUser(
    user: $user
    activeUpdatedAt: $activeUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        items {
          id
          active
          score
          matchID
          order
          teamID
          team {
            groups {
              items {
                group {
                  active
                  id
                  name
                }
              }
            }
            id
            name
            updatedAt
            user
            players(limit: 50) {
              items {
                id
                name
                order
                teamID
                user
              }
              nextToken
            }
          }
        }
        nextToken
      }
      players(limit: 50) {
        items {
          id
          active
          score
          matchID
          order
          playerID
          deviceID
          player {
            id
            name
            order
            teamID
            user
          }
          user
        }
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        items {
          id
          matchID
          user
          startingPoints
          tossUpEnabled
          tossUpPoints
          showWrongAnswerPenalty
          wrongAnswerPenaltyPoints
          showEarlyCorrectAnswerBonus
          earlyCorrectAnswerPoints
          showEarlyWrongAnswerPenalty
          earlyWrongAnswerPoints
          roundTimerEnabled
          roundMinutes
          roundSeconds
          ringInTimer
          showResetTimer
          resetTimerOption
          resetTimerDuration
          showComputationTime
          computationTime
          ringInLimit
          soundRoundTimerEnabled
          soundRingInEnabled
          soundRingInTimerEnabled
          soundDeliberationTimerEnabled
          teamEndDeliberationEnabled
          teamDeliberationMinutes
          teamDeliberationSeconds
          teamQuestionEnabled
          soundTeamDeliberationTimerEnabled
          bonusQuestionEnabled
          bonusTotalParts
          bonusPointsPerPart
          bonusBouncebackEnabled
          bonusImmediateBouncebackEnabled
          bonusQuestionsFollowTossUps
          bonusControl
          bonusAnswerTimerEnabled
          bonusAnswerSeconds
          bonusDeliberationTimerEnabled
          bonusDeliberationSeconds
          bonusComputationTimerEnabled
          bonusComputationSeconds
          soundBonusAnswerTimerEnabled
          soundBonusDeliberationTimerEnabled
          playerTimersEnabled
          templateID
          timeoutsEnabled
          timeouts {
            duration
            name
            order
          }
          tossUpAnswerEnabled
          tossUpAnswerSeconds
          soundTossUpAnswerEnabled
          soundTimeoutTimerEnabled
          preserveMinTimeEnabled
          preserveMinTimeSeconds
        }
        nextToken
      }
      scores(limit: 10000) {
        items {
          id
          type
          questionNumber
          points
          otherPlayers
          playerName
          matchID
          playerID
          teamID
          user
        }
        nextToken
      }
    }
    nextToken
  }
}
`;

// Include teams
export const matchesByDate = `query MatchesByDate(
  $user: String
  $activeMatchDate: ModelMatchMatchesByDateCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMatchFilterInput
  $limit: Int
  $nextToken: String
) {
  matchesByDate(
    user: $user
    activeMatchDate: $activeMatchDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        items {
          id
          active
          score
          matchID
          order
          teamID
          team {
            groups {
              items {
                group {
                  active
                  id
                  name
                }
              }
            }
            id
            name
            updatedAt
            user
            players(limit: 50) {
              items {
                id
                name
                order
                teamID
                user
              }
              nextToken
            }
          }
        }
        nextToken
      }
      players(limit: 50) {
        items {
          id
          active
          score
          matchID
          order
          playerID
          deviceID
          player {
            id
            name
            order
            teamID
            user
          }
          user
        }
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        items {
          id
          matchID
          user
          startingPoints
          tossUpEnabled
          tossUpPoints
          showWrongAnswerPenalty
          wrongAnswerPenaltyPoints
          showEarlyCorrectAnswerBonus
          earlyCorrectAnswerPoints
          showEarlyWrongAnswerPenalty
          earlyWrongAnswerPoints
          roundTimerEnabled
          roundMinutes
          roundSeconds
          ringInTimer
          showResetTimer
          resetTimerOption
          resetTimerDuration
          showComputationTime
          computationTime
          ringInLimit
          soundRoundTimerEnabled
          soundRingInEnabled
          soundRingInTimerEnabled
          soundDeliberationTimerEnabled
          teamEndDeliberationEnabled
          teamDeliberationMinutes
          teamDeliberationSeconds
          teamQuestionEnabled
          soundTeamDeliberationTimerEnabled
          bonusQuestionEnabled
          bonusTotalParts
          bonusPointsPerPart
          bonusBouncebackEnabled
          bonusImmediateBouncebackEnabled
          bonusQuestionsFollowTossUps
          bonusControl
          bonusAnswerTimerEnabled
          bonusAnswerSeconds
          bonusDeliberationTimerEnabled
          bonusDeliberationSeconds
          bonusComputationTimerEnabled
          bonusComputationSeconds
          soundBonusAnswerTimerEnabled
          soundBonusDeliberationTimerEnabled
          playerTimersEnabled
          templateID
          timeoutsEnabled
          timeouts {
            duration
            name
            order
          }
          tossUpAnswerEnabled
          tossUpAnswerSeconds
          soundTossUpAnswerEnabled
          soundTimeoutTimerEnabled
          preserveMinTimeEnabled
          preserveMinTimeSeconds
        }
        nextToken
      }
      scores(limit: 10000) {
        items {
          id
          type
          questionNumber
          points
          otherPlayers
          playerName
          matchID
          playerID
          teamID
          user
        }
        nextToken
      }
    }
    nextToken
  }
}
`;

export const matchesByName = `query MatchesByName(
  $user: String
  $activeMatchName: ModelMatchMatchesByNameCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMatchFilterInput
  $limit: Int
  $nextToken: String
) {
  matchesByName(
    user: $user
    activeMatchName: $activeMatchName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        items {
          id
          active
          score
          matchID
          order
          teamID
          team {
            groups {
              items {
                group {
                  active
                  id
                  name
                }
              }
            }
            id
            name
            updatedAt
            user
            players(limit: 50) {
              items {
                id
                name
                order
                teamID
                user
              }
              nextToken
            }
          }
        }
        nextToken
      }
      players(limit: 50) {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        items {
          id
          matchID
          user
          startingPoints
          tossUpEnabled
          tossUpPoints
          showWrongAnswerPenalty
          wrongAnswerPenaltyPoints
          showEarlyCorrectAnswerBonus
          earlyCorrectAnswerPoints
          showEarlyWrongAnswerPenalty
          earlyWrongAnswerPoints
          roundTimerEnabled
          roundMinutes
          roundSeconds
          ringInTimer
          showResetTimer
          resetTimerOption
          resetTimerDuration
          showComputationTime
          computationTime
          ringInLimit
          soundRoundTimerEnabled
          soundRingInEnabled
          soundRingInTimerEnabled
          soundDeliberationTimerEnabled
          teamEndDeliberationEnabled
          teamDeliberationMinutes
          teamDeliberationSeconds
          teamQuestionEnabled
          soundTeamDeliberationTimerEnabled
          bonusQuestionEnabled
          bonusTotalParts
          bonusPointsPerPart
          bonusBouncebackEnabled
          bonusImmediateBouncebackEnabled
          bonusQuestionsFollowTossUps
          bonusControl
          bonusAnswerTimerEnabled
          bonusAnswerSeconds
          bonusDeliberationTimerEnabled
          bonusDeliberationSeconds
          bonusComputationTimerEnabled
          bonusComputationSeconds
          soundBonusAnswerTimerEnabled
          soundBonusDeliberationTimerEnabled
          playerTimersEnabled
          templateID
          timeoutsEnabled
          timeouts {
            duration
            name
            order
          }
          tossUpAnswerEnabled
          tossUpAnswerSeconds
          soundTossUpAnswerEnabled
          soundTimeoutTimerEnabled
          preserveMinTimeEnabled
          preserveMinTimeSeconds
        }
        nextToken
      }
      scores(limit: 10000) {
        items {
          id
          type
          questionNumber
          points
          otherPlayers
          playerName
          matchID
          playerID
          teamID
          user
        }
        nextToken
      }
    }
    nextToken
  }
}
`;

// include all players
export const teamsByUser = `query TeamsByUser(
  $user: String
  $nameUpdatedAt: ModelTeamTeamsByUserCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTeamFilterInput
  $limit: Int
  $nextToken: String
) {
  teamsByUser(
    user: $user
    nameUpdatedAt: $nameUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      updatedAt
      user
      players(limit: 1000) {
        items {
          id
          name
          order
          teamID
          user
        }
        nextToken
      }
    }
    nextToken
  }
}
`;

export const activeTeamsByUser = `query ActiveTeamsByUser(
  $user: String
  $activeUpdatedAt: ModelTeamActiveTeamsByUserCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTeamFilterInput
  $limit: Int
  $nextToken: String
) {
  activeTeamsByUser(
    user: $user
    activeUpdatedAt: $activeUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      active
      groups {
        items {
          group {
            active
            id
            name
          }
          teamGroupID
        }
      }
      name
      updatedAt
      user
      matches(limit: 1000) {
        items {
          id
          match {
            id
            active
            matchStatus
          }
        }
        nextToken
      }
      players(limit: 1000, filter: { active: { eq: 1 } }) {
        items {
          id
          name
          order
          teamID
          user
        }
        nextToken
      }
      scores {
        nextToken
      }
    }
    nextToken
  }
}
`;

export const listTeamGroups = `query ListTeamGroups(
  $filter: ModelTeamGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeamGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      teams(limit: 1000) {
        items {
          teamGroupID
          team {
            active
            groups(limit: 1000) {
              items {
                id
                group {
                  active
                  name
                  id
                }
              }
            }
            id
            name
            matches(limit: 1000) {
              items {
                match {
                  active
                  id
                  matchStatus
                }
                id
              }
              nextToken
            }
            players(limit: 1000, filter: { active: { eq: 1 } }) {
              items {
                id
                name
                order
                teamID
                user
              }
              nextToken
            }
          }
        }
      }
      id
      active
      name
      updatedAt
      user
    }
    nextToken
  }
}
`;
