import React from 'react';
import styled from 'styled-components';

const DetailItem = ({ className, label, value }) => (
  <div className={`detail-item ${className}`}>
    <div className="detail-label">{label}:</div>
    <div className="detail-value">{value}</div>
  </div>
);

const StyledDetailItem = styled(DetailItem)`
  margin-bottom: 1.5rem;

  .detail-label,
  .detail-value {
    display: inline-block;
    font-size: 1.75rem;
    line-height: 2.5rem;
  }

  .detail-label {
    font-weight: bold;
    text-align: end;
    margin-right: 2rem;
    width: 40%;
    vertical-align: top;
  }

  .detail-value {
    width: calc(60% - 2rem);
  }
`;

export default StyledDetailItem;
