import LoaderCentered from 'Components/LoaderCentered';
import SettingsHeader from 'Components/SettingsHeader';
import { getTeamsWithoutGroups } from 'Helpers/Api/Team';
import { useMatchSettings } from 'Hooks/MatchSettingsContext';
import useShareTeams from 'Hooks/useShareTeams';
import React from 'react';
import styled from 'styled-components';
import TeamItem from './Components/TeamItem';

const Rosters = ({ className, disabledEdits = false }) => {
  const {
    playerCount,
    selectedTeams,
    setPlayerCount,
    errors,
    setNumberOfTeams,
  } = useMatchSettings();
  const [ownedTeamList, setOwnedTeamList] = React.useState([]);
  const [fetchTeamsComplete, setFetchTeamsComplete] = React.useState(false);
  const { teamGroups } = useShareTeams();

  const handlePlayerCountUpdate = (e) => {
    if (e.target.value < playerCount) {
      if (
        window.confirm(
          'You are going to a lower member count. Some data may be lost. Continue?',
        )
      ) {
        return setPlayerCount(e.target.value);
      }
    } else {
      return setPlayerCount(e.target.value);
    }
  };

  React.useEffect(() => {
    const fetchTeamsWithoutGroups = async () => {
      const { teamsWithoutGroups } = await getTeamsWithoutGroups();
      setOwnedTeamList(teamsWithoutGroups);
      setFetchTeamsComplete(true);
    };
    if (!fetchTeamsComplete) {
      fetchTeamsWithoutGroups();
    }
  }, []);

  let content = null;

  if (
    disabledEdits ||
    (fetchTeamsComplete &&
      (ownedTeamList.length !== 0 || teamGroups.length !== 0))
  ) {
    content = (
      <>
        {errors.teamsError && (
          <div className="error-container">
            <p>{errors.teamsError}</p>
          </div>
        )}
        <div className="roster-controls-container">
          <label>
            <span className="select-label title">Players Per Team</span>
            <select
              disabled={disabledEdits}
              value={playerCount}
              onChange={handlePlayerCountUpdate}
            >
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </label>
          <label>
            <span className="select-label title">Number of Teams</span>
            <select
              onChange={(e) => setNumberOfTeams(e.target.value)}
              value={selectedTeams.length}
              disabled={disabledEdits}
            >
              {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((n) => (
                <option key={n} value={n}>
                  {n}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="roster-container">
          {selectedTeams.map((team, index) => (
            <TeamItem
              key={index}
              team={team}
              disabledEdits={disabledEdits}
              setFetchTeamsComplete={setFetchTeamsComplete}
              ownedTeamList={ownedTeamList}
              teamNumber={index}
            />
          ))}
        </div>
      </>
    );
  }

  if (
    fetchTeamsComplete &&
    ownedTeamList.length === 0 &&
    teamGroups.length === 0
  ) {
    content = (
      <p className="roster-error">
        Please return to your dashboard and create a team on the Rosters tab.
      </p>
    );
  }

  if (!disabledEdits && !fetchTeamsComplete) {
    content = <LoaderCentered />;
  }

  return (
    <div className={`settings-rosters-container ${className}`}>
      <SettingsHeader text="Rosters" />
      {content}
    </div>
  );
};

const StyledRosters = styled(Rosters)`
  .title {
    font-weight: bold;
  }

  .add-team-button {
    float: right;
  }

  .remove-team-button {
    float: right;
    margin: 0px 10px;
  }

  .roster-controls-container {
    display: flex;
    justify-content: space-between;
  }

  .roster-container {
    display: grid;
    gap: 20px;
    width: 100%;
  }

  .roster-error {
    font-weight: bold;
    color: #ff0000;
    text-align: center;
  }

  .select-label {
    margin: 1rem;
    flex-basis: auto;
  }

  @media (min-width: 900px) {
    .roster-container {
      grid-template-columns: 1fr 1fr;
    }

    .select-label {
      display: inline;
    }
  }
`;

export default StyledRosters;
