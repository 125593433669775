import { isEmpty } from '../Helpers/MatchValidator';
import { checkTruthy } from '../Helpers/UtilHelper';

export const validateMatchTemplate = ({
  computationTime,
  earlyCorrectAnswerPoints,
  earlyWrongAnswerPoints,
  preserveMinTimeEnabled,
  preserveMinTimeSeconds,
  resetTimerDuration,
  resetTimerOption,
  ringInTimer,
  roundMinutes,
  roundSeconds,
  roundTimerEnabled,
  showComputationTime,
  showEarlyCorrectAnswerBonus,
  showEarlyWrongAnswerPenalty,
  showResetTimer,
  showWrongAnswerPenalty,
  startingPoints,
  tossUpEnabled,
  tossUpPoints,
  wrongAnswerPenaltyPoints,
  teamQuestionEnabled,
  teamDeliberationMinutes,
  teamDeliberationSeconds,
  templateName,
  bonusQuestionEnabled,
  bonusPointsPerPart,
  bonusAnswerTimerEnabled,
  bonusAnswerSeconds,
  bonusDeliberationTimerEnabled,
  bonusDeliberationSeconds,
  bonusComputationTimerEnabled,
  bonusComputationSeconds,
  timeoutsEnabled,
  timeouts,
  tossUpAnswerSeconds,
  tossUpAnswerEnabled,
}) => {
  const errors = {};

  const templateNameError = validateTemplateName(templateName);
  if (templateNameError) errors.templateName = templateNameError;

  const startingPointsError = validatePoints(startingPoints);
  if (startingPointsError) errors.startingPoints = startingPointsError;

  if (roundTimerEnabled) {
    const roundMinutesError = validateMinutes(roundMinutes);
    const roundSecondsError = validateSeconds(roundSeconds);
    if (roundMinutesError) errors.roundMinutes = roundMinutesError;
    if (roundSecondsError) errors.roundSeconds = roundSecondsError;
  }

  if (
    !tossUpEnabled &&
    !teamQuestionEnabled &&
    !checkTruthy(bonusQuestionEnabled)
  ) {
    errors.tossUpEnabled = 'Please enable at least one Question Type';
    errors.teamQuestionEnabled = 'Please enable at least one Question Type';
    errors.bonusQuestionEnabled = 'Please enable at least one Question Type';
  }

  if (teamQuestionEnabled) {
    const deliberationMinutesError = validateMinutes(teamDeliberationMinutes);
    if (deliberationMinutesError)
      errors.teamDeliberationMinutes = deliberationMinutesError;

    const deliberationSecondsError = validateSeconds(teamDeliberationSeconds);
    if (deliberationSecondsError)
      errors.teamDeliberationSeconds = deliberationSecondsError;
  }

  if (tossUpEnabled) {
    const tossUpPointsError = validatePoints(tossUpPoints);
    if (tossUpPointsError) errors.tossUpPoints = tossUpPointsError;

    const earlyCorrectAnswerPointsError = validatePoints(
      earlyCorrectAnswerPoints,
      showEarlyCorrectAnswerBonus === 'yes',
    );
    if (earlyCorrectAnswerPointsError)
      errors.earlyCorrectAnswerPoints = earlyCorrectAnswerPointsError;

    // TODO: Update validation for wrong answer penalty given the new dropdown values
    const wrongAnswerPenaltyPointsError = validatePoints(
      wrongAnswerPenaltyPoints,
      showWrongAnswerPenalty === 'custom',
    );
    if (wrongAnswerPenaltyPointsError)
      errors.wrongAnswerPenaltyPoints = wrongAnswerPenaltyPointsError;

    const earlyWrongAnswerPointsError = validatePoints(
      earlyWrongAnswerPoints,
      showEarlyWrongAnswerPenalty === 'yes',
    );
    if (earlyWrongAnswerPointsError)
      errors.earlyWrongAnswerPoints = earlyWrongAnswerPointsError;

    const ringInTimerError = validateSecondsUnlimited(ringInTimer);
    if (ringInTimerError) errors.ringInTimer = ringInTimerError;

    const computationTimeError = validateSecondsUnlimited(
      computationTime,
      showComputationTime === 'yes',
    );
    if (computationTimeError) errors.computationTime = computationTimeError;

    const resetTimerError = validateSeconds(
      resetTimerDuration,
      showResetTimer === 'yes' && resetTimerOption === 'custom',
    );

    if (resetTimerError) errors.resetTimerDuration = resetTimerError;

    const tossUpAnswerTimerError = validateSeconds(
      tossUpAnswerSeconds,
      tossUpAnswerEnabled === 'yes',
    );

    if (tossUpAnswerTimerError)
      errors.tossUpAnswerSeconds = tossUpAnswerTimerError;
    
    const preserveMinTimeSecondsError = validateSeconds(
      preserveMinTimeSeconds,
      preserveMinTimeEnabled === 'yes',
    )

    if (preserveMinTimeSecondsError) errors.preserveMinTimeSeconds = preserveMinTimeSecondsError;
  }

  if (checkTruthy(bonusQuestionEnabled)) {
    const bonusPointsPerPartError = validatePoints(bonusPointsPerPart, true);
    if (bonusPointsPerPartError)
      errors.bonusPointsPerPartError = bonusPointsPerPartError;

    const bonusAnswerSecondsError = validateSecondsUnlimited(
      bonusAnswerSeconds,
      bonusAnswerTimerEnabled,
    );
    if (bonusAnswerSecondsError)
      errors.bonusAnswerSecondsError = bonusAnswerSecondsError;

    const bonusDeliberationSecondsError = validateSecondsUnlimited(
      bonusDeliberationSeconds,
      bonusDeliberationTimerEnabled,
    );
    if (bonusDeliberationSecondsError)
      errors.bonusDeliberationSecondsError = bonusDeliberationSecondsError;

    const bonusComputationSecondsError = validateSeconds(
      bonusComputationSeconds,
      bonusComputationTimerEnabled,
    );
    if (bonusComputationSecondsError)
      errors.bonusComputationSecondsError = bonusComputationSecondsError;
  }

  const timeoutsError = validateTimeouts(timeoutsEnabled, timeouts);
  if (timeoutsError) errors.timeouts = timeoutsError;

  return errors;
};

const validateTimeouts = (timeoutsEnabled, timeouts) => {
  if (!checkTruthy(timeoutsEnabled)) return null;
  let timeoutsError = null;
  (timeouts || []).forEach((timeout) => {
    const { duration, name } = timeout || {};
    if (duration === '' || duration === null) {
      timeoutsError = 'Please enter a numeric seconds value';
    }
    if (duration <= 0) {
      timeoutsError = 'Please enter a positive seconds value';
    }
    if (isEmpty(name)) {
      timeoutsError = 'Please enter a name for every timeout';
    }
  });
  return timeoutsError;
};

const validateTemplateName = (templateName) => {
  if (templateName === '' || templateName === null) {
    return 'Please enter a match setting name';
  }
};

const validatePoints = (points, isEnabled = true) => {
  if (!isEnabled) return;
  if (points === '' || points === null) {
    return 'Please enter a valid point value';
  }
  if (isNaN(points)) {
    return 'Please enter a number point value';
  }
};

const validateMinutes = (minutes) => {
  if (minutes === '' || minutes === null) {
    return 'Please enter a numeric minute value';
  }
  if (minutes < 0) {
    return 'Please enter a positive minute value';
  }
};

const validateSeconds = (seconds, isEnabled = true) => {
  if (!isEnabled) return;
  if (seconds === '' || seconds === null) {
    return 'Please enter a numeric second value';
  }
  if (seconds < 0) {
    return 'Please enter a positive second value';
  }
  if (seconds > 59) {
    return 'Please enter a second value less than 60';
  }
};

const validateSecondsUnlimited = (seconds, isEnabled = true) => {
  if (!isEnabled) return;
  if (seconds === '' || seconds === null) {
    return 'Please enter a numeric second value';
  }
  if (seconds < 0) {
    return 'Please enter a positive second value';
  }
};
