import React, { createContext } from 'react';
import { getCustomer } from '../Helpers/Api/Customer';
import { fetchCodes, isValidCustomer } from '../Helpers/CustomerHelper';

export const CustomerContext = createContext(undefined);
export const { Provider, Consumer: CustomerConsumer } = CustomerContext;

const RESET_STATE = 'RESET_STATE';
const SET_CUSTOMER = 'SET_CUSTOMER';
const SET_IS_LOADING = 'SET_IS_LOADING';

const initialState = { bypassCodes: [], customer: null, isLoading: true };

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case RESET_STATE: {
      return initialState;
    }
    case SET_CUSTOMER: {
      const { bypassCodes, customer, isLoading } = payload;
      return {
        ...state,
        ...(bypassCodes !== undefined && { bypassCodes }),
        customer,
        isLoading,
      };
    }
    case SET_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading,
      };
    }
    default: {
      console.log(`CustomerProvider reducer type ${type} is not supported`);
      return state;
    }
  }
};

export const CustomerProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { bypassCodes, customer, isLoading } = state;

  const clearCustomer = () => dispatch({ type: RESET_STATE });

  const fetchCustomer = async () => {
    dispatch({ type: SET_IS_LOADING, payload: { isLoading: true } });
    const response = await getCustomer();
    const newCodes = await fetchCodes();
    await dispatch({
      type: SET_CUSTOMER,
      payload: { bypassCodes: newCodes, customer: response, isLoading: false },
    });
    return response;
  };

  const hasValidSubscription = (customerProp) => {
    if (customerProp) {
      return isValidCustomer({ bypassCodes, customer: customerProp });
    }
    return isValidCustomer({ bypassCodes, customer });
  };

  React.useEffect(() => {
    fetchCustomer();
  }, []);

  const value = {
    clearCustomer,
    customer,
    fetchCustomer,
    hasValidSubscription,
    isLoading,
  };
  return <Provider value={value}>{children && children}</Provider>;
};
