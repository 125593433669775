import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import XLSX from 'xlsx';
import LoaderCentered from '../../Components/LoaderCentered';
import {
  RESET_STATE,
  ScoreSheetContext,
  SET_ERRORS,
} from '../../Contexts/ScoreSheetContext';
import { fetchMatch } from '../../Helpers/Api/Match';
import { WindowClose } from '../../Helpers/IconHelper';
import {
  getCellEditsObj,
  submitEditScores,
} from '../../Helpers/ScoreSheetHelper';
import { validateCellData } from '../../Helpers/ScoreSheetValidator';
import { get } from '../../Helpers/UtilHelper';
import ScoreSheetBonusAndTeamSection from './Components/ScoreSheetBonusAndTeamSection';
import ScoreSheetPlayerSection from './Components/ScoreSheetPlayerSection';
import ScoreSheetTotalPointsSection from './Components/ScoreSheetTotalPointsSection';

const ScoreSheetModal = ({
  className,
  match,
  text = '',
  scoreListProp,
  toggleModal = () => {},
  matchID,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isFetchingData, setIsFetchingData] = React.useState(!scoreListProp);
  const [scoreList, setScoreList] = React.useState(scoreListProp);
  const [isExportingScoreSheet, setIsExportingScoreSheet] = React.useState(
    false,
  );
  const {
    teams: { items: teamList = [] } = {},
    settings: { items: settings } = {},
  } = match;
  const orderedTeamList = teamList.sort((a, b) => {
    return a.order - b.order;
  });
  const startingPoints = get(settings, '0.startingPoints', 0);
  const emptyPointColDisplay = [];
  const {
    uniquePointValues,
    totalNumberQuestions,
    initCellList,
    cellList,
    originalCellList,
    dispatch,
    errors,
  } = React.useContext(ScoreSheetContext);
  for (let i = 0; i < totalNumberQuestions; i++) {
    emptyPointColDisplay.push(<td key={i}></td>);
  }

  const fetchScores = async () => {
    const matchResult = await fetchMatch({ matchID });
    const { scores: { items: scoreItems } = {} } = matchResult || {};
    setScoreList(scoreItems);
    setIsFetchingData(false);
    return scoreItems;
  };

  React.useEffect(() => {
    setScoreList(scoreListProp);
  }, [scoreListProp]);

  React.useEffect(() => {
    const init = async () => {
      if (!scoreList) {
        const scores = await fetchScores();
        initCellList({ scoreList: scores });
      } else {
        initCellList({ scoreList });
      }
    };
    if (!isSubmitting) init();
  }, [isSubmitting, scoreList]);

  React.useEffect(() => {
    if (isExportingScoreSheet) {
      exportScoreSheet();
    }
  }, [isExportingScoreSheet]);

  const handleScoreSheetSubmit = async () => {
    const errors = validateCellData({
      cellList,
      totalNumberQuestions,
      teamList,
    });
    if (Object.keys(errors).length > 0) {
      dispatch({
        type: SET_ERRORS,
        payload: {
          newErrors: errors,
        },
      });
      return;
    } else {
      const scoreEdits = getCellEditsObj({
        cellList,
        originalCellList,
        totalNumberQuestions,
        teamList,
        matchID,
      });
      if (Object.keys(scoreEdits).length === 0) {
        handleCloseModal();
        return;
      }
      setIsSubmitting(true);
      await submitEditScores({ scoreEdits, matchID });
      setIsSubmitting(false);
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    dispatch({
      type: RESET_STATE,
    });
    setScoreList(null);
    toggleModal();
  };

  const exportScoreSheet = () => {
    const table = document.getElementsByClassName('score-table');
    var wb = XLSX.utils.table_to_book(table[0], { sheet: 'Scores' });
    XLSX.writeFile(wb, `Scores_Download_${matchID}.xlsx`, { cellStyles: true });
    setIsExportingScoreSheet(false);
  };

  return ReactDOM.createPortal(
    <div className={`modal-container ${className}`}>
      <div className="modal-overlay" onClick={handleCloseModal}></div>
      <div className="modal">
        <div className="modal-header">
          <p className="modal-text">{text}</p>
          <button className="modal-close-button" onClick={handleCloseModal}>
            <WindowClose />
          </button>
        </div>
        {isFetchingData ? (
          <div className="loader-container-scoresheet">
            <LoaderCentered />
          </div>
        ) : (
          <div className="table-container">
            <table
              border="1"
              cellSpacing="0"
              cellPadding="0"
              className="score-table"
            >
              <tbody>
                {orderedTeamList.map((team, index) => {
                  return (
                    <React.Fragment key={index}>
                      <ScoreSheetPlayerSection
                        teamData={team}
                        teamNumber={index + 1}
                        matchID={matchID}
                        isExportingScoreSheet={isExportingScoreSheet}
                      />
                      <ScoreSheetBonusAndTeamSection
                        teamData={team}
                        matchID={matchID}
                        isExportingScoreSheet={isExportingScoreSheet}
                      />
                      <ScoreSheetTotalPointsSection
                        teamData={team}
                        scoreList={scoreList}
                        startingPoints={startingPoints}
                        matchID={matchID}
                      />
                      {index + 1 !== teamList.length && (
                        // spacer row
                        <tr>
                          <td></td>
                          <td></td>
                          {emptyPointColDisplay}
                          <td></td>
                          {uniquePointValues.map((value, index) => {
                            return <td key={index}></td>;
                          })}
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {Object.keys(errors).length > 0 && (
          <p className="error-text">
            Please resolve the errors above. Toss-Ups may only have one score
            per team.
          </p>
        )}
        <div className="submit-container">
          <button
            disabled={Object.keys(errors).length > 0 || isFetchingData}
            onClick={handleScoreSheetSubmit}
          >
            {isSubmitting ? 'Loading...' : 'Save'}
          </button>
          <button onClick={() => setIsExportingScoreSheet(true)}>
            Download Scores
          </button>
          <button onClick={handleCloseModal}>Cancel</button>
        </div>
      </div>
    </div>,
    document.body,
  );
};

const StyledScoreSheetModal = styled(ScoreSheetModal)`
  overflow-y: hidden;

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    background: white;
    margin: 0 auto;
    border-radius: 3px;
    max-width: 90vw;
    max-height: 90vh;
    padding: 2rem;
    transition: all 0.5s;
    overflow-y: scroll;
  }

  .error-text {
    text-align: center;
    font-size: 1.2rem;
    color: red;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
  }

  .modal-close-button {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    background-color: white;
    color: steelblue;
    cursor: pointer;
    border: none;
    padding: 0;
    border-radius: 15%;
    border: 0px;
    margin: 0;
    height: 2rem;

    &:hover {
      background-color: steelblue;
      color: white;
    }
  }

  .modal-button-container {
    display: flex;
    justify-content: center;
    margin: 1rem;
  }

  .loader-container-scoresheet {
    width: 50vw;
    height: 50vh;
  }

  .table-container {
    overflow: auto;
  }

  .score-table {
    font-size: 11px;
    border-color: #e1e1e1;
    border-collapse: collapse;
    empty-cells: show;

    & > tbody > tr > td {
      text-align: center;
      font-size: 11px;
      padding: 0px 12px;
      border-color: #e1e1e1;
      border-style: solid;
      border-collapse: collapse;
      height: 1.7rem;
      white-space: nowrap;
    }
  }

  .submit-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (min-width: 500px) {
      flex-direction: row;
    }

    & > button {
      margin: 0 1rem;
    }
  }
`;

export default StyledScoreSheetModal;
