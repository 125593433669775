import Iframe from '@trendmicro/react-iframe';
import { HiddenIcon, ViewIcon } from 'Helpers/IconHelper';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { LinkButton } from '../../Components/Button';
import ConnectionTest from '../../Components/ConnectionTest';
import { AuthContext } from '../../Hooks/AuthContext';
import { MatchClientContext } from '../../Hooks/MatchClientContext';
import { SocketContext } from '../../Hooks/SocketContext';
import IFrameModal from '../IFrameModal';
import { InfoModal } from '../Modal';

const HOST_SETTINGS = 'hostSettings';
const MY_ACCOUNT = 'myAccount';
const LOGOUT = 'logout';

const HeaderAuthed = ({
  className,
  isHost = false,
  isClient = false,
  onLogout = () => {},
  playerName = null,
  roomID,
}) => {
  const [showFeaturesModal, setShowFeaturesModal] = React.useState(false);
  const [showContactModal, setShowContactModal] = React.useState(false);
  const [hiddenMatchID, setHiddenMatchID] = React.useState(false);
  const [showTestConnectionModal, setShowTestConnectionModal] =
    React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const { playerLogout } = React.useContext(MatchClientContext);
  const { signOut, userData } = React.useContext(AuthContext);
  const { leaveRoom } = React.useContext(SocketContext);
  const { email } = userData || {};
  const currentRoute = React.useMemo(() => {
    switch (location.pathname) {
      case '/host':
        return HOST_SETTINGS;
      case '/host/account':
        return MY_ACCOUNT;
      default:
        return '';
    }
  }, [location]);

  const leaveSocketRoom = () => {
    // Leave the socket room if navigating from a Match
    if (roomID) leaveRoom({ matchID: roomID, isHost: true, skipMessage: true });
  };

  const handleNavToAccount = () => {
    leaveSocketRoom();
    history.push('/host/account');
  };

  const handleNavToDashboard = () => {
    leaveSocketRoom();
    history.push('/host');
  };

  const handleHostLogout = async () => {
    const loggedInAsHost = !!email;
    if (loggedInAsHost) {
      // handle host signout
      await signOut();
    }
    // go to home screen
    history.push('/host/sign-in');
  };

  const handleClientLogout = async () => {
    const loggedInAsHost = !!email;
    // logout player
    onLogout();
    playerLogout();
    if (loggedInAsHost) {
      // host was also signed in as player, so go to host dashboard
      history.push('/host');
      return;
    }
    // go to home screen
    history.push('/');
  };

  const logoImage = (
    <img
      alt="QuizBowl PRO Logo"
      className="logo-image"
      src="/quizbowl-pro-cowboys-logo.png"
    />
  );
  let logoDisplay = logoImage;
  if (isHost) {
    logoDisplay = (
      <Link to="/host" onClick={() => leaveSocketRoom()}>
        {logoImage}
      </Link>
    );
  }

  const handleNavDropdownChange = (event) => {
    const selected = event.target.value;
    switch (selected) {
      case HOST_SETTINGS:
        handleNavToDashboard();
        break;
      case MY_ACCOUNT:
        handleNavToAccount();
        break;
      case LOGOUT:
        handleHostLogout();
        break;
      default:
        break;
    }
  };

  let navActions = null;
  if (isClient) {
    navActions = (
      <button className="logout-button" onClick={handleClientLogout}>
        <span className="user-type">Player</span> Logout
      </button>
    );
  } else if (isHost) {
    navActions = (
      <select
        className="nav-dropdown"
        value={currentRoute}
        onChange={handleNavDropdownChange}
      >
        {location.pathname === `/host/${roomID}` && (
          <option value="">Match</option>
        )}
        <option value={HOST_SETTINGS}>Host Settings</option>
        <option value={MY_ACCOUNT}>My Account</option>
        <option value={LOGOUT}>Logout</option>
      </select>
    );
  }

  const toggleFeaturesModal = () => {
    setShowFeaturesModal((prev) => !prev);
  };

  const toggleContactModal = () => {
    setShowContactModal((prev) => !prev);
  };

  const toggleTestConnectionModal = () => {
    setShowTestConnectionModal((prev) => !prev);
  };

  let notificationIframe = null;
  if (isHost) {
    notificationIframe = (
      <div className="iframe-container">
        <Iframe
          src="https://www.quizbowlsystems.com/hostnews_header.html"
          width="100%"
          height="110px"
          scrolling="no"
        />
        <button className="iframe-read-more" onClick={toggleFeaturesModal}>
          Read More
        </button>
      </div>
    );
  }

  const handleHideMatchID = () => setHiddenMatchID(!hiddenMatchID);

  return (
    <div className={`header-authed ${className}`}>
      <div className="nav-left">
        <div className="header-left">
          {isHost && email && <div className="user-name">Host: {email}</div>}
          {isClient && playerName && (
            <div className="user-name">Player: {playerName}</div>
          )}
        </div>
        <div className="header-center">
          {roomID && (
            <div className="matchid-text">
              Match ID: {hiddenMatchID ? '******' : roomID}
            </div>
          )}
          {roomID && isHost && (
            <div>
              <span className="visibility-icon" onClick={handleHideMatchID}>
                {hiddenMatchID ? <HiddenIcon /> : <ViewIcon />}
              </span>
            </div>
          )}
        </div>
      </div>
      <a
        href="https://sites.google.com/view/quizbowlsystems/support/how-to-videos/online-app-how-to-videos"
        target="_blank"
      >
        How-To Videos
      </a>
      {/* {notificationIframe} */}
      <div className="header-right">
        <div className="logo-container">
          {logoDisplay}
          <LinkButton onClick={toggleTestConnectionModal}>
            Test Connection
          </LinkButton>
          <button className="contact-button" onClick={toggleContactModal}>
            Contact Us
          </button>
        </div>
        <div className="nav-container">{navActions}</div>
      </div>
      {showFeaturesModal && (
        <IFrameModal
          toggleModal={toggleFeaturesModal}
          link={'https://www.quizbowlsystems.com/hostnews.html'}
        />
      )}
      {showContactModal && (
        <IFrameModal
          toggleModal={toggleContactModal}
          link={'https://www.quizbowlsystems.com/contact.html'}
        />
      )}
      {showTestConnectionModal && (
        <InfoModal toggleModal={toggleTestConnectionModal}>
          <ConnectionTest />
        </InfoModal>
      )}
    </div>
  );
};

const StyledHeaderAuthed = styled(HeaderAuthed)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  border-bottom: 1px solid #333;
  font-size: 1.25rem;

  .contact-button {
    display: none;
  }

  .logo-container {
    display: flex;
    flex-direction: column;
  }

  .nav-left {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    text-align: left;
  }

  .header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;

    .logout-button {
      margin-bottom: 0;
      margin-left: 1rem;
      padding: 0 1rem;

      &.dashboard-link {
        display: none;
      }

      .user-type {
        display: none;
      }
    }
  }

  .nav-dropdown {
    border-radius: 0;
    border-color: #333;
  }

  .user-name {
    width: 15rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 3rem;
  }

  .home-link {
    margin: 0 0.5rem;
  }

  .logo-image {
    height: 3rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .iframe-container {
    display: none;
  }

  .visibility-icon {
    font-size: 2rem;
  }

  .matchid-text {
    width: 18rem;
  }

  @media (min-width: 500px) {
    .logo-image {
      height: 5rem;
    }

    .user-name {
      width: auto;
    }
  }

  @media (min-width: 700px) {
    .logo-image {
      height: 5rem;
    }

    .user-name {
      width: auto;
    }

    .contact-button {
      display: none;
    }

    .iframe-container {
      display: none;
    }

    .iframe-read-more {
      margin: 0;
      font-size: 1.25rem;
      padding: 0.45rem 1.5rem;
      line-height: 1.5rem;
      height: auto;
      position: relative;
      top: -5px;
    }
  }

  @media (min-width: 900px) {
    font-size: 2rem;
    .header-left,
    .header-center,
    .header-right {
      display: flex;
      justify-content: left;
      align-items: center;
      & > * {
        margin: 1rem;
      }
    }

    .contact-button {
      display: block;
      margin: 5px auto;
      color: #1eaedb;
      height: auto;
      padding: 0;
      font-size: 1.6rem;
      font-weight: 400;
      letter-spacing: 0rem;
      background-color: transparent;
      line-height: 1.5rem;
      border: none;
      text-transform: none;
      text-decoration: underline;

      &:hover {
        color: #0fa0ce;
      }
    }

    .nav-left {
      flex-direction: column;
      flex: 1;
    }

    .header-left {
      justify-content: flex-start;
    }

    .iframe-container {
      max-width: 450px;
      display: block;
      flex: 1;
      text-align: center;
    }

    .iframe-container iframe {
      position: relative;
      top: 5px;
    }

    .header-right {
      flex: 1;
      justify-content: flex-end;

      .nav-container {
        display: flex;
        flex-direction: column;
      }

      .logout-button {
        padding: 0 3rem;
        margin: 0.25rem 0 1rem;

        &.dashboard-link {
          display: block;
        }

        .user-type {
          display: inline;
        }
      }
    }

    .logo-image {
      height: 8rem;
    }

    .visibility-icon {
      font-size: 3rem;
    }
  }
`;

export default StyledHeaderAuthed;
