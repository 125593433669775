import React from 'react';
import styled from 'styled-components';

const SendCodeForm = ({
  className,
  fields,
  handleFieldChange,
  onSubmit,
  validateFields,
}) => {
  const submitDisabled = !validateFields();

  const handleKeyDown = (e) => {
    const keycode = e.keyCode;
    if (keycode === 13 && !submitDisabled) onSubmit();
  };

  return (
    <div className={`send-code-form ${className}`}>
      <div className="description">
        Forgot your password? Please enter the email address associated with
        your account:
      </div>
      <div className="form-container">
        <label className="input-container">
          <input
            className="input"
            id="email"
            type="text"
            value={fields.email}
            onChange={handleFieldChange}
            onKeyDown={handleKeyDown}
            placeholder="e-mail address"
          />
        </label>
      </div>
      <button
        className="reset-password-submit"
        disabled={submitDisabled}
        onClick={onSubmit}
      >
        Send Code
      </button>
    </div>
  );
};

const StyledSendCodeForm = styled(SendCodeForm)`
  font-size: 2rem;
  line-height: 1.75rem;
  text-align: center;
`;

export default StyledSendCodeForm;
