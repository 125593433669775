import React from 'react';
import styled from 'styled-components';
import SettingsHeader from '../../../../Components/SettingsHeader';
import { MatchSettingsContext } from '../../../../Hooks/MatchSettingsContext';
import PointSettings from './Components/PointSettings';
import RinginSettings from './Components/RingInSettings';
import TimerSettings from './Components/TimerSettings';

const TossUpQuestions = ({ className, disabledEdits = false }) => {
  const { errors, setMatchSetting, tossUpEnabled } = React.useContext(
    MatchSettingsContext,
  );

  const handleTossUpEnabledChange = (e) => {
    setMatchSetting({
      payload: { tossUpEnabled: e.target.checked },
      errorsToClear: ['tossUpEnabled'],
    });
  };

  const settingsOptions = tossUpEnabled && (
    <>
      <PointSettings disabledEdits={disabledEdits} />
      <RinginSettings disabledEdits={disabledEdits} />
      <TimerSettings disabledEdits={disabledEdits} />
    </>
  );

  return (
    <div className={`toss-up-question-container ${className}`}>
      <SettingsHeader text="Toss-Up Questions" />
      <label className="checkbox-container">
        <input
          disabled={disabledEdits}
          type="checkbox"
          checked={tossUpEnabled}
          onChange={handleTossUpEnabledChange}
        />
        <span className="select-label">Activate Toss-Up Questions</span>
      </label>
      {settingsOptions}
      {errors.tossUpEnabled && (
        <div className="error-container">
          <p>{errors.tossUpEnabled}</p>
        </div>
      )}
    </div>
  );
};

const StyledTossUpQuestions = styled(TossUpQuestions)`
  .setting-container {
    display: flex;
  }

  .select-label {
    margin: 1rem;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
  }

  @media (min-width: 900px) {
    .select-label {
      display: inline;
    }
  }
`;

export default StyledTossUpQuestions;
