import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import BonusQuestions from '../../../../../../Components/MatchSettings/Components/BonusQuestions';
import MatchTemplateOptions from '../../../../../../Components/MatchSettings/Components/MatchTemplateOptions';
import RoundTimer from '../../../../../../Components/MatchSettings/Components/RoundTimer';
import Sounds from '../../../../../../Components/MatchSettings/Components/Sounds';
import StartingPoints from '../../../../../../Components/MatchSettings/Components/StartingPoints';
import TeamQuestions from '../../../../../../Components/MatchSettings/Components/TeamQuestions';
import Timeouts from '../../../../../../Components/MatchSettings/Components/Timeouts';
import TossUpQuestions from '../../../../../../Components/MatchSettings/Components/TossUpQuestions';
import Modal from '../../../../../../Components/Modal';
import { WindowClose } from '../../../../../../Helpers/IconHelper';
import { MatchSettingsContext } from '../../../../../../Hooks/MatchSettingsContext';

const CreateMatchTemplateModal = ({
  className,
  toggleCreateMatchTemplateModal,
  fetchMatchTemplates,
}) => {
  const [
    showConfirmNewTemplateModal,
    setShowConfirmNewTemplateModal,
  ] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [createTemplateError, setCreateTemplateError] = React.useState(false);
  const {
    handleResetState,
    validateAndCreateMatchTemplate,
    errors,
  } = React.useContext(MatchSettingsContext);

  const toggleConfirmNewTemplateModal = () => {
    setShowConfirmNewTemplateModal((prev) => !prev);
  };

  const hasValidationErrors = React.useMemo(() => {
    return Object.keys(errors).length !== 0;
  }, [errors]);

  const onCreateMatchTemplate = async () => {
    setIsSubmitting(true);
    setCreateTemplateError(false);
    const { matchTemplate, hasErrors } =
      (await validateAndCreateMatchTemplate()) || {};
    if (hasErrors) {
      // handle validation error
      setIsSubmitting(false);
      setShowConfirmNewTemplateModal(false);
      return;
    }
    if (!matchTemplate) {
      // handle API error
      setIsSubmitting(false);
      setShowConfirmNewTemplateModal(false);
      setCreateTemplateError(true);
      return;
    }
    // handle success - show dashboard view and refresh matches
    fetchMatchTemplates();
    setShowConfirmNewTemplateModal((prev) => !prev);
    toggleCreateMatchTemplateModal();
    handleResetState();
  };

  let errorDisplay = null;
  if (hasValidationErrors) {
    errorDisplay = (
      <div className="match-error-container">
        <p className="match-error-text">Please correct the errors above.</p>
      </div>
    );
  } else if (createTemplateError) {
    errorDisplay = (
      <div className="match-error-container">
        <p className="match-error-text">Something went wrong.</p>
      </div>
    );
  }

  return ReactDOM.createPortal(
    <div className={`modal-container ${className}`}>
      <div
        className="modal-overlay"
        onClick={toggleCreateMatchTemplateModal}
      ></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-text">Create Custom Match Settings</h2>
          <button
            className="modal-close-button"
            onClick={toggleCreateMatchTemplateModal}
          >
            <WindowClose />
          </button>
        </div>
        <MatchTemplateOptions headerText="Match Setting Name" showNameInput />
        <RoundTimer />
        <StartingPoints />
        <TossUpQuestions />
        <TeamQuestions />
        <BonusQuestions />
        <Timeouts />
        <Sounds />
        <div className="modal-button-container">
          {errorDisplay}
          <button
            className="button-create"
            disabled={isSubmitting}
            onClick={toggleConfirmNewTemplateModal}
          >
            {isSubmitting ? 'Loading...' : 'Create Match Setting'}
          </button>
          {showConfirmNewTemplateModal && (
            <Modal
              confirmAction={onCreateMatchTemplate}
              confirmLoading={isSubmitting}
              toggleModal={toggleConfirmNewTemplateModal}
              text="Are you sure you want to create this match setting?"
            />
          )}
        </div>
      </div>
    </div>,
    document.body,
  );
};

const StyledCreateMatchTemplateModal = styled(CreateMatchTemplateModal)`
  overflow-y: hidden;

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80vh;
    width: 80vw;
    max-width: 80rem;
    margin: 0 auto;
    z-index: 3;
    background: white;
    border-radius: 3px;
    padding: 2rem;
    overflow: scroll;
  }

  .modal-text {
    font-weight: bold;
    font-size: 3rem;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
  }

  .modal-close-button {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    color: steelblue;
    background-color: white;
    cursor: pointer;
    border: none;
    padding: 0;
    border-radius: 15%;
    border: 0px;
    margin: 0;
    height: 2rem;

    &:hover {
      background-color: steelblue;
      color: white;
    }
  }

  .modal-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .match-error-container {
    margin-top: 3rem;
    border: 2px solid red;
    border-radius: 3px;
    background-color: #ffdede;
  }

  .match-error-text {
    color: red;
    margin: 1rem;
  }

  .button-create {
    margin: 3rem;
    font-size: 2rem;
  }

  @media (min-width: 900px) {
  }
`;

export default StyledCreateMatchTemplateModal;
