import React from 'react';
import styled from 'styled-components';
import { checkTruthy } from '../../../../../../Helpers/UtilHelper';
import { MatchSettingsContext } from '../../../../../../Hooks/MatchSettingsContext';
import NumberInput from '../../../../../NumberInput';

const RingInSettings = ({ className, disabledEdits = false }) => {
  const {
    computationTime,
    errors,
    ringInTimer,
    setMatchSetting,
    showComputationTime,
  } = React.useContext(MatchSettingsContext);

  const handleRingInTimerChange = (e) => {
    setMatchSetting({
      payload: {
        ringInTimer: e.target.value,
      },
      errorsToClear: ['ringInTimer'],
    });
  };

  const handleShowComputationTimeChange = (e) => {
    setMatchSetting({
      payload: {
        showComputationTime: e.target.value,
      },
      errorsToClear: ['computationTime'],
    });
  };

  const handleComputationTimeChange = (e) => {
    setMatchSetting({
      payload: {
        computationTime: e.target.value,
      },
      errorsToClear: ['computationTime'],
    });
  };

  return (
    <div className={`ringin-settings-container ${className}`}>
      <div className="time-error-container">
        <div className="setting-container">
          <label>
            <span className="select-label">Ring-In Timer</span>
            <div className="label-container">
              <input
                disabled={disabledEdits}
                type="number"
                value={ringInTimer}
                onChange={handleRingInTimerChange}
                className="timer-input"
              />
              <span className="select-label">Seconds</span>
            </div>
          </label>
        </div>
        {errors.ringInTimer && (
          <div className="error-container">
            <p>{errors.ringInTimer}</p>
          </div>
        )}
      </div>
      <div className="time-error-container">
        <div className="setting-container">
          <label className="label-container">
            <span className="select-label">Computation Time:</span>
            <select
              disabled={disabledEdits}
              value={showComputationTime}
              onChange={handleShowComputationTimeChange}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </label>
          <NumberInput
            shouldRender={checkTruthy(showComputationTime)}
            value={computationTime}
            onChange={handleComputationTimeChange}
            label="Seconds"
            disabledEdits={disabledEdits}
          />
        </div>
        {errors.computationTime && (
          <div className="error-container">
            <p>{errors.computationTime}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const StyledRingInSettings = styled(RingInSettings)`
  .label-container {
    display: flex;
    align-items: center;
  }

  .timer-input {
    width: 6rem;
  }

  .select-label {
    width: 100%;
    display: block;
    text-align: center;
  }

  .setting-container {
    border: 0.5px solid #f5f5f5;
    margin: 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .time-error-container {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 900px) {
    .label-container {
      display: inline;
    }

    .setting-container {
      border: none;
      margin: 0;
      flex-direction: row;
      justify-content: left;
    }
  }
`;

export default StyledRingInSettings;
