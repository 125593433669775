export const onCreateMessage = `subscription OnCreateMessage {
  onCreateMessage {
    id
    matchID
    messageType
    playerID
    createdAt
    updatedAt
    match {
      id
      active
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
    }
    player {
      id
      name
      teamID
      user
      team {
        id
        name
        updatedAt
        user
      }
      matches {
        nextToken
      }
      messages {
        nextToken
      }
    }
  }
}
`;

// same response body as graphql/custom/queries > getMatch
export const onUpdateMatch = `subscription OnUpdateMatchPublic(
  $matchID: ID
) {
  onUpdateMatchPublic(matchID: $matchID) {
    id
    active
    activePlayerID
    bonusRoundOwner
    matchStatus
    updatedAt
    user
    teams {
      items {
        id
        active
        score
        matchID
        teamID
        team {
          id
          name
          updatedAt
          user
          players(limit: 50) {
            items {
              id
              name
              order
              teamID
              user
            }
            nextToken
          }
        }
        user
      }
      nextToken
    }
    players {
      items {
        id
        active
        order
        score
        matchID
        playerID
        player {
          id
          name
          order
          teamID
          user
        }
        user
      }
      nextToken
    }
    messages {
      items {
        id
        matchID
        messageType
        playerID
        updatedAt
      }
      nextToken
    }
  }
}
`;

// use public subscription && same response body as graphql/custom/queries > getMatch > match.team
export const onUpdateMatchTeam = `subscription OnUpdateMatchTeamPublic(
  $matchID: ID
) {
  onUpdateMatchTeamPublic(matchID: $matchID) {
    id
    active
    score
    matchID
    teamID
    team {
      id
      name
      updatedAt
      user
      players(limit: 50) {
        items {
          id
          name
          order
          teamID
          user
        }
        nextToken
      }
    }
    user
  }
}
`;

// use public subscription && same response body as graphql/custom/queries > getMatch > match.player
export const onUpdateMatchPlayer = `subscription OnUpdateMatchPlayerPublic(
  $matchID: ID
) {
  onUpdateMatchPlayerPublic(matchID: $matchID) {
    id
    active
    score
    matchID
    order
    playerID
    user
    player {
      id
      name
      order
      teamID
      user
      team {
        id
        name
        updatedAt
        user
      }
    }
  }
}
`;

export const onCreateScorePublic = `subscription OnCreateScorePublic($matchID: ID) {
  onCreateScorePublic(matchID: $matchID) {
    id
    type
    questionNumber
    points
    otherPlayers
    playerName
    matchID
    playerID
    teamID
    user
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    team {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
    player {
      id
      active
      name
      order
      teamID
      user
      team {
        id
        active
        name
        updatedAt
        user
      }
      matches {
        nextToken
      }
      messages {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;

export const onUpdateScorePublic = `subscription OnUpdateScorePublic($matchID: ID) {
  onUpdateScorePublic(matchID: $matchID) {
    id
    type
    questionNumber
    points
    otherPlayers
    playerName
    matchID
    playerID
    teamID
    user
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    team {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
    player {
      id
      active
      name
      order
      teamID
      user
      team {
        id
        active
        name
        updatedAt
        user
      }
      matches {
        nextToken
      }
      messages {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;
