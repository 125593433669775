import useRingInKeyPress from 'Hooks/useRingInKeyPress';
import { Howl } from 'howler';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import HeaderAuthed from '../../Components/HeaderAuthed';
import LoaderCentered from '../../Components/LoaderCentered';
import Scoreboard from '../../Components/Scoreboard';
import Timer, {
  TIMER_TYPE_BONUS_ANSWER,
  TIMER_TYPE_BONUS_DELIBERATION,
  TIMER_TYPE_RING_IN,
  TIMER_TYPE_TEAM_DELIBERATION,
} from '../../Components/Timer';
import {
  BONUS_QUESTION,
  TEAM_QUESTION,
  TOSS_UP_QUESTION,
} from '../../Helpers/HostRoomHelper';
import {
  getCanRingIn,
  getQuestionNumberFromScores,
  getQuestionTypeText,
} from '../../Helpers/MatchHelper';
import {
  MATCH_STATE_RING_IN_TEST_CLOSED,
  MATCH_STATE_RING_IN_TEST_OPEN,
  MESSAGE_TYPE_CHECK_HOST,
  MESSAGE_TYPE_CONFIRM_HOST,
  MESSAGE_TYPE_LEAVE_ROOM,
  MESSAGE_TYPE_PLAYER_SWAP,
  MESSAGE_TYPE_RING_IN,
  MESSAGE_TYPE_RING_IN_TEST,
  MESSAGE_TYPE_SET_CURRENT_QUESTION,
  MESSAGE_TYPE_SYNC_HOST_STATE,
  MESSAGE_TYPE_SYNC_PLAYER_STATE,
} from '../../Helpers/MessageHelper';
import { checkTruthy, get } from '../../Helpers/UtilHelper';
import { MatchClientContext } from '../../Hooks/MatchClientContext';
import { SocketContext } from '../../Hooks/SocketContext';
import {
  SET_CURRENT_SECONDS_LEFT,
  TIMER_EXPIRED,
  TimerContext,
} from '../../Hooks/TimerContext';

const ClientRoom = ({ className }) => {
  const history = useHistory();
  const alertSound = new Howl({ src: ['/sounds/AlertSound.wav'] });
  const {
    isFetchingMatch,
    isFetchingPlayerData,
    match,
    playerData,
    playerLogout,
    setIsPlayerDuplicate,
    scoreList,
    handleMatchSettingsUpdate,
  } = React.useContext(MatchClientContext);
  const { id: matchID } = match || {};
  const { deviceID, player: { id: playerID, name: playerName, teamID } = {} } =
    playerData || {};
  let settings = get(match, 'settings.items.0', {});

  let {
    computationTime,
    resetTimerDuration,
    resetTimerOption,
    ringInTimer,
    startingPoints,
    bonusDeliberationTimerEnabled,
    bonusAnswerTimerEnabled,
    bonusDeliberationSeconds,
    bonusAnswerSeconds,
    bonusComputationSeconds,
    playerTimersEnabled,
    soundRingInEnabled,
    preserveMinTimeEnabled,
    preserveMinTimeSeconds,
  } = settings;

  const shouldRenderTimers =
    !playerTimersEnabled || checkTruthy(playerTimersEnabled);

  const {
    activeQuestionNumber,
    activePlayerID,
    bonusTeamControlID,
    bonusDeliberationTimerState,
    bonusAnswerTimerState,
    currentBonusPart,
    dispatch,
    initMessageListener,
    joinRoom,
    matchState,
    questionType,
    removeMessageListener,
    ringInTimerState,
    sendMessage,
    showPointButtons,
    socket,
    teamsThatDidRingIn,
    useComputationTime,
    useBonusComputationTime,
    bonusAnswerTimerComplete,
    bonusDeliberationComplete,
    teamDeliberationTimerState,
    teamDeliberationTimeLeft,
  } = React.useContext(SocketContext);

  const timerContext = React.useContext(TimerContext);

  const {
    dispatchTimerRingIn,
    dispatchTimerBonusDeliberation,
    dispatchTimerBonusAnswer,
    dispatchTimerTeamDeliberation,
  } = timerContext;

  const questionText = React.useMemo(() => {
    return getQuestionTypeText({
      activeQuestionNumber,
      bonusPart: currentBonusPart,
      matchState,
    });
  }, [matchState, currentBonusPart]);

  React.useEffect(() => {
    const missingMatchData = !isFetchingMatch && !match;
    const missingPlayerData = !isFetchingPlayerData && !playerData;
    if (missingMatchData || missingPlayerData) history.push('/');
  }, [dispatch, isFetchingMatch, isFetchingPlayerData, match, playerData]);

  const [hostCheckComplete, setHostCheckComplete] = React.useState(false);
  const [hostConfirmed, setHostConfirmed] = React.useState(false);
  const checkForHost = () => {
    sendMessage({ matchID, type: MESSAGE_TYPE_CHECK_HOST });
    setTimeout(() => {
      setHostCheckComplete(true);
    }, 2000);
  };

  React.useEffect(() => {
    if (hostCheckComplete && !hostConfirmed) {
      playerLogout();
      history.push('/', { hostCheckFailed: true });
    }
  }, [hostCheckComplete, hostConfirmed]);

  const initQuestionNumber = () => {
    const questionNumberFromScores = getQuestionNumberFromScores(scoreList);
    dispatch({
      type: MESSAGE_TYPE_SET_CURRENT_QUESTION,
      payload: { currentQuestion: questionNumberFromScores },
    });
  };

  const messageHandler = (messageData) => {
    console.log('Received message:', messageData);
    const {
      payload: {
        playerID: messagePlayerID,
        isDuplicatePlayer,
        settings: newSettings,
        ringInTimerSecondsLeft,
        bonusAnswerTimerSecondsLeft,
        bonusDeliberationTimerSecondsLeft,
        bonusAnswerTimerExpired,
        bonusDeliberationTimerExpired,
        teamDeliberationTimeLeft,
        teamDeliberationTimerExpired,
      } = {},
      type,
    } = messageData;
    if (
      messagePlayerID === playerID &&
      (type === MESSAGE_TYPE_LEAVE_ROOM || type === MESSAGE_TYPE_PLAYER_SWAP)
    ) {
      const skipMessage = true;
      setIsPlayerDuplicate(isDuplicatePlayer);
      playerLogout(skipMessage);
      history.push('/');
    }
    if (type === MESSAGE_TYPE_SYNC_HOST_STATE) {
      handleMatchSettingsUpdate(newSettings);
    }
    if (type === MESSAGE_TYPE_SYNC_PLAYER_STATE) {
      if (bonusAnswerTimerExpired) {
        dispatchTimerBonusAnswer({
          type: TIMER_EXPIRED,
          payload: {
            duration: bonusAnswerSeconds,
          },
        });
      }
      if (bonusDeliberationTimerExpired) {
        dispatchTimerBonusAnswer({
          type: TIMER_EXPIRED,
          payload: {
            duration: bonusDeliberationSeconds,
          },
        });
      }
      if (teamDeliberationTimerExpired) {
        dispatchTimerTeamDeliberation({
          type: TIMER_EXPIRED,
          payload: {
            duration: teamDeliberationTimeLeft,
          },
        });
      }
      if (ringInTimerSecondsLeft) {
        dispatchTimerRingIn({
          type: SET_CURRENT_SECONDS_LEFT,
          payload: {
            currentSecondsLeft: ringInTimerSecondsLeft,
            timerSecondsLeft: ringInTimerSecondsLeft,
          },
        });
      }
      if (bonusAnswerTimerSecondsLeft) {
        dispatchTimerBonusAnswer({
          type: SET_CURRENT_SECONDS_LEFT,
          payload: {
            currentSecondsLeft: bonusAnswerTimerSecondsLeft,
            timerSecondsLeft: bonusAnswerTimerSecondsLeft,
          },
        });
      }
      if (bonusDeliberationTimerSecondsLeft) {
        dispatchTimerBonusDeliberation({
          type: SET_CURRENT_SECONDS_LEFT,
          payload: {
            currentSecondsLeft: bonusDeliberationTimerSecondsLeft,
            timerSecondsLeft: bonusDeliberationTimerSecondsLeft,
          },
        });
      }
      if (teamDeliberationTimeLeft) {
        dispatchTimerTeamDeliberation({
          type: SET_CURRENT_SECONDS_LEFT,
          payload: {
            currentSecondsLeft: teamDeliberationTimeLeft,
            timerSecondsLeft: teamDeliberationTimeLeft,
          },
        });
      }
    }
    if (type === MESSAGE_TYPE_CONFIRM_HOST) {
      setHostConfirmed(true);
      return;
    }
    dispatch(messageData);
  };

  const [isConnected, setIsConnected] = React.useState(false);
  const [didJoinRoom, setDidJoinRoom] = React.useState(false);

  React.useEffect(() => {
    const join = () => {
      setIsConnected(true);
      joinRoom({ matchID, playerID });
    };
    socket.on('reconnect', join);
    const onDisconnect = () => setIsConnected(false);
    socket.on('disconnect', onDisconnect);
    if (matchID && playerID) {
      // join room IF already connected (connect listener will handle future joins)
      if (socket.connected) {
        join();
        setDidJoinRoom(true);
      }
      socket.removeAllListeners('connect');
      initMessageListener(messageHandler);
      checkForHost();
      // get question number from scores
      initQuestionNumber();
      // window.addEventListener('blur', leave);
      // window.addEventListener('focus', join);
    }
    return () => {
      removeMessageListener(messageHandler);
      socket.removeAllListeners('connect');
      socket.off('reconnect', join);
      socket.off('disconnect', onDisconnect);
    };
  }, [matchID, playerID]);

  const canRingIn = getCanRingIn({
    activePlayerID,
    matchState,
    teamsThatDidRingIn,
    teamID,
  });
  const rangInFirst =
    (showPointButtons || matchState === MATCH_STATE_RING_IN_TEST_CLOSED) &&
    activePlayerID &&
    activePlayerID === playerID;

  React.useEffect(() => {
    if (
      rangInFirst &&
      (soundRingInEnabled === 'player' || soundRingInEnabled === 'both')
    ) {
      alertSound.play();
    }
  }, [rangInFirst, soundRingInEnabled]);

  const onRingIn = React.useCallback(() => {
    if (!canRingIn) return;
    const messageType =
      matchState === MATCH_STATE_RING_IN_TEST_OPEN
        ? MESSAGE_TYPE_RING_IN_TEST
        : MESSAGE_TYPE_RING_IN;
    sendMessage({ matchID, type: messageType, payload: { playerID } });
  }, [canRingIn, matchState, matchID, playerID]);

  useRingInKeyPress(onRingIn, [' ']);

  if (!matchID) return null;

  let buzzerClasses = 'buzzer';
  if (!canRingIn) buzzerClasses += ' buzzer-hidden';

  const rangInFirstText = rangInFirst ? (
    <div className="rang-in-container">You're First!</div>
  ) : null;

  const ringInTimerDuration =
    useComputationTime && computationTime ? computationTime : ringInTimer;
  const ringInTimerResetDurationSec =
    resetTimerOption === 'custom' && resetTimerDuration
      ? resetTimerDuration
      : ringInTimerDuration;
  const bonusAnswerTimerDuration =
    useBonusComputationTime && bonusComputationSeconds
      ? bonusComputationSeconds
      : bonusAnswerSeconds;

  let rightSideTimerDisplay;
  switch (questionType) {
    case TOSS_UP_QUESTION: {
      rightSideTimerDisplay = (
        <div>
          <Timer
            duration={ringInTimerDuration}
            enabled={shouldRenderTimers}
            label="Ring-in Timer"
            matchID={matchID}
            resetDuration={ringInTimerResetDurationSec}
            timerState={ringInTimerState}
            type={TIMER_TYPE_RING_IN}
            matchState={matchState}
            preserveMinTimeEnabled={checkTruthy(preserveMinTimeEnabled)}
            preserveMinTimeSeconds={preserveMinTimeSeconds}
          />
        </div>
      );
      break;
    }
    case TEAM_QUESTION: {
      rightSideTimerDisplay = (
        <div>
          <Timer
            duration={teamDeliberationTimeLeft}
            enabled={shouldRenderTimers}
            label="Deliberation Timer"
            matchID={matchID}
            timerState={teamDeliberationTimerState}
            type={TIMER_TYPE_TEAM_DELIBERATION}
            matchState={matchState}
          />
        </div>
      );
      break;
    }
    case BONUS_QUESTION: {
      rightSideTimerDisplay = (checkTruthy(bonusDeliberationTimerEnabled) ||
        checkTruthy(bonusAnswerTimerEnabled)) && (
        <div className="bonus-question-timer-group">
          <div className="bonus-timer">
            <Timer
              duration={bonusDeliberationSeconds}
              enabled={shouldRenderTimers}
              label="Deliberation Timer"
              matchID={matchID}
              timerState={bonusDeliberationTimerState}
              type={TIMER_TYPE_BONUS_DELIBERATION}
              matchState={matchState}
              timerComplete={bonusDeliberationComplete}
            />
          </div>
          <div className="bonus-timer">
            <Timer
              duration={bonusAnswerTimerDuration}
              enabled={shouldRenderTimers}
              label="Answer Timer"
              matchID={matchID}
              timerState={bonusAnswerTimerState}
              type={TIMER_TYPE_BONUS_ANSWER}
              matchState={matchState}
              timerComplete={bonusAnswerTimerComplete}
            />
          </div>
        </div>
      );
      break;
    }
    default: {
      break;
    }
  }

  return (
    <div className={`client-room ${className}`}>
      <HeaderAuthed isClient playerName={playerName} roomID={matchID} />
      {!hostCheckComplete && !hostConfirmed ? (
        <LoaderCentered />
      ) : (
        <div className="content-container">
          {didJoinRoom && !isConnected && (
            <div className="connection-error">
              <p>Connection lost! Waiting for new connection...</p>
              <p>
                If problem persists, check your status{' '}
                <a href="/connection-test" target="_blank">
                  here
                </a>
              </p>
            </div>
          )}
          <div
            className={`header-content ${shouldRenderTimers && 'header-timer'}`}
          >
            {rightSideTimerDisplay}
            <div className="question-type-text">{questionText}</div>
          </div>
          <Scoreboard
            currentPlayerID={playerID}
            match={match}
            matchState={matchState}
            startingPoints={startingPoints}
            scoreList={scoreList}
            teamsThatDidRingIn={teamsThatDidRingIn}
            teamIdWithBonusControl={bonusTeamControlID}
          />
          {/* {!!currentQuestion && (
        <div className="question-container">{`Question: ${currentQuestion}`}</div>
      )} */}
          <div className="buzzer-container">
            <button
              onTouchStart={onRingIn}
              onTouchEnd={(e) => e.preventDefault()}
              onMouseDown={onRingIn}
              className={buzzerClasses}
            >
              Ring In
            </button>
            {rangInFirstText}
          </div>
        </div>
      )}
    </div>
  );
};

const StyledClientRoom = styled(ClientRoom)`
  width: 100%;
  font-size: 2rem;
  line-height: 1.75rem;
  margin: 0 auto 1rem;
  text-align: center;

  .header-content {
    height: auto;
  }

  .header-timer {
    height: 10rem;
  }

  .bonus-timer {
    margin: 0 0.5rem;
  }

  .bonus-question-timer-group {
    display: flex;
    justify-content: center;
  }

  .content-container {
    position: relative;
    margin-top: 1rem;
  }

  .connection-error {
    color: red;
    line-height: 2.5rem;

    a {
      color: red;
      font-weight: bold;
    }
  }

  .question-container {
    margin-bottom: 1rem;
  }

  .question-type-text {
    text-transform: uppercase;
    height: 1.75rem;
    margin-top: 0.5rem;
  }

  .timer-gap-space {
    width: 12rem;
  }

  .buzzer-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 5rem auto;
  }

  .buzzer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d9dbd9;
    border: 1px solid black;
    padding: 1rem;
    width: 30rem;
    max-width: 75%;
    height: 15rem;
    border-radius: 2px;
    box-shadow: 0.3rem 0.3rem 0.5rem gray;
    font-size: 2rem;

    &:active {
      transform: scale(0.95);
      box-shadow: 0.1rem 0.1rem 0.5rem black;
      transition: 0.2s;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .buzzer-hidden {
    display: none;
  }

  .rang-in-container {
    padding: 3rem;
    border-radius: 5%;
    border: 3px solid black;
    font-weight: bold;
  }

  @media (min-width: 900px) {
    .content-container {
      margin-top: 3rem;
    }

    .buzzer {
      width: 60rem;
    }
  }

  @media screen and (orientation: landscape) {
    .buzzer {
      height: 7.5rem;
    }
  }
`;

export default StyledClientRoom;
