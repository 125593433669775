import {
  createPlayer as createPlayerMutation,
  updatePlayer as updatePlayerMutation,
} from '../../graphql/mutations';
import { getUserId } from '../AuthHelper';
import { mutate } from './index';

export const createPlayer = async ({ name, teamID, order }) => {
  let player = null;
  try {
    const user = await getUserId();
    if (!user) throw new Error('No Current User');

    const response = await mutate(createPlayerMutation, {
      active: 1,
      name,
      teamID,
      user,
      order,
    });
    if (response) player = response.data.createPlayer;
  } catch (err) {
    console.log(`Create Player Error: ${JSON.stringify(err)}`);
  }
  return player;
};

export const updatePlayer = async ({
  name,
  playerID,
  teamID,
  order,
  propsToUpdate = {},
}) => {
  let player = null;
  try {
    const user = await getUserId();
    if (!user) throw new Error('No Current User');

    const response = await mutate(updatePlayerMutation, {
      id: playerID,
      teamID,
      user,
      name,
      order,
      ...propsToUpdate,
    });
    if (response) player = response.data.updatePlayer;
  } catch (err) {
    console.log('Update Player Error:', err);
  }
  return player;
};
