import React, { createContext } from 'react';
import {
  checkUserIsAdmin,
  ERROR_TYPE_SIGNIN_DEFAULT,
  ERROR_TYPE_SIGNIN_EMAIL,
  ERROR_TYPE_SIGNIN_PASSWORD,
  getCurrentUser,
  handleSignIn,
  handleSignOut,
  handleSignUp,
} from '../Helpers/AuthHelper';
import { get } from '../Helpers/UtilHelper';
import { CustomerContext } from './CustomerContext';

export const AuthContext = createContext(undefined);
export const { Provider, Consumer: AuthConsumer } = AuthContext;

export const AuthProvider = ({ children }) => {
  const { clearCustomer, fetchCustomer } = React.useContext(CustomerContext);
  const [userData, setUserData] = React.useState(null);
  const [userGroups, setUserGroups] = React.useState(null);
  const [isAdmin, setIsAdmin] = React.useState(null);

  const fetchUserData = async () => {
    const response = await getCurrentUser();
    if (response && response.attributes) {
      setUserData(response.attributes);
    }
    const userGroups = get(
      response,
      `signInUserSession.idToken.payload['cognito:groups']`,
    );
    setUserGroups(userGroups);
    const isAdmin = checkUserIsAdmin(userGroups);
    setIsAdmin(isAdmin);
  };

  React.useEffect(() => {
    fetchUserData();
  }, []);

  const signUp = async (opts) => {
    try {
      const signUpResponse = await handleSignUp(opts);
      if (!signUpResponse || !signUpResponse.userSub) {
        throw new Error('Invalid signUp response');
      } else {
        const { username, password } = opts;
        return signIn({ username, password });
      }
    } catch (err) {
      let error;
      switch (err.code) {
        case 'UsernameExistsException':
          error = 'An account with that email already exists.';
          break;
        default:
          error = 'Something went wrong. Try again. (Code: AUTH-1)';
          break;
      }
      console.log(`Sign Up Error:`, err);
      return { error };
    }
  };

  const signIn = async (opts) => {
    try {
      const user = await handleSignIn(opts);
      if (user && user.attributes) {
        fetchUserData();
      }
      await fetchCustomer();
      return user;
    } catch (err) {
      let error;
      switch (err.code) {
        case 'UserNotFoundException':
          error = ERROR_TYPE_SIGNIN_EMAIL;
          break;
        case 'NotAuthorizedException':
          error = ERROR_TYPE_SIGNIN_PASSWORD;
          break;
        default:
          error = ERROR_TYPE_SIGNIN_DEFAULT;
          break;
      }
      console.log(`Sign In Error:`, err);
      return { error };
    }
  };

  const signOut = async () => {
    try {
      await handleSignOut();
      setUserData(null);
      setUserGroups(null);
      setIsAdmin(null);
      clearCustomer();
      return true;
    } catch (err) {
      console.log(`Sign Out Error:`, err);
    }
    return false;
  };

  const value = {
    fetchUserData,
    signIn,
    signOut,
    signUp,
    userData,
    userGroups,
    isAdmin,
  };
  return <Provider value={value}>{children && children}</Provider>;
};

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
