import React from 'react';
import styled from 'styled-components';
import SettingsHeader from '../../../../Components/SettingsHeader';
import { DEFAULT_TIMEOUTS } from '../../../../Helpers/MatchHelper';
import { checkTruthy } from '../../../../Helpers/UtilHelper';
import { MatchSettingsContext } from '../../../../Hooks/MatchSettingsContext';

const Timeouts = ({ className, disabledEdits = false }) => {
  const {
    errors,
    setMatchSetting,
    timeouts,
    timeoutsEnabled,
  } = React.useContext(MatchSettingsContext);

  React.useEffect(() => {
    if (!timeouts) {
      setMatchSetting({
        payload: {
          timeoutsEnabled: 'yes',
          timeouts: DEFAULT_TIMEOUTS,
          // set default timeouts for old matches with null timeouts
        },
        errorsToClear: ['timeoutsEnabled'],
      });
    }
  }, []);

  const handleTimeoutsEnabledChange = (e) => {
    const isEnabled = e.target.checked ? 'yes' : 'no';
    setMatchSetting({
      payload: {
        timeoutsEnabled: isEnabled,
        ...(isEnabled && { timeouts: DEFAULT_TIMEOUTS }),
      },
      errorsToClear: ['timeoutsEnabled'],
    });
  };

  const handleEditTimeout = ({ duration, name, order }) => {
    const newTimeouts = (timeouts || []).map((timeout) => {
      if (timeout.order !== order) return timeout;
      return {
        ...timeout,
        ...(duration !== undefined && { duration }),
        ...(name !== undefined && { name }),
      };
    });
    setMatchSetting({
      payload: { timeouts: newTimeouts },
      errorsToClear: ['timeouts'],
    });
  };

  const showTimeouts = checkTruthy(timeoutsEnabled) || !timeoutsEnabled;

  const timeoutOptions = (timeouts || []).map((timeout) => {
    const { duration, name, order } = timeout || {};
    return (
      <div key={order} className="timeout-option">
        <label>
          <span className="name-label">Timeout {order}</span>
          <input
            disabled={disabledEdits}
            type="text"
            className="input-name"
            value={name}
            onChange={(e) => handleEditTimeout({ name: e.target.value, order })}
            maxLength="60"
          />
        </label>
        <label>
          <input
            disabled={disabledEdits}
            type="number"
            min="0"
            className="input-duration"
            value={duration}
            onChange={(e) =>
              handleEditTimeout({ duration: e.target.value, order })
            }
          />
          <span className="duration-label">Seconds</span>
        </label>
      </div>
    );
  });

  return (
    <div className={`timeouts-container ${className}`}>
      <SettingsHeader text="Timeout Options" />
      <label className="checkbox-container">
        <input
          disabled={disabledEdits}
          type="checkbox"
          checked={showTimeouts}
          onChange={handleTimeoutsEnabledChange}
        />
        <span className="select-label">Activate Timeouts</span>
      </label>
      {showTimeouts && <div className="timeout-options">{timeoutOptions}</div>}
      {errors.timeouts && (
        <div className="error-container">
          <p>{errors.timeouts}</p>
        </div>
      )}
    </div>
  );
};

const StyledTimeouts = styled(Timeouts)`
  .checkbox-container {
    display: flex;
    align-items: center;
  }

  .select-label {
    margin: 1rem;
  }

  .input-duration {
    width: 7rem;
  }

  .name-label,
  .duration-label {
    margin: 1rem;
    font-weight: 600;
  }

  .name-label {
    display: block;
  }

  .timeout-options {
    margin-bottom: 1.5rem;
  }

  @media (min-width: 900px) {
    .select-label {
      display: inline;
    }

    .name-label {
      display: inline-block;
    }
  }
`;

export default StyledTimeouts;
