import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getCurrentUser } from '../Helpers/AuthHelper';
import { CustomerContext } from '../Hooks/CustomerContext';

const useHostLoader = ({ isSignUp = false, requiresAuth = false, requiresSubscription = false } = {}) => {
  const history = useHistory();
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const { hasValidSubscription, isLoading } = React.useContext(CustomerContext);
  const customerFetchCompleted = !isLoading;

  React.useEffect(() => {
    const fetchUser = async () => {
      const user = await getCurrentUser();
      setCurrentUser(user || null);
      if (isSignUp && hasValidSubscription()) {
        // only navigate away from SignUpHost screen if user has a valid subscription
        history.push('/host');
        return;
      }
      if (user && !requiresAuth && !isSignUp) {
        // exclude SignUpHost screen to allow for failed signup retries
        history.push('/host');
        return;
      }
      if (!hasValidSubscription() && requiresSubscription) {
        history.push('/host/account');
        return;
      }
      if (!user && requiresAuth) {
        history.push('/host/sign-in');
        return;
      }
      setLoadingComplete(true);
    };
    if (customerFetchCompleted && !loadingComplete) fetchUser();
  }, [customerFetchCompleted, history, loadingComplete, requiresAuth, requiresSubscription]);

  return { currentUser, loadingComplete };
};

export default useHostLoader;
