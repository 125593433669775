/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMatchTemplate = `query GetMatchTemplate($id: ID!) {
  getMatchTemplate(id: $id) {
    id
    active
    templateDescription
    templateName
    templateType
    updatedAt
    user
    startingPoints
    tossUpEnabled
    tossUpPoints
    showWrongAnswerPenalty
    wrongAnswerPenaltyPoints
    showEarlyCorrectAnswerBonus
    earlyCorrectAnswerPoints
    showEarlyWrongAnswerPenalty
    earlyWrongAnswerPoints
    roundTimerEnabled
    roundMinutes
    roundSeconds
    ringInTimer
    showResetTimer
    resetTimerOption
    resetTimerDuration
    showComputationTime
    computationTime
    ringInLimit
    soundRoundTimerEnabled
    soundRingInEnabled
    soundRingInTimerEnabled
    soundDeliberationTimerEnabled
    teamEndDeliberationEnabled
    teamDeliberationMinutes
    teamDeliberationSeconds
    teamQuestionEnabled
    soundTeamDeliberationTimerEnabled
    bonusQuestionEnabled
    bonusTotalParts
    bonusPointsPerPart
    bonusBouncebackEnabled
    bonusImmediateBouncebackEnabled
    bonusQuestionsFollowTossUps
    bonusControl
    bonusAnswerTimerEnabled
    bonusAnswerSeconds
    bonusDeliberationTimerEnabled
    bonusDeliberationSeconds
    bonusComputationTimerEnabled
    bonusComputationSeconds
    soundBonusAnswerTimerEnabled
    soundBonusDeliberationTimerEnabled
    playerTimersEnabled
    tossUpAnswerEnabled
    tossUpAnswerSeconds
    soundTossUpAnswerEnabled
    timeoutsEnabled
    soundTimeoutTimerEnabled
    preserveMinTimeEnabled
    preserveMinTimeSeconds
    timeouts {
      name
      duration
      order
    }
  }
}
`;
export const listMatchTemplates = `query ListMatchTemplates(
  $filter: ModelMatchTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  listMatchTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      active
      templateDescription
      templateName
      templateType
      updatedAt
      user
      startingPoints
      tossUpEnabled
      tossUpPoints
      showWrongAnswerPenalty
      wrongAnswerPenaltyPoints
      showEarlyCorrectAnswerBonus
      earlyCorrectAnswerPoints
      showEarlyWrongAnswerPenalty
      earlyWrongAnswerPoints
      roundTimerEnabled
      roundMinutes
      roundSeconds
      ringInTimer
      showResetTimer
      resetTimerOption
      resetTimerDuration
      showComputationTime
      computationTime
      ringInLimit
      soundRoundTimerEnabled
      soundRingInEnabled
      soundRingInTimerEnabled
      soundDeliberationTimerEnabled
      teamEndDeliberationEnabled
      teamDeliberationMinutes
      teamDeliberationSeconds
      teamQuestionEnabled
      soundTeamDeliberationTimerEnabled
      bonusQuestionEnabled
      bonusTotalParts
      bonusPointsPerPart
      bonusBouncebackEnabled
      bonusImmediateBouncebackEnabled
      bonusQuestionsFollowTossUps
      bonusControl
      bonusAnswerTimerEnabled
      bonusAnswerSeconds
      bonusDeliberationTimerEnabled
      bonusDeliberationSeconds
      bonusComputationTimerEnabled
      bonusComputationSeconds
      soundBonusAnswerTimerEnabled
      soundBonusDeliberationTimerEnabled
      playerTimersEnabled
      tossUpAnswerEnabled
      tossUpAnswerSeconds
      soundTossUpAnswerEnabled
      timeoutsEnabled
      soundTimeoutTimerEnabled
      preserveMinTimeEnabled
      preserveMinTimeSeconds
      timeouts {
        name
        duration
        order
      }
    }
    nextToken
  }
}
`;
export const matchTemplatesByType = `query MatchTemplatesByType(
  $templateType: String
  $user: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMatchTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  matchTemplatesByType(
    templateType: $templateType
    user: $user
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      active
      templateDescription
      templateName
      templateType
      updatedAt
      user
      startingPoints
      tossUpEnabled
      tossUpPoints
      showWrongAnswerPenalty
      wrongAnswerPenaltyPoints
      showEarlyCorrectAnswerBonus
      earlyCorrectAnswerPoints
      showEarlyWrongAnswerPenalty
      earlyWrongAnswerPoints
      roundTimerEnabled
      roundMinutes
      roundSeconds
      ringInTimer
      showResetTimer
      resetTimerOption
      resetTimerDuration
      showComputationTime
      computationTime
      ringInLimit
      soundRoundTimerEnabled
      soundRingInEnabled
      soundRingInTimerEnabled
      soundDeliberationTimerEnabled
      teamEndDeliberationEnabled
      teamDeliberationMinutes
      teamDeliberationSeconds
      teamQuestionEnabled
      soundTeamDeliberationTimerEnabled
      bonusQuestionEnabled
      bonusTotalParts
      bonusPointsPerPart
      bonusBouncebackEnabled
      bonusImmediateBouncebackEnabled
      bonusQuestionsFollowTossUps
      bonusControl
      bonusAnswerTimerEnabled
      bonusAnswerSeconds
      bonusDeliberationTimerEnabled
      bonusDeliberationSeconds
      bonusComputationTimerEnabled
      bonusComputationSeconds
      soundBonusAnswerTimerEnabled
      soundBonusDeliberationTimerEnabled
      playerTimersEnabled
      tossUpAnswerEnabled
      tossUpAnswerSeconds
      soundTossUpAnswerEnabled
      timeoutsEnabled
      soundTimeoutTimerEnabled
      preserveMinTimeEnabled
      preserveMinTimeSeconds
      timeouts {
        name
        duration
        order
      }
    }
    nextToken
  }
}
`;
export const activeTemplatesByType = `query ActiveTemplatesByType(
  $active: Int
  $templateTypeUser: ModelMatchTemplateActiveTemplatesByTypeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMatchTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  activeTemplatesByType(
    active: $active
    templateTypeUser: $templateTypeUser
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      active
      templateDescription
      templateName
      templateType
      updatedAt
      user
      startingPoints
      tossUpEnabled
      tossUpPoints
      showWrongAnswerPenalty
      wrongAnswerPenaltyPoints
      showEarlyCorrectAnswerBonus
      earlyCorrectAnswerPoints
      showEarlyWrongAnswerPenalty
      earlyWrongAnswerPoints
      roundTimerEnabled
      roundMinutes
      roundSeconds
      ringInTimer
      showResetTimer
      resetTimerOption
      resetTimerDuration
      showComputationTime
      computationTime
      ringInLimit
      soundRoundTimerEnabled
      soundRingInEnabled
      soundRingInTimerEnabled
      soundDeliberationTimerEnabled
      teamEndDeliberationEnabled
      teamDeliberationMinutes
      teamDeliberationSeconds
      teamQuestionEnabled
      soundTeamDeliberationTimerEnabled
      bonusQuestionEnabled
      bonusTotalParts
      bonusPointsPerPart
      bonusBouncebackEnabled
      bonusImmediateBouncebackEnabled
      bonusQuestionsFollowTossUps
      bonusControl
      bonusAnswerTimerEnabled
      bonusAnswerSeconds
      bonusDeliberationTimerEnabled
      bonusDeliberationSeconds
      bonusComputationTimerEnabled
      bonusComputationSeconds
      soundBonusAnswerTimerEnabled
      soundBonusDeliberationTimerEnabled
      playerTimersEnabled
      tossUpAnswerEnabled
      tossUpAnswerSeconds
      soundTossUpAnswerEnabled
      timeoutsEnabled
      soundTimeoutTimerEnabled
      preserveMinTimeEnabled
      preserveMinTimeSeconds
      timeouts {
        name
        duration
        order
      }
    }
    nextToken
  }
}
`;
export const listMatchs = `query ListMatchs(
  $filter: ModelMatchFilterInput
  $limit: Int
  $nextToken: String
) {
  listMatchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getMatch = `query GetMatch($id: ID!) {
  getMatch(id: $id) {
    id
    active
    activePlayerID
    bonusRoundOwner
    createdAt
    matchDate
    matchName
    matchStatus
    updatedAt
    user
    teams {
      items {
        id
        active
        score
        matchID
        teamID
        user
        order
      }
      nextToken
    }
    players(limit: 50) {
      items {
        id
        active
        score
        matchID
        playerID
        deviceID
        order
        user
      }
      nextToken
    }
    messages {
      items {
        id
        matchID
        messageType
        playerID
        createdAt
        updatedAt
      }
      nextToken
    }
    settings {
      items {
        id
        matchID
        user
        templateID
        startingPoints
        tossUpEnabled
        tossUpPoints
        showWrongAnswerPenalty
        wrongAnswerPenaltyPoints
        showEarlyCorrectAnswerBonus
        earlyCorrectAnswerPoints
        showEarlyWrongAnswerPenalty
        earlyWrongAnswerPoints
        roundTimerEnabled
        roundMinutes
        roundSeconds
        ringInTimer
        showResetTimer
        resetTimerOption
        resetTimerDuration
        showComputationTime
        computationTime
        ringInLimit
        soundRoundTimerEnabled
        soundRingInEnabled
        soundRingInTimerEnabled
        soundDeliberationTimerEnabled
        teamEndDeliberationEnabled
        teamDeliberationMinutes
        teamDeliberationSeconds
        teamQuestionEnabled
        soundTeamDeliberationTimerEnabled
        bonusQuestionEnabled
        bonusTotalParts
        bonusPointsPerPart
        bonusBouncebackEnabled
        bonusImmediateBouncebackEnabled
        bonusQuestionsFollowTossUps
        bonusControl
        bonusAnswerTimerEnabled
        bonusAnswerSeconds
        bonusDeliberationTimerEnabled
        bonusDeliberationSeconds
        bonusComputationTimerEnabled
        bonusComputationSeconds
        soundBonusAnswerTimerEnabled
        soundBonusDeliberationTimerEnabled
        playerTimersEnabled
        tossUpAnswerEnabled
        tossUpAnswerSeconds
        soundTossUpAnswerEnabled
        timeoutsEnabled
        soundTimeoutTimerEnabled
        preserveMinTimeEnabled
        preserveMinTimeSeconds
      }
      nextToken
    }
    scores {
      items {
        id
        type
        questionNumber
        points
        otherPlayers
        matchID
        playerID
        teamID
        user
      }
      nextToken
    }
  }
}
`;
export const matchesByUser = `query MatchesByUser(
  $user: String
  $activeUpdatedAt: ModelMatchMatchesByUserCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMatchFilterInput
  $limit: Int
  $nextToken: String
) {
  matchesByUser(
    user: $user
    activeUpdatedAt: $activeUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const matchesByDate = `query MatchesByDate(
  $user: String
  $activeMatchDate: ModelMatchMatchesByDateCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMatchFilterInput
  $limit: Int
  $nextToken: String
) {
  matchesByDate(
    user: $user
    activeMatchDate: $activeMatchDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const matchesByStatusAndDate = `query MatchesByStatusAndDate(
  $user: String
  $activeMatchStatusMatchDate: ModelMatchMatchesByStatusAndDateCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMatchFilterInput
  $limit: Int
  $nextToken: String
) {
  matchesByStatusAndDate(
    user: $user
    activeMatchStatusMatchDate: $activeMatchStatusMatchDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const matchesByName = `query MatchesByName(
  $user: String
  $activeMatchName: ModelMatchMatchesByNameCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMatchFilterInput
  $limit: Int
  $nextToken: String
) {
  matchesByName(
    user: $user
    activeMatchName: $activeMatchName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const matchesByStatusAndName = `query MatchesByStatusAndName(
  $user: String
  $activeMatchStatusMatchName: ModelMatchMatchesByStatusAndNameCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMatchFilterInput
  $limit: Int
  $nextToken: String
) {
  matchesByStatusAndName(
    user: $user
    activeMatchStatusMatchName: $activeMatchStatusMatchName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const listTeams = `query ListTeams(
  $filter: ModelTeamFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getTeam = `query GetTeam($id: ID!) {
  getTeam(id: $id) {
    id
    active
    name
    updatedAt
    user
    groups {
      items {
        id
        teamGroupID
        teamID
      }
      nextToken
    }
    matches {
      items {
        id
        active
        score
        matchID
        teamID
        user
        order
      }
      nextToken
    }
    players {
      items {
        id
        active
        name
        order
        teamID
        user
      }
      nextToken
    }
    scores {
      items {
        id
        type
        questionNumber
        points
        otherPlayers
        matchID
        playerID
        teamID
        user
      }
      nextToken
    }
  }
}
`;
export const teamsByUser = `query TeamsByUser(
  $user: String
  $nameUpdatedAt: ModelTeamTeamsByUserCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTeamFilterInput
  $limit: Int
  $nextToken: String
) {
  teamsByUser(
    user: $user
    nameUpdatedAt: $nameUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const activeTeamsByUser = `query ActiveTeamsByUser(
  $user: String
  $activeUpdatedAt: ModelTeamActiveTeamsByUserCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTeamFilterInput
  $limit: Int
  $nextToken: String
) {
  activeTeamsByUser(
    user: $user
    activeUpdatedAt: $activeUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const listTeamGroups = `query ListTeamGroups(
  $filter: ModelTeamGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeamGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      active
      name
      updatedAt
      user
      teams {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getTeamGroup = `query GetTeamGroup($id: ID!) {
  getTeamGroup(id: $id) {
    id
    active
    name
    updatedAt
    user
    teams {
      items {
        id
        teamGroupID
        teamID
      }
      nextToken
    }
  }
}
`;
export const teamGroupsByName = `query TeamGroupsByName(
  $active: Int
  $name: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTeamGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  teamGroupsByName(
    active: $active
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      active
      name
      updatedAt
      user
      teams {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getTeamGroupLink = `query GetTeamGroupLink($id: ID!) {
  getTeamGroupLink(id: $id) {
    id
    teamGroupID
    teamID
    team {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
    group {
      id
      active
      name
      updatedAt
      user
      teams {
        nextToken
      }
    }
  }
}
`;
export const listTeamGroupLinks = `query ListTeamGroupLinks(
  $filter: ModelTeamGroupLinkFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeamGroupLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      teamGroupID
      teamID
      team {
        id
        active
        name
        updatedAt
        user
      }
      group {
        id
        active
        name
        updatedAt
        user
      }
    }
    nextToken
  }
}
`;
export const listPlayers = `query ListPlayers(
  $filter: ModelPlayerFilterInput
  $limit: Int
  $nextToken: String
) {
  listPlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      active
      name
      order
      teamID
      user
      team {
        id
        active
        name
        updatedAt
        user
      }
      matches {
        nextToken
      }
      messages {
        nextToken
      }
      scores {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getPlayer = `query GetPlayer($id: ID!) {
  getPlayer(id: $id) {
    id
    active
    name
    order
    teamID
    user
    team {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
    matches {
      items {
        id
        active
        score
        matchID
        playerID
        deviceID
        order
        user
      }
      nextToken
    }
    messages {
      items {
        id
        matchID
        messageType
        playerID
        createdAt
        updatedAt
      }
      nextToken
    }
    scores {
      items {
        id
        type
        questionNumber
        points
        otherPlayers
        matchID
        playerID
        teamID
        user
      }
      nextToken
    }
  }
}
`;
export const playersByTeam = `query PlayersByTeam(
  $teamID: ID
  $name: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPlayerFilterInput
  $limit: Int
  $nextToken: String
) {
  playersByTeam(
    teamID: $teamID
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      active
      name
      order
      teamID
      user
      team {
        id
        active
        name
        updatedAt
        user
      }
      matches {
        nextToken
      }
      messages {
        nextToken
      }
      scores {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getMessage = `query GetMessage($id: ID!) {
  getMessage(id: $id) {
    id
    matchID
    messageType
    playerID
    createdAt
    updatedAt
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    player {
      id
      active
      name
      order
      teamID
      user
      team {
        id
        active
        name
        updatedAt
        user
      }
      matches {
        nextToken
      }
      messages {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;
export const listMessages = `query ListMessages(
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      matchID
      messageType
      playerID
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
      }
      player {
        id
        active
        name
        order
        teamID
        user
      }
    }
    nextToken
  }
}
`;
export const messagesByMatch = `query MessagesByMatch(
  $matchID: ID
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  messagesByMatch(
    matchID: $matchID
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      matchID
      messageType
      playerID
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
      }
      player {
        id
        active
        name
        order
        teamID
        user
      }
    }
    nextToken
  }
}
`;
export const messagesByPlayer = `query MessagesByPlayer(
  $playerID: ID
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  messagesByPlayer(
    playerID: $playerID
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      matchID
      messageType
      playerID
      createdAt
      updatedAt
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
      }
      player {
        id
        active
        name
        order
        teamID
        user
      }
    }
    nextToken
  }
}
`;
export const getMatchSettings = `query GetMatchSettings($id: ID!) {
  getMatchSettings(id: $id) {
    id
    matchID
    user
    templateID
    startingPoints
    tossUpEnabled
    tossUpPoints
    showWrongAnswerPenalty
    wrongAnswerPenaltyPoints
    showEarlyCorrectAnswerBonus
    earlyCorrectAnswerPoints
    showEarlyWrongAnswerPenalty
    earlyWrongAnswerPoints
    roundTimerEnabled
    roundMinutes
    roundSeconds
    ringInTimer
    showResetTimer
    resetTimerOption
    resetTimerDuration
    showComputationTime
    computationTime
    ringInLimit
    soundRoundTimerEnabled
    soundRingInEnabled
    soundRingInTimerEnabled
    soundDeliberationTimerEnabled
    teamEndDeliberationEnabled
    teamDeliberationMinutes
    teamDeliberationSeconds
    teamQuestionEnabled
    soundTeamDeliberationTimerEnabled
    bonusQuestionEnabled
    bonusTotalParts
    bonusPointsPerPart
    bonusBouncebackEnabled
    bonusImmediateBouncebackEnabled
    bonusQuestionsFollowTossUps
    bonusControl
    bonusAnswerTimerEnabled
    bonusAnswerSeconds
    bonusDeliberationTimerEnabled
    bonusDeliberationSeconds
    bonusComputationTimerEnabled
    bonusComputationSeconds
    soundBonusAnswerTimerEnabled
    soundBonusDeliberationTimerEnabled
    playerTimersEnabled
    tossUpAnswerEnabled
    tossUpAnswerSeconds
    soundTossUpAnswerEnabled
    timeoutsEnabled
    soundTimeoutTimerEnabled
    preserveMinTimeEnabled
    preserveMinTimeSeconds
    timeouts {
      name
      duration
      order
    }
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;
export const listMatchSettingss = `query ListMatchSettingss(
  $filter: ModelMatchSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  listMatchSettingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      matchID
      user
      templateID
      startingPoints
      tossUpEnabled
      tossUpPoints
      showWrongAnswerPenalty
      wrongAnswerPenaltyPoints
      showEarlyCorrectAnswerBonus
      earlyCorrectAnswerPoints
      showEarlyWrongAnswerPenalty
      earlyWrongAnswerPoints
      roundTimerEnabled
      roundMinutes
      roundSeconds
      ringInTimer
      showResetTimer
      resetTimerOption
      resetTimerDuration
      showComputationTime
      computationTime
      ringInLimit
      soundRoundTimerEnabled
      soundRingInEnabled
      soundRingInTimerEnabled
      soundDeliberationTimerEnabled
      teamEndDeliberationEnabled
      teamDeliberationMinutes
      teamDeliberationSeconds
      teamQuestionEnabled
      soundTeamDeliberationTimerEnabled
      bonusQuestionEnabled
      bonusTotalParts
      bonusPointsPerPart
      bonusBouncebackEnabled
      bonusImmediateBouncebackEnabled
      bonusQuestionsFollowTossUps
      bonusControl
      bonusAnswerTimerEnabled
      bonusAnswerSeconds
      bonusDeliberationTimerEnabled
      bonusDeliberationSeconds
      bonusComputationTimerEnabled
      bonusComputationSeconds
      soundBonusAnswerTimerEnabled
      soundBonusDeliberationTimerEnabled
      playerTimersEnabled
      tossUpAnswerEnabled
      tossUpAnswerSeconds
      soundTossUpAnswerEnabled
      timeoutsEnabled
      soundTimeoutTimerEnabled
      preserveMinTimeEnabled
      preserveMinTimeSeconds
      timeouts {
        name
        duration
        order
      }
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
      }
    }
    nextToken
  }
}
`;
export const settingsByMatch = `query SettingsByMatch(
  $matchID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelMatchSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  settingsByMatch(
    matchID: $matchID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      matchID
      user
      templateID
      startingPoints
      tossUpEnabled
      tossUpPoints
      showWrongAnswerPenalty
      wrongAnswerPenaltyPoints
      showEarlyCorrectAnswerBonus
      earlyCorrectAnswerPoints
      showEarlyWrongAnswerPenalty
      earlyWrongAnswerPoints
      roundTimerEnabled
      roundMinutes
      roundSeconds
      ringInTimer
      showResetTimer
      resetTimerOption
      resetTimerDuration
      showComputationTime
      computationTime
      ringInLimit
      soundRoundTimerEnabled
      soundRingInEnabled
      soundRingInTimerEnabled
      soundDeliberationTimerEnabled
      teamEndDeliberationEnabled
      teamDeliberationMinutes
      teamDeliberationSeconds
      teamQuestionEnabled
      soundTeamDeliberationTimerEnabled
      bonusQuestionEnabled
      bonusTotalParts
      bonusPointsPerPart
      bonusBouncebackEnabled
      bonusImmediateBouncebackEnabled
      bonusQuestionsFollowTossUps
      bonusControl
      bonusAnswerTimerEnabled
      bonusAnswerSeconds
      bonusDeliberationTimerEnabled
      bonusDeliberationSeconds
      bonusComputationTimerEnabled
      bonusComputationSeconds
      soundBonusAnswerTimerEnabled
      soundBonusDeliberationTimerEnabled
      playerTimersEnabled
      tossUpAnswerEnabled
      tossUpAnswerSeconds
      soundTossUpAnswerEnabled
      timeoutsEnabled
      soundTimeoutTimerEnabled
      preserveMinTimeEnabled
      preserveMinTimeSeconds
      timeouts {
        name
        duration
        order
      }
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
      }
    }
    nextToken
  }
}
`;
export const getScore = `query GetScore($id: ID!) {
  getScore(id: $id) {
    id
    type
    questionNumber
    points
    otherPlayers
    matchID
    playerID
    teamID
    user
    match {
      id
      active
      activePlayerID
      bonusRoundOwner
      createdAt
      matchDate
      matchName
      matchStatus
      updatedAt
      user
      teams {
        nextToken
      }
      players {
        nextToken
      }
      messages {
        nextToken
      }
      settings {
        nextToken
      }
      scores {
        nextToken
      }
    }
    team {
      id
      active
      name
      updatedAt
      user
      groups {
        nextToken
      }
      matches {
        nextToken
      }
      players {
        nextToken
      }
      scores {
        nextToken
      }
    }
    player {
      id
      active
      name
      order
      teamID
      user
      team {
        id
        active
        name
        updatedAt
        user
      }
      matches {
        nextToken
      }
      messages {
        nextToken
      }
      scores {
        nextToken
      }
    }
  }
}
`;
export const listScores = `query ListScores(
  $filter: ModelScoreFilterInput
  $limit: Int
  $nextToken: String
) {
  listScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      questionNumber
      points
      otherPlayers
      matchID
      playerID
      teamID
      user
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
      }
      team {
        id
        active
        name
        updatedAt
        user
      }
      player {
        id
        active
        name
        order
        teamID
        user
      }
    }
    nextToken
  }
}
`;
export const scoresByMatch = `query ScoresByMatch(
  $matchID: ID
  $teamIDPlayerID: ModelScoreScoresByMatchCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelScoreFilterInput
  $limit: Int
  $nextToken: String
) {
  scoresByMatch(
    matchID: $matchID
    teamIDPlayerID: $teamIDPlayerID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      questionNumber
      points
      otherPlayers
      matchID
      playerID
      teamID
      user
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
      }
      team {
        id
        active
        name
        updatedAt
        user
      }
      player {
        id
        active
        name
        order
        teamID
        user
      }
    }
    nextToken
  }
}
`;
export const scoresByPlayer = `query ScoresByPlayer(
  $playerID: ID
  $matchID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelScoreFilterInput
  $limit: Int
  $nextToken: String
) {
  scoresByPlayer(
    playerID: $playerID
    matchID: $matchID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      questionNumber
      points
      otherPlayers
      matchID
      playerID
      teamID
      user
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
      }
      team {
        id
        active
        name
        updatedAt
        user
      }
      player {
        id
        active
        name
        order
        teamID
        user
      }
    }
    nextToken
  }
}
`;
export const scoresByTeam = `query ScoresByTeam(
  $teamID: ID
  $matchID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelScoreFilterInput
  $limit: Int
  $nextToken: String
) {
  scoresByTeam(
    teamID: $teamID
    matchID: $matchID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      questionNumber
      points
      otherPlayers
      matchID
      playerID
      teamID
      user
      match {
        id
        active
        activePlayerID
        bonusRoundOwner
        createdAt
        matchDate
        matchName
        matchStatus
        updatedAt
        user
      }
      team {
        id
        active
        name
        updatedAt
        user
      }
      player {
        id
        active
        name
        order
        teamID
        user
      }
    }
    nextToken
  }
}
`;
export const getCustomer = `query GetCustomer($id: ID!) {
  getCustomer(id: $id) {
    id
    firstName
    lastName
    level
    nextBillingAt
    organization
    state
    stripeCustomerId
    stripeProductId
    stripeSubscriptionId
    stripeSubscriptionStatus
    stripeSubscriptionPaymentIntentStatus
    subscriptionEnding
    updatedAt
    user
  }
}
`;
export const listCustomers = `query ListCustomers(
  $filter: ModelCustomerFilterInput
  $limit: Int
  $nextToken: String
) {
  listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstName
      lastName
      level
      nextBillingAt
      organization
      state
      stripeCustomerId
      stripeProductId
      stripeSubscriptionId
      stripeSubscriptionStatus
      stripeSubscriptionPaymentIntentStatus
      subscriptionEnding
      updatedAt
      user
    }
    nextToken
  }
}
`;
export const customerByStripeCustomerId = `query CustomerByStripeCustomerId(
  $stripeCustomerId: String
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCustomerFilterInput
  $limit: Int
  $nextToken: String
) {
  customerByStripeCustomerId(
    stripeCustomerId: $stripeCustomerId
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      level
      nextBillingAt
      organization
      state
      stripeCustomerId
      stripeProductId
      stripeSubscriptionId
      stripeSubscriptionStatus
      stripeSubscriptionPaymentIntentStatus
      subscriptionEnding
      updatedAt
      user
    }
    nextToken
  }
}
`;
export const customerByStripeSubscriptionId = `query CustomerByStripeSubscriptionId(
  $stripeSubscriptionId: String
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCustomerFilterInput
  $limit: Int
  $nextToken: String
) {
  customerByStripeSubscriptionId(
    stripeSubscriptionId: $stripeSubscriptionId
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      level
      nextBillingAt
      organization
      state
      stripeCustomerId
      stripeProductId
      stripeSubscriptionId
      stripeSubscriptionStatus
      stripeSubscriptionPaymentIntentStatus
      subscriptionEnding
      updatedAt
      user
    }
    nextToken
  }
}
`;
export const customerByUser = `query CustomerByUser(
  $user: String
  $sortDirection: ModelSortDirection
  $filter: ModelCustomerFilterInput
  $limit: Int
  $nextToken: String
) {
  customerByUser(
    user: $user
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      level
      nextBillingAt
      organization
      state
      stripeCustomerId
      stripeProductId
      stripeSubscriptionId
      stripeSubscriptionStatus
      stripeSubscriptionPaymentIntentStatus
      subscriptionEnding
      updatedAt
      user
    }
    nextToken
  }
}
`;
