import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { WindowClose } from '../../Helpers/IconHelper';
import { DEFAULT_TIMEOUTS } from '../../Helpers/MatchHelper';

const TimeoutsModal = ({
  className,
  toggleModal = () => {},
  descriptionText = '',
  timeouts = [],
  startTimeout = () => {},
}) => {
  const [activeTimeouts, setActiveTimeouts] = React.useState(DEFAULT_TIMEOUTS);
  const handleSelectTimeout = (duration) => {
    startTimeout(duration);
    toggleModal();
  };

  React.useEffect(() => {
    if (timeouts) setActiveTimeouts(timeouts);
  }, [timeouts]);

  return ReactDOM.createPortal(
    <div className={`modal-container ${className}`}>
      <div className="modal-overlay" onClick={toggleModal}></div>
      <div className="modal">
        <div className="modal-header">
          <p className="modal-text">{descriptionText}</p>
          <button className="modal-close-button" onClick={toggleModal}>
            <WindowClose />
          </button>
        </div>
        <div className="timeout-container">
          {activeTimeouts.map(({ duration, name, order }) => {
            return (
              <div key={order} className="timeout-item">
                <p className="timeout-name">{name}</p>
                <p className="timeout-duration">{duration} Seconds</p>
                <button
                  className="button-yes"
                  onClick={() => handleSelectTimeout(duration)}
                >
                  Choose
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>,
    document.body,
  );
};

const StyledTimeoutsModal = styled(TimeoutsModal)`
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    background: white;
    margin: 0 auto;
    border-radius: 3px;
    width: 20rem;
    height: auto;
    padding: 2rem;
    transition: all 0.5s;
  }

  .modal-text {
    font-size: 1.5em;
  }

  .error-text {
    text-align: center;
    font-size: 1.2rem;
    color: red;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
  }

  .modal-close-button {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    background-color: white;
    color: steelblue;
    cursor: pointer;
    border: none;
    padding: 0;
    border-radius: 15%;
    border: 0px;
    margin: 0;
    height: 2rem;

    &:hover {
      background-color: steelblue;
      color: white;
    }
  }

  .timeout-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem;
  }

  .timeout-item {
    display: flex;
    flex-direction: column;
    align-items: auto;
    justify-content: auto;
  }

  .timeout-name {
    flex: auto;
  }

  .timeout-duration {
    flex: auto;
  }

  .button-yes,
  .button-no {
    margin: 1rem;
  }

  @media (min-width: 900px) {
    .modal {
      width: 45rem;
    }
    .timeout-container {
      display: flex;
      justify-content: center;
      margin: 1rem;
    }

    .timeout-item {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
    }

    .timeout-name {
      flex: 2;
    }

    .timeout-duration {
      flex: 1;
    }
  }
`;

export default StyledTimeoutsModal;
