import React from 'react';
import styled from 'styled-components';
import IconButton from '../../../../../../Components/IconButton';
import StyledModal from '../../../../../../Components/Modal';
import PromptModal from '../../../../../../Components/PromptModal';
import { updatePlayer } from '../../../../../../Helpers/Api/Player';
import {
  deleteTeamGroupLink,
  updateTeam,
  updateTeamGroup,
} from '../../../../../../Helpers/Api/Team';
import { Edit, Trash } from '../../../../../../Helpers/IconHelper';
import { get } from '../../../../../../Helpers/UtilHelper';
import EditTeamModal from '../EditTeamModal';

const TeamListItem = ({
  className,
  team,
  setTeamList,
  resetAndRefetchTeams,
  matches,
  isSharedTeams,
  sharedTeamsList,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [showEditTeamModal, setShowEditTeamModal] = React.useState(false);
  const [
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal,
  ] = React.useState(false);
  const [preventDeleteTeam, setPreventDeleteTeam] = React.useState(false);
  const [
    showPreventDeleteTeamModal,
    setShowPreventDeleteTeamModal,
  ] = React.useState(false);

  React.useEffect(() => {
    const newOverflow = showDeleteConfirmationModal ? 'hidden' : 'unset';
    document.body.style.overflow = newOverflow;
  }, [showDeleteConfirmationModal]);

  React.useEffect(() => {
    let shouldPreventDeleteTeam = false;
    matches.forEach((match) => {
      const matchStatus = get(match, 'match.matchStatus');
      const matchActive = get(match, 'match.active');
      if (
        !shouldPreventDeleteTeam &&
        matchActive &&
        matchStatus !== 'completed'
      ) {
        shouldPreventDeleteTeam = true;
      }
    });
    setPreventDeleteTeam(shouldPreventDeleteTeam);
  }, [matches]);

  const toggleDeleteConfirmationModal = () => {
    setShowDeleteConfirmationModal((prev) => !prev);
  };

  const togglePreventDeleteTeamModal = () => {
    setShowPreventDeleteTeamModal((prev) => !prev);
  };

  const handleDeleteTeam = async ({ id, name, players: { items } } = {}) => {
    const previousGroupID = get(team, 'groups.items.0.group.id');
    const previousGroupName = get(team, 'groups.items.0.group.name');
    setIsSubmitting(true);
    const updatedTeam = await updateTeam({
      teamID: id,
      name,
      propsToUpdate: { active: 0 },
    });
    if (updatedTeam) {
      // delete teamGroupLink if team belongs to a teamGroup
      const teamGroupLinkID = get(team, 'groups.items.0.id');
      if (teamGroupLinkID) {
        await deleteTeamGroupLink({ teamGroupLinkID });
        if (sharedTeamsList.length === 1) {
          await updateTeamGroup({
            active: 0,
            id: previousGroupID,
            name: previousGroupName,
          });
        }
      }
      // delete players
      const playerPromises = items.map(({ name, id: playerID, teamID }) =>
        updatePlayer({
          name,
          playerID,
          teamID,
          propsToUpdate: { active: 0 },
        }),
      );
      await Promise.all(playerPromises);
      toggleDeleteConfirmationModal();
      setIsSubmitting(false);
      if (isSharedTeams) {
        await resetAndRefetchTeams();
      } else {
        setTeamList((prev) => prev.filter((team) => team && team.id !== id));
      }
    }
  };

  return (
    <tr key={team.id} className={`${className}`}>
      <td>
        <button
          className="team-button"
          onClick={() => setShowEditTeamModal(true)}
        >
          <p>{team.name}</p>
        </button>
      </td>
      <td>{team.players.items.length}</td>
      <td>
        <IconButton onClick={() => setShowEditTeamModal(true)} Icon={Edit} />
        <IconButton
          onClick={
            preventDeleteTeam
              ? togglePreventDeleteTeamModal
              : toggleDeleteConfirmationModal
          }
          Icon={Trash}
        />
        {showPreventDeleteTeamModal && (
          <PromptModal
            confirmAction={togglePreventDeleteTeamModal}
            toggleModal={togglePreventDeleteTeamModal}
            descriptionText="Warning: This team cannot be deleted. Please complete all matches with this team in play and try again."
          />
        )}
        {showDeleteConfirmationModal && (
          <StyledModal
            confirmAction={() => handleDeleteTeam(team)}
            confirmLoading={isSubmitting}
            toggleModal={toggleDeleteConfirmationModal}
            text="Are you sure you want to delete this team?"
          />
        )}
      </td>
      {showEditTeamModal && (
        <EditTeamModal
          resetAndRefetchTeams={resetAndRefetchTeams}
          toggleModal={() => setShowEditTeamModal(false)}
          team={team}
          sharedTeamsList={sharedTeamsList}
        />
      )}
    </tr>
  );
};

const StyledTeamListItem = styled(TeamListItem)`
  width: 100%;
  line-height: 2rem;
  font-size: 1.6rem;
  margin: 0 auto 1rem;
  text-align: center;

  .team-button {
    color: #1EAEDB;
    width: auto;
    height: auto;
    background-color: #fff;
    padding: 0;
    margin: 0;
    border 0px;
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: center;
    font-weight: 400;
    text-transform: none;
    text-decoration: underline;
    white-space: pre-line;

    &:hover {
      color: #0FA0CE;
    }
  }

  th,
  td {
    text-align: center;
  }

  @media (min-width: 900px) {
    max-width: 80%;
  }
`;

export default StyledTeamListItem;
