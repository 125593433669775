import {
  activeTeamsByUser,
  listTeamGroups,
} from '../../graphql/custom/queries';
import {
  createTeam as createTeamMutation,
  createTeamGroup as createTeamGroupMutation,
  createTeamGroupLink as createTeamGroupLinkMutation,
  deleteTeamGroupLink as deleteTeamGroupLinkMutation,
  updateTeam as updateTeamMutation,
  updateTeamGroup as updateTeamGroupMutation,
  updateTeamGroupLink as updateTeamGroupLinkMutation,
} from '../../graphql/mutations';
import { getTeamGroup } from '../../graphql/queries';
import { getUserId } from '../AuthHelper';
import { get, objectMap } from '../UtilHelper';
import { mutate, queryAuthed } from './index';
import { createPlayer, updatePlayer } from './Player';

export const ADD_PLAYER = 'ADD_PLAYER';
export const DELETE_PLAYER = 'DELETE_PLAYER';
export const UPDATE_PLAYER = 'UPDATE_PLAYER';
export const UPDATE_TEAM_NAME = 'UPDATE_TEAM_NAME';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const ADD_GROUP = 'ADD_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';

export const ADD_TEAM_TO_GROUP = 'ADD_TEAM_TO_GROUP';
export const UPDATE_TEAM_TO_GROUP = 'UPDATE_TEAM_TO_GROUP';
export const CREATE_GROUP_AND_ADD_TEAM_TO_GROUP =
  'CREATE_GROUP_AND_ADD_TEAM_TO_GROUP';
export const CREATE_GROUP_AND_UPDATE_TEAM_TO_GROUP =
  'CREATE_GROUP_AND_UPDATE_TEAM_TO_GROUP';
export const REMOVE_TEAM_FROM_GROUP = 'REMOVE_TEAM_FROM_GROUP';

export const createTeam = async ({ name }) => {
  let team = null;
  try {
    const user = await getUserId();
    if (!user) throw new Error('No Current User');

    const response = await mutate(createTeamMutation, {
      active: 1,
      name,
      updatedAt: new Date().toISOString(),
      user,
    });
    if (response) team = response.data.createTeam;
  } catch (err) {
    console.log(`Create Team Error: ${JSON.stringify(err)}`);
  }
  return team;
};

export const createTeamGroup = async ({ name }) => {
  let group = null;
  try {
    const user = await getUserId();
    if (!user) throw new Error('No Current User');

    const response = await mutate(createTeamGroupMutation, {
      active: 1,
      name,
      updatedAt: new Date().toISOString(),
      user,
    });
    if (response) group = response.data.createTeamGroup;
  } catch (err) {
    console.log(`Create Team Group Error: ${JSON.stringify(err)}`);
  }
  return group;
};

export const updateTeamGroup = async ({ active, id, name }) => {
  let group = null;
  try {
    const user = await getUserId();
    if (!user) throw new Error('No Current User');

    const response = await mutate(updateTeamGroupMutation, {
      active,
      id,
      name,
    });
    if (response) group = response.data.updateTeamGroup;
  } catch (err) {
    console.log(`Update Team Group Error: ${JSON.stringify(err)}`);
  }
  return group;
};

export const createTeamGroupLink = async ({ teamGroupID, teamID }) => {
  let teamGroupLink = null;
  try {
    const user = await getUserId();
    if (!user) throw new Error('No Current User');

    const response = await mutate(createTeamGroupLinkMutation, {
      teamGroupID,
      teamID,
    });

    if (response) teamGroupLink = response.data.createTeamGroupLink;
  } catch (err) {
    console.log('Create Team Group Link Error:', err);
  }
  return teamGroupLink;
};

export const deleteTeamGroupLink = async ({
  postUpdateCallback,
  teamGroupLinkID,
}) => {
  let deletedTeamGroupLink = null;
  try {
    const user = await getUserId();
    if (!user) throw new Error('No Current User');

    const response = await mutate(deleteTeamGroupLinkMutation, {
      id: teamGroupLinkID,
    });

    if (response) deletedTeamGroupLink = response.data.deleteTeamGroupLink;
    if (postUpdateCallback) await postUpdateCallback();
  } catch (err) {
    console.log('Delete Team Group Link Error:', err);
  }
  return deletedTeamGroupLink;
};

export const updateTeamGroupLink = async ({
  postUpdateCallback,
  teamGroupLinkID,
  teamGroupID,
  teamID,
}) => {
  let teamGroupLink = null;
  try {
    const user = await getUserId();
    if (!user) throw new Error('No Current User');

    const response = await mutate(updateTeamGroupLinkMutation, {
      id: teamGroupLinkID,
      teamGroupID,
      teamID,
    });

    if (postUpdateCallback) await postUpdateCallback();

    if (response) teamGroupLink = response.data.updateTeamGroupLink;
  } catch (err) {
    console.log('Update Team Group Link Error:', err);
  }
  return teamGroupLink;
};

// returns true OR false to communicate success of mutations
export const createTeamWithPlayers = async ({
  enableShareTeams,
  newGroup,
  players = [],
  selectedGroup,
  teamName,
}) => {
  if (!teamName || !players.length) return false;

  let teamGroupID;
  if (enableShareTeams) {
    if (newGroup) {
      const newlyCreatedGroup = await createTeamGroup({ name: newGroup });
      teamGroupID = newlyCreatedGroup.id;
    } else {
      teamGroupID = selectedGroup;
    }
  }
  // create team
  const team = await createTeam({ name: teamName });
  const teamID = get(team, 'id');
  if (!teamID) return false;

  if (enableShareTeams) {
    await createTeamGroupLink({ teamID, teamGroupID });
  }
  // create players
  const playerPromises = players.map((name, index) =>
    createPlayer({ name, teamID, order: index + 1 }),
  );
  await Promise.all(playerPromises);
  return true;
};

export const getTeamsByUser = async ({ limit = 10, token } = {}) => {
  const userId = await getUserId();
  if (!userId) return null;
  let teams = null;
  try {
    const response = await queryAuthed(activeTeamsByUser, {
      activeUpdatedAt: { beginsWith: { active: 1 } },
      user: userId,
      nextToken: token,
      limit,
      sortDirection: 'DESC',
    });
    if (response) teams = response.data.activeTeamsByUser;
  } catch (err) {
    console.log('Fetch Teams By UserID Error:', err);
  }
  return teams;
};

export const updateTeam = async ({ name, teamID, propsToUpdate = {} }) => {
  let team = null;
  try {
    const user = await getUserId();
    if (!user) throw new Error('No Current User');

    const response = await mutate(updateTeamMutation, {
      id: teamID,
      active: 1,
      updatedAt: new Date().toISOString(),
      user,
      name,
      ...propsToUpdate,
    });
    if (response) team = response.data.updateTeam;
  } catch (err) {
    console.log('Update Team Error:', err);
  }
  return team;
};

export const createTeamGroupAndCreateTeamGroupLink = async ({
  groupName,
  teamID,
}) => {
  const newGroup = await createTeamGroup({ name: groupName });
  const newGroupID = get(newGroup, 'id');
  if (!newGroupID) return;
  return createTeamGroupLink({ teamGroupID: newGroupID, teamID });
};

export const createTeamGroupAndUpdateTeamGroupLink = async ({
  groupName,
  postUpdateCallback,
  teamGroupLinkID,
  teamID,
}) => {
  const newGroup = await createTeamGroup({ name: groupName });
  const newGroupID = get(newGroup, 'id');
  if (!newGroupID) return;
  return updateTeamGroupLink({
    postUpdateCallback,
    teamGroupLinkID,
    teamGroupID: newGroupID,
    teamID,
  });
};

export const editTeamWithPlayers = async (edits = {}) => {
  try {
    const promises = objectMap(edits, (edit) => {
      const {
        groupID,
        groupName,
        name,
        order,
        playerID,
        postUpdateCallback,
        teamGroupLinkID,
        teamID,
        type,
      } = edit;
      switch (type) {
        case ADD_PLAYER: {
          if (!name || name === '') return;
          return createPlayer({ name, teamID, order });
        }
        case DELETE_PLAYER: {
          return updatePlayer({
            name,
            playerID,
            teamID,
            propsToUpdate: { active: 0 },
          });
        }
        case UPDATE_PLAYER: {
          if (!name || name === '') return;
          return updatePlayer({ name, playerID, teamID, order });
        }
        case UPDATE_TEAM_NAME: {
          return updateTeam({
            name,
            teamID,
          });
        }
        case ADD_TEAM_TO_GROUP: {
          // Add team to existing group (create TeamGroupLink)
          return createTeamGroupLink({ teamGroupID: groupID, teamID });
        }
        case UPDATE_TEAM_TO_GROUP: {
          // Change team group to existing group (update TeamGroupLink)
          return updateTeamGroupLink({
            postUpdateCallback,
            teamGroupLinkID,
            teamGroupID: groupID,
            teamID,
          });
        }
        case CREATE_GROUP_AND_ADD_TEAM_TO_GROUP: {
          // Add team to new group (create TeamGroup & create TeamGroupLink)
          return createTeamGroupAndCreateTeamGroupLink({ groupName, teamID });
        }
        case CREATE_GROUP_AND_UPDATE_TEAM_TO_GROUP: {
          // Change team group to new group (create TeamGroup & update TeamGroupLink)
          return createTeamGroupAndUpdateTeamGroupLink({
            groupName,
            postUpdateCallback,
            teamGroupLinkID,
            teamID,
          });
        }
        case REMOVE_TEAM_FROM_GROUP: {
          // Remove team from group (delete TeamGroupLink)
          return deleteTeamGroupLink({ postUpdateCallback, teamGroupLinkID });
        }
        default: {
          console.log('editTeamWithPlayers type not recognized');
          return;
        }
      }
    });
    return Promise.all(promises);
  } catch (err) {
    console.log('Update Team with Players Error:', err);
  }
};

export const getAllTeamGroups = async () => {
  const user = await getUserId();
  if (!user) return null;
  let groups = null;
  try {
    const response = await queryAuthed(listTeamGroups, {
      filter: { active: { eq: 1 } },
      limit: 1000,
    });
    if (response) groups = response.data.listTeamGroups.items;
    groups = groups.filter((group) => {
      return group.teams.items.length !== 0;
    });
  } catch (err) {
    console.log('Fetch Groups Error:', err);
  }
  return groups;
};

export const getTeamsByGroup = async ({ groupID }) => {
  const user = await getUserId();
  if (!user) return null;
  let teams = null;
  try {
    const response = await queryAuthed(getTeamGroup, {
      id: groupID,
    });
    if (response)
      teams = response.data.getTeamGroup.teams.items[0].group.teams.items;
  } catch (err) {
    console.log('Fetch Teams By Groups Error:', err);
  }
  return teams;
};

export const fetchTeamsFromGroup = ({ teamGroups, selectedGroupID }) => {
  const selectedGroup = teamGroups.find((teamGroup) => {
    return teamGroup.id === selectedGroupID;
  });
  const selectedGroupTeams = get(selectedGroup, 'teams.items', []);
  const sortedSharedTeams = selectedGroupTeams.sort((a, b) => {
    return a.team.name > b.team.name ? 1 : -1;
  });
  return sortedSharedTeams;
};

export const getTeamsWithoutGroups = async (token) => {
  const response = await getTeamsByUser({ limit: 1000, token: token });
  const teams = get(response, 'items');
  if (!teams) return {};
  let teamsWithoutGroups = teams.filter((team) => {
    const groups = get(team, 'groups.items', []);
    return groups.length === 0;
  });
  teamsWithoutGroups = teamsWithoutGroups.sort((a, b) => {
    return a.name > b.name ? 1 : -1;
  });
  const nextToken = get(response, 'nextToken');
  return { teamsWithoutGroups, nextToken };
};
