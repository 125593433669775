import React, { createContext } from 'react';
import io from 'socket.io-client';
import { messageReducer, messageState } from '../Helpers/MessageHelper';
import {
  systemStatsReducer,
  systemStatsState,
} from '../Helpers/SystemStatHelper';

let socketUrl = `wss://online.quizbowlsystems.com`;
if (
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_ENV === 'dev'
) {
  socketUrl = `ws://${process.env.REACT_APP_PUBLIC_IP}`;
}
const socket = io(`${socketUrl}:4000`, {
  transports: ['websocket'],
  rejectUnauthorized: false,
});

export const SocketContext = createContext(undefined);
export const { Provider, Consumer: SocketConsumer } = SocketContext;

export const SocketProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(messageReducer, messageState);
  const [systemStats, dispatchStats] = React.useReducer(
    systemStatsReducer,
    systemStatsState,
  );
  const [messageListenerActive, setMessageListenerActive] =
    React.useState(false);
  const { clients } = state;
  React.useEffect(() => {
    window.addEventListener('beforeunload', () => socket.close());
  }, []);

  const joinRoom = (props) => {
    socket.emit('joinRoom', props);
  };
  const leaveRoom = (props) => socket.emit('leaveRoom', props);
  const sendMessage = ({ matchID, type, payload }) =>
    socket.emit('postMessage', { matchID, type, payload });
  const initMessageListener = (cb = () => {}) => {
    socket.on('message', cb);
    setMessageListenerActive(true);
  };
  const removeMessageListener = (cb = () => {}) => {
    socket.off('message', cb);
    setMessageListenerActive(false);
  };

  const sendConnectionTest = () => socket.emit('sendConnectionTest');
  const initConnectionTestListener = (cb = () => {}) => {
    socket.on('connectionTestResponse', cb);
  };
  const removeConnectionTestListener = (cb = () => {}) => {
    socket.off('connectionTestResponse', cb);
  };

  React.useEffect(() => {
    socket.on('systemStat', dispatchStats);
    return () => socket.off('systemStat');
  }, []);

  const value = {
    ...state,
    ...systemStats,
    clients,
    dispatch,
    initConnectionTestListener,
    initMessageListener,
    joinRoom,
    leaveRoom,
    messageListenerActive,
    removeConnectionTestListener,
    removeMessageListener,
    sendConnectionTest,
    sendMessage,
    socket,
  };
  return <Provider value={value}>{children && children}</Provider>;
};

export function useSocket() {
  const context = React.useContext(SocketContext);
  if (context === undefined) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
}
