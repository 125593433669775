import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import HeaderUnauthed from '../../Components/HeaderUnauthed';
import { MatchClientContext } from '../../Hooks/MatchClientContext';

const ChooseTeam = ({ className }) => {
  const history = useHistory();
  const { isFetchingMatch, match, playerLogin } = React.useContext(
    MatchClientContext,
  );
  React.useEffect(() => {
    if (!isFetchingMatch && !match) history.push('/');
  }, [history, isFetchingMatch, match]);
  const [selectedTeamID, setSelectedTeamID] = React.useState('');
  const [selectedPlayerID, setSelectedPlayerID] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  let {
    teams: { items: teamList = [] } = {},
    players: { items: matchPlayerList = [] } = {},
  } = match || {};

  let teamPlayerList = React.useMemo(() => {
    if (!selectedTeamID) return [];
    let result = matchPlayerList.filter(
      ({ player }) => player.teamID === selectedTeamID,
    );
    result = result.sort((a, b) => {
      return a.order - b.order;
    });
    return result.map(({ active, player }) => {
      return {
        ...player,
        isInGame: active,
      };
    });
  }, [matchPlayerList, teamList, selectedTeamID]);

  const onSubmit = () => {
    if (!selectedPlayerID) return;
    setLoading(true);
    playerLogin({ selectedPlayerID });
    history.push('/game');
  };

  return (
    <div className={`sign-in ${className}`}>
      <HeaderUnauthed />
      <label className="team-container">
        Choose Team:
        <select
          className="dropdown"
          onChange={(e) => setSelectedTeamID(e.target.value)}
          value={selectedTeamID}
        >
          <option value={''}>Select a Team</option>
          {teamList.map(({ team: { id, name } = {} }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </select>
      </label>
      <label className="team-container">
        Choose Player:
        <select
          className="dropdown"
          disabled={!selectedTeamID}
          onChange={(e) => setSelectedPlayerID(e.target.value)}
          value={selectedPlayerID}
        >
          <option value={''}>Select a Player</option>
          {teamPlayerList.map(({ id, name, isInGame } = {}) => {
            if (!isInGame) {
              return (
                <option key={id} value={id}>
                  {name}
                </option>
              );
            }
          })}
        </select>
      </label>
      <button
        disabled={!selectedPlayerID || loading}
        className="submit-button"
        onClick={onSubmit}
      >
        {loading ? 'Loading...' : 'Enter Match'}
      </button>
      {/* <Link className="btn-observer" to="/game">
        Click here if you want to observe
      </Link> */}
    </div>
  );
};

const StyledChooseTeam = styled(ChooseTeam)`
  font-size: 2rem;
  line-height: 1.75rem;
  text-align: center;

  .team-container {
    display: block;
    margin: 1.5rem;
    font-size: 1.6rem;
  }

  .dropdown {
    margin-bottom: 0.75rem;
    margin-left: 1rem;
    font-size: 1.6rem;
    max-width: 30rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .submit-button {
    display: block;
    margin: 1.5rem auto;
    font-size: 1.6rem;
  }
`;

export default StyledChooseTeam;
