import React from 'react';
import styled from 'styled-components';
import { ScoreSheetContext } from '../../../../Contexts/ScoreSheetContext';
import {
  getSumTotalPointsByQnum,
  getSumTotalPointsByType,
  getSumTotalPointsByTypeAndQNum,
} from '../../../../Helpers/ScoreSheetHelper';
import { get } from '../../../../Helpers/UtilHelper';

const ScoreSheetTotalPointsSection = ({
  className,
  teamData,
  startingPoints,
}) => {
  const {
    cellList,
    totalNumberQuestions,
    uniquePointValues,
    hostEdits,
  } = React.useContext(ScoreSheetContext);
  const teamId = get(teamData, 'team.id', null);
  const hostEditPoints = hostEdits[teamId] || 0;
  let totalPoints = startingPoints + hostEditPoints;

  const PointsRow = ({
    col1Display = '',
    col2Display = '',
    cellList,
    totalNumberQuestions,
    type,
  }) => {
    let pointTotalCols = [];
    for (let i = 0; i < totalNumberQuestions; i++) {
      // get total points for all points for this type, for this question number
      let points = 0;
      if (type) {
        points = getSumTotalPointsByTypeAndQNum({
          questionNumber: i + 1,
          cellList,
          teamId,
          type,
        });
      } else {
        // get total points for all types for this Qnum
        points = getSumTotalPointsByQnum({
          questionNumber: i + 1,
          cellList,
          teamId,
        });
      }

      pointTotalCols.push(
        <td key={i} className="blue-cell">
          {points}
        </td>,
      );
    }

    const totalBonusPoints = getSumTotalPointsByType({
      cellList,
      teamId,
      type: `bonusPart`,
    });

    return (
      <tr className={className}>
        {type === 'bonusPart' ? (
          <td className="blue-cell">{hostEditPoints}</td>
        ) : (
          <td className="black-cell">{col1Display}</td>
        )}
        <td className="black-cell">{col2Display}</td>
        {pointTotalCols}
        <td></td>
        {uniquePointValues.map((value, index) => {
          return <td key={index}></td>;
        })}
        <td></td>
        {type === 'bonusPart' ? (
          <td className="blue-cell">{totalBonusPoints}</td>
        ) : (
          <td></td>
        )}
        <td></td>
      </tr>
    );
  };

  const RunningTotalRow = ({
    col2Display = '',
    cellList,
    totalNumberQuestions,
  }) => {
    let pointTotalCols = [];
    for (let i = 0; i < totalNumberQuestions; i++) {
      // get total points for all types for this Qnum
      const pointsForQnum = getSumTotalPointsByQnum({
        questionNumber: i + 1,
        cellList,
        teamId,
      });
      totalPoints += pointsForQnum;

      pointTotalCols.push(
        <td key={i} className="blue-cell">
          {totalPoints}
        </td>,
      );
    }

    return (
      <tr className={className}>
        <td className="blue-cell">{startingPoints}</td>
        <td className="black-cell">{col2Display}</td>
        {pointTotalCols}
        <td></td>
        {uniquePointValues.map((value, index) => {
          return <td key={index}></td>;
        })}
        <td></td>
        <td></td>
        <td className="blue-cell">{totalPoints}</td>
      </tr>
    );
  };

  const QNumRow = ({ col2Display = '', totalNumberQuestions }) => {
    let qNumCols = [];
    for (let i = 0; i < totalNumberQuestions; i++) {
      qNumCols.push(
        <td className="black-cell" key={i}>
          {i + 1}
        </td>,
      );
    }

    return (
      <tr className={className}>
        <td></td>
        <td className="black-cell">{col2Display}</td>
        {qNumCols}
        <td></td>
        {uniquePointValues.map((value, index) => {
          return <td key={index}></td>;
        })}
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  };

  return (
    <>
      <PointsRow
        col2Display="Bonus Points"
        cellList={cellList}
        totalNumberQuestions={totalNumberQuestions}
        type="bonusPart"
      />
      <PointsRow
        col1Display="Starting Score"
        col2Display="Question Total"
        cellList={cellList}
        totalNumberQuestions={totalNumberQuestions}
      />
      <RunningTotalRow
        col2Display="Running Total"
        cellList={cellList}
        totalNumberQuestions={totalNumberQuestions}
      />
      <QNumRow
        col2Display="Question"
        totalNumberQuestions={totalNumberQuestions}
      />
    </>
  );
};

const StyledScoreSheetTotalPointsSection = styled(ScoreSheetTotalPointsSection)`
  .blue-cell {
    background-color: #2f75b5;
    color: #fff;
  }
  .black-cell {
    background-color: #000;
    color: #fff;
  }
  && {
    margin: 1rem;
  }
`;

export default StyledScoreSheetTotalPointsSection;
