import Iframe from '@trendmicro/react-iframe';
import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { WindowClose } from '../../Helpers/IconHelper';

const IFrameModal = ({
  className,
  toggleModal = () => {},
  link = 'https://www.quizbowlsystems.com/',
}) => {
  return ReactDOM.createPortal(
    <div className={`modal-container ${className}`}>
      <div className="modal-overlay" onClick={toggleModal}></div>
      <div className="modal">
        <div className="modal-header">
          <button className="modal-close-button" onClick={toggleModal}>
            <WindowClose />
          </button>
        </div>
        <div className="iframe-container">
          <Iframe src={link} width="100%" height="475px" />
        </div>
      </div>
    </div>,
    document.body,
  );
};

const StyledIFrameModal = styled(IFrameModal)`
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    background: white;
    margin: 0 auto;
    border-radius: 3px;
    width: 40rem;
    height: 50rem;
    max-height: 100%;
    padding: 2rem;
    transition: all 0.5s;
  }

  .modal-header {
    display: flex;
    justify-content: flex-end;
  }

  .modal-close-button {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    background-color: white;
    color: steelblue;
    cursor: pointer;
    border: none;
    padding: 0;
    border-radius: 15%;
    border: 0px;
    margin: 0;
    height: 2rem;

    &:hover {
      background-color: steelblue;
      color: white;
    }
  }

  .iframe-container {
    max-height: 475px;
  }

  @media (min-width: 900px) {
    .modal {
      width: 80%;
      max-width: 105rem;
    }
  }
`;

export default StyledIFrameModal;
