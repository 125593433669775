import { fetchTeamsFromGroup } from 'Helpers/Api/Team';
import { deviceIDs } from 'Helpers/DeviceIdHelper';
import { get } from 'Helpers/UtilHelper';
import { useMatchSettings } from 'Hooks/MatchSettingsContext';
import useShareTeams from 'Hooks/useShareTeams';
import React from 'react';
import styled from 'styled-components';

const TeamItem = ({ className, disabledEdits, ownedTeamList, teamNumber }) => {
  const [groupTeamList, setGroupTeamList] = React.useState([]);
  const { teamGroups } = useShareTeams();
  const {
    clearError,
    selectedTeams,
    setSelectedTeamId,
    setSelectedTeamPlayers,
    setSelectedTeamDevices,
    playerCount,
    setSelectedGroupId,
  } = useMatchSettings();
  const selectedTeamId = get(selectedTeams, `[${teamNumber}].teamID`);
  const selectedTeamPlayers = get(selectedTeams, `[${teamNumber}].players`);
  const selectedGroupID = get(selectedTeams, `[${teamNumber}].groupID`);
  const useOwnedTeams = get(selectedTeams, `[${teamNumber}].useOwnedTeams`);

  const teamPlayers = React.useMemo(() => {
    let team = {};
    let players = [];
    if (useOwnedTeams) {
      team = ownedTeamList.find((t) => t.id === selectedTeamId);
      players = get(team, 'players.items', []);
    } else if (!useOwnedTeams && groupTeamList) {
      team = groupTeamList.find((t) => t.team.id === selectedTeamId);
      players = get(team, 'team.players.items', []);
    }
    const sortedPlayersArray = players.sort((a, b) => {
      return a.order - b.order;
    });
    return sortedPlayersArray;
  }, [selectedTeamId, ownedTeamList, groupTeamList, useOwnedTeams, teamGroups]);

  React.useEffect(() => {
    const displayGroupTeams = () => {
      if (selectedGroupID !== 'local') {
        const sharedTeams = fetchTeamsFromGroup({
          teamGroups,
          selectedGroupID,
        });
        setGroupTeamList(sharedTeams);
      }
    };
    displayGroupTeams();
  }, [selectedGroupID, teamGroups]);

  React.useEffect(() => {
    if (teamPlayers.length !== 0 && !disabledEdits) {
      teamPlayers.forEach((player, index) => {
        if (index < playerCount) {
          setSelectedTeamPlayers({ teamNumber, index, playerID: player.id });
          setSelectedTeamDevices({
            teamNumber,
            index,
            deviceID: teamNumber * playerCount + index + 1,
          });
        } else {
          setSelectedTeamPlayers({ teamNumber, index });
          setSelectedTeamDevices({ teamNumber, index });
        }
      });
    }
  }, [disabledEdits, selectedTeamId, playerCount]);

  const groups = teamGroups.map(({ id, name } = {}) => {
    return (
      <option key={id} value={id}>
        {name}
      </option>
    );
  });

  const handlePlayerChange = (event, index) => {
    event.persist();
    setSelectedTeamPlayers({ teamNumber, index, playerID: event.target.value });
    clearError('teamsError');
  };

  const handleDeviceIdChange = (event, index) => {
    setSelectedTeamDevices({ teamNumber, index, deviceID: event.target.value });
    clearError('teamsError');
  };

  const handleTeamGroupChange = (e) => {
    const groupID = e.currentTarget.value;
    setSelectedGroupId({ teamNumber, groupID });
    clearError('teamsError');
  };

  const playersDisplay = [];

  function getKeyByValue(object, value) {
    const key = Object.keys(object).find((key) => object[key] === value);
    if (key !== undefined) {
      return parseInt(key);
    }
    return key;
  }

  const shouldDisablePlayerSelect = () => {
    if (
      useOwnedTeams &&
      (selectedTeamId === '' || disabledEdits || ownedTeamList.length === 0)
    ) {
      return true;
    } else if (
      !useOwnedTeams &&
      (selectedTeamId === '' || disabledEdits || groupTeamList.length === 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  for (let i = 0; i < playerCount; i++) {
    playersDisplay.push(
      <tr key={i}>
        <td className="team__table-cell">
          <span className="select-label">{`Player ${i + 1}`}</span>
        </td>
        <td className="team__table-cell max-width">
          <select
            className="dropdown"
            value={selectedTeamPlayers[i]?.playerID ?? ''}
            onChange={(e) => handlePlayerChange(e, i)}
            disabled={shouldDisablePlayerSelect()}
          >
            <option value={''}>Select a Player</option>
            {teamPlayers.map(({ id, name }) => {
              const selectPlayersIncludesId =
                Object.values(selectedTeamPlayers).includes(id);
              const isIndexDifferent =
                i !== getKeyByValue(selectedTeamPlayers, id);
              if (selectPlayersIncludesId && isIndexDifferent) {
                return null;
              }
              return (
                <option key={id} value={id}>
                  {name}
                </option>
              );
            })}
          </select>
        </td>
        <td className="team__table-cell">
          <select
            className="device-dropdown"
            value={selectedTeamPlayers[i]?.deviceID ?? ''}
            onChange={(e) => handleDeviceIdChange(e, i)}
          >
            {deviceIDs.map((id) => (
              <option key={id} value={id}>
                {id}
              </option>
            ))}
          </select>
        </td>
      </tr>,
    );
  }

  let teams = [];
  if (useOwnedTeams) {
    teams = ownedTeamList.map((team) => {
      return (
        <option key={team.id} value={team.id}>
          {team.name}
        </option>
      );
    });
  } else if (groupTeamList) {
    teams = groupTeamList.map((team) => {
      return (
        <option key={team.team.id} value={team.team.id}>
          {team.team.name}
        </option>
      );
    });
  }

  const handleTeamChange = (e) => {
    setSelectedTeamId({ teamNumber, teamID: e.target.value });
    clearError('teamsError');
  };

  return (
    <div className={`team-item-container ${className}`}>
      <table className="team-table">
        <tbody>
          <tr>
            <td className="team__table-cell">
              <span className="select-label title">Group</span>
            </td>
            <td className="team__table-cell" colSpan={2}>
              <select
                className="dropdown"
                disabled={disabledEdits}
                value={selectedGroupID || ''}
                onChange={handleTeamGroupChange}
              >
                <option value={'local'}>Local Group</option>
                {groups}
              </select>
            </td>
          </tr>
          <tr>
            <td className="team__table-cell border">
              <span className="select-label title">{`Team ${
                teamNumber + 1
              }`}</span>
            </td>
            <td className="team__table-cell border" colSpan={2}>
              <select
                className="dropdown"
                disabled={disabledEdits || teams.length === 0}
                value={selectedTeamId}
                onChange={handleTeamChange}
              >
                <option value={''}>Select a Team</option>
                {teams}
              </select>
            </td>
          </tr>
          <tr>
            <td className="team__table-cell"></td>
            <td className="team__table-cell"></td>
            <td className="device-header">Device</td>
          </tr>
          {playersDisplay}
        </tbody>
      </table>
    </div>
  );
};

const StyledTeamItem = styled(TeamItem)`
  border: 0.5px solid #f5f5f5;
  margin: 0.25rem;

  .team-table {
    width: 100%;
    margin: 0;
  }

  .max-width {
    width: 100%;
  }

  .device-header {
    padding: 5px 1.5rem;
    border: 0;
    font-weight: bold;
  }

  .team__table-cell {
    padding: 10px;
    border: 0;
    white-space: nowrap;
  }

  .select-label {
    font-weight: bold;
  }

  .title {
    font-weight: bold;
    color: steelblue;
  }

  .border {
    border-bottom: 1px solid #e1e1e1;
  }

  .dropdown-container {
    display: flex;
    text-overflow: ellipsis;
  }

  .heading-container {
    display: flex;
    justify-content: end;

    span {
      padding: 0 1.5rem;
    }
  }

  .dropdown {
    flex: 1;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  .device-dropdown {
    flex-grow: 0;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @media (min-width: 900px) {
    border: none;
    margin: 0;
  }
`;

export default StyledTeamItem;
