import React from 'react';
import styled from 'styled-components';
import Loader from '../Loader';

const LoaderCentered = ({ className, width }) => {
  return (
    <div className={`loader-centered ${className}`}>
      <Loader width={width} />
    </div>
  );
};

const StyledLoaderCentered = styled(LoaderCentered)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default StyledLoaderCentered;
