import React from 'react';
import { SortAsc, SortDesc } from './IconHelper';

export const DATE = 'DATE';
export const NAME = 'NAME';
export const ASC = 'ASC';
export const DESC = 'DESC';

// action types
export const TOGGLE_CREATE_MATCH_MODAL = 'TOGGLE_CREATE_MATCH_MODAL';
export const CLEAR_STATE_NEW_SORT = 'CLEAR_STATE_NEW_SORT';
export const SET_MATCH_SORT_STATE = 'SET_MATCH_SORT_STATE';
export const DELETE_MATCH = 'DELETE_MATCH';

export const SUPPORTED_FILE_TYPES = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
  'text/csv', // csv
  'application/vnd.ms-excel', // xls
];

export const sortIconToDisplay = ({
  currentType,
  matchSortType = '',
  matchSortOrder = '',
}) => {
  if (currentType !== matchSortType) {
    return (
      <span
        style={{ display: 'inline-block', width: '16px', height: '16px' }}
      ></span>
    );
  }
  if (matchSortOrder === DESC) {
    return SortDesc();
  }
  if (matchSortOrder === ASC) {
    return SortAsc();
  }
  return '';
};

export const initialState = {
  matchList: [],
  matchListToken: null,
  matchSortType: DATE,
  matchSortOrder: DESC,
  fetchMatchesComplete: false,
  fetchMoreMatchesComplete: true,
  showCreateMatchModal: false,
};

export const dashboardReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case TOGGLE_CREATE_MATCH_MODAL: {
      return {
        ...state,
        showCreateMatchModal: !state.showCreateMatchModal,
      };
    }
    case CLEAR_STATE_NEW_SORT: {
      const {
        fetchMatchesComplete,
        fetchMoreMatchesComplete,
        matchSortOrder,
        matchSortType,
        matchList,
        matchListToken,
      } = payload;
      return {
        ...state,
        ...(matchSortOrder !== undefined && { matchSortOrder }),
        ...(fetchMatchesComplete !== undefined && { fetchMatchesComplete }),
        ...(fetchMoreMatchesComplete !== undefined && {
          fetchMoreMatchesComplete,
        }),
        ...(matchSortType !== undefined && { matchSortType }),
        ...(matchList !== undefined && { matchList }),
        matchListToken,
      };
    }
    case SET_MATCH_SORT_STATE: {
      const {
        fetchMatchesComplete,
        fetchMoreMatchesComplete,
        matchSortType,
        matchList,
        matchListToken,
      } = payload;
      return {
        ...state,
        ...(fetchMatchesComplete !== undefined && { fetchMatchesComplete }),
        ...(fetchMoreMatchesComplete !== undefined && {
          fetchMoreMatchesComplete,
        }),
        ...(matchSortType !== undefined && { matchSortType }),
        ...(matchList !== undefined && { matchList }),
        matchListToken,
      };
    }
    case DELETE_MATCH: {
      const { matchID } = payload;
      return {
        ...state,
        matchList: state.matchList.filter(
          (match) => match && match.id !== matchID,
        ),
      };
    }
    default: {
      return;
    }
  }
};
