import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { testConnection } from '../../Helpers/Api/Match';
import { useSocket } from '../../Hooks/SocketContext';

/*
  ERROR CODES:
  0 - No errors
  1 - Socket & API error
  2 - Socket error
  3 - API error
*/

const XEmoji = () => (
  <span role="img" aria-label="Error">
    ❌
  </span>
);

const CheckEmoji = () => (
  <span role="img" aria-label="Success">
    ✔️
  </span>
);

const getErrorCodeDisplay = (code) => {
  switch (code) {
    case undefined:
      return null;
    case 0:
      return 'Connection successful! If you are still experiencing connection issues, see troubleshooting steps below.';
    case 1:
      return (
        <div>
          <p>There is an issue with your connection.</p>
          <p>
            <XEmoji /> Connection to socket.io server
          </p>
          <p>
            <XEmoji /> Connection to API
          </p>
        </div>
      );
    case 2:
      return (
        <div>
          <p>There is an issue with your connection.</p>
          <p>
            <XEmoji /> Connection to socket.io server
          </p>
          <p>
            <CheckEmoji /> Connection to API
          </p>
        </div>
      );
    case 3:
      return (
        <div>
          <p>There is an issue with your connection.</p>
          <p>
            <CheckEmoji /> Connection to socket.io server
          </p>
          <p>
            <XEmoji /> ️Connection to API
          </p>
        </div>
      );
    default:
      return `Unable to connect. See troubleshooting steps below. [Error code: ${code}]`;
  }
};

const ConnectionTest = ({ className }) => {
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState(undefined);
  const [socketConfirmed, setSocketConfirmed] = useState(false);
  const [socketCheckTimer, setSocketCheckTimer] = useState(null);
  const [socketCheckTimedOut, setSocketCheckTimedOut] = useState(false);
  const [apiConfirmed, setApiConfirmed] = useState(undefined);

  const {
    initConnectionTestListener,
    removeConnectionTestListener,
    sendConnectionTest,
  } = useSocket();

  const socketListener = () => {
    console.log('@@@ SOCKET LISTENER');
    setSocketConfirmed(true);
    setSocketCheckTimer((prev) => {
      if (prev) clearTimeout(prev);
      return null;
    });
  };

  useEffect(() => {
    initConnectionTestListener(socketListener);
    return () => {
      removeConnectionTestListener(socketListener);
    };
  }, []);

  useEffect(() => {
    const calculateResult = () => {
      const setError = (code) => {
        setLoading(false);
        setErrorCode(code);
      };
      if (!socketConfirmed && !apiConfirmed) {
        setError(1);
        return;
      }
      if (!socketConfirmed) {
        setError(2);
        return;
      }
      if (!apiConfirmed) {
        setError(3);
        return;
      }
      setError(0);
    };
    if (apiConfirmed !== undefined && !socketCheckTimer) calculateResult();
  }, [apiConfirmed, socketCheckTimedOut, socketCheckTimer, socketConfirmed]);

  const checkSocketConnection = () => {
    sendConnectionTest();
    const timer = setTimeout(() => {
      setSocketCheckTimedOut(true);
      setSocketCheckTimer(null);
    }, 10000);
    setSocketCheckTimer(timer);
  };

  const onSubmit = async () => {
    setLoading(true);
    setErrorCode(undefined);
    setSocketConfirmed(false);
    setSocketCheckTimer((prev) => {
      if (prev) clearTimeout(prev);
      return null;
    });
    setSocketCheckTimedOut(false);
    setApiConfirmed(undefined);

    // check socket connection
    checkSocketConnection();

    // check API connection
    const items = await testConnection();
    let apiSuccess = true;
    if (!items || !items.length) apiSuccess = false;
    setApiConfirmed(apiSuccess);
  };

  return (
    <div className={className}>
      <div className="container">
        <h1 className="title">
          Having connection issues? Test your connection below:
        </h1>
        <button className="submit-button" disabled={loading} onClick={onSubmit}>
          {loading ? 'Checking your connection...' : 'Test Connection'}
        </button>
        <div className="result">{getErrorCodeDisplay(errorCode)}</div>
        <h2 className="troubleshooting-header">Troubleshooting Steps:</h2>
        <ol className="troubleshooting-list">
          <li>Refresh your browser window.</li>
          <li>
            Use the Google Chrome browser. Chrome works best with the
            application.
          </li>
          <li>Temporarily disable any browser ad blockers.</li>
          <li>
            If the above doesn't work, temporarily disable any Antivirus or
            Firewall protections.
          </li>
          <li>
            Test on another device, preferably one not connected to a
            school/work network to isolate the issue. If this other device
            works, it may be a proxy or VPN connection issue.
          </li>
          <li>
            If there are any proxy or VPN connections in place, please ask your
            IT manager to allow websockets via:
            wss://online.quizbowlsystems.com:4000
          </li>
        </ol>
      </div>
    </div>
  );
};

const StyledConnectionTest = styled(ConnectionTest)`
  font-size: 2rem;
  line-height: 1.75rem;
  text-align: center;

  .title {
    display: block;
    margin: 1.5rem;
    font-size: 3rem;
  }

  .submit-button {
    font-size: 2rem;
    margin-top: 2rem;
  }

  .result {
    margin-top: 2rem;
    line-height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0rem;
  }

  .result div {
    text-align: left;
  }

  .troubleshooting-header {
    margin-top: 5rem;
    font-size: 2.5rem;
  }

  .troubleshooting-list {
    max-width: 60rem;
    margin: 0 auto;

    li {
      font-size: 1.75rem;
      text-align: left;
      margin-bottom: 2rem;
      line-height: 2.5rem;
    }
  }
`;

export default StyledConnectionTest;
