import React from 'react';
import styled from 'styled-components';
import IconButton from '../../../../../../Components/IconButton';
import { Trash } from '../../../../../../Helpers/IconHelper';

const EditPlayerItem = ({
  className,
  player,
  index,
  key,
  deleteDisabled,
  handleEditPlayer,
  handleRemovePlayer,
  handleRemovePlayerError,
}) => {
  const { id, name } = player || {};
  return (
    <div className={`${className} input-container`} key={key}>
      <label className="label-container">
        <input
          type="text"
          className="input-name"
          value={name}
          onChange={(e) => handleEditPlayer(e.target.value, id)}
          onKeyDown={handleRemovePlayerError}
          maxLength="60"
        />
        <IconButton
          onClick={() => handleRemovePlayer(id)}
          disabled={deleteDisabled}
          Icon={Trash}
        />
      </label>
    </div>
  );
};

const StyledEditPlayerItem = styled(EditPlayerItem)`

  .input-container {
    width: 100%;
  }

  .input-name {
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;
    flex: 1;
    text-overflow: ellipsis;
  }

  .text-label {
    margin: 0 1rem;
  }

  .label-container {
    display: flex;
    flex: 1;
    align-items: center;
    width: 95%;
    margin-left: 5%;
  }

  @media (min-width: 900px) {
    .input-container {
      width: 65%;
      margin 0 auto;
    }

    .input-name {
      width: 100%;
    }
  }
`;

export default StyledEditPlayerItem;
