import React from 'react';
import styled from 'styled-components';
import SettingsHeader from '../../../../Components/SettingsHeader';
import { MatchSettingsContext } from '../../../../Hooks/MatchSettingsContext';
import RingInSettingItem from './Components/RingInSettingItem';
import SettingItem from './Components/SettingItem';

const Sounds = ({ className, disabledEdits = false }) => {
  const {
    setMatchSetting,
    soundRoundTimerEnabled,
    soundRingInEnabled,
    soundRingInTimerEnabled,
    soundTeamDeliberationTimerEnabled,
    soundTimeoutTimerEnabled,
    soundBonusDeliberationTimerEnabled,
    soundBonusAnswerTimerEnabled,
    soundTossUpAnswerEnabled,
    playerTimersEnabled,
  } = React.useContext(MatchSettingsContext);

  const handleRoundTimerChange = (e) => {
    setMatchSetting({ payload: { soundRoundTimerEnabled: e.target.value } });
  };

  const handleRingInChange = (e) => {
    setMatchSetting({ payload: { soundRingInEnabled: e.target.value } });
  };

  const handleRingInTimerChange = (e) => {
    setMatchSetting({ payload: { soundRingInTimerEnabled: e.target.value } });
  };

  const handleTeamDeliberationTimerChange = (e) => {
    setMatchSetting({
      payload: { soundTeamDeliberationTimerEnabled: e.target.value },
    });
  };

  const handleBonusDeliberationTimerChange = (e) => {
    setMatchSetting({
      payload: { soundBonusDeliberationTimerEnabled: e.target.value },
    });
  };

  const handleBonusAnswerTimerChange = (e) => {
    setMatchSetting({
      payload: { soundBonusAnswerTimerEnabled: e.target.value },
    });
  };

  const handleTossUpAnswerTimerChange = (e) => {
    setMatchSetting({
      payload: { soundTossUpAnswerEnabled: e.target.value },
    });
  };

  const handlePlayerTimersEnabledChange = (e) => {
    setMatchSetting({
      payload: { playerTimersEnabled: e.target.value },
    });
  };

  const handleTimeoutTimerEnabledChange = (e) => {
    setMatchSetting({
      payload: { soundTimeoutTimerEnabled: e.target.value },
    });
  }

  return (
    <div className={`settings-sounds-container ${className}`}>
      <SettingsHeader text="Sounds and Display Options" />
      <div className="settings-options-container">
        <SettingItem
          disabledEdits={disabledEdits}
          text="Play Round Timer Expiration Sound"
          onChange={handleRoundTimerChange}
          value={soundRoundTimerEnabled}
        />
        <RingInSettingItem
          disabledEdits={disabledEdits}
          text="Play Ring-In Sound"
          onChange={handleRingInChange}
          value={soundRingInEnabled}
        />
        <SettingItem
          disabledEdits={disabledEdits}
          text="Play Ring-In Timer Sound"
          onChange={handleRingInTimerChange}
          value={soundRingInTimerEnabled}
        />
        <SettingItem
          disabledEdits={disabledEdits}
          text="Play Toss-Up Answer Expiration Sound"
          onChange={handleTossUpAnswerTimerChange}
          value={soundTossUpAnswerEnabled || 'yes'}
        />
        <SettingItem
          disabledEdits={disabledEdits}
          text="Play Team Deliberation Expiration Sound"
          onChange={handleTeamDeliberationTimerChange}
          value={soundTeamDeliberationTimerEnabled}
        />
        <SettingItem
          disabledEdits={disabledEdits}
          text="Play Bonus Deliberation Expiration Sound"
          onChange={handleBonusDeliberationTimerChange}
          value={soundBonusDeliberationTimerEnabled}
        />
        <SettingItem
          disabledEdits={disabledEdits}
          text="Play Bonus Answer Expiration Sound"
          onChange={handleBonusAnswerTimerChange}
          value={soundBonusAnswerTimerEnabled}
        />
        <SettingItem
          disabledEdits={disabledEdits}
          text="Play Timeout Expiration Sound"
          onChange={handleTimeoutTimerEnabledChange}
          value={soundTimeoutTimerEnabled || 'no'}
        />
        <SettingItem
          disabledEdits={disabledEdits}
          text="Display Timer(s) on Player Screens?"
          onChange={handlePlayerTimersEnabledChange}
          value={playerTimersEnabled || 'yes'}
        />
      </div>
    </div>
  );
};

const StyledSounds = styled(Sounds)`
  .settings-options-container {
    display: flex;
    flex-direction: column;
  }
`;

export default StyledSounds;
