import React from 'react';
import styled from 'styled-components';
import IconButton from '../../../../../../Components/IconButton';
import StyledModal from '../../../../../../Components/Modal';
import { updateMatchTemplate } from '../../../../../../Helpers/Api/Match';
import { Edit, Trash, ViewIcon } from '../../../../../../Helpers/IconHelper';
import { get } from '../../../../../../Helpers/UtilHelper';
import { AuthContext } from '../../../../../../Hooks/AuthContext';
import { MatchSettingsContext } from '../../../../../../Hooks/MatchSettingsContext';
import { MatchTemplateContext } from '../../../../../../Hooks/MatchTemplateContext';
import EditMatchTemplateModal from '../EditMatchTemplateModal';
import ViewMatchTemplateModal from '../ViewMatchTemplateModal';

const MatchTemplateItem = ({ className, template }) => {
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showViewModal, setShowViewModal] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { handleResetState } = React.useContext(MatchSettingsContext);
  const { isAdmin } = React.useContext(AuthContext);
  const { fetchMatchTemplates, setMatchTemplateSettings } = React.useContext(
    MatchTemplateContext,
  );

  const templateId = get(template, 'id');
  const templateName = get(template, 'templateName', 'n/a');
  const templateType = get(template, 'templateType');
  const templateUser = get(template, 'user');

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const toggleViewModal = (value = false) => {
    if (value) {
      setShowViewModal((prev) => !prev);
      setMatchTemplateSettings(templateId);
    } else {
      handleResetState();
    }
    setShowViewModal(value);
  };

  const toggleEditModal = (value = false) => {
    if (value) {
      setShowEditModal((prev) => !prev);
      setMatchTemplateSettings(templateId);
    } else {
      handleResetState();
    }
    setShowEditModal(value);
  };

  const handleDeleteTemplate = async () => {
    setIsSubmitting(true);
    try {
      const updatedTemplate = await updateMatchTemplate({
        id: templateId,
        templateType,
        user: templateUser,
        propsToUpdate: { active: 0 },
      });
      if (updatedTemplate) {
        fetchMatchTemplates();
        toggleDeleteModal();
        setIsSubmitting(false);
      }
    } catch (err) {
      toggleDeleteModal();
      setIsSubmitting(false);
      console.log(`Delete Match Template Error: ${err}`);
    }
  };

  return (
    <tr className={`matchitem-container ${className}`} key={templateId}>
      {(isAdmin && templateType === 'public') || templateType === 'private' ? (
        <td>
          <button className="edit-button" onClick={() => toggleEditModal(true)}>
            {templateName}
          </button>
        </td>
      ) : (
        <td>{templateName}</td>
      )}
      <td>
        {(isAdmin && templateType === 'public') ||
        templateType === 'private' ? (
          <div>
            <IconButton Icon={Edit} onClick={() => toggleEditModal(true)} />
            <IconButton Icon={Trash} onClick={toggleDeleteModal} />
          </div>
        ) : (
          <IconButton Icon={ViewIcon} onClick={toggleViewModal} />
        )}
        {showEditModal && (
          <EditMatchTemplateModal
            toggleEditMatchTemplateModal={toggleEditModal}
            id={templateId}
            templateUser={templateUser}
          />
        )}
        {showViewModal && (
          <ViewMatchTemplateModal
            toggleViewMatchTemplateModal={toggleViewModal}
          />
        )}
        {showDeleteModal && (
          <StyledModal
            confirmAction={handleDeleteTemplate}
            confirmLoading={isSubmitting}
            toggleModal={toggleDeleteModal}
            text="Are you sure you want to delete this template?"
          />
        )}
      </td>
    </tr>
  );
};
const StyledMatchTemplateItem = styled(MatchTemplateItem)`
  line-height: 3.75rem;

  .match-state {
    text-transform: capitalize;
  }

  .edit-button {
    color: #1EAEDB;
    width: auto;
    height: auto;
    background-color: #fff;
    padding: 0;
    margin: 0;
    border 0px;
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: center;
    font-weight: 400;
    text-transform: none;
    text-decoration: underline;
    white-space: pre-line;

    &:hover {
      color: #0FA0CE;
    }
  }
`;

export default StyledMatchTemplateItem;
