import React from 'react';
import styled from 'styled-components';
import { getQuestionNumberFromScores } from '../../../../Helpers/MatchHelper';

const QuestionNumberInput = ({
  activeQuestionNumber,
  className,
  disableQuestionNumberInput,
  isReplayingQuestion,
  questionNumberInputDisplay,
  scoreList,
  setDisableQuestionNumberInput,
  setNextQuestionNumberToPlay,
  setReplayQuestionNumber,
  setQuestionNumberInputDisplay,
}) => {
  const handleKeyDownNewQuestionNumber = (e) => {
    const keycode = e.keyCode;
    switch (keycode) {
      case 13: {
        setDisableQuestionNumberInput(true);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleReplayQuestionNumberChange = (e) => {
    const eNumber = parseInt(e.target.value) || '';
    if ((eNumber <= activeQuestionNumber && eNumber > 0) || eNumber === '') {
      const questionNumberFromScores = getQuestionNumberFromScores(scoreList);
      setNextQuestionNumberToPlay(questionNumberFromScores);
      setQuestionNumberInputDisplay(eNumber);
      setReplayQuestionNumber(eNumber);
      return;
    }
    e.preventDefault();
  };

  const handleNumberInputOnBlur = () => {
    setDisableQuestionNumberInput(true);
  };

  return (
    <>
      <label
        className="question-label"
        key="question-number-input-text"
        htmlFor="question-number-input-value"
      >
        Question:
      </label>
      <input
        disabled={isReplayingQuestion}
        readOnly={disableQuestionNumberInput}
        type="number"
        value={questionNumberInputDisplay || ''}
        onClick={() => {
          setDisableQuestionNumberInput(false);
        }}
        onChange={handleReplayQuestionNumberChange}
        onKeyDown={handleKeyDownNewQuestionNumber}
        onBlur={handleNumberInputOnBlur}
        className={`question-number-input ${className}`}
        title="Enter Question Number"
        name="question-number-input-value"
        min="1"
        max={activeQuestionNumber}
      ></input>
    </>
  );
};

const StyledQuestionNumberInput = styled(QuestionNumberInput)`
   && {
    width: 6rem;
    font-size: 2rem;
    text-align: center;
    height: 3.8rem;
    font-weight: bold;
    -moz-appearance: textfield;

    &:read-only {
      background-color: #eee;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:hover {
      border: 1px solid #000;
    }
    .question-number-input {
      width: 6rem;
    }
   } 
  }
`;

export default StyledQuestionNumberInput;
