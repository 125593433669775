import React from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import {
  MESSAGE_TYPE_PAUSE_ROUND_TIMER,
  MESSAGE_TYPE_PLAY_ROUND_TIMER,
  TIMER_STATE_HIDDEN,
  TIMER_STATE_PAUSED,
  TIMER_STATE_RESET,
  TIMER_STATE_RESET_AND_START,
  TIMER_STATE_RUNNING,
  TIMER_STATE_VISIBLE,
} from '../../Helpers/MessageHelper';
import { parseMinutesAndSeconds } from '../../Helpers/UtilHelper';
import { SocketContext } from '../../Hooks/SocketContext';
import {
  EDIT_TIMER,
  PAUSE_TIMER,
  RESET_TIMER,
  SET_CURRENT_SECONDS_LEFT,
  SET_TIMER_ENABLED,
  START_TIMER,
  TimerContext,
  TIMER_EXPIRED,
} from '../../Hooks/TimerContext';
import EditTimerModal from '../EditTimerModal';

export const getCurrentTime = () => Math.floor(new Date().getTime() / 1000);

const formatTimer = (durationInSeconds) => {
  if (durationInSeconds !== 0 && !durationInSeconds) return null;
  const { minutes, seconds } = parseMinutesAndSeconds(durationInSeconds);
  let secondsDisplay = seconds;
  let minutesDisplay = minutes;
  if (seconds === 0) {
    secondsDisplay = '00';
  } else if (seconds < 10) {
    secondsDisplay = `0${seconds}`;
  }
  if (minutes === 0) {
    minutesDisplay = '';
    if (seconds === 0) {
      secondsDisplay = '0';
    } else if (seconds < 10) {
      secondsDisplay = `${seconds}`;
    }
  } else {
    minutesDisplay = `${minutes}:`;
  }
  return `${minutesDisplay}${secondsDisplay}`;
};

export const ROUND_TIMER_COOKIE = 'ROUND_TIMER_COOKIE';
export const TIMER_TYPE_ROUND = 'TIMER_TYPE_ROUND';
export const TIMER_TYPE_RING_IN = 'TIMER_TYPE_RING_IN';
export const TIMER_TYPE_TEAM_DELIBERATION = 'TIMER_TYPE_TEAM_DELIBERATION';
export const TIMER_TYPE_BONUS_DELIBERATION = 'TIMER_TYPE_BONUS_DELIBERATION';
export const TIMER_TYPE_BONUS_ANSWER = 'TIMER_TYPE_BONUS_ANSWER';
export const TIMER_TYPE_TIMEOUT = 'TIMER_TYPE_TIMEOUT';
export const TIMER_TYPE_TOSS_UP_ANSWER = 'TIMER_TYPE_TOSS_UP_ANSWER';

const Timer = ({
  className,
  duration = 0,
  durationStored = 0,
  enabled = false,
  label,
  matchID,
  onEnd = () => {},
  resetDuration = 0,
  shouldPlaySound = false,
  sound,
  timerState,
  type,
  timerComplete = false,
  setRoundTimerExpired,
  preserveMinTimeEnabled = false,
  preserveMinTimeSeconds = 0,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const {
    stateTimerRound,
    dispatchTimerRound,
    stateTimerRingIn,
    dispatchTimerRingIn,
    stateTimerTeamDeliberation,
    dispatchTimerTeamDeliberation,
    stateTimerBonusDeliberation,
    dispatchTimerBonusDeliberation,
    stateTimerBonusAnswer,
    dispatchTimerBonusAnswer,
    stateTimerTimeout,
    dispatchTimerTimeout,
    stateTimerTossUpAnswer,
    dispatchTimerTossUpAnswer,
  } = React.useContext(TimerContext);
  const isRoundTimer = React.useMemo(() => type === TIMER_TYPE_ROUND, [type]);
  const [showEditTimerModal, setShowEditTimerModal] = React.useState(false);
  const { sendMessage } = React.useContext(SocketContext);
  let state;
  let dispatch;

  switch (type) {
    case TIMER_TYPE_ROUND: {
      state = stateTimerRound;
      dispatch = dispatchTimerRound;
      break;
    }
    case TIMER_TYPE_RING_IN: {
      state = stateTimerRingIn;
      dispatch = dispatchTimerRingIn;
      break;
    }
    case TIMER_TYPE_TEAM_DELIBERATION: {
      state = stateTimerTeamDeliberation;
      dispatch = dispatchTimerTeamDeliberation;
      break;
    }
    case TIMER_TYPE_BONUS_DELIBERATION: {
      state = stateTimerBonusDeliberation;
      dispatch = dispatchTimerBonusDeliberation;
      break;
    }
    case TIMER_TYPE_BONUS_ANSWER: {
      state = stateTimerBonusAnswer;
      dispatch = dispatchTimerBonusAnswer;
      break;
    }
    case TIMER_TYPE_TIMEOUT: {
      state = stateTimerTimeout;
      dispatch = dispatchTimerTimeout;
      break;
    }
    case TIMER_TYPE_TOSS_UP_ANSWER: {
      state = stateTimerTossUpAnswer;
      dispatch = dispatchTimerTossUpAnswer;
      break;
    }
    default: {
      console.log(`No timer type ${type} is available.`);
    }
  }

  const {
    currentSecondsLeft,
    timerEnabled,
    timerExpiration,
    timerExpired,
    timerRunning,
    timerSecondsLeft,
  } = state;

  const toggleTimerModal = () => {
    if (timerRunning) handlePauseTimer();
    setShowEditTimerModal((prev) => !prev);
  };

  React.useEffect(() => {
    // update timer based on timerState prop passed from parent (external timer controls)
    // TO DO: Currently, this useEffect doesn't support more than 2 teams if multiple teams ring-in. Need to update this to include a flag that we can check against if multiple teams are playing.
    const handleUpdate = () => {
      switch (timerState) {
        case TIMER_STATE_HIDDEN: {
          const newTimerSecondsLeft =
            type === TIMER_TYPE_TIMEOUT ? null : duration;
          dispatch({
            type: PAUSE_TIMER,
            payload: { timerSecondsLeft: newTimerSecondsLeft },
          });
          dispatch({
            type: SET_TIMER_ENABLED,
            payload: { duration, timerEnabled: false },
          });
          return;
        }
        case TIMER_STATE_PAUSED: {
          dispatch({
            type: PAUSE_TIMER,
            payload: {
              timerSecondsLeft: currentSecondsLeft || 0,
            },
          });
          return;
        }
        case TIMER_STATE_RESET_AND_START: {
          // reset timer after incorrect answer with new duration
          const currentTime = getCurrentTime();
          let newExpiration =
            currentTime + (resetDuration || timerSecondsLeft);
          if (type === TIMER_TYPE_RING_IN && preserveMinTimeEnabled) {
            newExpiration = currentSecondsLeft > preserveMinTimeSeconds ? currentTime + currentSecondsLeft : currentTime + preserveMinTimeSeconds
          }
          dispatch({
            type: START_TIMER,
            payload: { timerExpiration: newExpiration },
          });
          return;
        }
        case TIMER_STATE_RESET: {
          dispatch({
            type: RESET_TIMER,
            payload: { timerSecondsLeft: resetDuration || duration },
          });
          return;
        }
        case TIMER_STATE_RUNNING: {
          const currentTime = getCurrentTime();
          const newExpiration = currentTime + (timerSecondsLeft || duration);
          dispatch({
            type: START_TIMER,
            payload: {
              timerExpiration: newExpiration,
            },
          });
          return;
        }
        case TIMER_STATE_VISIBLE: {
          dispatch({
            type: SET_TIMER_ENABLED,
            payload: {
              duration: durationStored || duration,
              timerEnabled: true,
            },
          });
          return;
        }
        default: {
          console.log(`${timerState} is not a supported timerState`);
        }
      }
    };
    if (enabled) handleUpdate();
  }, [timerState, duration]);

  const setCurrentSecondsLeft = (value) => {
    dispatch({
      type: SET_CURRENT_SECONDS_LEFT,
      payload: {
        currentSecondsLeft: value,
      },
    });
  };

  const saveTimerToCookie = (secondsLeft) => {
    // skip if not the round timer
    if (!isRoundTimer) return;
    // save timer value every 10 seconds, so we return if not divisible by 10
    if (secondsLeft % 10 !== 0) return;
    // save the cookie
    const newCookieValue = { matchID, secondsLeft };
    setCookie(ROUND_TIMER_COOKIE, newCookieValue, { path: '/' });
  };

  const handleTimerEnd = () => {
    let secondsToRefresh = duration;
    if (type === TIMER_TYPE_RING_IN && preserveMinTimeEnabled) secondsToRefresh = preserveMinTimeSeconds;
    const newDuration = type === TIMER_TYPE_TIMEOUT ? null : secondsToRefresh;
    dispatch({ type: TIMER_EXPIRED, payload: { duration: newDuration } });
    onEnd();
    if (isRoundTimer) {
      removeCookie(ROUND_TIMER_COOKIE, { path: '/' });
      setRoundTimerExpired(true);
    }
    if (shouldPlaySound && sound) sound.play();
  };

  const handlePauseTimer = () => {
    sendMessage({ matchID, type: MESSAGE_TYPE_PAUSE_ROUND_TIMER });
  };

  React.useEffect(() => {
    // update timer based on local Timer state management (core timer logic)
    let newTimerInterval;
    if (timerRunning) {
      // handle timer started (500ms interval for higher accuracy):
      const onTimerInterval = () => {
        const currentTime = getCurrentTime();
        const newSecondsLeft = timerExpiration - currentTime;
        if (newSecondsLeft <= 0 && !null) {
          if (newTimerInterval) clearInterval(newTimerInterval); // clear interval to stop negative countdown
          handleTimerEnd();
          return;
        }
        saveTimerToCookie(newSecondsLeft);
        setCurrentSecondsLeft(newSecondsLeft);
      };
      onTimerInterval();
      newTimerInterval = setInterval(onTimerInterval, 500);
    } else {
      // handle timer paused:
      setCurrentSecondsLeft(timerSecondsLeft);
    }
    return () => {
      if (newTimerInterval) clearInterval(newTimerInterval);
    };
    // restart interval if shouldPlaySound or timerExpiration change
  }, [shouldPlaySound, timerExpiration, timerRunning]);

  let timerDisplay = formatTimer(0);
  if (!timerExpired) {
    const renderActiveTimer = timerRunning && currentSecondsLeft !== null;
    timerDisplay = renderActiveTimer
      ? formatTimer(currentSecondsLeft)
      : formatTimer(timerSecondsLeft);
  }
  if (timerComplete) {
    timerDisplay = formatTimer(0);
  }

  const handleEditTimerSubmit = (totalSecondsLeft) => {
    if (totalSecondsLeft === 0) {
      dispatch({ type: TIMER_EXPIRED, payload: { duration } });
      if (isRoundTimer) setRoundTimerExpired(true);
      return;
    }
    if (isRoundTimer) setRoundTimerExpired(false);
    const currentTime = getCurrentTime();
    const newExpiration = currentTime + totalSecondsLeft;
    dispatch({
      type: EDIT_TIMER,
      payload: {
        timerSecondsLeft: totalSecondsLeft,
        timerExpiration: newExpiration,
      },
    });
  };

  return timerEnabled ? (
    <div className={className}>
      <div className="timer">
        <div className="round-timer">{label || 'Timer'}</div>
        <div className="timer-count">
          <div className="timer-display">{timerDisplay}</div>
          {isRoundTimer && (
            <div>
              {timerRunning ? (
                <button
                  className="timer-button"
                  onClick={handlePauseTimer}
                  title="Pause the timer."
                >
                  Pause
                </button>
              ) : (
                <button
                  className="timer-button"
                  onClick={() => {
                    setRoundTimerExpired(false);
                    sendMessage({
                      matchID,
                      type: MESSAGE_TYPE_PLAY_ROUND_TIMER,
                    });
                  }}
                  title="Play the timer."
                >
                  Play
                </button>
              )}
              <button className="timer-button" onClick={toggleTimerModal}>
                Edit
              </button>
            </div>
          )}
        </div>
        {showEditTimerModal && (
          <EditTimerModal
            currentTime={timerSecondsLeft}
            toggleModal={toggleTimerModal}
            handleEditTimerSubmit={handleEditTimerSubmit}
            timerHeading={'Edit Round Timer'}
          />
        )}
      </div>
    </div>
  ) : null;
};

const StyledTimer = styled(Timer)`
  font-weight: bold;
  max-width: 300px;
  margin: auto;

  .timer {
    border: 1px solid black;
  }
  .round-timer {
    font-size: 1.5rem;
    border-bottom: 1px solid black;
    padding: 0.25rem 0.5rem;
  }
  .timer-count {
    display: flex;
    margin: 1rem;
    text-rendering: optimizeSpeed;
    font-feature-settings: 'tnum';
    font-variant-numeric: tabular-nums;
  }
  .timer-display {
    height: fit-content;
    margin: auto;
  }
  .enable {
    display: inline;
  }
  .disable {
    display: none;
  }
  .timer-button {
    padding: 0 0.5rem;
    margin: 0.25rem;

    &:disabled:hover {
      background-color: steelblue;
      color: #fff;
    }
  }

  @media (min-width: 900px) {
    .timer-count {
      font-size: 4rem;
      margin: 1.5rem;
    }
  }
`;

export default StyledTimer;
