import {
  getMatch,
  listMatchs,
  listMatchsTest,
  matchesByDate,
  matchesByName,
  matchesByUser,
} from 'graphql/custom/queries';
import {
  onUpdateMatch,
  onUpdateMatchPlayer,
  onUpdateMatchTeam,
} from 'graphql/custom/subscriptions';
import { updateMatch as updateMatchMutation } from '../../graphql/custom/mutations';
import {
  createMatch as createMatchMutation,
  createMatchPlayer as createMatchPlayerMutation,
  createMatchSettings as createMatchSettingsMutation,
  createMatchTeam as createMatchTeamMutation,
  createMatchTemplate as createMatchTemplateMutation,
  updateMatchPlayer as updateMatchPlayerMutation,
  updateMatchSettings as updateMatchSettingsMutation,
  updateMatchTeam as updateMatchTeamMutation,
  updateMatchTemplate as updateMatchTemplateMutation,
} from '../../graphql/mutations';
import { activeTemplatesByType } from '../../graphql/queries';
import { getUserId } from '../AuthHelper';
import { get, offsetDateByTimezone } from '../UtilHelper';
import { mutate, query, queryAuthed, subscribe } from './index';

export const ROOM_CODE_LENGTH = 6;
export const MATCH_COMPLETED = 'completed';
export const MATCH_STARTED = 'started';
export const MATCH_NEW = 'new';
export const DESC = 'DESC';
export const ASC = 'ASC';

/**
 * @param {String} matchID
 */
export const fetchMatch = async ({ matchID }) => {
  let match = null;
  try {
    const response = await query(getMatch, { id: matchID });
    if (response) match = response.data.getMatch;
  } catch (err) {
    console.log('Fetch Match Error', err);
  }
  return match;
};

export const fetchMatchesByUserId = async ({ token } = {}) => {
  const userId = await getUserId();
  if (!userId) return;
  let matches = null;
  try {
    const response = await queryAuthed(matchesByUser, {
      activeUpdatedAt: { beginsWith: { active: 1 } },
      user: userId,
      nextToken: token,
      sortDirection: 'DESC',
    });
    if (response) matches = response.data.matchesByUser;
  } catch (err) {
    console.log('Fetch Matches By UserID Error:', err);
  }
  return matches;
};

export const fetchMatchesByDateOrder = async ({
  token,
  sortOrder = DESC,
} = {}) => {
  const userId = await getUserId();
  if (!userId) return;
  let matches = null;
  try {
    const response = await queryAuthed(matchesByDate, {
      activeMatchDate: { beginsWith: { active: 1 } },
      user: userId,
      nextToken: token,
      sortDirection: sortOrder,
    });
    if (response) matches = response.data.matchesByDate;
  } catch (err) {
    console.log('Fetch Matches By Dates Error:', err);
  }
  return matches;
};

export const fetchMatchesByNameOrder = async ({
  token,
  sortOrder = DESC,
} = {}) => {
  const userId = await getUserId();
  if (!userId) return;
  let matches = null;
  try {
    const response = await queryAuthed(matchesByName, {
      activeMatchName: { beginsWith: { active: 1 } },
      user: userId,
      nextToken: token,
      sortDirection: sortOrder,
    });
    if (response) matches = response.data.matchesByName;
  } catch (err) {
    console.log('Fetch Matches By Dates Error:', err);
  }
  return matches;
};

export const listMatches = async () => {
  let matches = null;
  try {
    const response = await query(listMatchs);
    if (response) matches = response.data.listMatchs.items;
  } catch (err) {
    console.log('List Matches Error:', err);
  }
  return matches;
};

export const testConnection = async () => {
  let matches = null;
  try {
    const response = await query(listMatchsTest);
    if (response) matches = get(response, 'data.listMatchs.items');
  } catch (err) {
    console.log('Test Connection Error', err);
  }
  return matches;
};

export const generateMatchID = () => {
  let result = '';
  const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < ROOM_CODE_LENGTH; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const getTeamIDs = (selectedTeams) => {
  return Object.values(selectedTeams).map((team) => get(team, 'teamID'));
};

const getPlayerObjects = (selectedTeams) => {
  return Object.values(selectedTeams).reduce((allPlayers, team) => {
    let newPlayers = Object.entries(get(team, 'players')).map(
      ([key, { matchPlayerID, playerID, deviceID, active }]) => {
        return {
          order: parseInt(key, 10) + 1,
          playerID,
          deviceID,
          matchPlayerID,
          active,
        };
      },
    );
    return [...allPlayers, ...newPlayers];
  }, []);
};

export const createMatch = async ({ settings, selectedTeams = {} } = {}) => {
  let match = null;
  try {
    const user = await getUserId();
    if (!user) throw new Error('No Current User');

    let newID = generateMatchID();
    const matchExists = fetchMatch({ matchID: newID });
    if (matchExists) newID = generateMatchID();

    const { matchDate, matchName } = settings || {};

    const response = await mutate(createMatchMutation, {
      id: newID,
      active: 1,
      matchDate: offsetDateByTimezone(new Date(matchDate)).toISOString(),
      matchName,
      matchStatus: MATCH_NEW,
      updatedAt: new Date().toISOString(),
      user,
    });
    if (response) {
      match = response.data.createMatch;
      const teamIDs = getTeamIDs(selectedTeams);
      const playerObjects = getPlayerObjects(selectedTeams);
      const matchTeamPromises = teamIDs.map((teamID, index) => {
        return createMatchTeam({
          matchID: newID,
          user,
          order: index + 1,
          teamID,
        });
      });
      const matchPlayerPromises = playerObjects.map(
        ({ playerID, deviceID, order }) => {
          return createMatchPlayer({
            matchID: newID,
            user,
            playerID,
            deviceID,
            order,
          });
        },
      );
      const promises = [
        createMatchSettings({ matchID: newID, settings, user }),
        ...matchTeamPromises,
        ...matchPlayerPromises,
      ];
      await Promise.all(promises);
    }
  } catch (err) {
    console.log('Create Match Error:', err);
  }
  return match;
};

export const editMatch = async ({
  matchID,
  matchDate,
  matchName,
  matchStatus,
  settings,
  selectedTeams,
}) => {
  const match = await updateMatch({
    matchID,
    matchDate,
    matchName,
    matchStatus,
    settings,
  });

  const playerObjects = getPlayerObjects(selectedTeams);

  const matchPlayerPromises = playerObjects.map(
    ({ matchPlayerID, playerID, deviceID, active }) => {
      return updateMatchPlayer({
        input: {
          id: matchPlayerID,
          matchID,
          playerID,
          deviceID,
          active,
        },
      });
    },
  );

  await Promise.all(matchPlayerPromises);

  return { match };
};

export const createMatchTeam = async ({ matchID, teamID, order, user }) => {
  let matchTeam = null;
  try {
    const response = await mutate(createMatchTeamMutation, {
      active: 1,
      matchID,
      teamID,
      order,
      user,
    });
    if (response) {
      matchTeam = response.data.createMatchTeam;
    }
  } catch (err) {
    console.log(`Create MatchTeam Error: ${JSON.stringify(err)}`);
  }
  return matchTeam;
};

export const updateMatchTeam = async ({ input }) => {
  let matchTeam = null;
  try {
    const user = await getUserId();
    if (!user) throw new Error('No Current User');
    const response = await mutate(updateMatchTeamMutation, {
      ...input,
      user,
    });
    if (response) {
      matchTeam = response.data.updateMatchTeam;
    }
  } catch (err) {
    console.log(`Update MatchTeam Error: ${JSON.stringify(err)}`);
  }
  return matchTeam;
};

export const createMatchPlayer = async ({
  matchID,
  playerID,
  deviceID,
  user,
  order,
}) => {
  let matchPlayer = null;
  try {
    const response = await mutate(createMatchPlayerMutation, {
      active: 0,
      matchID,
      playerID,
      deviceID,
      user,
      order,
    });
    if (response) {
      matchPlayer = response.data.createMatchPlayer;
    }
  } catch (err) {
    console.log(`Create MatchPlayer Error: ${JSON.stringify(err)}`);
  }
  return matchPlayer;
};

export const updateMatchPlayer = async ({ input }) => {
  let matchPlayer = null;
  try {
    const user = await getUserId();
    if (!user) throw new Error('No Current User');
    const response = await mutate(updateMatchPlayerMutation, {
      ...input,
      user,
    });
    if (response) {
      matchPlayer = response.data.updateMatchPlayer;
    }
  } catch (err) {
    console.log(`Update MatchPlayer Error: ${JSON.stringify(err)}`);
  }
  return matchPlayer;
};

const getMatchSettingsInput = ({ matchID, settings, user }) => {
  const {
    startingPoints,
    tossUpEnabled,
    tossUpPoints,
    showWrongAnswerPenalty,
    wrongAnswerPenaltyPoints,
    showEarlyCorrectAnswerBonus,
    earlyCorrectAnswerPoints,
    showEarlyWrongAnswerPenalty,
    earlyWrongAnswerPoints,
    roundTimerEnabled,
    roundMinutes,
    roundSeconds,
    ringInTimer,
    showResetTimer,
    resetTimerOption,
    resetTimerDuration,
    showComputationTime,
    computationTime,
    ringInLimit,
    soundRoundTimerEnabled,
    soundRingInEnabled,
    soundRingInTimerEnabled,
    soundTeamDeliberationTimerEnabled,
    soundBonusAnswerTimerEnabled,
    soundBonusDeliberationTimerEnabled,
    teamEndDeliberationEnabled,
    teamDeliberationMinutes,
    teamDeliberationSeconds,
    teamQuestionEnabled,
    bonusQuestionEnabled,
    bonusAnswerTimerEnabled,
    bonusAnswerSeconds,
    bonusTotalParts,
    bonusPointsPerPart,
    bonusBouncebackEnabled,
    bonusImmediateBouncebackEnabled,
    bonusQuestionsFollowTossUps,
    bonusControl,
    bonusDeliberationTimerEnabled,
    bonusDeliberationSeconds,
    bonusComputationTimerEnabled,
    bonusComputationSeconds,
    playerTimersEnabled,
    templateID,
    timeoutsEnabled,
    timeouts,
    tossUpAnswerEnabled,
    tossUpAnswerSeconds,
    soundTossUpAnswerEnabled,
    soundTimeoutTimerEnabled,
    preserveMinTimeEnabled,
    preserveMinTimeSeconds,
  } = settings || {};
  return {
    id: matchID,
    matchID,
    user,
    startingPoints,
    tossUpEnabled,
    tossUpPoints,
    showWrongAnswerPenalty,
    wrongAnswerPenaltyPoints,
    showEarlyCorrectAnswerBonus,
    earlyCorrectAnswerPoints,
    showEarlyWrongAnswerPenalty,
    earlyWrongAnswerPoints,
    roundTimerEnabled,
    roundMinutes,
    roundSeconds,
    ringInTimer,
    showResetTimer,
    resetTimerOption,
    resetTimerDuration,
    showComputationTime,
    computationTime,
    ringInLimit,
    soundRoundTimerEnabled,
    soundRingInEnabled,
    soundRingInTimerEnabled,
    soundTeamDeliberationTimerEnabled,
    soundBonusAnswerTimerEnabled,
    soundBonusDeliberationTimerEnabled,
    teamEndDeliberationEnabled,
    teamDeliberationMinutes,
    teamDeliberationSeconds,
    teamQuestionEnabled,
    bonusQuestionEnabled,
    bonusAnswerTimerEnabled,
    bonusAnswerSeconds,
    bonusTotalParts,
    bonusPointsPerPart,
    bonusBouncebackEnabled,
    bonusImmediateBouncebackEnabled,
    bonusQuestionsFollowTossUps,
    bonusControl,
    bonusDeliberationTimerEnabled,
    bonusDeliberationSeconds,
    bonusComputationTimerEnabled,
    bonusComputationSeconds,
    playerTimersEnabled,
    templateID,
    timeoutsEnabled,
    timeouts,
    tossUpAnswerEnabled,
    tossUpAnswerSeconds,
    soundTossUpAnswerEnabled,
    soundTimeoutTimerEnabled,
    preserveMinTimeEnabled,
    preserveMinTimeSeconds,
  };
};

export const createMatchSettings = async ({ matchID, settings, user }) => {
  let matchSettings = null;
  const input = getMatchSettingsInput({ matchID, settings, user });
  try {
    const response = await mutate(createMatchSettingsMutation, input);
    if (response) {
      matchSettings = response.data.createMatchSettings;
    }
  } catch (err) {
    console.log(`Create MatchSettings Error: ${JSON.stringify(err)}`);
  }
  return matchSettings;
};

export const updateMatchSettings = async ({ matchID, settings, user }) => {
  let matchSettings = null;
  const input = getMatchSettingsInput({ matchID, settings, user });
  try {
    const response = await mutate(updateMatchSettingsMutation, {
      ...input,
      user,
    });
    if (response) {
      matchSettings = response.data.updateMatchSettings;
    }
  } catch (err) {
    console.log(`Update MatchSettings Error: ${JSON.stringify(err)}`);
  }
  return matchSettings;
};

export const updateMatch = async ({
  matchID,
  matchDate,
  matchName,
  matchStatus,
  propsToUpdate = {},
  settings,
}) => {
  let match = null;
  try {
    const user = await getUserId();
    if (!user) throw new Error('No Current User');

    const response = await mutate(updateMatchMutation, {
      id: matchID,
      active: 1,
      matchDate: offsetDateByTimezone(new Date(matchDate)).toISOString(),
      matchName,
      matchStatus,
      updatedAt: new Date().toISOString(),
      user,
      ...propsToUpdate,
    });
    if (response) {
      match = response.data.updateMatch;
      if (settings) {
        await updateMatchSettings({ matchID, settings, user });
      }
    }
  } catch (err) {
    console.log('Update Match Error:', err);
  }
  return match;
};

export const subscribeToMatchUpdates = async ({
  handleUpdate = () => {},
  matchID,
  matchUser,
}) => {
  return subscribe(onUpdateMatch, { matchID, user: matchUser }, handleUpdate);
};

export const subscribeToMatchTeamUpdates = async ({
  handleUpdate = () => {},
  matchID,
  user,
}) => {
  return subscribe(onUpdateMatchTeam, { matchID, user }, handleUpdate);
};

export const subscribeToMatchPlayerUpdates = async ({
  handleUpdate = () => {},
  matchID,
  user,
}) => {
  return subscribe(onUpdateMatchPlayer, { matchID, user }, handleUpdate);
};

export const updateMatchScore = ({ updatedMatchTeam, setMatchCb }) => {
  if (!updatedMatchTeam || !setMatchCb) return;
  const { id: matchTeamID, score } = updatedMatchTeam;
  setMatchCb((match) => {
    const { teams, teams: { items = [] } = {} } = match || {};
    const newTeamItems = items.map((item) => {
      if (item.id === matchTeamID) {
        return {
          ...item,
          score,
        };
      }
      return item;
    });
    return {
      ...match,
      teams: {
        ...teams,
        items: newTeamItems,
      },
    };
  });
};

export const updateMatchPlayersInRoom = ({
  updatedMatchPlayer,
  setMatchCb,
}) => {
  if (!updatedMatchPlayer || !setMatchCb) return;
  const { id: matchPlayerID } = updatedMatchPlayer;
  setMatchCb((match) => {
    const { players, players: { items = [] } = {} } = match || {};
    const newPlayerItems = items.map((item) => {
      if (item.id === matchPlayerID) {
        return updatedMatchPlayer;
      }
      return item;
    });
    return {
      ...match,
      players: {
        ...players,
        items: newPlayerItems,
      },
    };
  });
};

export const updateNewMatchSettings = ({ settings, setMatchCb }) => {
  setMatchCb((match) => {
    match.settings.items[0] = settings;
    return match;
  });
};

export const createMatchTemplate = async ({ settings } = {}) => {
  let matchTemplate = null;
  try {
    const user = await getUserId();
    if (!user) throw new Error('No Current User');

    const {
      templateName,
      templateType,
      startingPoints,
      tossUpEnabled,
      tossUpPoints,
      templateDescription,
      showWrongAnswerPenalty,
      wrongAnswerPenaltyPoints,
      showEarlyCorrectAnswerBonus,
      earlyCorrectAnswerPoints,
      showEarlyWrongAnswerPenalty,
      earlyWrongAnswerPoints,
      roundTimerEnabled,
      roundMinutes,
      roundSeconds,
      ringInTimer,
      showResetTimer,
      resetTimerOption,
      resetTimerDuration,
      showComputationTime,
      computationTime,
      ringInLimit,
      soundRoundTimerEnabled,
      soundRingInEnabled,
      soundRingInTimerEnabled,
      soundTeamDeliberationTimerEnabled,
      soundBonusAnswerTimerEnabled,
      soundBonusDeliberationTimerEnabled,
      teamEndDeliberationEnabled,
      teamDeliberationMinutes,
      teamDeliberationSeconds,
      teamQuestionEnabled,
      bonusQuestionEnabled,
      bonusAnswerTimerEnabled,
      bonusAnswerSeconds,
      bonusTotalParts,
      bonusPointsPerPart,
      bonusBouncebackEnabled,
      bonusImmediateBouncebackEnabled,
      bonusQuestionsFollowTossUps,
      bonusControl,
      bonusDeliberationTimerEnabled,
      bonusDeliberationSeconds,
      bonusComputationTimerEnabled,
      bonusComputationSeconds,
      playerTimersEnabled,
      timeoutsEnabled,
      timeouts,
      tossUpAnswerEnabled,
      tossUpAnswerSeconds,
      soundTossUpAnswerEnabled,
      soundTimeoutTimerEnabled,
      preserveMinTimeEnabled,
      preserveMinTimeSeconds,
    } = settings || {};
    const response = await mutate(createMatchTemplateMutation, {
      active: 1,
      templateName,
      templateType,
      templateDescription,
      startingPoints,
      tossUpEnabled,
      tossUpPoints,
      showWrongAnswerPenalty,
      wrongAnswerPenaltyPoints,
      showEarlyCorrectAnswerBonus,
      earlyCorrectAnswerPoints,
      showEarlyWrongAnswerPenalty,
      earlyWrongAnswerPoints,
      roundTimerEnabled,
      roundMinutes,
      roundSeconds,
      ringInTimer,
      showResetTimer,
      resetTimerOption,
      resetTimerDuration,
      showComputationTime,
      computationTime,
      ringInLimit,
      soundRoundTimerEnabled,
      soundRingInEnabled,
      soundRingInTimerEnabled,
      soundTeamDeliberationTimerEnabled,
      soundBonusAnswerTimerEnabled,
      soundBonusDeliberationTimerEnabled,
      teamEndDeliberationEnabled,
      teamDeliberationMinutes,
      teamDeliberationSeconds,
      teamQuestionEnabled,
      bonusQuestionEnabled,
      bonusAnswerTimerEnabled,
      bonusAnswerSeconds,
      bonusTotalParts,
      bonusPointsPerPart,
      bonusBouncebackEnabled,
      bonusImmediateBouncebackEnabled,
      bonusQuestionsFollowTossUps,
      bonusControl,
      bonusDeliberationTimerEnabled,
      bonusDeliberationSeconds,
      bonusComputationTimerEnabled,
      bonusComputationSeconds,
      playerTimersEnabled,
      timeoutsEnabled,
      timeouts,
      user,
      tossUpAnswerEnabled,
      tossUpAnswerSeconds,
      soundTossUpAnswerEnabled,
      soundTimeoutTimerEnabled,
      preserveMinTimeEnabled,
      preserveMinTimeSeconds,
    });
    if (response) {
      matchTemplate = response;
    }
  } catch (err) {
    console.log('Create Match Template Error:', err);
  }
  return matchTemplate;
};

export const fetchActiveTemplatesByType = async ({
  user = '',
  templateType = '',
} = {}) => {
  let matchTemplates = null;
  try {
    const response = await queryAuthed(activeTemplatesByType, {
      active: 1,
      limit: 100,
      templateTypeUser: {
        beginsWith: {
          templateType,
          user,
        },
      },
    });
    if (response) {
      matchTemplates = get(response, 'data.activeTemplatesByType');
    }
  } catch (err) {
    console.log('Fetch Match Templates by Type Error:', err);
  }
  return matchTemplates;
};

export const updateMatchTemplate = async ({
  id,
  templateType,
  user,
  propsToUpdate = {},
}) => {
  const {
    active,
    templateName,
    templateType: updatedTemplateType,
    templateDescription,
    startingPoints,
    tossUpEnabled,
    tossUpPoints,
    showWrongAnswerPenalty,
    wrongAnswerPenaltyPoints,
    showEarlyCorrectAnswerBonus,
    earlyCorrectAnswerPoints,
    showEarlyWrongAnswerPenalty,
    earlyWrongAnswerPoints,
    roundTimerEnabled,
    roundMinutes,
    roundSeconds,
    ringInTimer,
    showResetTimer,
    resetTimerOption,
    resetTimerDuration,
    showComputationTime,
    computationTime,
    ringInLimit,
    soundRoundTimerEnabled,
    soundRingInEnabled,
    soundRingInTimerEnabled,
    soundTeamDeliberationTimerEnabled,
    soundBonusAnswerTimerEnabled,
    soundBonusDeliberationTimerEnabled,
    teamEndDeliberationEnabled,
    teamDeliberationMinutes,
    teamDeliberationSeconds,
    teamQuestionEnabled,
    bonusQuestionEnabled,
    bonusAnswerTimerEnabled,
    bonusAnswerSeconds,
    bonusTotalParts,
    bonusPointsPerPart,
    bonusBouncebackEnabled,
    bonusImmediateBouncebackEnabled,
    bonusQuestionsFollowTossUps,
    bonusControl,
    bonusDeliberationTimerEnabled,
    bonusDeliberationSeconds,
    bonusComputationTimerEnabled,
    bonusComputationSeconds,
    playerTimersEnabled,
    timeoutsEnabled,
    timeouts,
    tossUpAnswerEnabled,
    tossUpAnswerSeconds,
    soundTossUpAnswerEnabled,
    soundTimeoutTimerEnabled,
    preserveMinTimeEnabled,
    preserveMinTimeSeconds,
  } = propsToUpdate || {};
  let matchTemplate = null;
  const userId = await getUserId();
  if (!userId) return;
  try {
    const response = await mutate(updateMatchTemplateMutation, {
      id,
      active,
      user,
      templateName,
      templateType: templateType || updatedTemplateType,
      templateDescription,
      startingPoints,
      tossUpEnabled,
      tossUpPoints,
      showWrongAnswerPenalty,
      wrongAnswerPenaltyPoints,
      showEarlyCorrectAnswerBonus,
      earlyCorrectAnswerPoints,
      showEarlyWrongAnswerPenalty,
      earlyWrongAnswerPoints,
      roundTimerEnabled,
      roundMinutes,
      roundSeconds,
      ringInTimer,
      showResetTimer,
      resetTimerOption,
      resetTimerDuration,
      showComputationTime,
      computationTime,
      ringInLimit,
      soundRoundTimerEnabled,
      soundRingInEnabled,
      soundRingInTimerEnabled,
      soundTeamDeliberationTimerEnabled,
      soundBonusAnswerTimerEnabled,
      soundBonusDeliberationTimerEnabled,
      teamEndDeliberationEnabled,
      teamDeliberationMinutes,
      teamDeliberationSeconds,
      teamQuestionEnabled,
      bonusQuestionEnabled,
      bonusAnswerTimerEnabled,
      bonusAnswerSeconds,
      bonusTotalParts,
      bonusPointsPerPart,
      bonusBouncebackEnabled,
      bonusImmediateBouncebackEnabled,
      bonusQuestionsFollowTossUps,
      bonusControl,
      bonusDeliberationTimerEnabled,
      bonusDeliberationSeconds,
      bonusComputationTimerEnabled,
      bonusComputationSeconds,
      playerTimersEnabled,
      timeoutsEnabled,
      timeouts,
      tossUpAnswerEnabled,
      tossUpAnswerSeconds,
      soundTossUpAnswerEnabled,
      soundTimeoutTimerEnabled,
      preserveMinTimeEnabled,
      preserveMinTimeSeconds,
    });
    if (response) {
      matchTemplate = get(response, 'data.updateMatchTemplate');
    }
  } catch (err) {
    console.log('Update Match Template Error:', err);
  }
  return matchTemplate;
};
