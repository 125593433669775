import { deleteScore } from './Api/Score';
import {
  BONUS_QUESTION,
  TEAM_QUESTION,
  TOSS_UP_QUESTION,
} from './HostRoomHelper';
import {
  MATCH_STATE_ANSWER_AND_SCORING,
  MATCH_STATE_ASKING_QUESTION,
  MATCH_STATE_BONUS_QUESTION_OPEN,
  MATCH_STATE_RING_IN_OPEN,
  MATCH_STATE_RING_IN_OPEN_LIMITED_TEAMS,
  MATCH_STATE_RING_IN_PAUSED,
  MATCH_STATE_RING_IN_PAUSED_LIMITED,
  MATCH_STATE_RING_IN_TEST_CLOSED,
  MATCH_STATE_RING_IN_TEST_OPEN,
  MATCH_STATE_TEAM_QUESTION_CLOSED,
  MATCH_STATE_TEAM_QUESTION_OPEN,
  MATCH_STATE_TEAM_QUESTION_PAUSED,
  MATCH_STATE_TEAM_QUESTION_STARTED,
} from './MessageHelper';
import { checkTruthy, get } from './UtilHelper';

export const DEFAULT_TIMEOUTS = [
  {
    duration: 180,
    name: 'Inquiry',
    order: 1,
  },
  {
    duration: 180,
    name: 'Rebuttal',
    order: 2,
  },
  {
    duration: 300,
    name: 'Halftime',
    order: 3,
  },
];

export const getPointValues = (settings) => {
  const {
    earlyCorrectAnswerPoints,
    earlyWrongAnswerPoints,
    showEarlyCorrectAnswerBonus,
    showEarlyWrongAnswerPenalty,
    showWrongAnswerPenalty,
    tossUpPoints,
    wrongAnswerPenaltyPoints,
  } = settings || {};
  // power (early correct) points
  let powerPoints = 0;
  if (showEarlyCorrectAnswerBonus) {
    powerPoints = tossUpPoints + earlyCorrectAnswerPoints;
  }
  // wrong points
  let wrongPoints = 0;
  if (showWrongAnswerPenalty === 'question') {
    wrongPoints = -tossUpPoints;
  }
  if (showWrongAnswerPenalty === 'custom') {
    wrongPoints = -wrongAnswerPenaltyPoints;
  }
  // neg (early wrong) points
  let negPoints = 0;
  if (checkTruthy(showEarlyWrongAnswerPenalty)) {
    negPoints = -earlyWrongAnswerPoints;
  }
  return {
    correctPoints: tossUpPoints,
    powerPoints,
    wrongPoints,
    negPoints,
  };
};

export const getQuestionNumberFromScores = (scoreItems) => {
  let newQuestionNumber = 0;
  scoreItems.forEach(({ questionNumber }) => {
    if (questionNumber > newQuestionNumber) newQuestionNumber = questionNumber;
  });
  return newQuestionNumber + 1;
};

export const getOtherPlayersString = (playerList, activePlayerID) => {
  const otherPlayersArray = playerList.filter(
    ({ playerID }) => playerID !== activePlayerID,
  );
  const otherPlayersAndTeams = otherPlayersArray.map(({ player }) => {
    return {
      playerID: player.id,
      playerName: player.name,
      teamID: player.teamID,
    };
  });
  return JSON.stringify(otherPlayersAndTeams);
};

export const getTeamScore = ({
  startingPoints = 0,
  scoreList = [],
  teamID,
}) => {
  let totalPoints = startingPoints;
  scoreList.forEach(({ points, teamID: scoreTeamID } = {}) => {
    if (scoreTeamID === teamID) totalPoints += points;
  });
  return totalPoints;
};

const canRingInStates = [
  MATCH_STATE_RING_IN_TEST_OPEN,
  MATCH_STATE_RING_IN_OPEN,
  MATCH_STATE_RING_IN_PAUSED,
  MATCH_STATE_ASKING_QUESTION,
];
const canRingInLimitedStates = [
  MATCH_STATE_RING_IN_OPEN_LIMITED_TEAMS,
  MATCH_STATE_RING_IN_PAUSED_LIMITED,
];

export const getCanRingIn = ({
  activePlayerID,
  matchState,
  teamsThatDidRingIn = [],
  teamID,
}) => {
  const teamCanRingIn = !teamID || !teamsThatDidRingIn.includes(teamID);
  const canRingInLimited =
    canRingInLimitedStates.includes(matchState) && teamCanRingIn;
  return (
    (!activePlayerID && canRingInStates.includes(matchState)) ||
    canRingInLimited
  );
};

export const getQuestionTypeText = ({
  activeQuestionNumber,
  bonusPart = 0,
  matchState,
}) => {
  const tossUpStates = [
    MATCH_STATE_ASKING_QUESTION,
    MATCH_STATE_RING_IN_OPEN,
    MATCH_STATE_RING_IN_OPEN_LIMITED_TEAMS,
    MATCH_STATE_RING_IN_PAUSED,
    MATCH_STATE_RING_IN_PAUSED_LIMITED,
    MATCH_STATE_ANSWER_AND_SCORING,
  ];

  const ringInStates = [
    MATCH_STATE_RING_IN_TEST_OPEN,
    MATCH_STATE_RING_IN_TEST_CLOSED,
  ];

  const teamQuestionStates = [
    MATCH_STATE_TEAM_QUESTION_OPEN,
    MATCH_STATE_TEAM_QUESTION_STARTED,
    MATCH_STATE_TEAM_QUESTION_PAUSED,
    MATCH_STATE_TEAM_QUESTION_CLOSED,
  ];

  const bonusQuestionStates = [MATCH_STATE_BONUS_QUESTION_OPEN];

  let questionText = '';

  if (tossUpStates.includes(matchState))
    questionText = `Toss-up Question ${activeQuestionNumber}`;
  if (ringInStates.includes(matchState)) questionText = `Ring-in Test`;
  if (teamQuestionStates.includes(matchState))
    questionText = `Team Question ${activeQuestionNumber}`;
  if (bonusQuestionStates.includes(matchState))
    questionText = `Bonus Question ${activeQuestionNumber} - Part ${bonusPart}`;

  return questionText;
};

export const getOrderedTeamList = (teamList) => {
  return teamList.sort((a, b) => a.order - b.order);
};

export const scoreQuestionTypes = {
  [TOSS_UP_QUESTION]: 'playerTossUp',
  [TEAM_QUESTION]: 'team',
  [BONUS_QUESTION]: 'bonusPart',
};

export const hasTypeBeenAnswered = ({
  currentQuestion = 0,
  questionType,
  scoreList = [],
}) => {
  const currentQuestionScores = getCurrentQuestionScores({
    currentQuestion,
    scoreList,
  });
  if (currentQuestionScores.length === 0) return false;
  const scoreTypeString = getScoreTypeString({
    questionType,
    scoreQuestionTypes,
  });
  const questionTypeScores = getQuestionTypeScores(
    currentQuestionScores,
    scoreTypeString,
  );
  return questionTypeScores.length > 0;
};

export const getCurrentQuestionScores = ({ currentQuestion, scoreList }) => {
  return scoreList.filter((score) => score.questionNumber === currentQuestion);
};

export const getScoreTypeString = ({ questionType, scoreQuestionTypes }) => {
  return scoreQuestionTypes[questionType];
};

export const getQuestionTypeScores = (
  currentQuestionScores,
  scoreTypeString,
) => {
  return currentQuestionScores.filter((score) =>
    (score.type || '').includes(scoreTypeString),
  );
};

export const getActivePlayerTeamID = (activePlayerID, playerList) => {
  try {
    const matchPlayer = playerList.find(
      (player) => player.playerID === activePlayerID,
    );
    if (!matchPlayer) throw new Error('Missing matchPlayer');
    const { player: { teamID } = {} } = matchPlayer;
    return teamID;
  } catch (err) {
    console.log('getActivePlayerTeamID Error:', err);
  }
  return null;
};

export const getActivePlayerName = (activePlayerID, playerList) => {
  try {
    const matchPlayer = playerList.find(
      (player) => player.playerID === activePlayerID,
    );
    if (!matchPlayer) throw new Error('Missing matchPlayer');
    const playerName = get(matchPlayer, 'player.name', '');
    return playerName;
  } catch (err) {
    console.log('getActivePlayerName Error:', err);
  }
  return null;
};

export const getReplayQuestionScoresByType = ({
  questionNumber,
  questionTypeArray,
  scoreList,
  scoreQuestionTypes,
}) => {
  const replayQuestionScores = getCurrentQuestionScores({
    currentQuestion: parseInt(questionNumber),
    scoreList,
  });
  const scoreTypeStringArray = questionTypeArray.map((questionType) => {
    return getScoreTypeString({
      questionType,
      scoreQuestionTypes,
    });
  });
  const replayQuestionScoresForCurrentQuestionType = replayQuestionScores.filter(
    (score) => {
      let scoreMatches = false;
      scoreTypeStringArray.forEach((scoreType) => {
        if (get(score, 'type', '').includes(scoreType)) {
          scoreMatches = true;
          return;
        }
      });
      return scoreMatches;
    },
  );
  return replayQuestionScoresForCurrentQuestionType;
};

export const deletePreviousScores = async ({
  questionNumber,
  questionTypeArray,
  scoreList,
  scoreQuestionTypes,
}) => {
  const replayQuestionScoresByType = getReplayQuestionScoresByType({
    questionNumber,
    questionTypeArray,
    scoreList,
    scoreQuestionTypes,
  });
  if (replayQuestionScoresByType && replayQuestionScoresByType.length !== 0) {
    const deleteScorePromises = replayQuestionScoresByType.map((score) => {
      return deleteScore({ scoreID: score.id });
    });
    await Promise.all(deleteScorePromises);
  }
};

export const getRemainingReplayQuestions = ({
  questionType,
  replayQuestionArray,
}) => {
  return replayQuestionArray.filter((question) => question !== questionType);
};

export const getNewReplayQuestionArray = ({
  bonusQuestionEnabled,
  bonusQuestionsFollowTossUps,
  questionType,
  tossUpEnabled,
}) => {
  if (questionType === TEAM_QUESTION) {
    return [TEAM_QUESTION];
  }
  if (
    checkTruthy(bonusQuestionEnabled) &&
    bonusQuestionsFollowTossUps === 'ifAnsweredCorrectly' &&
    tossUpEnabled &&
    questionType === TOSS_UP_QUESTION
  ) {
    return [TOSS_UP_QUESTION, BONUS_QUESTION];
  }
  return [questionType];
};
