import React from 'react';
import styled from 'styled-components';
import { MatchSettingsContext } from '../../../../Hooks/MatchSettingsContext';
import SettingsHeader from '../../../SettingsHeader';

const RoundTimer = ({ className, disabledEdits = false }) => {
  const {
    errors,
    roundMinutes,
    roundSeconds,
    roundTimerEnabled,
    setMatchSetting,
  } = React.useContext(MatchSettingsContext);

  const handleRoundTimerEnabledChange = (e) => {
    setMatchSetting({
      payload: { roundTimerEnabled: e.target.checked },
      errorsToClear: ['roundMinutes', 'roundSeconds'],
    });
  };

  const handleRoundMinutesChange = (e) => {
    setMatchSetting({
      payload: { roundMinutes: e.target.value },
      errorsToClear: ['roundMinutes'],
    });
  };

  const handleRoundSecondsChange = (e) => {
    setMatchSetting({
      payload: { roundSeconds: e.target.value },
      errorsToClear: ['roundSeconds'],
    });
  };

  return (
    <div className={`settings-round-timer-container ${className}`}>
      <SettingsHeader text="Round Timer" />
      <label className="checkbox-container">
        <input
          disabled={disabledEdits}
          type="checkbox"
          checked={roundTimerEnabled}
          onChange={handleRoundTimerEnabledChange}
        />
        <span className="select-label">Activate Round Timer</span>
      </label>
      {roundTimerEnabled && (
        <div className="timer-fields-container">
          <div className="minutes-container">
            <label>
              <span className="select-label">Minutes</span>
              <input
                disabled={disabledEdits}
                type="number"
                min="0"
                className="input-time"
                value={roundMinutes}
                onChange={handleRoundMinutesChange}
              />
            </label>
            {errors.roundMinutes && (
              <div className="error-container">
                <p>{errors.roundMinutes}</p>
              </div>
            )}
          </div>
          <div className="seconds-container">
            <label>
              <span className="select-label">Seconds</span>
              <input
                disabled={disabledEdits}
                type="number"
                min="0"
                max="59"
                className="input-time"
                value={roundSeconds}
                onChange={handleRoundSecondsChange}
              />
            </label>
            {errors.roundSeconds && (
              <div className="error-container">
                <p>{errors.roundSeconds}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const StyledRoundTimer = styled(RoundTimer)`
  .checkbox-container {
    display: flex;
    align-items: center;
  }

  .select-label {
    margin: 1rem;
  }

  .timer-fields-container {
    display: flex;
  }

  .input-time {
    width: 6rem;
  }

  .minutes-container,
  .seconds-container {
    display: flex;
    flex-direction: column;
    width: 25%;
  }

  @media (min-width: 900px) {
    .select-label {
      display: inline;
    }
  }
`;

export default StyledRoundTimer;
