import { loadStripe } from '@stripe/stripe-js';
import React, { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import LoadingModal from '../../Components/CheckoutLoadingModal';
import HeaderUnauthed from '../../Components/HeaderUnauthed';
import PlanButton from '../../Components/PlanButton';
import {
  checkout,
  createCustomer,
  getProducts,
} from '../../Helpers/Api/Customer';
import { getCurrentUser } from '../../Helpers/AuthHelper';
import { isValidCustomer } from '../../Helpers/CustomerHelper';
import { get, getSignUpEmailUrl } from '../../Helpers/UtilHelper';
import { AuthContext } from '../../Hooks/AuthContext';
import { CustomerContext } from '../../Hooks/CustomerContext';
import useHostLoader from '../../Hooks/useHostLoader';
import {
  getSignUpFormCookieExpiration,
  SIGN_UP_FORM_COOKIE_NAME,
  STATES,
  SUBMIT_BUTTON_TEXT,
  validateFields,
} from './Helper';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const SignUpHost = ({ className }) => {
  const { bypassCode } = useParams();
  useHostLoader({ isSignUp: true });
  const [cookies, setCookie, removeCookie] = useCookies();
  const formCookie = get(cookies, SIGN_UP_FORM_COOKIE_NAME, {});
  const { signUp } = useContext(AuthContext);
  const { fetchCustomer } = useContext(CustomerContext);
  const [firstName, setFirstName] = useState(formCookie.firstName || '');
  const [lastName, setLastName] = useState(formCookie.lastName || '');
  const [organization, setOrganization] = useState(
    formCookie.organization || '',
  );
  const [level, setLevel] = useState(formCookie.level || '');
  const [state, setState] = useState(formCookie.state || '');
  const [email, setEmail] = useState(formCookie.email || '');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [products, setProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(
    formCookie.selectedProductId || null,
  );
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  React.useEffect(() => {
    const fetchProducts = async () => {
      const response = await getProducts();
      if (response && response.products) setProducts(response.products);
    };
    if (!bypassCode) fetchProducts();
  }, [bypassCode]);

  React.useEffect(() => {
    if (!selectedProductId) {
      const featuredProduct = products.find(
        (product) => get(product, 'price.recurring.interval') === 'year',
      );
      if (featuredProduct) setSelectedProductId(featuredProduct.id);
    }
  }, [products, selectedProductId]);

  const onSubmit = async () => {
    setError(null);
    setFieldErrors([]);

    const fieldsAreValid = validateFields({
      bypassCode,
      firstName,
      lastName,
      organization,
      level,
      state,
      email,
      password,
      passwordConfirm,
      selectedProductId,
      setError,
      setFieldErrors,
    });
    if (!fieldsAreValid) return;

    setIsSubmitting(true);
    const user = await getCurrentUser();
    const userEmail = get(user, 'attributes.email');
    let userID = get(user, 'username');
    if (!userID || userEmail !== email) {
      // sign up and sign in with Cognito
      const user = await signUp({
        attributes: {
          email: email.toLowerCase(),
        },
        password,
        username: email.toLowerCase(),
      });
      userID = get(user, 'username');
      const signUpError = get(user, 'error');
      if (signUpError) {
        setError(signUpError);
        setIsSubmitting(false);
        return;
      }
    }

    let customer = await fetchCustomer();
    const customerUser = get(customer, 'user');
    const attemptCustomerUpdate =
      customer && bypassCode && !isValidCustomer({ customer });
    if (!customer || customerUser !== userID || attemptCustomerUpdate) {
      // create stripe customer and create customer record in DynamoDB
      const customerParams = {
        bypassCode,
        email,
        firstName,
        lastName,
        level,
        organization,
        state,
        userID,
      };
      const customerResponse = await createCustomer(customerParams);
      if (
        !customerResponse ||
        !customerResponse.customer ||
        customerResponse.error
      ) {
        if (
          customerResponse &&
          customerResponse.error === 'Invalid bypass code'
        ) {
          // Display separate code for invalid bypass code values
          setError('Something went wrong. Try again. (Code: AUTH-5)');
        } else {
          setError('Something went wrong. Try again. (Code: AUTH-2)');
        }
        setIsSubmitting(false);
        return;
      }
      customer = await fetchCustomer();
    }

    if (bypassCode && customer) {
      const url = getSignUpEmailUrl({
        email,
        firstName,
        lastName,
        path: '/host/account',
      });
      window.location = url;
      return;
    }

    const { id: customerId, stripeCustomerId } = customer || {};
    const selectedProduct = products.find(
      (product) => product.id === selectedProductId,
    );
    const planID = get(selectedProduct, 'price.id');

    // create stripe session
    const checkoutResponse = await checkout({
      customerId,
      planID,
      stripeCustomerId,
      url: window.location.origin,
      cancelPath: '/host/sign-up',
    });
    const sessionId = get(checkoutResponse, 'session.id');
    if (!checkoutResponse || !sessionId) {
      setError('Something went wrong. Try again. (Code: AUTH-3)');
      setIsSubmitting(false);
      return;
    }

    const newCookieValue = {
      firstName,
      lastName,
      organization,
      level,
      state,
      email,
      selectedProductId,
    };
    setCookie(SIGN_UP_FORM_COOKIE_NAME, newCookieValue, {
      path: '/',
      expires: getSignUpFormCookieExpiration(),
    });

    // attempt to redirect to stripe checkout page
    const stripe = await stripePromise;
    const { error: { message } = {} } = await stripe.redirectToCheckout({
      sessionId,
    });
    if (message) setError('Something went wrong. Try again. (Code: AUTH-4)');
    setIsSubmitting(false);
  };

  const handleKeyDown = (e) => {
    const keycode = e.keyCode;
    switch (keycode) {
      case 13: {
        onSubmit();
        break;
      }
      default: {
        break;
      }
    }
  };

  const getInputClasses = (field) => {
    const errorClass = fieldErrors.includes(field) ? 'error' : '';
    return `input ${errorClass}`;
  };

  return (
    <div className={`host-sign-up ${className}`}>
      <HeaderUnauthed hideHostLogin showContactUs />
      <div className="container">
        <h1 className="title">Account Setup</h1>
        <div className="signup-link">
          Already have an account? <Link to="/host/sign-in">Log In Here</Link>
        </div>
        {error && (
          <div className="error-container">
            <p>{error}</p>
          </div>
        )}
        <label className="input-container">
          <div className="label">Subscriber</div>
          <input
            className={getInputClasses('firstName')}
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="first name"
            id="fname"
          />
          <input
            className={getInputClasses('lastName')}
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="last name"
            id="lname"
          />
        </label>
        <label className="input-container">
          <div className="label">Organization</div>
          <input
            className={getInputClasses('organization')}
            type="text"
            value={organization}
            onChange={(e) => setOrganization(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="organization name"
            id="orgname"
          />
        </label>
        <label className="input-container">
          <div className="label">Level</div>
          <select
            className={getInputClasses('level')}
            value={level}
            onChange={(e) => setLevel(e.target.value)}
            id="level"
          >
            <option value=""></option>
            <option value="elementary">Elementary</option>
            <option value="middleSchool">Middle School</option>
            <option value="highSchool">High School</option>
            <option value="collegiate">Collegiate</option>
          </select>
          <div className="label">State</div>
          <select
            className={getInputClasses('state')}
            value={state}
            onChange={(e) => setState(e.target.value)}
            id="state"
          >
            <option value=""></option>
            {STATES.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        </label>
        <label className="input-container">
          <div className="label">Username *</div>
          <input
            className={getInputClasses('email')}
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="e-mail address"
            id="username"
          />
        </label>
        <div className="email-disclaimer">
          * Subscriber e-mail addresses are neither sold, leased, nor rented to
          anyone for any reason.
        </div>
        <label className="input-container">
          <div className="label">Password</div>
          <input
            className={getInputClasses('password')}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="password"
            id="password"
          />
          <input
            className={getInputClasses('passwordConfirm')}
            type="password"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="confirm password"
            id="confirmpassword"
          />
        </label>
        {!bypassCode && (
          <label
            className={`input-container plans ${
              fieldErrors.includes('selectedProductId') ? 'error' : ''
            }`}
          >
            <div className="label">Select a Plan</div>
            {products.length ? (
              products.map((product) => (
                <PlanButton
                  key={product.id}
                  onClick={() => setSelectedProductId(product.id)}
                  product={product}
                  selected={product.id === selectedProductId}
                />
              ))
            ) : (
              <div className="plan-loader">Loading plans...</div>
            )}
          </label>
        )}
        <button
          className="submit-button"
          disabled={isSubmitting}
          onClick={onSubmit}
          id="subscribe"
        >
          {SUBMIT_BUTTON_TEXT}
        </button>
        <div className="disclaimer">
          By clicking "<span className="uppercase">{SUBMIT_BUTTON_TEXT}</span>",
          you agree to our{' '}
          <a
            href="http://www.quizbowlsystems.com/termsandconditions.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </a>{' '}
          and acknowledge receipt of our{' '}
          <a
            href="http://www.quizbowlsystems.com/privacypolicy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          .
        </div>
      </div>
      {isSubmitting && <LoadingModal />}
    </div>
  );
};

const StyledSignUpHost = styled(SignUpHost)`
  font-size: 2rem;
  line-height: 1.75rem;

  .container {
    width: 60rem;
    margin-top: -2rem;
    margin-bottom: 5rem;
  }

  .error-container {
    display: block;
    text-align: center;
  }

  .title {
    font-size: 3.5rem;
    margin-bottom: 3rem;
    text-align: center;
  }

  .signup-link {
    font-size: 1.75rem;
    margin: 3rem;
    text-align: center;
  }

  .input-container {
    display: flex;
    align-items: center;
    margin: 1.25rem;

    &.plans {
      min-height: 89px;
    }
  }

  .label {
    min-width: 13rem;
    text-align: end;
    line-height: 2.8rem;
    flex: 0;
  }

  .input {
    height: 30px;
    margin: 0.3rem 0 0.3rem 1rem;
    flex: 1;
    text-align: center;

    &.error {
      border-color: red;
    }
  }

  .email-disclaimer {
    font-size: 1.2rem;
    text-align: center;
  }

  .submit-button {
    width: 80%;
    margin: 0 10%;
    font-size: 2rem;
  }

  .disclaimer {
    font-size: 1.7rem;
    line-height: 2.5rem;
    margin: 2rem;
    text-align: center;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .plan-loader {
    display: flex;
    justify-content: center;
    width: 100%;
    font-weight: 400;
    font-size: 1.5rem;
  }
`;

export default StyledSignUpHost;
