import React from 'react';
import styled from 'styled-components';

const SettingsHeader = ({ className, text }) => {
  return <h3 className={`setting-header-text ${className}`}>{text}</h3>;
};

const StyledSettingsHeader = styled(SettingsHeader)`
  font-size: 1.75rem;
  margin: 2rem auto;
  padding: 0.5rem;
  background-color: steelblue;
  color: white;
  text-align: center;
  border-radius: 3px;
`;

export default StyledSettingsHeader;
