import React from 'react';
import styled from 'styled-components';
import XLSX from 'xlsx';
import UploadTeamModal from '../../../../Components/UploadTeamModal';
import {
  createTeamGroup,
  createTeamWithPlayers,
  fetchTeamsFromGroup,
  getTeamsByUser,
  getTeamsWithoutGroups,
} from '../../../../Helpers/Api/Team';
import { SUPPORTED_FILE_TYPES } from '../../../../Helpers/DashboardHelper';
import { get } from '../../../../Helpers/UtilHelper';
import { AuthContext } from '../../../../Hooks/AuthContext';
import useShareTeams from '../../../../Hooks/useShareTeams';
import CreateTeamModal from './Components/CreateTeamModal';
import TeamList from './Components/TeamList';

const TeamView = ({ className }) => {
  const [showModal, setShowModal] = React.useState(false);
  const [teamList, setTeamList] = React.useState([]);
  const [teamListToken, setTeamListToken] = React.useState(undefined);
  const [fetchTeamsComplete, setFetchTeamsComplete] = React.useState(false);
  const fileInput = React.useRef(null);
  const [showUploadTeamModal, setShowUploadTeamModal] = React.useState(false);
  const [fileSelected, setFileSelected] = React.useState(false);
  const [fileUploadError, setFileUploadError] = React.useState(false);
  const [uploadErrorText, setUploadErrorText] = React.useState(null);
  const [uploadedTeamData, setUploadedTeamData] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [fileUploadSuccess, setFileUploadSuccess] = React.useState(false);
  const [allTeamList, setAllTeamList] = React.useState([]);
  const [duplicateListOfTeams, setDuplicateListOfTeams] = React.useState([]);
  const [uniqueListOfTeams, setUniqueListOfTeams] = React.useState([]);
  const [teamDataToBeUploaded, setTeamDataToBeUploaded] = React.useState([]);
  const [teamUploadSuccess, setTeamUploadSuccess] = React.useState(false);
  const [teamUploadError, setTeamUploadError] = React.useState(false);
  const [teamUploadSnackbarText, setTeamUploadSnackbarText] = React.useState(
    '',
  );
  const [selectedGroupID, setSelectedGroupID] = React.useState('');
  const [sharedTeamsList, setSharedTeamsList] = React.useState([]);
  const [isLoadingSharedGroups, setIsLoadingSharedGroups] = React.useState(
    false,
  );
  const { isAdmin } = React.useContext(AuthContext);

  const {
    enableShareTeams,
    groupErrorText,
    handleRemoveGroupError,
    newGroup,
    onChangeNewGroup,
    onChangeSelectedGroup,
    selectedGroup,
    setEnableShareTeams,
    setGroupErrorText,
    teamGroups,
    fetchTeamGroups,
  } = useShareTeams();

  React.useEffect(() => {
    fetchTeams();
  }, []);

  React.useEffect(() => {
    if (sharedTeamsList.length === 0) {
      fetchTeamGroups();
    }
  }, [sharedTeamsList]);

  React.useEffect(() => {
    if (teamUploadSuccess) {
      setTimeout(() => {
        setTeamUploadSuccess(false);
        setTeamUploadSnackbarText('');
      }, 5000);
    }
  }, [teamUploadSuccess]);

  React.useEffect(() => {
    if (teamUploadError) {
      setTimeout(() => {
        setTeamUploadSuccess(false);
        setTeamUploadSnackbarText('');
      }, 5000);
    }
  }, [teamUploadError]);

  React.useEffect(() => {
    const newOverflow = showModal ? 'hidden' : 'unset';
    document.body.style.overflow = newOverflow;
  }, [showModal]);

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const toggleUploadTeamModal = () => {
    setShowUploadTeamModal((prev) => !prev);
    setFileSelected(false);
    setFileUploadError(false);
    setUploadedTeamData(null);
  };

  const closeUploadSuccessModal = () => {
    setFileUploadSuccess(false);
    toggleUploadTeamModal();
    setDuplicateListOfTeams([]);
    setUniqueListOfTeams([]);
    setTeamDataToBeUploaded([]);
    resetAndRefetchTeams();
  };

  const checkDuplicateTeam = (teamToCheck) => {
    // "all teams" in this instance is owned teams, reusing some old logic prior to shared teams
    const duplicateInOwnedTeams = allTeamList.some(
      (team) => team.name.toUpperCase() === teamToCheck.toUpperCase(),
    );
    const duplicateInSharedTeams = teamGroups.some((teamGroup) => {
      const teams = get(teamGroup, 'teams.items');
      return teams.some((team) => {
        return (
          get(team, 'team.name', '').toUpperCase() === teamToCheck.toUpperCase()
        );
      });
    });
    if (isAdmin) {
      return duplicateInOwnedTeams || duplicateInSharedTeams;
    } else {
      return duplicateInOwnedTeams;
    }
  };

  const validateTeamUpload = (teamData) => {
    let hasTeamError = false;
    let hasPlayerError = false;
    let hasMoreThanFifteenPlayers = false;
    let hasMoreThanTwentyFiveTeams = false;
    if (teamData.length > 26) {
      hasMoreThanTwentyFiveTeams = true;
    } else {
      teamData.forEach((team, index) => {
        if (team.length === 0) {
          // check if row is missing data and skip it
          return;
        }
        if (team.length > 16) {
          // check if row has more than 15 players
          hasMoreThanFifteenPlayers = true;
          return;
        }
        if (index > 0) {
          let hasAtLeastOnePlayer = false;
          for (let i = 0; i < team.length; i++) {
            if (i === 0) {
              if (team[i] === undefined || team[i] === '') {
                // check for missing team name
                hasTeamError = true;
                return;
              }
            } else {
              if (team[i]) {
                // check for at least one player on each team
                hasAtLeastOnePlayer = true;
              }
            }
          }
          if (!hasAtLeastOnePlayer) {
            hasPlayerError = true;
            return;
          }
        }
      });
    }

    if (hasTeamError) {
      setUploadErrorText('Invalid or missing team name(s).');
    }
    if (hasPlayerError) {
      setUploadErrorText('Invalid or missing player name(s).');
    }
    if (hasMoreThanFifteenPlayers) {
      setUploadErrorText('Teams should not have more than 15 players.');
    }
    if (hasMoreThanTwentyFiveTeams) {
      setUploadErrorText('Please upload no more than 25 teams.');
    }
    return (
      hasTeamError ||
      hasPlayerError ||
      hasMoreThanFifteenPlayers ||
      hasMoreThanTwentyFiveTeams
    );
  };

  const fetchTeams = async (token) => {
    const { teamsWithoutGroups, nextToken } = await getTeamsWithoutGroups(
      token,
    );
    setTeamList((prev) => [...prev, ...teamsWithoutGroups]);
    if (nextToken) setTeamListToken(nextToken);
    setFetchTeamsComplete(true);
  };

  const fetchAllTeams = async () => {
    const response = await getTeamsByUser({ limit: 10000 });
    const teams = get(response, 'items');
    if (!teams) return;
    setAllTeamList(teams);
  };

  const getSharedTeams = (selectedGroupID, newTeamGroups) => {
    const selectedGroupTeams = fetchTeamsFromGroup({
      ...(newTeamGroups ? { teamGroups: newTeamGroups } : { teamGroups }),
      selectedGroupID,
    });
    setSharedTeamsList(selectedGroupTeams);
  };

  const resetAndRefetchTeams = async () => {
    setTeamListToken(undefined);
    setTeamList([]);
    await fetchTeams();
    const newTeamGroups = await fetchTeamGroups();
    if (selectedGroupID !== '') {
      getSharedTeams(selectedGroupID, newTeamGroups);
    }
  };

  const handleFileUpload = (e) => {
    fetchAllTeams();
    setFileUploadError(false);
    setDuplicateListOfTeams([]);
    setUniqueListOfTeams([]);
    setTeamDataToBeUploaded([]);
    const csvFile = e.target.files[0];
    const type = get(csvFile, 'type');
    if (SUPPORTED_FILE_TYPES.includes(type)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        if (data) setFileSelected(true);
        try {
          const workbook = XLSX.read(data, { type: 'array' });
          const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(first_worksheet, {
            header: 1,
          });
          setUploadedTeamData(jsonData);
        } catch (err) {
          setFileUploadError(true);
          setUploadErrorText(err.message);
          setFileSelected(false);
        }
      };
      reader.readAsArrayBuffer(csvFile);
    } else {
      setFileUploadError(true);
      setUploadErrorText('Please upload a CSV or XLS file with teams.');
      setFileSelected(false);
      return;
    }
  };

  const getTeamsToUpload = () => {
    const hasError = validateTeamUpload(uploadedTeamData);
    let hasAtLeastOneUniqueTeam = false;
    if (hasError) {
      setFileUploadError(true);
      return;
    }
    uploadedTeamData.forEach((team, index) => {
      let teamName;
      let players = [];
      if (index === 0) {
        // do not create the first row (title row)
        return;
      } else if (team[0] === '' || team[0] === undefined) {
        // do not create teams with empty or undefined team names
        return;
      } else if (checkDuplicateTeam(team[0])) {
        setDuplicateListOfTeams((prev) => [...prev, team[0]]);
        return;
      } else {
        teamName = team[0];
        setUniqueListOfTeams((prev) => [...prev, teamName]);
        hasAtLeastOneUniqueTeam = true;
        team.forEach((player, index) => {
          if (index === 0) {
            return;
          } else if (!player || player === '') {
            return;
          } else {
            players.push(player);
          }
        });
      }
      setTeamDataToBeUploaded((prev) => [...prev, { teamName, players }]);
    });
    if (!hasAtLeastOneUniqueTeam) {
      setFileUploadError(true);
      setUploadErrorText(
        'No unique teams found. Please upload new teams only.',
      );
      return;
    }
    setFileUploadSuccess(true);
  };

  const createUploadedTeams = async () => {
    setIsSubmitting(true);
    let hasGroupError;
    if (enableShareTeams && newGroup === '' && selectedGroup === '') {
      setGroupErrorText('Please select a group or enter a new group name.');
      hasGroupError = true;
    } else {
      hasGroupError = false;
    }
    if (hasGroupError) {
      setIsSubmitting(false);
      return;
    } else {
      let groupID = selectedGroup;
      if (isAdmin && enableShareTeams && newGroup) {
        const newlyCreatedGroup = await createTeamGroup({ name: newGroup });
        const newGroupID = get(newlyCreatedGroup, 'id');
        if (newGroupID) groupID = newGroupID;
      }
      const teamUploadPromises = teamDataToBeUploaded.map(
        ({ teamName, players }) => {
          return createTeamWithPlayers({
            teamName,
            players,
            ...(isAdmin && {
              enableShareTeams,
              selectedGroup: groupID,
            }),
          });
        },
      );
      const responses = await Promise.all(teamUploadPromises);
      const hasError = responses.includes(false);
      if (hasError) {
        setTeamUploadError(true);
        setTeamUploadSnackbarText('There was an error. Please try again.');
        setIsSubmitting(false);
        return;
      } else {
        setTeamUploadSuccess(true);
        setTeamUploadSnackbarText('Success! Teams uploaded');
      }
      setIsSubmitting(false);
      closeUploadSuccessModal();
    }
  };

  return (
    <div className={`your-rosters ${className}`}>
      <button className="team-button" onClick={toggleModal}>
        Create Team
      </button>
      <button className="team-button" onClick={toggleUploadTeamModal}>
        Upload Team
      </button>
      {teamUploadSnackbarText && (
        <div className="alert-text">
          <p>{teamUploadSnackbarText}</p>
        </div>
      )}
      {showModal ? (
        <CreateTeamModal
          resetAndRefetchTeams={resetAndRefetchTeams}
          toggleModal={toggleModal}
        />
      ) : null}
      {showUploadTeamModal && (
        <UploadTeamModal
          toggleModal={toggleUploadTeamModal}
          descriptionText="Upload a Team"
          uploadAction={handleFileUpload}
          createTeam={createUploadedTeams}
          fileInput={fileInput}
          fileSelected={fileSelected}
          fileUploadError={fileUploadError}
          uploadErrorText={uploadErrorText}
          isSubmitting={isSubmitting}
          fileUploadSuccess={fileUploadSuccess}
          closeUploadSuccessModal={closeUploadSuccessModal}
          duplicateListOfTeams={duplicateListOfTeams}
          getTeamsToUpload={getTeamsToUpload}
          uniqueListOfTeams={uniqueListOfTeams}
          enableShareTeams={enableShareTeams}
          groupErrorText={groupErrorText}
          handleRemoveGroupError={handleRemoveGroupError}
          newGroup={newGroup}
          onChangeNewGroup={onChangeNewGroup}
          onChangeSelectedGroup={onChangeSelectedGroup}
          selectedGroup={selectedGroup}
          setEnableShareTeams={setEnableShareTeams}
          setGroupErrorText={setGroupErrorText}
          teamGroups={teamGroups}
        />
      )}
      {isAdmin ? (
        <div className="template-container">
          <TeamList
            isAdmin={isAdmin}
            isSharedTeams={false}
            showModal={showModal}
            toggleModal={toggleModal}
            teamList={teamList}
            setTeamList={setTeamList}
            fetchTeamsComplete={fetchTeamsComplete}
            teamListToken={teamListToken}
            fetchTeams={fetchTeams}
            resetAndRefetchTeams={resetAndRefetchTeams}
            headerText={'Your Teams'}
          />
          <TeamList
            teamGroups={teamGroups}
            isAdmin={isAdmin}
            isSharedTeams
            showModal={showModal}
            toggleModal={toggleModal}
            teamList={teamList}
            setTeamList={setTeamList}
            fetchTeamsComplete={fetchTeamsComplete}
            teamListToken={teamListToken}
            fetchTeams={fetchTeams}
            resetAndRefetchTeams={resetAndRefetchTeams}
            headerText={'Your Shared Teams'}
            selectedGroupID={selectedGroupID}
            sharedTeamsList={sharedTeamsList}
            setSelectedGroupID={setSelectedGroupID}
            getSharedTeams={getSharedTeams}
            setSharedTeamsList={setSharedTeamsList}
            isLoadingSharedGroups={isLoadingSharedGroups}
          />
        </div>
      ) : (
        <TeamList
          showModal={showModal}
          toggleModal={toggleModal}
          teamList={teamList}
          setTeamList={setTeamList}
          fetchTeamsComplete={fetchTeamsComplete}
          teamListToken={teamListToken}
          fetchTeams={fetchTeams}
          resetAndRefetchTeams={resetAndRefetchTeams}
          headerText={'Your Teams'}
        />
      )}
    </div>
  );
};

const StyledTeamView = styled(TeamView)`
  width: 100%;
  font-size: 2rem;
  line-height: 1.75rem;
  margin: 0 auto 1rem;
  text-align: center;

  .template-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .team-button {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .alert-text {
    font-size: 1.75rem;
    width: 100%;
    font-weight: bold;
  }

  @media (min-width: 900px) {
    .button-container {
      margin-top: 5rem;
    }

    .template-container {
      flex-direction: row;
      width: 80%;
      margin: 0 auto;
    }
  }
`;

export default StyledTeamView;
