import { API } from 'aws-amplify';
import { getCustomer as getCustomerQuery } from '../../graphql/queries';
import { getUserId } from '../AuthHelper';
import { get } from '../UtilHelper';
import { queryAuthed } from './index';

const apiName = 'stripeapi';
const initParams = (body = {}, headers = {}) => ({
  body,
  headers,
});

export const getCustomer = async () => {
  let customer = null;
  const userId = await getUserId();
  if (!userId) return customer;
  try {
    const response = await queryAuthed(getCustomerQuery, {
      id: userId,
    });
    if (response) customer = response.data.getCustomer;
  } catch (err) {
    console.log('Fetch Customer Error:', err);
  }
  return customer;
};

export const createCustomer = async (params) => {
  try {
    const customer = await API.post(
      apiName,
      '/create-customer',
      initParams(params),
    );
    return { customer };
  } catch (err) {
    const message = get(err, 'response.data.error', err.message);
    console.log('Create Customer Error:', message);
    return { error: message };
  }
};

export const checkout = async ({
  customerId,
  email,
  planID,
  stripeCustomerId,
  url,
  cancelPath,
}) => {
  let response = null;
  const cancelUrl = `${url}${cancelPath || '/host/account'}`;
  try {
    const params = {
      customerId,
      email,
      planID,
      stripeCustomerId,
      success_url: `${url}/confirm-purchase?session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${cancelUrl}`,
    };
    response = await API.post(apiName, '/checkout', initParams(params));
  } catch (err) {
    console.log('Checkout Error:', err);
  }
  return response;
};

export const getProducts = async () => {
  let response = [];
  try {
    response = await API.get(apiName, '/products');
  } catch (err) {
    console.log('Products error:', err);
  }
  return response;
};

export const manageSubscription = async ({ stripeCustomerId, url }) => {
  let response = null;
  try {
    const params = {
      stripeCustomerId,
      return_url: `${url}/host/account`,
    };
    response = await API.post(
      apiName,
      '/update-subscription',
      initParams(params),
    );
  } catch (err) {
    console.log('Checkout Error:', err);
  }
  return response;
};
