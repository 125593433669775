import React from 'react';
import styled from 'styled-components';
import { checkTruthy } from '../../../../Helpers/UtilHelper';
import { AuthContext } from '../../../../Hooks/AuthContext';
import { MatchSettingsContext } from '../../../../Hooks/MatchSettingsContext';
import SettingsHeader from '../../../SettingsHeader';

const MatchTemplateOptions = ({
  className,
  headerText,
  showNameInput = false,
  showSaveInput = false,
  disabledEdits = false,
}) => {
  const [saveTemplate, setSaveTemplate] = React.useState('no');
  const {
    templateName,
    errors,
    setMatchSetting,
    templateType,
    templateDescription,
  } = React.useContext(MatchSettingsContext);
  const { isAdmin } = React.useContext(AuthContext);

  const handleMatchTemplateNameChange = (e) => {
    setMatchSetting({
      payload: {
        templateName: e.target.value,
      },
      errorsToClear: ['templateName'],
    });
  };

  const handleToggleSaveTemplate = (e) => {
    setSaveTemplate(e.target.value);
    if (e.target.value === 'yes') {
      setMatchSetting({
        payload: {
          templateType: 'private',
          saveTemplate: 'yes',
        },
      });
    } else {
      setMatchSetting({
        payload: {
          templateType: '',
          saveTemplate: 'no',
        },
      });
    }
  };

  const handleToggleShareTemplate = (e) => {
    if (e.target.value === 'yes') {
      setMatchSetting({
        payload: {
          templateType: 'public',
        },
      });
    } else {
      setMatchSetting({
        payload: {
          templateType: 'private',
        },
      });
    }
  };

  const handleTemplateDescriptionChange = (e) => {
    setMatchSetting({
      payload: {
        templateDescription: e.target.value,
      },
    });
  };

  const saveInput = showSaveInput && (
    <div className="type-container settings-container">
      <label className="label-container">
        <span className="select-label">Save These Match Settings?</span>
        <select
          disabled={disabledEdits}
          onChange={handleToggleSaveTemplate}
          value={saveTemplate}
        >
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </label>
    </div>
  );

  const nameInput = (showNameInput || checkTruthy(saveTemplate)) && (
    <div className="name-container settings-container">
      <label className="label-container">
        <span className="select-label">Match Setting Name</span>
        <input
          type="text"
          disabled={disabledEdits}
          className="input-name"
          value={templateName}
          onChange={handleMatchTemplateNameChange}
          maxLength="80"
        />
      </label>
      {errors.templateName && (
        <div className="error-container">
          <p>{errors.templateName}</p>
        </div>
      )}
    </div>
  );

  const description = (showNameInput || checkTruthy(saveTemplate)) && (
    <div className="description-container">
      <label className="label-container">
        <span>Description</span>
        <textarea
          disabled={disabledEdits}
          className="description-input"
          value={templateDescription}
          onChange={handleTemplateDescriptionChange}
          maxLength="140"
        />
      </label>
    </div>
  );

  const showAdminDisplay =
    isAdmin && (!showSaveInput || checkTruthy(saveTemplate));
  const adminDisplay = showAdminDisplay && (
    <div className="settings-container">
      <label className="label-container">
        <span>Share settings with all hosts?</span>
        <select
          disabled={disabledEdits}
          onChange={handleToggleShareTemplate}
          value={templateType === 'public' ? 'yes' : 'no'}
        >
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </label>
    </div>
  );

  return (
    <div className={`settings-name-container ${className}`}>
      <SettingsHeader text={headerText} />
      {saveInput}
      {nameInput}
      {description}
      {adminDisplay}
    </div>
  );
};

const StyledMatchTemplateOptions = styled(MatchTemplateOptions)`
  .settings-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 1rem;
  }

  .description-input {
    width: 80%;
    font-family: 'Open Sans', 'HelveticaNeue', 'Helvetica Neue', Helvetica,
      Arial, sans-serif;
    margin-left: 1rem;
  }

  .input-name {
    flex: 1;
  }

  .description-container {
    margin: 1rem;
  }

  .label-container {
    display: flex;
    padding-right: 1rem;
    align-items: left;
    width: 100%;
  }

  @media (min-width: 900px) {
    .settings-container {
      width: 100%;
      flex-direction: row;
      align-items: center;
    }

    .name-container {
      width: 90%;
    }
    .select-label {
      display: inline;
    }
  }
`;

export default StyledMatchTemplateOptions;
