import React from 'react';
import ConnectionTestComponent from '../../Components/ConnectionTest';
import HeaderUnauthed from '../../Components/HeaderUnauthed';

const ConnectionTest = () => {
  return (
    <div>
      <HeaderUnauthed showPlayerLogin />
      <ConnectionTestComponent />
    </div>
  );
};

export default ConnectionTest;
