import React from 'react';
import styled from 'styled-components';
import { ScoreSheetContext } from '../../../../Contexts/ScoreSheetContext';
import {
  getCellDataPerType,
  getSumTotalPointsByType,
} from '../../../../Helpers/ScoreSheetHelper';
import { get } from '../../../../Helpers/UtilHelper';
import ScoreSheetCell from '../ScoreSheetCell';

const ScoreSheetBonusAndTeamSection = ({
  className,
  matchID,
  teamData,
  isExportingScoreSheet,
}) => {
  let bonusRowsToDisplay = [];
  const teamId = get(teamData, 'team.id', null);
  const { cellList, totalBonusParts, uniquePointValues } = React.useContext(
    ScoreSheetContext,
  );

  const BonusRow = ({ bonusPartDisplay = '', cellList }) => {
    const bonusScores = getCellDataPerType({
      cellList,
      type: `bonusPart-${bonusPartDisplay}`,
      teamId,
    });

    let bonusPointCols = bonusScores.map((score, index) => {
      return (
        <ScoreSheetCell
          points={score.points}
          key={index}
          cellId={score.cellId}
          matchID={matchID}
          type={'bonus'}
          isExportingScoreSheet={isExportingScoreSheet}
        />
      );
    });

    return (
      <tr className={className}>
        <td></td>
        <td className="black-cell">Bonus Part {bonusPartDisplay}</td>
        {bonusPointCols}
        <td></td>
        {uniquePointValues.map((value, index) => {
          return <td key={index}></td>;
        })}
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  };

  const TeamRow = ({ teamQuestionDisplay = '', cellList }) => {
    const teamScores = getCellDataPerType({
      cellList,
      type: `team`,
      teamId,
    });
    let teamPointCols = teamScores.map((score, index) => {
      return (
        <ScoreSheetCell
          cellId={score.cellId}
          points={score.points}
          key={index}
          matchID={matchID}
          type={'team'}
          isExportingScoreSheet={isExportingScoreSheet}
        />
      );
    });

    const totalTeamPoints = getSumTotalPointsByType({
      cellList,
      teamId,
      type: 'team',
    });

    return (
      <tr className={className}>
        <td className="black-cell">Host Edits</td>
        <td className="black-cell">{teamQuestionDisplay}</td>
        {teamPointCols}
        <td></td>
        {uniquePointValues.map((value, index) => {
          return <td key={index}></td>;
        })}
        <td></td>
        <td className="blue-cell">{totalTeamPoints}</td>
        <td></td>
      </tr>
    );
  };

  for (let i = 0; i < totalBonusParts; i++) {
    bonusRowsToDisplay.push(
      <BonusRow bonusPartDisplay={i + 1} cellList={cellList} key={i} />,
    );
  }

  return (
    <>
      {bonusRowsToDisplay}
      <TeamRow teamQuestionDisplay="Team Question" cellList={cellList} />
    </>
  );
};

const StyledScoreSheetBonusAndTeamSection = styled(
  ScoreSheetBonusAndTeamSection,
)`
  .blue-cell {
    background-color: #2f75b5;
    color: #fff;
  }
  .black-cell {
    background-color: #000;
    color: #fff;
  }
  && {
    margin: 1rem;
  }
`;

export default StyledScoreSheetBonusAndTeamSection;
