import React from 'react';
import styled from 'styled-components';
import { get } from '../../../../Helpers/UtilHelper';
import { MatchSettingsContext } from '../../../../Hooks/MatchSettingsContext';
import { MatchTemplateContext } from '../../../../Hooks/MatchTemplateContext';
import LoaderCentered from '../../../LoaderCentered';
import SettingsHeader from '../../../SettingsHeader';

const MatchTemplateSettings = ({
  className,
  excludeTemplateDetails = false,
  isEditingMatch = false,
}) => {
  const {
    fetchMatchTemplates,
    publicMatchTemplates,
    privateMatchTemplates,
    setMatchTemplateSettings,
    fetchMatchTemplatesComplete,
  } = React.useContext(MatchTemplateContext);
  const { setMatchSetting, templateID } = React.useContext(
    MatchSettingsContext,
  );
  const [templateDescription, setTemplateDescription] = React.useState(null);
  const [
    defaultMatchTemplateDisabled,
    setDefaultMatchTemplateDisabled,
  ] = React.useState(false);

  React.useEffect(() => {
    fetchMatchTemplates();
  }, []);

  React.useEffect(() => {
    // find template description from chosen match, save it to local state
    if (
      fetchMatchTemplatesComplete &&
      (publicMatchTemplates || privateMatchTemplates)
    ) {
      const matchTemplates = [
        ...publicMatchTemplates,
        ...privateMatchTemplates,
      ];
      const selectedMatchTemplate = matchTemplates.find(
        ({ id }) => id === templateID,
      );
      const selectedMatchTemplateDescription = get(
        selectedMatchTemplate,
        'templateDescription',
        '',
      );
      if (selectedMatchTemplateDescription) {
        setTemplateDescription(selectedMatchTemplateDescription);
      } else {
        setTemplateDescription('');
      }
    }
  }, [templateID, publicMatchTemplates, privateMatchTemplates]);

  const handleSetMatchTemplate = (e) => {
    setDefaultMatchTemplateDisabled(true);
    setMatchTemplateSettings(
      e.target.value,
      excludeTemplateDetails,
      isEditingMatch,
    );
    setMatchSetting({
      payload: {
        templateID: e.target.value,
      },
    });
  };

  const privateMatchTemplateDropdowns = privateMatchTemplates.map(
    ({ id, templateName }) => {
      return (
        <option key={id} value={id}>
          {templateName}
        </option>
      );
    },
  );

  const publicMatchTemplateDropdowns = publicMatchTemplates.map(
    ({ id, templateName }) => {
      return (
        <option key={id} value={id}>
          {templateName}
        </option>
      );
    },
  );

  return (
    <div className={`settings-name-container ${className}`}>
      <SettingsHeader text="Standard Settings" />
      {fetchMatchTemplatesComplete ? (
        <div className="settings-container">
          {publicMatchTemplates.length === 0 &&
          privateMatchTemplates.length === 0 ? (
            <p className="template-error">No Saved Match Settings Available</p>
          ) : (
            <div className="type-container">
              <label className="label-container">
                <span className="select-label">Standard Match Settings</span>
                <select
                  className="select-dropdown"
                  onChange={handleSetMatchTemplate}
                  value={templateID}
                >
                  <option value="" disabled={defaultMatchTemplateDisabled}>
                    None
                  </option>
                  {publicMatchTemplates.length > 0 && (
                    <optgroup label="Shared Match Settings">
                      {publicMatchTemplateDropdowns}
                    </optgroup>
                  )}
                  {privateMatchTemplates.length > 0 && (
                    <optgroup label="Your Saved Match Settings">
                      {privateMatchTemplateDropdowns}
                    </optgroup>
                  )}
                </select>
              </label>
              <div className="label-container">
                <p className="template-description">{templateDescription}</p>
              </div>
            </div>
          )}
        </div>
      ) : (
        <LoaderCentered width="4rem" />
      )}
    </div>
  );
};

const StyledMatchTemplateSettings = styled(MatchTemplateSettings)`
  .settings-container {
    display: flex;
    flex-direction: row;
    align-items: left;
    margin: 1rem;
  }

  .select-label {
  }

  .input-name {
    flex: 1;
  }

  .label-container {
    display: flex;
    padding-right: 1rem;
    align-items: left;
    width: 100%;
    align-items: center;
    flex-direction: column;
  }

  .template-error {
    width: 100%;
    font-weight: bold;
    text-align: center;
  }

  .type-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .select-dropdown {
    width: 75%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .template-description {
    margin: 1rem;
  }

  @media (min-width: 900px) {
    .label-container {
      width: 50%;
    }
    .settings-container {
      width: 100%;
      flex-direction: row;
      align-items: center;
    }

    .type-container {
      flex-direction: row;
    }

    .date-container {
      width: 50%;
    }

    .name-container {
      width: 50%;
    }
    .select-label {
      display: inline;
    }
  }
`;

export default StyledMatchTemplateSettings;
