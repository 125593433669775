import NumberInput from 'Components/NumberInput';
import { useMatchSettings } from 'Hooks/MatchSettingsContext';
import React from 'react';
import styled from 'styled-components';

const PointSettings = ({ className, disabledEdits = false }) => {
  const {
    errors,
    selectedTeams,
    setMatchSetting,
    bonusTotalParts,
    bonusPointsPerPart,
    bonusBouncebackEnabled,
    bonusImmediateBouncebackEnabled,
    bonusQuestionsFollowTossUps,
    bonusControl,
  } = useMatchSettings();

  const handleBonusTotalPartsChange = (e) => {
    setMatchSetting({
      payload: { bonusTotalParts: e.target.value },
      errorsToClear: ['bonusTotalParts'],
    });
  };

  const handleBonusPointsPerPartChange = (e) => {
    setMatchSetting({
      payload: { bonusPointsPerPart: e.target.value },
      errorsToClear: ['bonusPointsPerPart'],
    });
  };

  const handleBonusBouncebackEnabledChange = (e) => {
    setMatchSetting({
      payload: { bonusBouncebackEnabled: e.target.value },
      errorsToClear: ['bonusBouncebackEnabled'],
    });
  };

  const handleBonusImmediateBouncebackEnabledChange = (e) => {
    setMatchSetting({
      payload: { bonusImmediateBouncebackEnabled: e.target.value },
      errorsToClear: ['bonusImmediateBouncebackEnabled'],
    });
  };

  const handleBonusQuestionsFollowTossUpsChange = (e) => {
    setMatchSetting({
      payload: { bonusQuestionsFollowTossUps: e.target.value },
      errorsToClear: ['bonusQuestionsFollowTossUps'],
    });
  };

  const handleBonusControlChange = (e) => {
    setMatchSetting({
      payload: { bonusControl: e.target.value },
      errorsToClear: ['bonusControl'],
    });
  };

  return (
    <div className={`point-settings-container ${className}`}>
      <div className="setting-container">
        <label>
          <span className="select-label">Bonus Parts:</span>
          <select
            disabled={disabledEdits}
            value={bonusTotalParts}
            onChange={handleBonusTotalPartsChange}
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={8}>8</option>
            <option value={10}>10</option>
          </select>
        </label>
      </div>
      <div className="setting-container">
        <div className="setting-container">
          <label>
            <span className="select-label">Bonus Points Per Part:</span>
            <NumberInput
              disabledEdits={disabledEdits}
              shouldRender={true}
              value={bonusPointsPerPart}
              onChange={handleBonusPointsPerPartChange}
              className="inline-display"
            />
          </label>
          {errors.bonusPointsPerPartError && (
            <div className="error-container">
              <p>{errors.bonusPointsPerPartError}</p>
            </div>
          )}
        </div>
      </div>
      <div className="setting-container">
        <label>
          <span className="select-label">Enable Bounceback:</span>
          <select
            disabled={disabledEdits || selectedTeams.length > 2}
            value={bonusBouncebackEnabled}
            onChange={handleBonusBouncebackEnabledChange}
          >
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>
      </div>
      <div className="setting-container">
        <label>
          <span className="select-label">Enable Immediate Bounceback:</span>
          <select
            disabled={disabledEdits || selectedTeams.length > 2}
            value={bonusImmediateBouncebackEnabled}
            onChange={handleBonusImmediateBouncebackEnabledChange}
          >
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>
      </div>
      <div className="setting-container">
        <label>
          <span className="select-label">Bonus Questions Follow Toss-Ups:</span>
          <select
            disabled={disabledEdits}
            value={bonusQuestionsFollowTossUps}
            onChange={handleBonusQuestionsFollowTossUpsChange}
          >
            <option value="ifAnsweredCorrectly">If Answered Correctly</option>
            <option value="ifSelected">If Selected</option>
          </select>
        </label>
      </div>
      <div className="setting-container">
        <label>
          <span className="select-label">Bonus Control:</span>
          <select
            disabled={disabledEdits}
            value={bonusControl}
            onChange={handleBonusControlChange}
          >
            <option value="tossUpWinner">Toss Up Winner</option>
            <option value="prompt">Prompt</option>
            <option value="takeTurns">Take Turns</option>
          </select>
        </label>
      </div>
    </div>
  );
};

const StyledPointSettings = styled(PointSettings)`
  .setting-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0.5px solid #f5f5f5;
    margin: 0.25rem;
  }

  .point-input {
    width: 6rem;
  }

  .select-label {
    display: inline;
  }

  .points-error-container {
    display: flex;
    flex-direction: column;
  }

  .bonus-points-container {
    display: inline;
  }

  @media (min-width: 900px) {
    .setting-container {
      border: none;
      margin: 0;
      flex-direction: row;
    }
  }
`;

export default StyledPointSettings;
