import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import HeaderAuthed from '../../Components/HeaderAuthed';
import LoaderCentered from '../../Components/LoaderCentered';
import Modal from '../../Components/Modal';
import PromptModal from '../../Components/PromptModal';
import { useAuth } from '../../Hooks/AuthContext';
import useHostLoader from '../../Hooks/useHostLoader';
import MatchTemplateView from './Components/MatchTemplateView';
import MatchView from './Components/MatchView';
import StatisticsView from './Components/StatisticsView';
import TeamView from './Components/TeamView';

const Dashboard = ({ className }) => {
  const { loadingComplete } = useHostLoader({
    requiresAuth: true,
    requiresSubscription: true,
  });
  const [pageView, setPageView] = React.useState('matches');
  const [showHostKickModal, setShowHostKickModal] = React.useState(false);
  const [showNewHostModal, setShowNewHostModal] = React.useState(false);
  const location = useLocation();
  const history = useHistory();
  const { matchID: roomToJoin, kickedForNewHost } = location.state || {};
  const { isAdmin } = useAuth();

  React.useEffect(() => {
    if (roomToJoin) setShowNewHostModal(true);
    if (kickedForNewHost) setShowHostKickModal(true);
  }, [roomToJoin, kickedForNewHost]);

  const toggleNewHostModal = () => {
    setShowNewHostModal((prev) => !prev);
    history.replace('/host', { matchID: undefined });
  };

  const toggleHostKickModal = () => {
    setShowHostKickModal((prev) => !prev);
    history.replace('/host', { kickedForNewHost: undefined });
  };

  const handleHostKick = () => {
    history.push(`/host/${roomToJoin}`, { isNewHost: true });
  };

  const renderPageView = (view) => {
    switch (view) {
      case 'matches':
        return <MatchView loadingComplete={loadingComplete} />;
      case 'teams':
        return <TeamView />;
      case 'settings':
        return <MatchTemplateView />;
      case 'statistics':
        return <StatisticsView />;
      default:
        console.log('There was an error displaying the dashboard view.');
        return null;
    }
  };

  const content = (
    <div>
      <div className="button-container">
        <button
          className={`${
            pageView === 'matches' ? 'active' : 'inactive'
          } view-button`}
          onClick={() => setPageView('matches')}
        >
          Matches
        </button>
        <span>|</span>
        <button
          className={`${
            pageView === 'teams' ? 'active' : 'inactive'
          } view-button`}
          onClick={() => setPageView('teams')}
        >
          Teams
        </button>
        <span>|</span>
        <button
          className={`${
            pageView === 'settings' ? 'active' : 'inactive'
          } view-button`}
          onClick={() => setPageView('settings')}
        >
          Match Settings
        </button>
        {isAdmin ? (
          <>
            <span>|</span>
            <button
              className={`${
                pageView === 'statistics' ? 'active' : 'inactive'
              } view-button`}
              onClick={() => setPageView('statistics')}
            >
              Statistics
            </button>
          </>
        ) : null}
      </div>
      {renderPageView(pageView)}
      {showNewHostModal && (
        <Modal
          text="It appears another host session is already active. Would you like to kick the other host session and join the match?"
          confirmAction={handleHostKick}
          toggleModal={toggleNewHostModal}
        />
      )}
      {showHostKickModal && (
        <PromptModal
          descriptionText="You were removed from the match. Only one active host can be in a match at a time."
          toggleModal={toggleHostKickModal}
        />
      )}
    </div>
  );

  return (
    <div className={`dashboard ${className}`}>
      <HeaderAuthed isHost />
      {loadingComplete ? content : <LoaderCentered />}
    </div>
  );
};

const StyledDashboard = styled(Dashboard)`
  .button-container {
    margin-top: 1rem;
    margin-left: 10%;
    font-size: 1.75rem;

    a {
      color: #000;
    }
  }

  .view-button {
    padding: 0 1.5rem;
    line-height: 2rem;
    color: #000;
    background-color: transparent;
    border: none;
    font-weight: normal;
    font-size: 1.4rem;
    transition: none;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      text-decoration: underline;
      outline: none;
    }
  }

  .active {
    font-weight: bold;
    text-decoration: underline;
  }

  .inactive {
    text-decoration: none;
  }

  @media (min-width: 900px) {
  }
`;

export default StyledDashboard;
