import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LoadingModal from '../../Components/CheckoutLoadingModal';
import HeaderAuthed from '../../Components/HeaderAuthed';
import LoaderCentered from '../../Components/LoaderCentered';
import { getProducts, manageSubscription } from '../../Helpers/Api/Customer';
import {
  formatPrice,
  getLevelDisplay,
  getSubStatusDisplay,
  isBypassUser,
} from '../../Helpers/CustomerHelper';
import { get } from '../../Helpers/UtilHelper';
import { CustomerContext } from '../../Hooks/CustomerContext';
import useHostLoader from '../../Hooks/useHostLoader';
import CheckoutForm from './Components/CheckoutForm';
import DetailItem from './Components/DetailItem';

const HostAccount = ({ className }) => {
  const { loadingComplete } = useHostLoader({ requiresAuth: true });
  const [products, setProducts] = React.useState([]);
  const [fetchingProducts, setFetchingProducts] = React.useState(false);
  const { customer, hasValidSubscription, isLoading } = React.useContext(
    CustomerContext,
  );
  const hasSubscription = isLoading || hasValidSubscription();
  const [selectedProductId, setSelectedProductId] = React.useState(null);
  const [
    submittingManageSubscription,
    setSubmittingManageSubscription,
  ] = React.useState(false);

  const {
    firstName,
    lastName,
    level,
    nextBillingAt,
    organization,
    state,
    stripeCustomerId,
    stripeProductId,
    stripeSubscriptionStatus,
    subscriptionEnding,
  } = customer || {};

  React.useEffect(() => {
    const fetchProducts = async () => {
      setFetchingProducts(true);
      const response = await getProducts();
      const newProducts = response && response.products;
      setProducts(newProducts || []);
      setFetchingProducts(false);
    };
    if (stripeCustomerId && !isBypassUser(stripeCustomerId)) fetchProducts();
  }, [stripeCustomerId]);

  React.useEffect(() => {
    if (!selectedProductId) {
      const featuredProduct = products.find(
        (product) => get(product, 'price.recurring.interval') === 'year',
      );
      if (featuredProduct) setSelectedProductId(featuredProduct.id);
    }
  }, [products, selectedProductId]);

  const currentProduct = React.useMemo(() => {
    return products.find((product) => product.id === stripeProductId);
  }, [products, stripeProductId]);

  const onManageSubscription = async () => {
    setSubmittingManageSubscription(true);
    const response = await manageSubscription({
      stripeCustomerId,
      url: window.location.origin,
    });
    const portalUrl = get(response, 'session.url');
    if (!portalUrl) {
      setSubmittingManageSubscription(false);
      return;
    }
    window.location.href = portalUrl;
  };

  const {
    description: planDescription,
    name: planName,
    price: {
      recurring: { interval: planInterval } = {},
      unit_amount: planPrice,
    } = {},
  } = currentProduct || {};

  const statusDisplay = React.useMemo(
    () => getSubStatusDisplay(stripeCustomerId, stripeSubscriptionStatus),
    [stripeCustomerId, stripeSubscriptionStatus],
  );

  const levelDisplay = React.useMemo(() => getLevelDisplay(level), [level]);

  let billingDisplay = 'N/A';
  if (nextBillingAt) {
    const dateDisplay = new Date(nextBillingAt).toLocaleDateString();
    if (subscriptionEnding) {
      billingDisplay = `Your subscription will expire on ${dateDisplay}`;
    } else {
      billingDisplay = `You will be charged ${formatPrice(
        planPrice,
      )} on ${dateDisplay}`;
    }
  }

  let planContent = null;
  if (fetchingProducts) {
    planContent = <LoaderCentered width="4rem" />;
  } else if (hasValidSubscription()) {
    planContent = (
      <>
        <DetailItem label="Plan" value={planName || 'N/A'} />
        <DetailItem label="Status" value={statusDisplay} />
        <DetailItem label="Billing" value={billingDisplay} />
        {!isBypassUser(stripeCustomerId) && (
          <button
            className="manage-button"
            disabled={submittingManageSubscription}
            onClick={onManageSubscription}
          >
            Manage Subscription
          </button>
        )}
        {submittingManageSubscription && <LoadingModal />}
      </>
    );
  } else if (products && products.length > 0) {
    planContent = <CheckoutForm products={products} />;
  } else if (isBypassUser(stripeCustomerId)) {
    planContent = (
      <div className="details-title">Your subscription has ended.</div>
    );
  }

  const content = (
    <div className="container">
      <h1 className="title">My Account</h1>
      {!hasSubscription && (
        <div className="error-container">
          <p>
            You do not have an active subscription. To continue using QuizBowl
            PRO, please subscribe below.
          </p>
        </div>
      )}
      <div className="details-container">
        <div className="details-item-container">
          <h5 className="details-title">Details</h5>
          <DetailItem label="Name" value={`${firstName} ${lastName}`} />
          <DetailItem label="Organization" value={organization} />
          <DetailItem label="Level" value={levelDisplay} />
          <DetailItem label="State" value={state} />
          <Link className="link" to="/host/change-email">
            Change Email Address
          </Link>
        </div>
        <div className="details-item-container">
          <h5 className="details-title">Subscription</h5>
          {planContent}
        </div>
      </div>
    </div>
  );

  return (
    <div className={`account ${className}`}>
      <HeaderAuthed isHost />
      {loadingComplete ? content : <LoaderCentered />}
    </div>
  );
};

const StyledHostAccount = styled(HostAccount)`
  .container {
    margin-bottom: 5rem;
  }

  .error-container {
    text-align: center;
    font-size: 1.75rem;
    margin-bottom: 3rem;

    & p {
      width: 100%;
    }
  }

  .title {
    font-size: 3.5rem;
    margin: 2rem 2rem 4rem;
    text-align: center;
  }

  .details-container {
    display: flex;
    flex-direction: column;
  }

  .details-item-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .details-title {
    text-align: center;
  }

  .divider {
    height: 3rem;
  }

  .plans-container {
    display: flex;
  }

  .disclaimer {
    font-size: 1.7rem;
    line-height: 2.5rem;
    margin: 2rem;
    text-align: center;
  }

  .manage-button {
    width: 70%;
    margin: 1rem 10% 0 20%;
    font-size: 2rem;
  }

  .link {
    text-align: center;
  }

  @media (min-width: 900px) {
    .details-container {
      flex-direction: row;
    }
  }
`;

export default StyledHostAccount;
