import React from 'react';
import {
  getNumberOfQuestionPoints,
  getOtherPlayersFromScoreList,
  getQuestionPoints,
  getScoreIDFromScoreList,
  getSumTotalHostEditPoints,
  getTeamPlayers,
  getTeams,
  getTotalBonusParts,
  getUniquePointValues,
} from '../Helpers/ScoreSheetHelper';

export const ScoreSheetContext = React.createContext(null);
export const { Provider, Consumer: ScoreSheetConsumer } = ScoreSheetContext;

export const SET_CELL_LIST = 'SET_CELL_LIST';
export const SET_CELL = 'SET_CELL';
export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const RESET_STATE = 'RESET_STATE';

const defaultScoreSheetState = {
  cellList: {},
  originalCellList: {},
  editsObj: {},
  matchID: '', // not used
  totalNumberQuestions: 0,
  uniquePointValues: [],
  totalBonusParts: 0,
  errors: {},
  hostEdits: {},
};

const scoreSheetReducer = (state, message) => {
  const { type, payload } = message;

  switch (type) {
    case SET_CELL_LIST: {
      const { cellList, hostEdits } = payload;
      const totalNumberQuestions = getNumberOfQuestionPoints({
        cellList,
      });
      const uniquePointValues = getUniquePointValues({ cellList });
      const totalBonusParts = getTotalBonusParts({ cellList });
      return {
        ...state,
        cellList,
        originalCellList: { ...cellList },
        totalNumberQuestions,
        uniquePointValues,
        totalBonusParts,
        hostEdits,
      };
    }
    case SET_CELL: {
      const { cellId, pointsDisplay: newPoints, shouldClearErrors } = payload;
      const { cellList } = state;

      const newCell = {
        ...cellList[cellId],
        points: newPoints,
      };

      let newCellList = {
        ...cellList,
        [cellId]: newCell,
      };

      const newUniquePointValues = getUniquePointValues({
        cellList: newCellList,
      });

      return {
        ...state,
        cellList: newCellList,
        uniquePointValues: newUniquePointValues,
        ...(shouldClearErrors && { errors: {} }),
      };
    }
    case SET_ERRORS: {
      const { newErrors } = payload;
      const { errors } = state;

      return {
        ...state,
        errors: {
          ...errors,
          ...newErrors,
        },
      };
    }
    // case CLEAR_ERRORS: {
    //   return {
    //     ...state,
    //     errors: {},
    //   };
    // }
    case RESET_STATE: {
      return defaultScoreSheetState;
    }
    default: {
      console.log(`Timer reducer type ${type} is not supported.`);
      return state;
    }
  }
};

export const ScoreSheetProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    scoreSheetReducer,
    defaultScoreSheetState,
  );

  const initCellList = ({ scoreList }) => {
    const totalNumberQuestions = getNumberOfQuestionPoints({ scoreList });
    const totalBonusParts = getTotalBonusParts({ scoreList });
    const teamIds = getTeams({ scoreList });
    let cellList = {};
    let hostEdits = {};

    teamIds.forEach((teamId) => {
      const teamPlayers = getTeamPlayers({ scoreList, teamId });
      for (let i = 0; i < totalNumberQuestions; i++) {
        const hostEditPoints = getSumTotalHostEditPoints({
          scoreList,
          teamId,
        });
        hostEdits = {
          ...hostEdits,
          [teamId]: hostEditPoints,
        };
        // cells for toss-up questions
        teamPlayers.forEach((player) => {
          const questionNumber = i + 1;
          const playerName = player.playerName;
          const playerId = player.playerID;
          const type = 'playerTossUp';
          const scoreId = getScoreIDFromScoreList({
            scoreList,
            type,
            questionNumber,
            teamId,
          });
          const otherPlayers = getOtherPlayersFromScoreList({
            scoreList,
            type,
            questionNumber,
            playerName,
          });
          const scoreCellId = `${player.playerName}-${teamId}-${questionNumber}-${type}`;
          let points = getQuestionPoints({
            playerName,
            questionNumber,
            scoreList,
            teamId,
            type,
          });
          cellList[scoreCellId] = {
            cellId: scoreCellId,
            otherPlayers,
            points,
            playerName,
            playerId,
            questionNumber,
            scoreId,
            teamId,
            type,
          };
        });

        // cells for bonus
        for (let j = 0; j < totalBonusParts; j++) {
          const questionNumber = i + 1;
          const type = `bonusPart-${j + 1}`;
          const scoreId = getScoreIDFromScoreList({
            scoreList,
            type,
            questionNumber,
            teamId,
          });
          const otherPlayers = getOtherPlayersFromScoreList({
            scoreList,
            type,
            questionNumber,
          });
          const scoreCellId = `${teamId}-${questionNumber}-${type}`;
          let points = getQuestionPoints({
            questionNumber,
            scoreList,
            teamId,
            type,
          });
          cellList[scoreCellId] = {
            cellId: scoreCellId,
            otherPlayers,
            points,
            playerId: 0,
            questionNumber,
            scoreId,
            teamId,
            type,
          };
        }

        // cells for team
        const questionNumber = i + 1;
        const type = `team`;
        const scoreId = getScoreIDFromScoreList({
          scoreList,
          type,
          questionNumber,
          teamId,
        });
        const otherPlayers = getOtherPlayersFromScoreList({
          scoreList,
          type,
          questionNumber,
        });
        const scoreCellId = `${teamId}-${questionNumber}-${type}`;
        let points = getQuestionPoints({
          questionNumber,
          scoreList,
          teamId,
          type,
        });
        cellList[scoreCellId] = {
          cellId: scoreCellId,
          otherPlayers,
          points,
          playerId: 0,
          questionNumber,
          scoreId,
          teamId,
          type,
        };
      }
    });

    dispatch({
      type: SET_CELL_LIST,
      payload: {
        cellList,
        hostEdits,
      },
    });
  };

  const resetErrors = () => {
    dispatch({
      type: CLEAR_ERRORS,
    });
  };

  const value = {
    initCellList,
    ...state,
    dispatch,
    resetErrors,
  };
  return <Provider value={value}>{children && children}</Provider>;
};
