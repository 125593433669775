import React from 'react';
import styled from 'styled-components';
import { useSocket } from '../../../../Hooks/SocketContext';

const StatisticsView = ({ className }) => {
  const { totalClientsConnected } = useSocket();
  return (
    <div className={className}>
      <div className="template-container">
        <h3>Statistics</h3>
        <p>Total clients connected: {totalClientsConnected}</p>
      </div>
    </div>
  );
};

const StyledStatisticsView = styled(StatisticsView)`
  width: 100%;
  font-size: 2rem;
  line-height: 1.75rem;
  margin: 0 auto 1rem;

  .template-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 10%;
  }
`;

export default StyledStatisticsView;
