export const SUBMIT_BUTTON_TEXT = 'Subscribe';

export const isStatusValid = (status) => {
  let isValid;
  switch (status) {
    // valid states
    case 'trialing':
    case 'active':
    case 'incomplete':
    case 'past_due':
    case 'unpaid':
      isValid = true;
      break;

    // invalid states
    case 'canceled':
    case 'incomplete_expired':
      isValid = false;
      break;
    default:
      break;
  }
  return isValid;
};

export const isBypassUser = (stripeCustomerId) =>
  stripeCustomerId && stripeCustomerId.includes('bypass-');

export const isBypassCodeValid = (bypassCodes, stripeCustomerId) => {
  const bypassCode =
    isBypassUser(stripeCustomerId) && stripeCustomerId.replace('bypass-', '');
  return (
    bypassCode && Array.isArray(bypassCodes) && bypassCodes.includes(bypassCode)
  );
};

export const isValidCustomer = ({ bypassCodes, customer }) => {
  let valid = false;
  try {
    if (
      customer &&
      (isStatusValid(customer.stripeSubscriptionStatus) ||
        isBypassCodeValid(bypassCodes, customer.stripeCustomerId))
    ) {
      valid = true;
    }
  } catch (err) {
    console.log('isValidCustomer error:', err.message);
  }
  return valid;
};

export const formatPrice = (unitAmount) => {
  if (!unitAmount) return '';
  const amountString = (unitAmount / 100).toString();
  const amountLength = amountString.length;
  const decimalIndex = amountString.indexOf('.');
  const hasDecimal = decimalIndex !== -1;
  const decimalPosition = amountLength - decimalIndex;
  let price = '';
  if (!hasDecimal) {
    price = `${amountString}.00`;
  } else if (decimalPosition === 2) {
    price = `${amountString}0`;
  } else if (decimalPosition === 3) {
    price = amountString;
  } else {
    price = amountString.substr(0, decimalIndex + 2);
  }
  return `$${price}`;
};

export const fetchCodes = async () => {
  let data = [];
  try {
    const response = await fetch(process.env.REACT_APP_BYPASS_CODE_URL);
    data = await response.json();
  } catch (err) {
    console.log('Fetch Bypass Codes Error:', err.message);
  }
  return data;
};

export const getSubStatusDisplay = (
  stripeCustomerId,
  stripeSubscriptionStatus,
) => {
  if (isBypassUser(stripeCustomerId)) return 'Active';
  switch (stripeSubscriptionStatus) {
    case 'trialing':
      return 'Trial';
    case 'active':
      return 'Active';
    case 'incomplete':
      return 'Incomplete';
    case 'past_due':
      return 'Past Due';
    case 'unpaid':
      return 'Unpaid';
    case 'canceled':
      return 'Cancelled';
    case 'incomplete_expired':
      return 'Incomplete Expired';
    default:
      return 'N/A';
  }
};

export const getLevelDisplay = (level) => {
  switch (level) {
    case 'highSchool':
      return 'High School';
    case 'middleSchool':
      return 'Middle School';
    case 'elementary':
      return 'Elementary';
    case 'collegiate':
      return 'Collegiate';
    default:
      return 'N/A';
  }
};
