import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import styled from 'styled-components';
import LoadingModal from '../../../Components/CheckoutLoadingModal';
import PlanButton from '../../../Components/PlanButton';
import { checkout } from '../../../Helpers/Api/Customer';
import { SUBMIT_BUTTON_TEXT } from '../../../Helpers/CustomerHelper';
import { get } from '../../../Helpers/UtilHelper';
import { CustomerContext } from '../../../Hooks/CustomerContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CheckoutForm = ({ className, products }) => {
  const { customer } = React.useContext(CustomerContext);
  const [selectedProductId, setSelectedProductId] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (!selectedProductId) {
      const featuredProduct = products.find(
        (product) => get(product, 'price.recurring.interval') === 'year',
      );
      if (featuredProduct) setSelectedProductId(featuredProduct.id);
    }
  }, [products, selectedProductId]);

  const onSubmit = async () => {
    setError(null);
    setIsSubmitting(true);
    const { id: customerId, stripeCustomerId } = customer || {};
    const selectedProduct = products.find(
      (product) => product.id === selectedProductId,
    );
    const planID = get(selectedProduct, 'price.id');

    // create stripe session
    const checkoutResponse = await checkout({
      customerId,
      planID,
      stripeCustomerId,
      url: window.location.origin,
    });
    const sessionId = get(checkoutResponse, 'session.id');
    if (!checkoutResponse || !sessionId) {
      setError('Something went wrong. Try again. (Code: CHECKOUT-1)');
      setIsSubmitting(false);
      return;
    }

    // attempt to redirect to stripe checkout page
    const stripe = await stripePromise;
    const { error: { message } = {} } = await stripe.redirectToCheckout({
      sessionId,
    });
    if (message)
      setError('Something went wrong. Try again. (Code: CHECKOUT-2)');
    setIsSubmitting(false);
  };

  return (
    <div className={`checkout-form ${className}`}>
      {error && (
        <div className="error-container">
          <p>{error}</p>
        </div>
      )}
      <div className="plans-container">
        {products.map((product) => (
          <PlanButton
            key={product.id}
            onClick={() => setSelectedProductId(product.id)}
            product={product}
            selected={product.id === selectedProductId}
          />
        ))}
      </div>
      <button
        className="submit-button"
        disabled={isSubmitting}
        onClick={onSubmit}
      >
        {SUBMIT_BUTTON_TEXT}
      </button>
      <div className="disclaimer">
        By clicking "<span className="uppercase">{SUBMIT_BUTTON_TEXT}</span>",
        you agree to our{' '}
        <a
          href="http://www.quizbowlsystems.com/termsandconditions.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </a>{' '}
        and acknowledge receipt of our{' '}
        <a
          href="http://www.quizbowlsystems.com/privacypolicy.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </div>
      {isSubmitting && <LoadingModal />}
    </div>
  );
};

const StyledCheckoutForm = styled(CheckoutForm)`
  .error-container {
    text-align: center;
    font-size: 1.75rem;
    margin-bottom: 3rem;

    & p {
      width: 100%;
    }
  }

  .plans-container {
    display: flex;
  }

  .disclaimer {
    font-size: 1.7rem;
    line-height: 2.5rem;
    margin: 2rem;
    text-align: center;
  }

  .submit-button {
    width: 80%;
    margin: 2rem 10% 0;
    font-size: 2rem;
  }

  .uppercase {
    text-transform: uppercase;
  }
`;

export default StyledCheckoutForm;
