import { keyFromDeviceID } from 'Helpers/DeviceIdHelper';
import useRingInKeyPress from 'Hooks/useRingInKeyPress';
import React from 'react';
import styled from 'styled-components';
import Modal from '../../../../Components/Modal';
import { WindowClose as KickIcon } from '../../../../Helpers/IconHelper';
import {
  MATCH_STATE_RING_IN_TEST_OPEN,
  MESSAGE_TYPE_LEAVE_ROOM,
  MESSAGE_TYPE_RING_IN,
  MESSAGE_TYPE_RING_IN_TEST,
} from '../../../../Helpers/MessageHelper';
import { get } from '../../../../Helpers/UtilHelper';
import { SocketContext } from '../../../../Hooks/SocketContext';

const PlayerItem = ({
  className,
  matchPlayer,
  currentPlayerID,
  isHost,
  match,
  matchState,
  canRingIn,
}) => {
  const { activePlayerID, sendMessage } = React.useContext(SocketContext);
  const [showModal, setShowModal] = React.useState(false);

  const { id: matchID } = match || {};
  const { id: playerID, name } = matchPlayer.player || {};
  const playerIsInRoom =
    playerID === currentPlayerID || get(matchPlayer, 'active');
  const isActivePlayer = playerID === activePlayerID;
  const classes = `${isActivePlayer ? 'buzzed-player' : ''} ${
    playerIsInRoom ? 'is-in-room' : ''
  }`;
  const ringInEnabled = canRingIn && isHost;

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const handleKickPlayer = () => {
    if (matchID) {
      sendMessage({
        matchID,
        type: MESSAGE_TYPE_LEAVE_ROOM,
        payload: { playerID: matchPlayer.playerID },
      });
    }
    toggleModal();
  };

  const handleRingIn = React.useCallback(() => {
    if (!ringInEnabled) return;
    const messageType =
      matchState === MATCH_STATE_RING_IN_TEST_OPEN
        ? MESSAGE_TYPE_RING_IN_TEST
        : MESSAGE_TYPE_RING_IN;
    sendMessage({ matchID, type: messageType, payload: { playerID } });
  }, [matchID, ringInEnabled, matchState, playerID]);

  useRingInKeyPress(handleRingIn, [keyFromDeviceID(matchPlayer.deviceID)]);

  const nameDisplay = ringInEnabled ? (
    <button className="player-name-button" onClick={handleRingIn}>
      {name}
    </button>
  ) : (
    <p className="player-name">{name}</p>
  );

  return (
    <div key={playerID} className={`${classes} ${className}`}>
      {nameDisplay}
      {isHost && playerIsInRoom ? (
        <button className="kick-button" onClick={toggleModal}>
          <KickIcon />
        </button>
      ) : null}
      {showModal ? (
        <Modal
          confirmAction={handleKickPlayer}
          toggleModal={toggleModal}
          text="Are you sure you want to remove this player?"
        />
      ) : null}
    </div>
  );
};

const StyledPlayerItem = styled(PlayerItem)`
  line-height: 2rem;
  border-bottom: 1px solid black;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  font-size: 1.5rem;

    &.is-in-room {
      background-color: #fff;
    }

    &.buzzed-player {
      font-weight: bold;
      background-color: yellow;
    }

    .player-name {
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-grow: 1;
    }

    .player-name-button {
      color: #333;
      background: transparent;
      border: none;
      font-weight: 400;
      padding: 0;
      margin: 0;
      height: auto;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: normal;
      text-transform: none;
      width: 90%;
      text-align: center;
      transition: none;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: 'Open Sans', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;

      &:hover {
        font-weight: bold;
      }
    }

    .kick-button {
      height: 1.7rem;
      width: 1.7rem;
      line-height: 0;
      padding: 0;
      margin-bottom: 0;
      flex-shrink: 0;
      background-color: transparent;
      color: red;
      border: none;
      font-size: 1.5rem;

      &:hover {
        background-color: red;
        color: white;
      }

      &:focus {
        border: 1px solid black;
      }
    }
  }

  @media (min-width: 900px) {
    font-size: 2rem;
    line-height: 3rem;
    border-bottom: 1px solid black;

    .kick-button {
      height: 2rem;
      width: 2rem;
      font-size: 1.8rem;
    }
  }
`;

export default StyledPlayerItem;
