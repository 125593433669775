import React from 'react';
import styled from 'styled-components';
import { formatPrice } from '../../Helpers/CustomerHelper';

const PlanButton = ({ className, onClick, product, selected }) => {
  const {
    name,
    price: { recurring: { interval } = {}, unit_amount: unitAmount } = {},
  } = product;
  return (
    <button
      className={`${className} ${selected ? 'selected' : ''}`}
      onClick={onClick}
    >
      <div className="plan-name">{name}</div>
      <div className="price">{`${formatPrice(unitAmount)}/${interval}`}</div>
    </button>
  );
};

const StyledPlanButton = styled(PlanButton)`
  background-color: #fff;
  border: 2px solid #fff;
  color: #555;
  width: auto;
  height: auto;
  margin: 0 1rem;
  padding: 1rem 1.5rem;
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
  font-weight: 400;
  text-transform: none;
  transition: none;
  white-space: pre-line;

  &:hover {
    border: 2px solid #fff;
    color: #555;
  }

  &:focus {
    border: 2px solid steelblue;
    color: #555;
  }

  &.selected {
    border-color: steelblue;
    color: steelblue;

    &:hover {
      color: steelblue;
    }

    &:focus {
      border: 2px solid #steelblue;
    }
  }

  .plan-name {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
`;

export default StyledPlanButton;
