import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import HeaderUnauthed from '../../Components/HeaderUnauthed';
import LoaderCentered from '../../Components/LoaderCentered';
import { getCurrentUser } from '../../Helpers/AuthHelper';
import { get, getSignUpEmailUrl } from '../../Helpers/UtilHelper';
import { CustomerContext } from '../../Hooks/CustomerContext';

const VALIDATE_RETRIES = 10;

const ConfirmPurchase = ({ className }) => {
  const history = useHistory();
  const { fetchCustomer, hasValidSubscription } = React.useContext(
    CustomerContext,
  );

  const validateCustomer = async (retries) => {
    const customer = await fetchCustomer();
    if (hasValidSubscription(customer)) {
      handleSuccess(customer);
      return;
    }
    if (retries <= 0) {
      console.log('Error: No more retries');
      handleFail();
      return;
    }
    let timeoutDuration = 1000;
    if (retries < 8) timeoutDuration = 2000;
    if (retries < 4) timeoutDuration = 4000;
    setTimeout(() => {
      validateCustomer(retries - 1);
    }, timeoutDuration);
  };

  const handleFail = () => {
    history.push('host/account');
  };

  const handleSuccess = async (customer) => {
    const { firstName, lastName } = customer || {};
    const user = await getCurrentUser();
    const email = get(user, 'attributes.email', '');
    const url = getSignUpEmailUrl({
      email,
      firstName,
      lastName,
      path: '/host',
    });
    window.location = url;
  };

  React.useEffect(() => {
    validateCustomer(VALIDATE_RETRIES);
  }, []);

  return (
    <div className={`confirm-purchase ${className}`}>
      <HeaderUnauthed disableHomeLink hideHostLogin />
      <div className="loader-container">
        <LoaderCentered />
      </div>
      <div className="description">
        Just a moment while we confirm your purchase...
      </div>
    </div>
  );
};

const StyledConfirmPurchase = styled(ConfirmPurchase)`
  font-size: 2rem;
  line-height: 1.75rem;
  text-align: center;

  .description {
    margin-top: 4rem;
  }
`;

export default StyledConfirmPurchase;
