import React from 'react';
import styled from 'styled-components';

export function LinkButton({ children, className, onClick }) {
  return (
    <StyledLinkButton className={className} onClick={onClick}>
      {children}
    </StyledLinkButton>
  );
}

const StyledLinkButton = styled.button`
  display: block;
  margin: 5px;
  color: #1eaedb;
  height: auto;
  padding: 0;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0rem;
  background-color: transparent;
  line-height: 1.5rem;
  border: none;
  text-transform: none;
  text-decoration: underline;

  &:hover {
    color: #0fa0ce;
  }
`;
