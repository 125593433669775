import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import IconButton from '../../../../../../Components/IconButton';
import StyledModal from '../../../../../../Components/Modal';
import ScoreSheetModal from '../../../../../../Components/ScoreSheetModal';
import { updateMatch } from '../../../../../../Helpers/Api/Match';
import {
  Edit,
  ScoresClipboard,
  Trash,
} from '../../../../../../Helpers/IconHelper';
import { enableViewScores } from '../../../../../../Helpers/ScoreSheetHelper';
import { get } from '../../../../../../Helpers/UtilHelper';
import { MatchSettingsContext } from '../../../../../../Hooks/MatchSettingsContext';
import EditMatchModal from '../EditMatchModal';

const enableEditMatch = (createdAt) => {
  if (!createdAt) return false;
  const playerOrderReleaseDate = '2020-09-14T02:00:00.000Z';
  return new Date(createdAt) > new Date(playerOrderReleaseDate);
};

const MatchListItem = ({
  className,
  match = {},
  fetchMatches,
  deleteMatch = () => {},
}) => {
  const [showViewScoreSheetModal, setViewScoreSheetModal] = React.useState(
    false,
  );
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { handleResetState, initMatchSettings } = React.useContext(
    MatchSettingsContext,
  );
  const matchID = get(match, 'id');
  const matchName = get(match, 'matchName', 'n/a');
  const matchDate = get(match, 'matchDate');
  const date = matchDate ? format(new Date(matchDate), 'PP') : 'n/a';
  const matchStatus = get(match, 'matchStatus') || 'n/a';
  const matchSettings = get(match, 'settings.items.0', {});
  const {
    createdAt,
    players: { items: matchPlayers = [] } = {},
    scores: { items: scoreList = [] } = {},
    teams: { items: matchTeams = [] } = {},
  } = match || {};

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const toggleEditModal = (value = false) => {
    if (value) {
      const settings = {
        ...matchSettings,
        matchName,
        matchDate: format(new Date(matchDate), 'yyyy-MM-dd'),
      };
      initMatchSettings({ matchPlayers, matchTeams, settings });
    } else {
      handleResetState();
    }
    setShowEditModal(value);
  };

  const toggleViewScoreSheetModal = () => {
    setViewScoreSheetModal((prev) => !prev);
  };

  const handleDeleteMatch = async () => {
    setIsSubmitting(true);
    const updatedMatch = await updateMatch({
      matchID,
      matchDate,
      matchName,
      matchStatus,
      propsToUpdate: { active: 0 },
    });
    if (updatedMatch) {
      toggleDeleteModal();
      deleteMatch(matchID);
    }
  };

  React.useEffect(() => {
    showDeleteModal || showEditModal || showViewScoreSheetModal
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }, [showDeleteModal, showEditModal, showViewScoreSheetModal]);

  return (
    <tr className={`matchitem-container ${className}`} key={matchID}>
      <td>
        <Link to={`/host/${matchID}`}>{matchID}</Link>
      </td>
      <td>{matchName}</td>
      <td>{date}</td>
      <td className={`${matchStatus === 'n/a' ? '' : 'match-state'}`}>
        {matchStatus}
      </td>
      <td>
        {enableViewScores(createdAt) && (
          <IconButton
            Icon={ScoresClipboard}
            onClick={toggleViewScoreSheetModal}
          />
        )}
        {showViewScoreSheetModal && (
          <ScoreSheetModal
            text="Detailed Score Sheet"
            toggleModal={toggleViewScoreSheetModal}
            match={match}
            matchID={matchID}
          />
        )}
        {enableEditMatch(createdAt) && (
          <IconButton Icon={Edit} onClick={() => toggleEditModal(true)} />
        )}
        {showEditModal && (
          <EditMatchModal
            fetchMatches={fetchMatches}
            matchID={matchID}
            matchStatus={matchStatus}
            toggleEditMatchModal={toggleEditModal}
          />
        )}
        <IconButton Icon={Trash} onClick={toggleDeleteModal} />
        {showDeleteModal ? (
          <StyledModal
            confirmAction={handleDeleteMatch}
            confirmLoading={isSubmitting}
            toggleModal={toggleDeleteModal}
            text="Are you sure you want to delete this match?"
          />
        ) : null}
      </td>
    </tr>
  );
};
const StyledMatchListItem = styled(MatchListItem)`
  .match-state {
    text-transform: capitalize;
  }
`;

export default StyledMatchListItem;
