export const SYSTEM_STAT_NEW_CONNECTION = 'SYSTEM_STAT_NEW_CONNECTION';
export const SYSTEM_STAT_SOCKET_DISCONNECTED =
  'SYSTEM_STAT_SOCKET_DISCONNECTED';

export const systemStatsState = {
  totalClientsConnected: 0,
};

export const systemStatsReducer = (state, stat) => {
  const { type, payload } = stat;
  switch (type) {
    case SYSTEM_STAT_NEW_CONNECTION:
    case SYSTEM_STAT_SOCKET_DISCONNECTED: {
      const { totalClientsConnected } = payload;
      return { ...state, totalClientsConnected };
    }
    default: {
      return { ...state };
    }
  }
};
