import React from 'react';
import styled from 'styled-components';
import LoaderCentered from '../../../../../../Components/LoaderCentered';
import SettingsHeader from '../../../../../../Components/SettingsHeader';
import { MatchTemplateContext } from '../../../../../../Hooks/MatchTemplateContext';
import MatchTemplateListItem from '../MatchTemplateListItem';

const MatchTemplatesList = ({ className, headerText, matchTemplates = [] }) => {
  const { fetchMatchTemplatesComplete } = React.useContext(
    MatchTemplateContext,
  );
  let content;
  if (matchTemplates.length === 0) {
    content = (
      <tr>
        <td colSpan="2">
          <p className="template-error">No Match Settings Available</p>
        </td>
      </tr>
    );
  } else {
    content = matchTemplates.map((template) => {
      return <MatchTemplateListItem template={template} key={template.id} />;
    });
  }

  return (
    <div className={`matchlist-container ${className}`}>
      <SettingsHeader text={headerText} />
      <table className="matchlist-table">
        <thead>
          <tr>
            <th className="col-med">Settings Name</th>
            <th className="col-med">Actions</th>
          </tr>
        </thead>
        <tbody>
          {fetchMatchTemplatesComplete ? (
            content
          ) : (
            <tr>
              <td colSpan="2">
                <LoaderCentered width="4rem" />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const StyledMatchTemplatesList = styled(MatchTemplatesList)`
  width: 100%;
  line-height: 2rem;
  font-size: 1.6rem;
  text-align: center;

  .template-error {
    width: 100%;
    font-weight: bold;
    text-align: center;
  }

  .name-button {
    color: #1EAEDB;
    width: auto;
    height: auto;
    background-color: #fff;
    padding: 0;
    margin: 0;
    border 0px;
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: center;
    font-weight: 400;
    text-transform: none;
    text-decoration: underline;
    white-space: pre-line;

    &:hover {
      color: #0FA0CE;
    }
  }

  .loading-text {
    text-align: center;
    font-weight: bold;
    padding: 5rem;
  }

  .loading-container {
    display: flex;
    justify-content: center;
  }

  .matchlist-header {
    margin: 2rem auto;
    padding: 1rem;
    background-color: steelblue;
    color: white;
    border-radius: 3px;
  }

  .matchlist-table {
    width: 100%;
  }

  .sort-header:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .sort-spacer {
    display: inline-block;
    width: 16px;
    height: '16px';
  }

  .col-lg {
    width: 45%;
  }

  .col-med {
    width: 15%;
  }

  .col-sm {
    width: 10%;
  }

  th,
  td {
    text-align: center;
  }

  .loadmore-button {
    margin-top: 1rem;
  }

  @media (min-width: 900px) {
    margin: 0 1rem;
    max-width: 80%;
  }
`;

export default StyledMatchTemplatesList;
