import React from 'react';
import styled from 'styled-components';
import {
  DESC,
  fetchMatchesByDateOrder,
  fetchMatchesByNameOrder,
} from '../../../../Helpers/Api/Match';
import {
  CLEAR_STATE_NEW_SORT,
  dashboardReducer,
  DATE,
  DELETE_MATCH,
  initialState,
  NAME,
  SET_MATCH_SORT_STATE,
  TOGGLE_CREATE_MATCH_MODAL,
} from '../../../../Helpers/DashboardHelper';
import { get } from '../../../../Helpers/UtilHelper';
import { MatchSettingsContext } from '../../../../Hooks/MatchSettingsContext';
import CreateMatchModal from './Components/CreateMatchModal';
import MatchList from './Components/MatchList';

const MatchView = ({ className, loadingComplete }) => {
  const [state, dispatch] = React.useReducer(dashboardReducer, initialState);
  const { handleResetState } = React.useContext(MatchSettingsContext);
  const {
    matchList,
    showCreateMatchModal,
    matchListToken,
    matchSortType,
    matchSortOrder,
    fetchMatchesComplete,
    fetchMoreMatchesComplete,
  } = state;

  React.useEffect(() => {
    if (loadingComplete) {
      fetchMatches();
    }
  }, []);

  const toggleCreateMatchModal = () => {
    dispatch({
      type: TOGGLE_CREATE_MATCH_MODAL,
    });
    handleResetState();
  };

  React.useEffect(() => {
    const newOverflow = showCreateMatchModal ? 'hidden' : 'unset';
    document.body.style.overflow = newOverflow;
  }, [showCreateMatchModal]);

  const fetchMatchType = (matchSortType) => {
    switch (matchSortType) {
      case DATE:
        return fetchMatchesByDateOrder;
      case NAME:
        return fetchMatchesByNameOrder;
      default:
        console.log('No match type found');
    }
  };

  const fetchMatches = async ({
    ignoreToken = false,
    nextMatchListSortType = DATE,
    nextMatchListSortOrder = DESC,
    loadMore = false,
  } = {}) => {
    // defaults for fetching matches
    const newSortPayload = {
      fetchMoreMatchesComplete: false,
      matchSortType: nextMatchListSortType,
      matchSortOrder: nextMatchListSortOrder,
      fetchMatchesComplete: false,
    };
    // handle click new sort option
    if (nextMatchListSortType !== matchSortType) {
      newSortPayload.matchList = [];
      newSortPayload.matchListToken = undefined;
      newSortPayload.fetchMoreMatchesComplete = true;
      // handle load more button logic
    } else if (loadMore) {
      newSortPayload.fetchMatchesComplete = true;
    }
    dispatch({
      type: CLEAR_STATE_NEW_SORT,
      payload: newSortPayload,
    });
    const token = ignoreToken ? undefined : matchListToken;
    const fetchMatchMethod = fetchMatchType(nextMatchListSortType);
    const response = await fetchMatchMethod({
      token,
      sortOrder: nextMatchListSortOrder,
    });
    const matches = get(response, 'items');
    const nextToken = get(response, 'nextToken');
    if (!matches) return;
    const setMatchSortPayload = {
      matchListToken: nextToken,
      fetchMatchesComplete: true,
      fetchMoreMatchesComplete: true,
    };
    const nextMatchList = ignoreToken ? matches : [...matchList, ...matches];
    setMatchSortPayload.matchList = nextMatchList;
    dispatch({
      type: SET_MATCH_SORT_STATE,
      payload: setMatchSortPayload,
    });
  };

  const deleteMatch = (matchID) => {
    dispatch({
      type: DELETE_MATCH,
      payload: { matchID },
    });
  };

  return (
    <div className={`your-matches ${className}`}>
      <button onClick={toggleCreateMatchModal}>Create Match</button>
      {showCreateMatchModal && (
        <CreateMatchModal
          toggleCreateMatchModal={toggleCreateMatchModal}
          fetchMatches={fetchMatches}
        />
      )}
      <MatchList
        fetchMatches={fetchMatches}
        matchSortType={matchSortType}
        matchList={matchList}
        fetchMatchesComplete={fetchMatchesComplete}
        fetchMoreMatchesComplete={fetchMoreMatchesComplete}
        deleteMatch={deleteMatch}
        matchListToken={matchListToken}
        matchSortOrder={matchSortOrder}
      />
    </div>
  );
};

const StyledMatchView = styled(MatchView)`
  width: 100%;
  font-size: 2rem;
  line-height: 1.75rem;
  margin: 0 auto 1rem;
  text-align: center;

  @media (min-width: 900px) {
    .button-container {
      margin-top: 5rem;
    }
  }
`;

export default StyledMatchView;
