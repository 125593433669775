import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import LoaderCentered from '../../Components/LoaderCentered';

const Modal = ({ className }) => {
  return ReactDOM.createPortal(
    <div className={`modal-container ${className}`}>
      <div className="modal-overlay"></div>
      <div className="modal">
        <div className="loader-wrapper">
          <LoaderCentered />
        </div>
        <p className="modal-text">Preparing your account information.</p>
        <p className="modal-text">This will only take a moment...</p>
      </div>
    </div>,
    document.body,
  );
};

const StyledModal = styled(Modal)`
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    background: white;
    margin: 0 auto;
    border-radius: 3px;
    width: 20rem;
    padding: 2rem;
    transition: all 0.5s;
    text-align: center;
  }

  .loader-wrapper {
    margin: 3rem 0;
  }

  @media (min-width: 900px) {
    .modal {
      width: 40rem;
    }
  }
`;

export default StyledModal;
