import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { WindowClose } from '../../Helpers/IconHelper';

const Modal = ({
  className,
  confirmAction = () => {},
  confirmLoading = false,
  toggleModal = () => {},
  text = '',
  showModalError = false,
}) => {
  return ReactDOM.createPortal(
    <div className={`modal-container ${className}`}>
      <div className="modal-overlay" onClick={toggleModal}></div>
      <div className="modal">
        <div className="modal-header">
          <p className="modal-text">{text}</p>
          <button className="modal-close-button" onClick={toggleModal}>
            <WindowClose />
          </button>
        </div>
        <div className="modal-button-container">
          <button
            disabled={confirmLoading}
            className="button-yes"
            onClick={confirmAction}
          >
            {confirmLoading ? 'Loading...' : 'Yes'}
          </button>
          <button className="button-no" onClick={toggleModal}>
            No
          </button>
        </div>
        {showModalError && (
          <p className="error-text">There was an error, please try again.</p>
        )}
      </div>
    </div>,
    document.body,
  );
};

const StyledModal = styled(Modal)`
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    background: white;
    margin: 0 auto;
    border-radius: 3px;
    width: 20rem;
    height: 13rem;
    padding: 2rem;
    transition: all 0.5s;
  }

  .error-text {
    text-align: center;
    font-size: 1.2rem;
    color: red;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
  }

  .modal-close-button {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    background-color: white;
    color: steelblue;
    cursor: pointer;
    border: none;
    padding: 0;
    border-radius: 15%;
    border: 0px;
    margin: 0;
    height: 2rem;

    &:hover {
      background-color: steelblue;
      color: white;
    }
  }

  .modal-button-container {
    display: flex;
    justify-content: center;
    margin: 1rem;
  }

  .button-yes,
  .button-no {
    margin: 1rem;
  }

  @media (min-width: 900px) {
    .modal {
      width: 40rem;
    }
  }
`;

export default StyledModal;

const InfoModal = ({ className, toggleModal = () => {}, children }) => {
  return ReactDOM.createPortal(
    <div className={`modal-container ${className}`}>
      <div className="modal-overlay" onClick={toggleModal}></div>
      <div className="modal">
        <div className="modal-header">
          <div className="modal-text">{children}</div>
          <button className="modal-close-button" onClick={toggleModal}>
            <WindowClose />
          </button>
        </div>
      </div>
    </div>,
    document.body,
  );
};

const StyledInfoModal = styled(InfoModal)`
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }

  .modal-text {
    margin: auto;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    background: white;
    margin: 0 auto;
    border-radius: 3px;
    padding: 2rem;
    transition: all 0.5s;
    width: 80%;
    height: 80%;
    overflow: scroll;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
  }

  .modal-close-button {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    background-color: white;
    color: steelblue;
    cursor: pointer;
    border: none;
    padding: 0;
    border-radius: 15%;
    border: 0px;
    margin: 0;
    height: 2rem;

    &:hover {
      background-color: steelblue;
      color: white;
    }
  }
`;

export { StyledInfoModal as InfoModal };
