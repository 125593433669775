import React from 'react';
import styled from 'styled-components';
import { ScoreSheetContext } from '../../../../Contexts/ScoreSheetContext';
import {
  getCellDataPerPlayer,
  getNumberTossUpsHeard,
  getSumPlayerTossUpPoints,
  getSumPlayerUniquePointsScored,
  getSumTotalPointsByType,
  getTeamPlayersFromCellData,
} from '../../../../Helpers/ScoreSheetHelper';
import { get } from '../../../../Helpers/UtilHelper';
import ScoreSheetCell from '../ScoreSheetCell';

const ScoreSheetPlayerSection = ({
  className,
  matchID,
  teamData,
  teamNumber,
  isExportingScoreSheet,
}) => {
  const teamName = get(teamData, 'team.name', null);
  const teamId = get(teamData, 'team.id', null);
  const {
    cellList,
    totalNumberQuestions,
    uniquePointValues,
  } = React.useContext(ScoreSheetContext);
  const teamPlayers = getTeamPlayersFromCellData({ cellList, teamId });

  const HeaderRow = ({
    col1Display = '',
    col2Display = '',
    pointColDisplay = '',
    pointColsHeaderDisplay = '',
    tossUpHeardColDisplay = '',
    pointCountColDisplay = '',
    tossUpPointColDisplay = '',
    subtotalColDisplay = '',
    finalScoreColDisplay = '',
    displayTossUpPoint = false,
    teamNameRow = false,
  }) => {
    let pointCols = [];
    if (pointColsHeaderDisplay) {
      pointCols.push(
        <td
          key="pointColHeader"
          className="black-cell"
          colSpan={totalNumberQuestions}
        >
          {pointColsHeaderDisplay}
        </td>,
      );
    } else if (pointColDisplay === '') {
      for (let i = 0; i < totalNumberQuestions; i++) {
        pointCols.push(<td key={i}>{pointColDisplay}</td>);
      }
    }

    return (
      <tr className={className}>
        <td>{col1Display}</td>
        <td className={`${teamNameRow ? 'blue-cell' : 'black-cell'}`}>
          {col2Display}
        </td>
        {pointCols}
        <td className="black-cell">{tossUpHeardColDisplay}</td>
        {uniquePointValues.map((value, index) => {
          if (displayTossUpPoint) {
            return <td key={index} className="black-cell">{`${value}s`}</td>;
          } else {
            return (
              <td key={index} className="black-cell">
                {pointCountColDisplay}
              </td>
            );
          }
        })}
        <td className="black-cell">{tossUpPointColDisplay}</td>
        <td className="black-cell">{subtotalColDisplay}</td>
        <td className="black-cell">{finalScoreColDisplay}</td>
      </tr>
    );
  };

  const TossUpRow = ({ playerName = '', cellList, teamId, index }) => {
    // for each question, determine if the user answered the question. if so, display the points. Otherwise, display -
    const scoresByPlayer = getCellDataPerPlayer({
      cellList,
      playerName,
      teamId,
    });
    let tossUpPointsDisplay = scoresByPlayer.map((score, index) => {
      return (
        <ScoreSheetCell
          cellId={score.cellId}
          points={score.points.toString()}
          key={index}
          matchID={matchID}
          type={'playerTossUp'}
          isExportingScoreSheet={isExportingScoreSheet}
        />
      );
    });

    const numberTossUpsHeard = getNumberTossUpsHeard({
      cellList,
      playerName,
      teamId,
    });

    const playerTossUpPoints = getSumPlayerTossUpPoints({
      cellList,
      playerName,
      teamId,
    });

    const subTotalTossUpPoints = getSumTotalPointsByType({
      cellList,
      teamId,
      type: 'playerTossUp',
    });

    return (
      <tr className={className}>
        <td className="black-cell">{`Player ${index + 1}`}</td>
        <td className="blue-cell">{playerName}</td>
        {tossUpPointsDisplay}
        <td className="blue-cell">{numberTossUpsHeard}</td>
        {uniquePointValues.map((value, index) => {
          const sum = getSumPlayerUniquePointsScored({
            playerName,
            pointValue: value,
            cellList,
            teamId,
          });
          return (
            <td key={index} className="blue-cell">
              {sum}
            </td>
          );
        })}
        <td className="blue-cell">{playerTossUpPoints}</td>
        {index === 0 ? (
          <td className="blue-cell">{subTotalTossUpPoints}</td>
        ) : (
          <td></td>
        )}
        <td></td>
      </tr>
    );
  };

  return (
    <>
      <HeaderRow
        col2Display={`Team ${teamNumber}`}
        tossUpHeardColDisplay="Player"
        pointCountColDisplay="Number"
        tossUpPointColDisplay="Player"
      />
      <HeaderRow
        col2Display={teamName}
        tossUpHeardColDisplay="Toss-Ups"
        pointCountColDisplay="Of"
        tossUpPointColDisplay="Toss-Up"
        finalScoreColDisplay="Total"
        teamNameRow={true}
      />
      <HeaderRow
        col2Display="Players"
        pointColsHeaderDisplay="Scores by Question"
        tossUpHeardColDisplay="Heard"
        displayTossUpPoint={true}
        tossUpPointColDisplay="Points"
        finalScoreColDisplay="Score"
        subtotalColDisplay="SubTotals"
      />
      {teamPlayers.map((playerName, index) => {
        return (
          <TossUpRow
            playerName={playerName}
            teamId={teamId}
            index={index}
            cellList={cellList}
            key={index}
          />
        );
      })}
    </>
  );
};

const StyledScoreSheetPlayerSection = styled(ScoreSheetPlayerSection)`
  .blue-cell {
    background-color: #2f75b5;
    color: #fff;
  }
  .black-cell {
    background-color: #000;
    color: #fff;
  }
  && {
    margin: 1rem;
  }
`;

export default StyledScoreSheetPlayerSection;
